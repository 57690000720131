import { getAuth } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';
import { db } from './firebase';

export async function verifyAdmin(): Promise<boolean> {
  const auth = getAuth();
  const user = auth.currentUser;
  
  if (!user) {
    console.error('verifyAdmin: Utente non autenticato');
    throw new Error('Utente non autenticato');
  }

  console.log('verifyAdmin: Verifica per utente:', user.uid);
  
  try {
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    console.log('verifyAdmin: Documento utente:', userDoc.exists() ? 'trovato' : 'non trovato');
    
    if (!userDoc.exists()) {
      console.error('verifyAdmin: Documento utente non trovato');
      throw new Error('Utente non trovato');
    }

    const userData = userDoc.data();
    console.log('verifyAdmin: Dati utente:', {
      userType: userData.userType,
      isAdmin: userData.isAdmin,
      docId: userDoc.id
    });
    
    const isAdmin = userData.userType === 'admin' || userData.isAdmin === true;
    console.log('verifyAdmin: Risultato verifica:', isAdmin);
    
    return isAdmin;
  } catch (error) {
    console.error('verifyAdmin: Errore durante la verifica:', error);
    throw error;
  }
}

export async function requireAdmin(): Promise<void> {
  const isAdmin = await verifyAdmin();
  if (!isAdmin) {
    throw new Error('Permessi insufficienti');
  }
}
import React, { useState, useEffect } from 'react';
import { User, Mail, Phone, Building2, MessageSquare, FileText, MapPin, HelpCircle, X } from 'lucide-react';
import { useAuthContext } from '../../contexts/AuthContext';
import { updateUserProfile } from '../../lib/auth';
import { toast } from 'react-toastify';
import { DeleteAccountModal } from './DeleteAccountModal';
import { SupportDashboard } from '../support/SupportDashboard';
import { PaymentsHistory } from './PaymentsHistory';
import { ProfilePlacesAutocomplete } from './ProfilePlacesAutocomplete';
import { Switch } from '@headlessui/react';
import { countries } from '../../utils/countries';
import { ProfileCompletion } from './ProfileCompletion';
import { Tooltip } from '../ui/Tooltip';

const countryNameToCode: { [key: string]: string } = {
  'Italia': 'IT',
  'Germania': 'DE',
  'Francia': 'FR',
  'Spagna': 'ES',
  // ... altri paesi ...
};

const countryCodeToName: { [key: string]: string } = {
  'IT': 'Italia',
  'DE': 'Germania',
  'FR': 'Francia',
  'ES': 'Spagna',
  // ... altri paesi ...
};

export function ProfileSettings() {
  const { userProfile } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: userProfile?.firstName || '',
    lastName: userProfile?.lastName || '',
    email: userProfile?.email || '',
    phoneNumber: userProfile?.phoneNumber || '',
    requiresInvoice: userProfile?.requiresInvoice || false,
    billingAddress: userProfile?.billingAddress || {
      street: '',
      streetNumber: '',
      city: '',
      zipCode: '',
      province: '',
      country: 'Italia',
      formattedAddress: '',
      lat: 0,
      lng: 0
    },
    fiscalType: userProfile?.fiscalType || 'cf',
    fiscalCode: userProfile?.fiscalCode || '',
    vatNumber: userProfile?.vatNumber || '',
    sdiCode: userProfile?.sdiCode || '',
    businessName: userProfile?.businessName || ''
  });
  const [selectedCountry, setSelectedCountry] = useState('');

  const addressMapping: Record<string, { [key: string]: string[] }> = {
    'IT': {
      route: ['route'],
      city: ['locality', 'postal_town'],
      province: ['administrative_area_level_2'],
      zipCode: ['postal_code']
    },
    'FR': {
      route: ['route'],
      city: ['locality', 'postal_town'],
      province: ['administrative_area_level_1'],
      zipCode: ['postal_code']
    },
    // ... altri paesi ...
  };

  useEffect(() => {
    if (userProfile?.billingAddress?.country) {
      const countryCode = countryNameToCode[userProfile.billingAddress.country];
      setSelectedCountry(countryCode);
      
      setFormData(prev => ({
        ...prev,
        ...userProfile,
        billingAddress: {
          ...prev.billingAddress,
          ...userProfile.billingAddress
        }
      }));
    }
  }, [userProfile]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    if (name === 'country') {
      setSelectedCountry(value);
      setFormData(prev => ({
        ...prev,
        billingAddress: {
          ...prev.billingAddress,
          country: value,
          street: '',
          city: '',
          zipCode: '',
          province: '',
          formattedAddress: ''
        }
      }));
    } else if (name.startsWith('billingAddress.')) {
      const field = name.split('.')[1];
      setFormData(prev => ({
        ...prev,
        billingAddress: {
          ...prev.billingAddress,
          [field]: value
        }
      }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handlePlaceSelect = async (place: google.maps.places.PlaceResult) => {
    if (!place.formatted_address || !place.geometry?.location) {
      toast.error('Seleziona un indirizzo valido dalla lista');
      return;
    }

    try {
      const mapping = {
        route: ['route'],
        city: ['locality', 'administrative_area_level_3'],
        province: ['administrative_area_level_1'],
        country: ['country']
      };

      const postalCode = place.address_components?.find(component => 
        component.types.includes('postal_code')
      )?.short_name || '';

      const findAddressComponent = (types: string[]) => {
        const component = place.address_components?.find(component => 
          types.some(type => component.types.includes(type))
        );
        return component?.long_name || '';
      };

      const newBillingAddress = {
        ...formData.billingAddress,
        street: findAddressComponent(mapping.route),
        city: findAddressComponent(mapping.city),
        province: findAddressComponent(mapping.province),
        zipCode: postalCode,
        country: selectedCountry,
        formattedAddress: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };

      console.log('New billing address:', newBillingAddress);

      setFormData(prev => ({
        ...prev,
        billingAddress: newBillingAddress
      }));

    } catch (error) {
      console.error('Error getting place details:', error);
      toast.error('Errore nel recupero dei dettagli dell\'indirizzo');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!userProfile?.id) return;

    // Validazione del codice postale
    const postalPattern = getPostalCodePattern(selectedCountry);
    if (postalPattern && !new RegExp(postalPattern).test(formData.billingAddress.zipCode)) {
      toast.error(`${getPostalCodeLabel(selectedCountry)} non valido`);
      return;
    }

    // Validazione
    if (!formData.firstName || !formData.lastName) {
      toast.error('Nome e cognome sono obbligatori');
      return;
    }

    if (!formData.billingAddress.formattedAddress) {
      toast.error('Indirizzo di fatturazione obbligatorio');
      return;
    }

    if (formData.fiscalType === 'cf' && !formData.fiscalCode) {
      toast.error('Codice fiscale obbligatorio');
      return;
    }

    if (formData.fiscalType === 'piva' && (!formData.vatNumber || !formData.sdiCode || !formData.businessName)) {
      toast.error('P.IVA, codice SDI e ragione sociale sono obbligatori');
      return;
    }

    setLoading(true);
    try {
      const profileData = {
        ...formData,
        billingAddress: {
          ...formData.billingAddress,
          country: countryCodeToName[selectedCountry]
        }
      };

      await updateUserProfile(userProfile.id, profileData);
      toast.success('Profilo aggiornato con successo');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Errore durante l\'aggiornamento del profilo');
    } finally {
      setLoading(false);
    }
  };

  const handleFiscalTypeChange = (isChecked: boolean) => {
    setFormData(prev => ({
      ...prev,
      fiscalType: isChecked ? 'piva' : 'cf'
    }));
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const code = e.target.value;
    const countryName = countryCodeToName[code];
    setSelectedCountry(code);
    
    setFormData(prev => ({
      ...prev,
      billingAddress: {
        ...prev.billingAddress,
        country: countryName,
        street: '',
        city: '',
        zipCode: '',
        province: '',
        formattedAddress: ''
      }
    }));
  };

  const isValidCountry = selectedCountry && selectedCountry.trim() !== '';

  const getPostalCodeLabel = (countryCode: string) => {
    const postalLabels: Record<string, string> = {
      'IT': 'CAP',
      'FR': 'Codice Postale Francia',
      'DE': 'Codice Postale Germania',
      'ES': 'Codice Postale Spagna',
      'GB': 'Codice Postale Regno Unito',
      'US': 'Codice Postale Stati Uniti',
      'AT': 'Codice Postale Austria',
      'BE': 'Codice Postale Belgio',
      'BG': 'Codice Postale Bulgaria',
      'HR': 'Codice Postale Croazia',
      'DK': 'Codice Postale Danimarca',
      'EE': 'Codice Postale Estonia',
      'FI': 'Codice Postale Finlandia',
      'GR': 'Codice Postale Grecia',
      'IE': 'Codice Postale Irlanda',
      'LV': 'Codice Postale Lettonia',
      'LT': 'Codice Postale Lituania',
      'LU': 'Codice Postale Lussemburgo',
      'MT': 'Codice Postale Malta',
      'NL': 'Codice Postale Paesi Bassi',
      'PL': 'Codice Postale Polonia',
      'PT': 'Codice Postale Portogallo',
      'CZ': 'Codice Postale Repubblica Ceca',
      'RO': 'Codice Postale Romania',
      'SK': 'Codice Postale Slovacchia',
      'SI': 'Codice Postale Slovenia',
      'SE': 'Codice Postale Svezia',
      'HU': 'Codice Postale Ungheria',
      'CY': 'Codice Postale Cipro'
    };
    return postalLabels[countryCode] || 'Codice Postale';
  };

  const getPostalCodePattern = (countryCode: string) => {
    const patterns: Record<string, string> = {
      'IT': '^[0-9]{5}$',
      'US': '^[0-9]{5}(-[0-9]{4})?$',
      'GB': '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
      'DE': '^[0-9]{5}$',
      'FR': '^[0-9]{5}$'
    };
    return patterns[countryCode] || '';
  };

  const getProvinceLabel = (countryCode: string) => {
    const provinceLabels: Record<string, string> = {
      'IT': 'Provincia',
      'FR': 'Dipartimento',
      'DE': 'Stato Federale',
      'ES': 'Provincia',
      'GB': 'Contea',
      'US': 'Stato',
      'AT': 'Stato Federale',
      'BE': 'Provincia',
      'BG': 'Provincia',
      'HR': 'Regione',
      'DK': 'Regione',
      'EE': 'Contea',
      'FI': 'Regione',
      'GR': 'Regione',
      'IE': 'Contea',
      'LV': 'Regione',
      'LT': 'Contea',
      'LU': 'Distretto',
      'MT': 'Regione',
      'NL': 'Provincia',
      'PL': 'Voivodato',
      'PT': 'Distretto',
      'CZ': 'Regione',
      'RO': 'Distretto',
      'SK': 'Regione',
      'SI': 'Regione',
      'SE': 'Contea',
      'HU': 'Contea',
      'CY': 'Distretto'
    };
    return provinceLabels[countryCode] || 'Provincia/Stato';
  };

  return (
    <div className="space-y-8">
      {/* Header Card */}
      <div className="bg-gradient-to-br from-blue-500 to-blue-600 rounded-xl sm:rounded-2xl p-4 sm:p-8 text-white shadow-lg">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start gap-4">
          <div className="flex flex-col">
            <h2 className="text-lg sm:text-xl font-semibold text-white/90">Il tuo profilo</h2>
            <p className="text-xs sm:text-sm text-white/80 mt-1">Gestisci le tue informazioni personali</p>
            <div className="flex items-center gap-3 mt-4">
              <User className="h-8 w-8 sm:h-12 sm:w-12 text-white/90" />
              <div>
                <span className="text-lg sm:text-2xl font-bold truncate">{`${formData.firstName} ${formData.lastName}`}</span>
                <p className="text-xs sm:text-sm text-white/80 truncate">{formData.email}</p>
              </div>
            </div>
          </div>

          {/* Pulsante assistenza */}
          <button
            onClick={() => setShowSupportModal(true)}
            className="flex items-center gap-2 px-3 py-1.5 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-colors text-sm border border-white/20 w-full sm:w-auto justify-center sm:justify-start"
          >
            <MessageSquare className="h-4 w-4" />
            <span>Assistenza</span>
          </button>
        </div>
      </div>

      {/* Barra di Progressione */}
      <ProfileCompletion formData={formData} />

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Informazioni Personali */}
        <div className="bg-white rounded-2xl p-4 md:p-8 border border-gray-100">
          <div className="flex items-center gap-3 mb-6">
            <div className="p-2 bg-blue-100 rounded-lg">
              <User className="h-5 w-5 text-blue-600" />
            </div>
            <h3 className="text-xl font-semibold text-gray-900">Informazioni Personali</h3>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Nome</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Cognome</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
                className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div>
              <div className="flex items-center gap-1 mb-2">
                <label className="text-sm font-medium text-gray-700">Email</label>
                <Tooltip content="Per modificare l'email contatta l'assistenza">
                  <HelpCircle className="h-4 w-4 text-gray-400 cursor-help" />
                </Tooltip>
              </div>
              <input
                type="email"
                name="email"
                value={formData.email}
                disabled
                className="h-10 w-full px-4 rounded-lg border border-gray-300 bg-gray-50 cursor-not-allowed"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Numero di Telefono</label>
              <input
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
                className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
        </div>

        {/* Indirizzo di Fatturazione */}
        <div className="bg-white rounded-2xl p-4 md:p-8 border border-gray-100">
          <div className="flex items-center gap-3 mb-6">
            <div className="p-2 bg-red-100 rounded-lg">
              <MapPin className="h-5 w-5 text-red-600" />
            </div>
            <h3 className="text-xl font-semibold text-gray-900">Indirizzo di Fatturazione</h3>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Stato
              </label>
              <select
                name="billingAddress.country"
                value={selectedCountry || ''}
                onChange={handleCountryChange}
                className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="">Seleziona uno stato</option>
                {countries.map(country => (
                  <option 
                    key={country.code} 
                    value={country.code}
                    selected={country.code === selectedCountry}
                  >
                    {country.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Indirizzo completo
              </label>
              <ProfilePlacesAutocomplete
                onPlaceSelect={handlePlaceSelect}
                placeholder="Inserisci l'indirizzo"
                className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                required
                countryCode={selectedCountry}
                defaultValue={formData.billingAddress?.formattedAddress || ''}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {getPostalCodeLabel(selectedCountry)}
              </label>
              <input
                type="text"
                name="billingAddress.zipCode"
                value={formData.billingAddress.zipCode}
                className="h-10 w-full px-4 rounded-lg border border-gray-300 bg-gray-50"
                required
                disabled={true}
                readOnly
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Città
              </label>
              <input
                type="text"
                name="billingAddress.city"
                value={formData.billingAddress.city}
                className="h-10 w-full px-4 rounded-lg border border-gray-300 bg-gray-50"
                required
                disabled={true}
                readOnly
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {getProvinceLabel(selectedCountry)}
              </label>
              <input
                type="text"
                name="billingAddress.province"
                value={formData.billingAddress.province}
                className="h-10 w-full px-4 rounded-lg border border-gray-300 bg-gray-50"
                required
                disabled={true}
                readOnly
              />
            </div>
          </div>
        </div>

        {/* Informazioni Fiscali */}
        <div className="bg-white rounded-2xl p-4 md:p-8 border border-gray-100">
          <div className="flex items-center gap-3 mb-6">
            <div className="p-2 bg-green-100 rounded-lg">
              <FileText className="h-5 w-5 text-green-600" />
            </div>
            <h3 className="text-xl font-semibold text-gray-900">Informazioni Fiscali</h3>
          </div>

          <div className="space-y-6">
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium text-gray-700">
                {formData.fiscalType === 'cf' ? 'Persona Fisica' : 'Azienda'}
              </span>
              <Switch.Group>
                <div className="flex items-center">
                  <Switch.Label className="mr-3 text-sm text-gray-600">
                    {formData.fiscalType === 'cf' ? 'Passa ad Azienda' : 'Passa a Persona fisica'}
                  </Switch.Label>
                  <Switch
                    checked={formData.fiscalType === 'piva'}
                    onChange={handleFiscalTypeChange}
                    className={`${
                      formData.fiscalType === 'piva' ? 'bg-blue-600' : 'bg-gray-200'
                    } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                  >
                    <span
                      className={`${
                        formData.fiscalType === 'piva' ? 'translate-x-6' : 'translate-x-1'
                      } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                    />
                  </Switch>
                </div>
              </Switch.Group>
            </div>

            {formData.fiscalType === 'cf' ? (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Codice Fiscale</label>
                <input
                  type="text"
                  name="fiscalCode"
                  value={formData.fiscalCode}
                  onChange={handleChange}
                  required
                  className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            ) : (
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Ragione Sociale</label>
                  <input
                    type="text"
                    name="businessName"
                    value={formData.businessName}
                    onChange={handleChange}
                    required
                    className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Partita IVA</label>
                  <input
                    type="text"
                    name="vatNumber"
                    value={formData.vatNumber}
                    onChange={handleChange}
                    required
                    className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Codice SDI</label>
                  <input
                    type="text"
                    name="sdiCode"
                    value={formData.sdiCode}
                    onChange={handleChange}
                    required
                    className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="bg-white rounded-lg p-4 sm:p-6 border border-gray-100">
          <PaymentsHistory />
        </div>

        <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
          <button
            type="button"
            onClick={() => setShowDeleteModal(true)}
            className="w-full sm:w-auto text-red-600 hover:text-red-700 font-medium text-sm sm:text-base"
          >
            Elimina Account
          </button>

          <button
            type="submit"
            disabled={loading}
            className="w-full sm:w-auto px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 text-sm sm:text-base font-medium"
          >
            {loading ? 'Salvataggio...' : 'Salva Modifiche'}
          </button>
        </div>
      </form>

      {showDeleteModal && (
        <DeleteAccountModal onClose={() => setShowDeleteModal(false)} />
      )}

      {showSupportModal && (
        <div className="fixed inset-0 z-50">
          <div className="fixed inset-0 bg-black/50 backdrop-blur-sm" onClick={() => setShowSupportModal(false)} />
          <div className="fixed inset-0 sm:inset-6 flex items-start sm:items-center justify-center sm:p-4">
            <div className="bg-white w-full h-[100dvh] sm:h-[90vh] sm:max-w-4xl sm:rounded-lg shadow-xl flex flex-col overflow-hidden">
              <div className="flex-1 overflow-hidden">
                <SupportDashboard onBack={() => setShowSupportModal(false)} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
import React, { useState } from 'react';
import { X, AlertTriangle } from 'lucide-react';
import { deleteUserAccount, reauthenticateUser } from '../../lib/auth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../lib/firebase';

interface DeleteAccountModalProps {
  onClose: () => void;
  requiresPassword: boolean;
}

export function DeleteAccountModal({ onClose, requiresPassword }: DeleteAccountModalProps) {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState('');
  const navigate = useNavigate();

  const handleDelete = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (confirmation !== 'ELIMINA') {
      toast.error('Inserisci la conferma come richiesto');
      return;
    }

    if (!auth.currentUser) {
      toast.error('Devi essere autenticato per eliminare l\'account');
      return;
    }

    setLoading(true);
    try {
      if (requiresPassword) {
        await reauthenticateUser(password);
      }
      
      await deleteUserAccount(requiresPassword ? password : undefined);
      
      toast.success('Account eliminato con successo');
      navigate('/');
    } catch (error: any) {
      console.error('Errore dettagliato durante l\'eliminazione:', error);
      
      if (error.code === 'auth/requires-recent-login') {
        toast.error('Per motivi di sicurezza, effettua nuovamente il login prima di eliminare l\'account');
      } else if (error.code === 'permission-denied') {
        toast.error('Non hai i permessi necessari per eliminare l\'account. Contatta il supporto.');
      } else {
        toast.error(error.message || 'Errore durante l\'eliminazione dell\'account');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ margin: 0 }} className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-start sm:items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-md w-full">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold text-red-600">Elimina Account</h2>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-6 w-6" />
            </button>
          </div>

          <div className="bg-red-50 p-4 rounded-lg mb-6">
            <div className="flex items-center">
              <AlertTriangle className="h-5 w-5 text-red-600 mr-2" />
              <p className="text-sm text-red-600">
                Questa azione è irreversibile. Tutti i tuoi dati verranno eliminati permanentemente.
              </p>
            </div>
          </div>

          <form onSubmit={handleDelete} className="space-y-4">
            {requiresPassword && (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
                />
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Digita ELIMINA per confermare
              </label>
              <input
                type="text"
                value={confirmation}
                onChange={(e) => setConfirmation(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
              />
            </div>

            <div className="flex gap-4 pt-4">
              <button
                type="button"
                onClick={onClose}
                className="flex-1 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                Annulla
              </button>
              <button
                type="submit"
                disabled={loading || confirmation !== 'ELIMINA'}
                className="flex-1 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
              >
                {loading ? 'Eliminazione...' : 'Elimina Account'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
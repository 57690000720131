import React from 'react';
import { Link } from 'react-router-dom';

export function Footer() {
  return (
    <footer className="bg-white border-t mt-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/app-unistanza-49b40.firebasestorage.app/o/Logo-2025.svg?alt=media&token=96c7878b-0941-46a5-a9ae-55bc626e31af" 
              alt="Unistanza Logo" 
              className="h-8 mb-4"
            />
            <p className="mt-4 text-base text-gray-500">
              Unistanza è la piattaforma che semplifica la ricerca di alloggi per studenti universitari.
            </p>
          </div>
          <div>
            <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">Link Utili</h3>
            <ul className="mt-4 space-y-4">
              <li>
                <Link to="/come-funziona" className="text-base text-gray-500 hover:text-gray-900">
                  Come Funziona
                </Link>
              </li>
              <li>
                <Link to="/faq" className="text-base text-gray-500 hover:text-gray-900">
                  FAQ
                </Link>
              </li>
              <li>
                <Link to="/support" className="text-base text-gray-500 hover:text-gray-900">
                  Supporto
                </Link>
              </li>
              <li>
                <Link to="/blog" className="text-base text-gray-500 hover:text-gray-900">
                  Blog
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">Legale</h3>
            <ul className="mt-4 space-y-4">
              <li>
                <a 
                  href="https://www.iubenda.com/privacy-policy/33504273" 
                  className="text-base text-gray-500 hover:text-gray-900"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a 
                  href="https://www.iubenda.com/privacy-policy/33504273/cookie-policy" 
                  className="text-base text-gray-500 hover:text-gray-900"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cookie Policy
                </a>
              </li>
              <li>
                <a 
                  href="https://www.iubenda.com/termini-e-condizioni/33504273" 
                  className="text-base text-gray-500 hover:text-gray-900"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Termini e Condizioni
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-8 border-t pt-8">
          <p className="text-base text-gray-400 text-center">
            © {new Date().getFullYear()} Unistanza. Tutti i diritti riservati.
          </p>
        </div>
      </div>
    </footer>
  );
}
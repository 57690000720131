import React from 'react';
import { featuresLabels, kitchenLabels, bathroomLabels } from '../../../types/listing';
import { Home, ChefHat, Bath } from 'lucide-react';

interface FeaturesStepProps {
  formData: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function FeaturesStep({ formData, onChange }: FeaturesStepProps) {
  return (
    <div className="space-y-8">
      {/* General Features */}
      <div className="bg-gradient-to-br from-blue-500 to-blue-600 rounded-2xl p-4 md:p-8 text-white shadow-xl">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-white/10 rounded-lg backdrop-blur-sm">
            <Home className="h-5 w-5 text-white" />
          </div>
          <h3 className="text-xl font-semibold text-white/90">Caratteristiche Generali</h3>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {Object.entries(featuresLabels).map(([key, label]) => (
            <label key={key} className="relative flex items-center p-4 rounded-lg border border-white/20 hover:border-white/40 cursor-pointer transition-colors backdrop-blur-sm">
              <input
                type="checkbox"
                name={`features.${key}`}
                checked={formData.features[key] || false}
                onChange={onChange}
                className="h-4 w-4 text-blue-600 rounded border-white/40 bg-white/10 focus:ring-offset-blue-600 focus:ring-2"
              />
              <span className="ml-3 text-sm text-white">{label}</span>
            </label>
          ))}
        </div>
      </div>

      {/* Kitchen Features */}
      <div className="bg-white rounded-2xl p-4 md:p-8 shadow-xl border border-gray-100">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-orange-100 rounded-lg">
            <ChefHat className="h-5 w-5 text-orange-600" />
          </div>
          <h3 className="text-xl font-semibold text-gray-900">Caratteristiche Cucina</h3>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {Object.entries(kitchenLabels).map(([key, label]) => (
            <label key={key} className="relative flex items-center p-4 rounded-lg border border-gray-200 hover:border-blue-500 cursor-pointer transition-colors">
              <input
                type="checkbox"
                name={`kitchenFeatures.${key}`}
                checked={formData.kitchenFeatures[key] || false}
                onChange={onChange}
                className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
              />
              <span className="ml-3 text-sm text-gray-700">{label}</span>
            </label>
          ))}
        </div>
      </div>

      {/* Bathroom Features */}
      <div className="bg-white rounded-2xl p-4 md:p-8 shadow-xl border border-gray-100">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-blue-100 rounded-lg">
            <Bath className="h-5 w-5 text-blue-600" />
          </div>
          <h3 className="text-xl font-semibold text-gray-900">Caratteristiche Bagno</h3>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {Object.entries(bathroomLabels).map(([key, label]) => (
            <label key={key} className="relative flex items-center p-4 rounded-lg border border-gray-200 hover:border-blue-500 cursor-pointer transition-colors">
              <input
                type="checkbox"
                name={`bathroomFeatures.${key}`}
                checked={formData.bathroomFeatures[key] || false}
                onChange={onChange}
                className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
              />
              <span className="ml-3 text-sm text-gray-700">{label}</span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
}
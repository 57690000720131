import React, { useState, useEffect } from 'react';
import { 
  format, 
  addDays, 
  subDays, 
  startOfWeek, 
  isBefore, 
  isToday, 
  isAfter, 
  set, 
  addMinutes, 
  endOfWeek,
  parse,
  isSameDay,
  eachDayOfInterval
} from 'date-fns';
import { it } from 'date-fns/locale';
import { Clock, ChevronLeft, ChevronRight, X } from 'lucide-react';
import { toast } from 'react-toastify';
import { updateMeeting, isSlotAvailable } from '../../lib/meetings';
import { getAvailableSlots, getLandlordAvailability } from '../../lib/availability';
import { DayAvailability } from '../../types/availability';
import { Meeting } from '../../types/meeting';
import { Timestamp, serverTimestamp } from 'firebase/firestore';
import { createMeeting } from '../../lib/daily';

interface RescheduleMeetingModalProps {
  meetingId: string;
  existingMeeting: Meeting;
  onClose: () => void;
  onSuccess?: () => void;
  isStudent?: boolean;
  landlordId?: string;
}

export function RescheduleMeetingModal({ 
  meetingId, 
  existingMeeting, 
  onClose,
  onSuccess,
  isStudent = false,
  landlordId: landlordIdProp
}: RescheduleMeetingModalProps) {
  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState(() => {
    try {
      if (existingMeeting.date instanceof Timestamp) {
        return existingMeeting.date.toDate();
      }
      if (typeof existingMeeting.date === 'string') {
        return new Date(existingMeeting.date);
      }
      return existingMeeting.date;
    } catch (error) {
      console.error('Error parsing meeting date:', error);
      return new Date();
    }
  });

  const [availability, setAvailability] = useState<DayAvailability[]>([]);
  const [selectedDate, setSelectedDate] = useState<string | null>(() => {
    try {
      const date = existingMeeting.date instanceof Timestamp 
        ? existingMeeting.date.toDate() 
        : new Date(existingMeeting.date);
      return format(date, 'yyyy-MM-dd');
    } catch (error) {
      console.error('Error formatting selected date:', error);
      return format(new Date(), 'yyyy-MM-dd');
    }
  });

  const [selectedSlot, setSelectedSlot] = useState<string | null>(() => {
    try {
      if (existingMeeting.time instanceof Timestamp) {
        return format(existingMeeting.time.toDate(), 'HH:mm');
      }
      if (typeof existingMeeting.time === 'string') {
        return existingMeeting.time;
      }
      return format(new Date(existingMeeting.time), 'HH:mm');
    } catch (error) {
      console.error('Error formatting selected time:', error);
      return null;
    }
  });

  const dayNameMap: { [key: string]: string } = {
    'lunedì': 'monday',
    'martedì': 'tuesday',
    'mercoledì': 'wednesday',
    'giovedì': 'thursday',
    'venerdì': 'friday',
    'sabato': 'saturday',
    'domenica': 'sunday'
  };

  useEffect(() => {
    const loadExistingMeeting = async () => {
      if (!existingMeeting) return;
      
      try {
        // Gestione della data
        let meetingDate: Date;
        if (existingMeeting.date instanceof Timestamp) {
          meetingDate = existingMeeting.date.toDate();
        } else if (typeof existingMeeting.date === 'string') {
          meetingDate = new Date(existingMeeting.date);
        } else {
          meetingDate = new Date(existingMeeting.date);
        }

        // Se la data del meeting è passata
        if (isBefore(meetingDate, new Date())) {
          const today = new Date();
          setCurrentDate(today);
          
          // Carica le disponibilità per trovare il primo slot disponibile
          const landlordId = existingMeeting?.landlordId || landlordIdProp;
          if (landlordId) {
            const landlordAvailability = await getLandlordAvailability(landlordId);
            if (landlordAvailability?.weeklySchedule) {
              const currentWeekStart = startOfWeek(today, { weekStartsOn: 1 });
              const endDate = addDays(currentWeekStart, 6);
              
              // Trova il primo giorno con slot disponibili
              const availableDays = eachDayOfInterval({ start: today, end: endDate })
                .map(date => {
                  const dayName = format(date, 'EEEE', { locale: it }).toLowerCase();
                  const englishDayName = dayNameMap[dayName];
                  return {
                    date,
                    slots: landlordAvailability.weeklySchedule[englishDayName] || []
                  };
                })
                .filter(day => day.slots.length > 0);

              if (availableDays.length > 0) {
                const firstAvailableDay = availableDays[0];
                setSelectedDate(format(firstAvailableDay.date, 'yyyy-MM-dd'));
                if (firstAvailableDay.slots[0]) {
                  setSelectedSlot(firstAvailableDay.slots[0].startTime);
                }
              }
            }
          }
        } else {
          setCurrentDate(meetingDate);
          setSelectedDate(format(meetingDate, 'yyyy-MM-dd'));
          
          if (existingMeeting.time instanceof Timestamp) {
            const timeDate = existingMeeting.time.toDate();
            if (!isNaN(timeDate.getTime())) {
              setSelectedSlot(format(timeDate, 'HH:mm'));
            }
          } else if (typeof existingMeeting.time === 'string') {
            setSelectedSlot(existingMeeting.time);
          }
        }
      } catch (error) {
        console.error('Errore nel caricamento del meeting:', error);
      }
    };

    loadExistingMeeting();
  }, [existingMeeting, landlordIdProp]);

  useEffect(() => {
    loadAvailability();
  }, [currentDate, existingMeeting.landlordId]);

  const loadAvailability = async () => {
    const landlordId = existingMeeting?.landlordId || landlordIdProp;

    if (!landlordId) {
      console.error('Missing landlordId:', landlordId);
      return;
    }

    try {
      setLoading(true);

      // Get landlord availability settings
      const landlordAvailability = await getLandlordAvailability(landlordId);
      
      if (!landlordAvailability || !landlordAvailability.weeklySchedule) {
        setAvailability([]);
        setLoading(false);
        return;
      }

      const today = new Date();
      const currentWeekStart = startOfWeek(currentDate, { weekStartsOn: 1 });
      const endDate = addDays(currentWeekStart, 6);
      const startDate = isBefore(currentWeekStart, today) ? today : currentWeekStart;

      // Assicuriamoci che la data di fine non sia precedente alla data di inizio
      const adjustedEndDate = isBefore(endDate, startDate) ? startDate : endDate;

      const availabilityWithDays = eachDayOfInterval({ 
        start: startDate, 
        end: adjustedEndDate 
      }).map(date => {
        const italianDayName = format(date, 'EEEE', { locale: it }).toLowerCase();
        const englishDayName = dayNameMap[italianDayName];
        
        const daySlots = landlordAvailability.weeklySchedule[englishDayName] || [];

        // Generate slots for this day
        const availableSlots = daySlots.flatMap(timeSlot => {
          const slots = [];
          const start = parse(timeSlot.startTime, 'HH:mm', date);
          const end = parse(timeSlot.endTime, 'HH:mm', date);
          
          let current = start;
          while (isBefore(current, end)) {
            // Se è oggi, controlla se lo slot è già passato
            if (isToday(date)) {
              const now = new Date();
              const slotTime = set(date, {
                hours: current.getHours(),
                minutes: current.getMinutes(),
                seconds: 0,
                milliseconds: 0
              });

              // Aggiungi solo gli slot futuri (con 5 minuti di margine)
              if (isAfter(slotTime, addMinutes(now, 5))) {
                slots.push({
                  id: `${format(date, 'yyyy-MM-dd')}-${format(current, 'HH:mm')}`,
                  startTime: format(current, 'HH:mm'),
                  endTime: format(
                    addMinutes(current, landlordAvailability.meetingDuration),
                    'HH:mm'
                  )
                });
              }
            } else {
              slots.push({
                id: `${format(date, 'yyyy-MM-dd')}-${format(current, 'HH:mm')}`,
                startTime: format(current, 'HH:mm'),
                endTime: format(
                  addMinutes(current, landlordAvailability.meetingDuration),
                  'HH:mm'
                )
              });
            }
            current = addMinutes(
              current,
              landlordAvailability.meetingDuration + landlordAvailability.breakDuration
            );
          }
          return slots;
        });

        return {
          date: format(date, 'yyyy-MM-dd'),
          dayName: format(date, 'EEE', { locale: it }),
          dayNumber: format(date, 'd'),
          slots: availableSlots.sort((a, b) => 
            parse(a.startTime, 'HH:mm', new Date()).getTime() - 
            parse(b.startTime, 'HH:mm', new Date()).getTime()
          )
        };
      });

      setAvailability(availabilityWithDays);
    } catch (error) {
      console.error('Error loading availability:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePrevWeek = () => {
    const today = new Date();
    const prevWeek = subDays(currentDate, 7);
    
    if (isBefore(endOfWeek(prevWeek, { weekStartsOn: 1 }), today)) {
      toast.info('Non puoi prenotare date passate');
      return;
    }
    
    setCurrentDate(prevWeek);
  };

  const handleNextWeek = () => {
    setCurrentDate(addDays(currentDate, 7));
  };

  const handleDateSelect = (date: string) => {
    const selectedDateTime = new Date(date);
    const now = new Date();

    // Don't allow selecting past dates
    if (isBefore(selectedDateTime, startOfWeek(now, { weekStartsOn: 1 }))) {
      toast.error('Non puoi selezionare una data passata');
      return;
    }

    setSelectedDate(date);
    setSelectedSlot(null);
  };

  const handleSlotSelect = (slot: string) => {
    const selectedDateTime = parse(slot, 'HH:mm', new Date());
    const now = new Date();

    // Se è oggi, non permettere di selezionare orari passati
    if (isToday(new Date(selectedDate!)) && isBefore(selectedDateTime, now)) {
      toast.error('Non puoi selezionare un orario passato');
      return;
    }

    setSelectedSlot(slot);
  };

  const handleReschedule = async () => {
    if (!selectedDate || !selectedSlot || !existingMeeting) {
      toast.error('Seleziona una data e un orario');
      return;
    }

    try {
      setLoading(true);
      
      const [hours, minutes] = selectedSlot.split(':');
      const meetingDateTime = new Date(selectedDate);
      meetingDateTime.setHours(parseInt(hours), parseInt(minutes), 0, 0);

      // Verifica se è lo stesso slot
      const isSameSlot = format(meetingDateTime, 'yyyy-MM-dd HH:mm') === format(
        existingMeeting.date instanceof Timestamp 
          ? existingMeeting.date.toDate() 
          : new Date(existingMeeting.date),
        'yyyy-MM-dd HH:mm'
      );

      if (!isSameSlot) {
        const isAvailable = await isSlotAvailable(
          existingMeeting.landlordId,
          meetingDateTime,
          selectedSlot
        );
        
        if (!isAvailable) {
          toast.error('Lo slot selezionato non è più disponibile');
          await loadAvailability();
          return;
        }
      }

      // Crea un nuovo link per la videochiamata solo se lo slot è diverso
      let updateData: any = {
        date: Timestamp.fromDate(meetingDateTime),
        time: Timestamp.fromDate(meetingDateTime),
        updatedAt: serverTimestamp()
      };

      if (!isSameSlot) {
        try {
          const meetingUrl = await createMeeting({
            roomName: `meeting-${crypto.randomUUID()}`,
            expiryMinutes: 60
          });
          updateData.meetingUrl = meetingUrl;
        } catch (error) {
          console.error('Error creating meeting URL:', error);
          toast.error('Errore nella creazione del link della videochiamata');
          return;
        }
      }

      await updateMeeting(meetingId, updateData);

      toast.success('Videochiamata riprogrammata con successo');
      if (onSuccess) {
        onSuccess();
      } else {
        onClose();
      }
    } catch (error) {
      console.error('Error rescheduling meeting:', error);
      toast.error('Errore durante la riprogrammazione della videochiamata');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 z-[60] flex items-center justify-center p-4">
      <div className="bg-white rounded-xl shadow-xl w-full max-w-lg max-h-[90vh] flex flex-col">
        <div className="p-4 sm:p-6 border-b">
          <div className="flex justify-between items-center">
            <h2 className="text-lg sm:text-xl font-semibold">
              Riprogramma Videochiamata
            </h2>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-5 w-5 sm:h-6 sm:w-6" />
            </button>
          </div>
        </div>

        <div className="p-4 sm:p-6 overflow-y-auto flex-1">
          {loading ? (
            <div className="text-center py-8">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
              <p className="mt-4 text-gray-500">Caricamento disponibilità...</p>
            </div>
          ) : (
            <div className="space-y-6">
              <div className="flex justify-between items-center mb-4">
                <button
                  onClick={handlePrevWeek}
                  className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  <ChevronLeft className="h-5 w-5" />
                </button>
                <span className="text-sm sm:text-base font-medium text-center px-2">
                  {format(startOfWeek(currentDate, { weekStartsOn: 1 }), 'd MMM', { locale: it })} - 
                  {format(endOfWeek(currentDate, { weekStartsOn: 1 }), 'd MMM yyyy', { locale: it })}
                </span>
                <button
                  onClick={handleNextWeek}
                  className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  <ChevronRight className="h-5 w-5" />
                </button>
              </div>

              <div className="relative">
                <div className="overflow-x-auto pb-4 hide-scrollbar">
                  <div className="flex gap-2 sm:gap-3">
                    {availability.map((day) => {
                      const hasSlots = day.slots.length > 0;
                      const isDisabled = !hasSlots || 
                        (isToday(new Date(day.date)) && !day.slots.some(slot => {
                          const [hours, minutes] = slot.startTime.split(':');
                          const slotTime = set(new Date(), {
                            hours: parseInt(hours),
                            minutes: parseInt(minutes),
                            seconds: 0,
                            milliseconds: 0
                          });
                          return isAfter(slotTime, addMinutes(new Date(), 5));
                        }));

                      return (
                        <button
                          key={day.date}
                          onClick={() => !isDisabled && handleDateSelect(day.date)}
                          disabled={isDisabled}
                          className={`
                            flex-shrink-0 w-[100px] sm:w-[120px] p-2 sm:p-4 rounded-lg border transition-all
                            ${selectedDate === day.date 
                              ? 'border-blue-500 bg-blue-50' 
                              : isDisabled
                                ? 'border-gray-200 bg-gray-50 cursor-not-allowed opacity-60'
                                : 'border-gray-200 hover:border-blue-200'
                            }
                          `}
                        >
                          <div className="text-center">
                            <div className="text-xs sm:text-sm font-medium text-gray-600">
                              {format(new Date(day.date), 'EEE', { locale: it })}
                            </div>
                            <div className="text-lg sm:text-2xl font-semibold my-1">
                              {format(new Date(day.date), 'd', { locale: it })}
                            </div>
                          </div>
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>

              {selectedDate && (
                <div className="mt-6 pt-6 border-t">
                  <div className="flex justify-between items-center mb-4">
                    <h4 className="text-base sm:text-lg font-medium">Orari Disponibili</h4>
                  </div>
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-3">
                    {availability
                      .find(d => d.date === selectedDate)
                      ?.slots.map(slot => {
                        const isCurrentSlot = selectedSlot === slot.startTime;
                        const isPastSlot = isToday(new Date(selectedDate)) && 
                          isBefore(parse(slot.startTime, 'HH:mm', new Date()), new Date());

                        return (
                          <button
                            key={slot.id}
                            onClick={() => !isPastSlot && handleSlotSelect(slot.startTime)}
                            disabled={isPastSlot}
                            className={`
                              p-3 sm:p-4 rounded-lg border transition-all
                              ${isCurrentSlot 
                                ? 'border-blue-500 bg-blue-50' 
                                : isPastSlot
                                  ? 'border-gray-200 bg-gray-50 opacity-50 cursor-not-allowed'
                                  : 'border-gray-200 hover:border-blue-200'
                              }
                            `}
                          >
                            <Clock className="h-4 w-4 sm:h-5 sm:w-5 mx-auto mb-2" />
                            <span className="text-xs sm:text-sm font-medium">
                              {slot.startTime}
                            </span>
                          </button>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="p-4 sm:p-6 border-t bg-gray-50 rounded-b-xl">
          <div className="flex flex-col sm:flex-row justify-end gap-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              Annulla
            </button>
            <button
              onClick={handleReschedule}
              disabled={!selectedDate || !selectedSlot || loading}
              className={`
                px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 disabled:opacity-50
              `}
            >
              {loading ? 'Salvataggio...' : 'Conferma'}
            </button>
          </div>
        </div>
      </div>

      <style>{`
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </div>
  );
}
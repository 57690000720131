import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { XCircle } from 'lucide-react';

interface AttachmentUploadProps {
  attachments: (string | File)[];
  onChange: (files: (string | File)[]) => void;
  maxAttachments?: number;
  title?: string;
  subtitle?: string;
}

export function AttachmentUpload({
  attachments,
  onChange,
  maxAttachments = 5,
  title = "Aggiungi allegati",
  subtitle = "PNG, JPG, PDF fino a 10MB"
}: AttachmentUploadProps) {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const newFiles = acceptedFiles.slice(0, maxAttachments - attachments.length);
    onChange([...attachments, ...newFiles]);
  }, [attachments, maxAttachments, onChange]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
      'application/pdf': ['.pdf']
    },
    maxSize: 10 * 1024 * 1024 // 10MB
  });

  const removeAttachment = (index: number) => {
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    onChange(newAttachments);
  };

  const renderPreview = (file: string | File, index: number) => {
    const isFileObject = file instanceof File;
    const fileUrl = isFileObject ? URL.createObjectURL(file) : file;
    const fileName = isFileObject ? file.name : file.split('/').pop();
    const isImage = isFileObject 
      ? file.type.startsWith('image/') 
      : file.match(/\.(jpg|jpeg|png|gif)$/i);

    return (
      <div key={index} className="relative group">
        <div className="border rounded-lg p-2 bg-gray-50">
          {isImage ? (
            <div className="relative aspect-square w-20">
              <img
                src={fileUrl}
                alt={fileName}
                className="w-full h-full object-cover rounded"
              />
            </div>
          ) : (
            <div className="w-20 h-20 flex items-center justify-center bg-gray-100 rounded">
              <span className="text-xs text-gray-500">PDF</span>
            </div>
          )}
          <button
            type="button"
            onClick={() => removeAttachment(index)}
            className="absolute -top-2 -right-2 bg-white rounded-full shadow-sm hover:bg-gray-100"
          >
            <XCircle className="h-5 w-5 text-gray-500" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-4">
      {attachments.length > 0 && (
        <div className="flex flex-wrap gap-3">
          {attachments.map((file, index) => renderPreview(file, index))}
        </div>
      )}
      
      {attachments.length < maxAttachments && (
        <div
          {...getRootProps()}
          className={`border-2 border-dashed rounded-lg p-4 text-center cursor-pointer transition-colors
            ${isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-gray-400'}`}
        >
          <input {...getInputProps()} />
          <p className="text-sm font-medium text-gray-900">{title}</p>
          <p className="text-xs text-gray-500 mt-1">{subtitle}</p>
        </div>
      )}
    </div>
  );
}
import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft, BarChart2 } from 'lucide-react';
import { generateNumericId } from '../../utils/ids';

interface ListingBreadcrumbProps {
  listingId: string;
  isOwner?: boolean;
  isAnalyticsPage?: boolean;
  className?: string;
}

export function ListingBreadcrumb({
  listingId,
  isOwner = false,
  isAnalyticsPage = false,
  className = ''
}: ListingBreadcrumbProps) {
  return (
    <div className="flex items-center justify-between w-full">
      <nav className="flex items-center space-x-3">
        {/* Link principale - visibile sempre */}
        <Link 
          to={isOwner ? "/dashboard/landlord" : "/search"}
          className="flex items-center gap-2 px-3 py-1.5 text-gray-600 hover:text-gray-900 rounded-md hover:bg-gray-100 transition-all"
        >
          <ChevronLeft className="h-5 w-5 sm:h-4 sm:w-4" />
          <span className="hidden sm:inline">{isOwner ? 'Dashboard' : 'Ricerca'}</span>
        </Link>

        {/* Resto del breadcrumb - visibile solo su desktop */}
        <div className="hidden sm:flex items-center space-x-3">
          <div className="text-gray-400">/</div>
          
          <div className="group relative">
            {isAnalyticsPage ? (
              <Link 
                to={`/listing/${listingId}`}
                className="px-3 py-1.5 text-gray-900 font-medium hover:bg-gray-100 rounded-md"
              >
                ID: {generateNumericId(listingId)}
              </Link>
            ) : (
              <span className="px-3 py-1.5 text-gray-900 font-medium">
                ID: {generateNumericId(listingId)}
              </span>
            )}
            
            <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 bg-gray-900 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
              Identificativo Annuncio
            </div>
          </div>

          {isAnalyticsPage && (
            <>
              <div className="text-gray-400">/</div>
              <span className="px-3 py-1.5 text-blue-600 font-medium bg-blue-50 rounded-md">
                Analytics
              </span>
            </>
          )}
        </div>
      </nav>

      {/* Analytics Button */}
      {isOwner && !isAnalyticsPage && (
        <div className="flex-shrink-0">
          <Link
            to={`/dashboard/landlord/analytics/${listingId}`}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 transition-all shadow-sm"
          >
            <BarChart2 className="h-4 w-4 mr-2" />
            <span className="hidden sm:inline">Visualizza Analytics</span>
            <span className="sm:hidden">Analytics</span>
          </Link>
        </div>
      )}
    </div>
  );
}
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import { API_ENDPOINTS, PAYMENT_CONFIG, API_BASE_URL } from '../config/api';
import { collection, doc, setDoc, updateDoc, serverTimestamp, getDoc, deleteDoc, onSnapshot, writeBatch } from 'firebase/firestore';
import { db } from '@/lib/firebase';
import { getAuth } from 'firebase/auth';

const paymentsCollection = collection(db, 'payments');
const featuredPlansCollection = collection(db, 'featuredPlans');

let stripePromise: Promise<any> | null = null;

const getStripe = async () => {
  if (!stripePromise) {
    if (!PAYMENT_CONFIG.stripe.publishableKey) {
      throw new Error('Stripe publishable key non configurata');
    }
    stripePromise = loadStripe(PAYMENT_CONFIG.stripe.publishableKey);
  }
  
  const stripe = await stripePromise;
  if (!stripe) {
    throw new Error('Failed to initialize Stripe');
  }
  return stripe;
};

export interface PaymentMetadata {
  userId: string;
  type: 'credits' | 'featured';
  quantity?: number;
  packageId?: string;
  listingId?: string;
  planId?: string;
  userType?: string;
  requiresInvoice?: boolean;
  discountCode?: string;
  discountType?: 'percentage' | 'fixed';
  discountValue?: number;
  originalAmount?: number;
}

export async function createPayment(data: Omit<Payment, 'id' | 'createdAt' | 'updatedAt'>): Promise<string> {
  try {
    const paymentId = crypto.randomUUID();
    const paymentRef = doc(paymentsCollection, paymentId);
    
    if (typeof data.userId !== 'string') {
      throw new Error('userId deve essere una stringa');
    }

    // Recupera i dati utente
    const userRef = doc(db, 'users', data.userId);
    const userDoc = await getDoc(userRef);
    const userData = userDoc.data() || {};

    // Recupera i dati di fatturazione dal profilo utente
    const billingAddress = userData.billingAddress || {};

    const invoiceData = {
      user: {
        firstName: userData.firstName || '',
        lastName: userData.lastName || '',
        email: userData.email || '',
        address: billingAddress.formattedAddress || '',
        city: billingAddress.city || '',
        postalCode: billingAddress.zipCode || '',
        province: billingAddress.province || '',
        country: billingAddress.country || '',
        fiscalCode: userData.fiscalType === 'cf' ? userData.fiscalCode : '',
        vatNumber: userData.fiscalType === 'piva' ? userData.vatNumber : '',
        businessName: userData.fiscalType === 'piva' ? userData.businessName : '',
        sdiCode: userData.fiscalType === 'piva' ? userData.sdiCode : ''
      }
    };

    // Arricchimento metadati...
    if (data.metadata?.type === 'featured' && data.metadata.listingId) {
      const [listingDoc, planDoc] = await Promise.all([
        getDoc(doc(db, 'listings', data.metadata.listingId)),
        getDoc(doc(db, 'featuredPlans', data.metadata.planId))
      ]);

      const listingData = listingDoc.data();
      const planData = planDoc.data();

      if (listingData) {
        data.metadata.listingTitle = listingData.title;
      }
      
      if (planData) {
        data.metadata = {
          ...data.metadata,
          planName: planData.name,
          planDuration: planData.durationHours,
          planDurationLabel: planData.durationLabel,
          planDescription: planData.description,
          planPrice: planData.price,
          planFeatures: planData.features,
          planPopular: planData.popular
        };
      }
    }

    const cleanPaymentData = {
      id: paymentId,
      amount: data.amount,
      userId: data.userId,
      status: data.status,
      sessionId: data.sessionId,
      paymentMethod: data.paymentMethod,
      metadata: {
        ...data.metadata,
        type: data.metadata.type
      },
      invoiceData,
      discount: data.metadata?.discountCode ? {
        code: data.metadata.discountCode,
        type: data.metadata.discountType || 'percentage',
        value: parseFloat(data.metadata.discountValue?.toString() || '0'),
        originalAmount: parseFloat(data.metadata.originalAmount?.toString() || '0')
      } : null,
      requiresInvoice: data.metadata?.requiresInvoice ?? data.requiresInvoice ?? userData.requiresInvoice ?? false,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    };

    await setDoc(paymentRef, cleanPaymentData);
    return paymentId;
  } catch (error) {
    console.error('Errore in createPayment:', error);
    throw error;
  }
}

export async function getPayment(paymentId: string): Promise<Payment | null> {
  try {
    const paymentRef = doc(paymentsCollection, paymentId);
    const paymentDoc = await getDoc(paymentRef);
    
    if (!paymentDoc.exists()) {
      return null;
    }

    return {
      id: paymentDoc.id,
      ...paymentDoc.data(),
      createdAt: paymentDoc.data().createdAt?.toDate(),
      updatedAt: paymentDoc.data().updatedAt?.toDate()
    } as Payment;
  } catch (error) {
    console.error('Error getting payment:', error);
    return null;
  }
}

export async function createStripeCheckoutSession(sessionData: {
  amount: number;
  metadata: PaymentMetadata;
  lineItems: any;
  requiresInvoice: boolean;
  invoiceData?: any;
}): Promise<string> {
  const { currentUser } = getAuth();
  
  if (!currentUser) {
    throw new Error('Utente non autenticato');
  }

  try {
    const sessionId = crypto.randomUUID();
    const checkoutSessionRef = doc(collection(db, `customers/${currentUser.uid}/checkout_sessions`), sessionId);
    
    const sessionDoc = {
      amount: sessionData.amount,
      mode: 'payment',
      success_url: `${window.location.origin}/payment/success?session_id=${sessionId}`,
      cancel_url: `${window.location.origin}/payment/cancel`,
      line_items: [{
        quantity: 1,
        amount: Math.round(sessionData.amount * 100),
        currency: 'eur',
        name: sessionData.lineItems.name || 'Pagamento',
        description: sessionData.lineItems.description || ''
      }],
      metadata: {
        ...sessionData.metadata,
        quantity: sessionData.metadata.type === 'credits' ? sessionData.metadata.quantity : '1',
        paymentId: sessionId
      }
    };

    if (sessionData.requiresInvoice && sessionData.invoiceData) {
      sessionDoc.invoice_creation = {
        enabled: true,
        invoice_data: sessionData.invoiceData
      };
    }

    await setDoc(checkoutSessionRef, sessionDoc);

    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(checkoutSessionRef, (snap) => {
        const data = snap.data();
        if (data?.sessionId) {
          unsubscribe();
          resolve(data.sessionId);
        }
        if (data?.error) {
          unsubscribe();
          reject(new Error(data.error.message));
        }
      });

      setTimeout(() => {
        unsubscribe();
        reject(new Error('Timeout nella creazione della sessione'));
      }, 10000);
    });
  } catch (error) {
    console.error('Errore creazione sessione Stripe:', error);
    throw error;
  }
}

async function deletePayment(paymentId: string): Promise<void> {
  const paymentRef = doc(paymentsCollection, paymentId);
  await deleteDoc(paymentRef);
}

export interface PayPalOrderMetadata extends PaymentMetadata {
  orderId: string;
}

export async function createPayPalOrder(amount: number, metadata: PaymentMetadata): Promise<string> {
  try {
    const response = await fetch(API_ENDPOINTS.payments.createPaypalOrder, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount,
        metadata: {
          ...metadata,
          timestamp: new Date().toISOString()
        }
      }),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Network response error' }));
      throw new Error(errorData.message || 'Failed to create PayPal order');
    }

    const { orderId } = await response.json();
    
    if (!orderId) {
      throw new Error('Invalid order ID received from server');
    }

    return orderId;
  } catch (error: any) {
    console.error('PayPal order creation error:', { error });
    toast.error(error.message || 'Failed to create PayPal order');
    throw error;
  }
}

export async function capturePayPalOrder(orderId: string, metadata: PayPalOrderMetadata): Promise<void> {
  try {
    const response = await fetch(API_ENDPOINTS.payments.capturePaypalOrder, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderId,
        metadata: {
          ...metadata,
          timestamp: new Date().toISOString()
        }
      }),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Network response error' }));
      throw new Error(errorData.message || 'Failed to capture PayPal payment');
    }

    await response.json();
  } catch (error: any) {
    console.error('PayPal capture error:', { error });
    toast.error(error.message || 'Failed to complete PayPal payment');
    throw error;
  }
}

export async function updatePaymentStatus(
  paymentId: string, 
  status: Payment['status']
) {
  const paymentRef = doc(paymentsCollection, paymentId);
  await updateDoc(paymentRef, {
    status,
    updatedAt: serverTimestamp()
  });
}
import { ref, uploadBytes, getDownloadURL, deleteObject, listAll, copyBytes } from 'firebase/storage';
import { storage } from './firebase';
import { v4 as uuidv4 } from 'uuid';
import { requireAdmin } from './admin';
import { toast } from 'react-toastify';
import { auth } from './firebase';

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const MAX_TOTAL_SIZE = 50 * 1024 * 1024; // 50MB for multiple files
const MAX_FILES = 10;
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

export async function validateFile(file: File): Promise<boolean> {
  if (file.size > MAX_FILE_SIZE) {
    toast.error(`Il file ${file.name} supera i 10MB`);
    return false;
  }

  const allowedTypes = ['image/jpeg', 'image/png', 'image/webp', 'application/pdf'];
  if (!allowedTypes.includes(file.type)) {
    toast.error(`Il file ${file.name} non è in un formato valido`);
    return false;
  }

  return true;
}

async function uploadSingleFile(file: File, path: string, retryCount = 0): Promise<string> {
  try {
    const fileRef = ref(storage, path);
    const snapshot = await uploadBytes(fileRef, file);
    return await getDownloadURL(snapshot.ref);
  } catch (error: any) {
    console.error(`Upload attempt ${retryCount + 1} failed:`, error);
    
    if (retryCount < MAX_RETRIES && error.code === 'storage/retry-limit-exceeded') {
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY * (retryCount + 1)));
      return uploadSingleFile(file, path, retryCount + 1);
    }
    
    throw error;
  }
}

export async function uploadListingImages(files: File[], listingId: string): Promise<string[]> {
  if (files.length > MAX_FILES) {
    throw new Error(`Puoi caricare al massimo ${MAX_FILES} immagini`);
  }

  const imageUrls: string[] = [];
  const uploadPromises: Promise<string>[] = [];

  for (const file of files) {
    try {
      if (!await validateFile(file)) {
        continue;
      }

      const fileExtension = file.name.split('.').pop()?.toLowerCase() || 'jpg';
      const fileName = `listings/${listingId}/images/${uuidv4()}.${fileExtension}`;
      
      uploadPromises.push(
        uploadSingleFile(file, fileName)
          .then(url => {
            imageUrls.push(url);
            return url;
          })
      );
    } catch (error) {
      console.error('Error processing file:', error);
      throw error;
    }
  }

  try {
    await Promise.all(uploadPromises);
    return imageUrls;
  } catch (error) {
    // Clean up any successfully uploaded files
    await Promise.all(imageUrls.map(url => deleteFile(url).catch(console.error)));
    throw error;
  }
}

export async function uploadFloorPlans(files: File[], listingId: string): Promise<string[]> {
  const floorPlanUrls: string[] = [];
  const uploadPromises: Promise<string>[] = [];

  for (const file of files) {
    try {
      if (!await validateFile(file)) {
        continue;
      }

      const fileExtension = file.name.split('.').pop()?.toLowerCase() || 'jpg';
      const fileName = `listings/${listingId}/floorplans/${uuidv4()}.${fileExtension}`;
      
      uploadPromises.push(
        uploadSingleFile(file, fileName)
          .then(url => {
            floorPlanUrls.push(url);
            return url;
          })
      );
    } catch (error) {
      console.error('Error processing file:', error);
      throw error;
    }
  }

  try {
    await Promise.all(uploadPromises);
    return floorPlanUrls;
  } catch (error) {
    // Clean up any successfully uploaded files
    await Promise.all(floorPlanUrls.map(url => deleteFile(url).catch(console.error)));
    throw error;
  }
}

export async function uploadProductImage(file: File): Promise<string> {
  try {
    if (!await validateFile(file)) {
      throw new Error('File non valido');
    }

    const fileExtension = file.name.split('.').pop()?.toLowerCase() || 'jpg';
    const fileName = `products/${uuidv4()}.${fileExtension}`;
    
    return await uploadSingleFile(file, fileName);
  } catch (error: any) {
    console.error('Upload error:', error);
    throw new Error(error.message || 'Errore durante il caricamento del file');
  }
}

export async function uploadTicketAttachments(files: File[], ticketId: string): Promise<string[]> {
  const attachmentUrls: string[] = [];
  const errors: string[] = [];
  let totalSize = 0;

  if (files.length > MAX_FILES) {
    throw new Error(`Puoi caricare al massimo ${MAX_FILES} file`);
  }

  for (const file of files) {
    totalSize += file.size;
  }

  if (totalSize > MAX_TOTAL_SIZE) {
    throw new Error('La dimensione totale dei file supera il limite di 50MB');
  }

  const uploadPromises = files.map(async (file) => {
    try {
      if (!await validateFile(file)) {
        throw new Error(`File ${file.name} non valido`);
      }

      const fileExtension = file.name.split('.').pop()?.toLowerCase() || 'bin';
      const fileName = `tickets/${ticketId}/attachments/${uuidv4()}.${fileExtension}`;
      const url = await uploadSingleFile(file, fileName);
      attachmentUrls.push(url);
      return url;
    } catch (error: any) {
      errors.push(`Error uploading ${file.name}: ${error.message}`);
      return null;
    }
  });

  await Promise.all(uploadPromises);

  if (errors.length > 0) {
    // Clean up any successfully uploaded files
    await Promise.all(attachmentUrls.map(url => deleteFile(url).catch(console.error)));
    throw new Error('Errore durante il caricamento dei file:\n' + errors.join('\n'));
  }

  return attachmentUrls;
}

export async function deleteFile(url: string): Promise<void> {
  try {
    // Estrai il percorso del file dall'URL
    const decodedUrl = decodeURIComponent(url);
    const path = decodedUrl.split('/o/')[1]?.split('?')[0];
    if (!path) throw new Error('URL file non valido');

    // Crea il riferimento al file
    const fileRef = ref(storage, path);
    
    // Debug: mostra il path che stiamo per cancellare
    console.log('Attempting to delete file at path:', path);
    
    // Elimina il file
    await deleteObject(fileRef);
    
    // Debug: conferma della cancellazione
    console.log('Delete completed successfully for:', path);
    
    // Verifica che il file sia stato effettivamente eliminato
    const folderPath = path.split('/').slice(0, -1).join('/');
    const folderRef = ref(storage, folderPath);
    const listResult = await listAll(folderRef);
    console.log('Files remaining in folder:', listResult.items.map(item => item.name));

  } catch (error: any) {
    console.error('Error deleting file:', error);
    throw new Error('Error deleting file: ' + error.message);
  }
}

export async function uploadFiles(files: File[], options: {
  folder: string;
  maxFiles?: number;
  maxFileSize?: number;
  allowedTypes?: string[];
  onProgress?: (progress: number) => void;
  silent?: boolean;
}): Promise<string[]> {
  const {
    folder,
    maxFiles = MAX_FILES,
    maxFileSize = MAX_FILE_SIZE,
    allowedTypes = ['image/jpeg', 'image/png', 'image/webp'],
    onProgress,
    silent = false
  } = options;

  if (files.length > maxFiles) {
    throw new Error(`Puoi caricare al massimo ${maxFiles} file`);
  }

  const uploadedUrls: string[] = [];
  const errors: string[] = [];
  let totalSize = 0;

  for (const file of files) {
    totalSize += file.size;
  }

  if (totalSize > maxFileSize) {
    throw new Error('La dimensione totale dei file supera il limite di 50MB');
  }

  const uploadPromises = files.map(async (file) => {
    try {
      if (!await validateFile(file)) {
        throw new Error(`File ${file.name} non valido`);
      }

      const fileExtension = file.name.split('.').pop()?.toLowerCase() || 'bin';
      const fileName = `${folder}/${uuidv4()}.${fileExtension}`;
      const url = await uploadSingleFile(file, fileName);
      uploadedUrls.push(url);
      return url;
    } catch (error: any) {
      errors.push(`Error uploading ${file.name}: ${error.message}`);
      return null;
    }
  });

  await Promise.all(uploadPromises);

  if (errors.length > 0) {
    // Clean up any successfully uploaded files
    await Promise.all(uploadedUrls.map(url => deleteFile(url).catch(console.error)));
    throw new Error('Errore durante il caricamento dei file:\n' + errors.join('\n'));
  }

  return uploadedUrls;
}

export async function cleanupTempFiles(userId: string): Promise<void> {
  try {
    const tempFolderRef = ref(storage, `temp/${userId}`);
    const tempFiles = await listAll(tempFolderRef);
    
    await Promise.all(
      tempFiles.items.map(fileRef => deleteObject(fileRef))
    );
  } catch (error) {
    console.error('Error cleaning up temp files:', error);
    // Non lanciare l'errore per non bloccare altre operazioni
  }
}

async function moveFileFromTemp(tempUrl: string, listingId: string, type: 'images' | 'floorplans'): Promise<string> {
  const userId = auth.currentUser?.uid;
  if (!userId) throw new Error('Utente non autenticato');

  // Estrai il nome del file dal tempUrl
  const fileName = tempUrl.split('/').pop()?.split('?')[0];
  if (!fileName) throw new Error('URL file non valido');

  // Crea i riferimenti
  const tempRef = ref(storage, `temp/${userId}/listings/temp/${type}/${fileName}`);
  const newRef = ref(storage, `listings/${listingId}/${type}/${fileName}`);

  // Scarica il file temporaneo
  const response = await fetch(tempUrl);
  const blob = await response.blob();

  // Carica il file nella nuova posizione
  await uploadBytes(newRef, blob);
  
  // Elimina il file temporaneo
  await deleteObject(tempRef);

  // Restituisci il nuovo URL
  return await getDownloadURL(newRef);
}
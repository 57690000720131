import React, { useState, useEffect } from 'react';
import { CreditCard, Star, Building2, ArrowUp, ArrowDown, ChevronLeft, ChevronRight, ArrowDownLeft } from 'lucide-react';
import { useAuthContext } from '../../contexts/AuthContext';
import { getCreditHistory } from '../../lib/credits';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

interface Transaction {
  id: string;
  type: 'purchase' | 'usage' | 'refund' | 'featured';
  amount: number;
  description: string;
  createdAt: Date;
  status: string;
}

export function TransactionsHistory() {
  const { userProfile } = useAuthContext();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const transactionsPerPage = 5;

  useEffect(() => {
    loadTransactions();
  }, [userProfile?.id]);

  const loadTransactions = async () => {
    if (!userProfile?.id) return;
    try {
      setLoading(true);
      const data = await getCreditHistory(userProfile.id);
      setTransactions(data);
    } catch (error) {
      console.error('Error loading transactions:', error);
      toast.error('Errore nel caricamento dei movimenti');
    } finally {
      setLoading(false);
    }
  };

  // Calcola l'indice iniziale e finale per la paginazione
  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);
  const totalPages = Math.ceil(transactions.length / transactionsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const getTransactionStyle = (transaction) => {
    switch (transaction.type) {
      case 'refund':
        return {
          icon: <ArrowDownLeft className="h-4 w-4 text-green-600" />,
          textColor: 'text-green-600',
          bgColor: 'bg-green-100',
          prefix: '+'
        };
      case 'purchase':
        return {
          icon: <ArrowUp className="h-4 w-4 text-green-600" />,
          textColor: 'text-green-600',
          bgColor: 'bg-green-100',
          prefix: '+'
        };
      default:
        return {
          icon: <ArrowDown className="h-4 w-4 text-red-600" />,
          textColor: 'text-red-600',
          bgColor: 'bg-red-100',
          prefix: '-'
        };
    }
  };

  if (loading) {
    return (
      <div className="text-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
        <p className="mt-2 text-sm text-gray-500">Caricamento movimenti...</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="text-base sm:text-lg font-medium text-gray-900">Storico Movimenti</h3>
        <span className="text-sm text-gray-500">
          {transactions.length} movimenti totali
        </span>
      </div>

      <div className="space-y-3">
        {transactions.length === 0 ? (
          <p className="text-sm text-gray-500 text-center py-4">Nessun movimento disponibile</p>
        ) : (
          <>
            {currentTransactions.map((transaction) => (
              <div
                key={transaction.id}
                className="bg-gray-50 rounded-lg p-3 sm:p-4"
              >
                <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:items-center justify-between">
                  <div className="flex items-start space-x-3">
                    <div className={`${getTransactionStyle(transaction).bgColor} p-2 rounded-lg shrink-0`}>
                      {getTransactionStyle(transaction).icon}
                    </div>
                    
                    <div className="min-w-0 flex-1">
                      <p className="text-sm sm:text-base font-medium text-gray-900">
                        {transaction.description}
                      </p>
                      <p className="text-xs sm:text-sm text-gray-500">
                        {format(new Date(transaction.createdAt), 'dd/MM/yyyy HH:mm')}
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-row sm:flex-row items-center justify-between sm:justify-end gap-2 sm:gap-4">
                    <div className={`text-sm sm:text-base font-medium whitespace-nowrap ${
                      getTransactionStyle(transaction).textColor
                    }`}>
                      {getTransactionStyle(transaction).prefix} {Math.abs(transaction.amount)} crediti
                    </div>
                    
                    {transaction.type === 'debit' && (
                      <div className="flex items-center gap-1.5 bg-blue-50 px-2 py-1 rounded-md shrink-0">
                        <Star className="h-3 w-3 sm:h-4 sm:w-4 text-blue-600" />
                        <span className="text-xs sm:text-sm text-blue-600 font-medium whitespace-nowrap">
                          {transaction.remainingCredits} rimasti
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}

            {/* Paginazione migliorata */}
            {totalPages > 1 && (
              <div className="flex justify-center items-center gap-1 pt-6 bg-white rounded-lg p-2">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handlePageChange(currentPage - 1);
                  }}
                  disabled={currentPage === 1}
                  className="inline-flex items-center justify-center p-1.5 text-gray-500 hover:text-gray-700 disabled:opacity-50 hover:bg-gray-100 rounded-md"
                  aria-label="Pagina precedente"
                  type="button"
                >
                  <ChevronLeft className="h-4 w-4" />
                </button>
                
                {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => {
                  if (
                    pageNumber === 1 ||
                    pageNumber === totalPages ||
                    (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
                  ) {
                    return (
                      <button
                        key={pageNumber}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handlePageChange(pageNumber);
                        }}
                        type="button"
                        className={`min-w-[28px] h-7 flex items-center justify-center rounded-md text-sm font-medium transition-colors ${
                          currentPage === pageNumber
                            ? 'bg-blue-600 text-white'
                            : 'text-gray-700 hover:bg-gray-100'
                        }`}
                      >
                        {pageNumber}
                      </button>
                    );
                  } else if (
                    pageNumber === currentPage - 2 ||
                    pageNumber === currentPage + 2
                  ) {
                    return (
                      <span
                        key={pageNumber}
                        className="px-1 text-gray-400"
                      >
                        ...
                      </span>
                    );
                  }
                  return null;
                })}
                
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handlePageChange(currentPage + 1);
                  }}
                  disabled={currentPage === totalPages}
                  className="inline-flex items-center justify-center p-1.5 text-gray-500 hover:text-gray-700 disabled:opacity-50 hover:bg-gray-100 rounded-md"
                  aria-label="Pagina successiva"
                  type="button"
                >
                  <ChevronRight className="h-4 w-4" />
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateListingFeatured } from '@/lib/listings';
import { doc, collection, setDoc, updateDoc, increment, serverTimestamp, getDoc, query, where, limit, getDocs, arrayUnion, writeBatch } from 'firebase/firestore';
import { db } from '@/lib/firebase';
import { API_BASE_URL, API_ENDPOINTS } from '@/config/api';
import { createPayment } from '@/lib/payments';
import { CheckCircle2, XCircle, Loader2 } from 'lucide-react';
import { getAuth } from 'firebase/auth';

interface PaymentStatus {
  status: 'loading' | 'success' | 'error';
  message: string;
  userType?: 'student' | 'landlord';
}

export function PaymentSuccess() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>({
    status: 'loading',
    message: 'Completamento pagamento in corso...'
  });
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged((user) => {
      console.log('🔐 Auth state changed:', { user: user?.uid });
      setAuthChecked(true);
    });

    return () => unsubscribe();
  }, []);

  const safeParse = (value: any): number => {
    if (typeof value === 'number') return value;
    const parsed = parseFloat(value?.toString() || '0');
    return isNaN(parsed) ? 0 : parsed;
  };

  useEffect(() => {
    console.log('💫 Effect triggered:', { authChecked });
    if (!authChecked) return;

    const sessionId = searchParams.get('session_id');
    console.log('🔍 Session ID:', sessionId);
    
    if (!sessionId) {
      setPaymentStatus({
        status: 'error',
        message: 'Nessun pagamento da processare. Torna alla dashboard.'
      });
      return;
    }

    const handlePaymentSuccess = async () => {
      try {
        const auth = getAuth();
        const currentUser = auth.currentUser;
        
        console.log('👤 Current user:', currentUser?.uid);
        
        if (!currentUser) {
          setPaymentStatus({
            status: 'error',
            message: 'Utente non autenticato. Effettua il login e riprova.'
          });
          return;
        }

        // Verifica se esiste già un pagamento con questo sessionId
        const existingPaymentQuery = query(
          collection(db, 'payments'), 
          where('sessionId', '==', sessionId)
        );
        const existingPaymentDocs = await getDocs(existingPaymentQuery);

        if (!existingPaymentDocs.empty) {
          console.log('✅ Pagamento già processato:', sessionId);
          setPaymentStatus({
            status: 'success',
            message: 'Pagamento già completato! Puoi tornare alla dashboard quando vuoi.'
          });
          return;
        }

        // Recupera i dati della sessione di checkout
        console.log('🔄 Recupero dati sessione checkout...');
        const checkoutSessionRef = doc(db, `customers/${currentUser.uid}/checkout_sessions`, sessionId);
        const checkoutSessionDoc = await getDoc(checkoutSessionRef);

        if (!checkoutSessionDoc.exists()) {
          console.error('❌ Sessione checkout non trovata:', sessionId);
          setPaymentStatus({
            status: 'error',
            message: 'Dati del pagamento non trovati. Contatta il supporto.'
          });
          return;
        }

        const checkoutData = checkoutSessionDoc.data();
        console.log('📦 Dati checkout recuperati:', checkoutData);

        // Usa i dati della sessione di checkout per creare il payment
        const cleanMetadata = {
          type: checkoutData.metadata.type,
          quantity: parseInt(checkoutData.metadata.quantity || '0'),
          userId: currentUser.uid,
          ...(checkoutData.metadata.packageId && { packageId: checkoutData.metadata.packageId }),
          ...(checkoutData.metadata.packageName && { packageName: checkoutData.metadata.packageName }),
          ...(checkoutData.metadata.discountCode && {
            discountCode: checkoutData.metadata.discountCode,
            discountType: checkoutData.metadata.discountType,
            discountValue: safeParse(checkoutData.metadata.discountValue),
            originalAmount: safeParse(checkoutData.metadata.originalAmount)
          })
        };

        const paymentId = await createPayment({
          amount: safeParse(checkoutData.amount),
          userId: currentUser.uid,
          status: 'succeeded',
          sessionId: sessionId,
          paymentMethod: 'stripe',
          requiresInvoice: checkoutData.requiresInvoice || false,
          metadata: cleanMetadata,
          invoiceData: checkoutData.invoiceData || null
        });

        console.log('✅ Payment creato:', paymentId);

        // Aggiorna il conteggio del codice sconto se presente
        if (checkoutData.metadata.discountCode) {
          console.log('🎯 Gestione sconto Stripe:', {
            type: checkoutData.metadata.type,
            discountCode: checkoutData.metadata.discountCode
          });

          const discountRef = collection(db, 'discountCodes');
          const q = query(
            discountRef, 
            where('code', '==', checkoutData.metadata.discountCode.toUpperCase())
          );
          const querySnapshot = await getDocs(q);
          
          if (!querySnapshot.empty) {
            const discountDoc = querySnapshot.docs[0];
            await updateDoc(doc(db, 'discountCodes', discountDoc.id), {
              usedCount: increment(1),
              usedBy: arrayUnion(currentUser.uid),
              lastUsedAt: serverTimestamp(),
              lastUsedBy: currentUser.uid,
              updatedAt: serverTimestamp()
            });
            
            console.log('✅ Aggiornato utilizzo codice sconto:', {
              code: checkoutData.metadata.discountCode,
              type: checkoutData.metadata.type,
              docId: discountDoc.id
            });
          }
        }

        // Gestisci il caso di pagamento per piano in evidenza
        if (checkoutData.metadata.type === 'featured' && 
            checkoutData.metadata.listingId && 
            checkoutData.metadata.planId) {
          console.log('🌟 Aggiornamento piano in evidenza:', {
            listingId: checkoutData.metadata.listingId,
            planId: checkoutData.metadata.planId
          });

          // Recupera i dati completi del piano e dell'annuncio
          const [listingDoc, planDoc] = await Promise.all([
            getDoc(doc(db, 'listings', checkoutData.metadata.listingId)),
            getDoc(doc(db, 'featuredPlans', checkoutData.metadata.planId))
          ]);

          if (!listingDoc.exists() || !planDoc.exists()) {
            throw new Error('Dati piano o annuncio non trovati');
          }

          const listingData = listingDoc.data();
          const planData = planDoc.data();

          // Aggiorna i metadati con i dati completi
          const enrichedMetadata = {
            ...cleanMetadata,
            listingTitle: listingData.title,
            planName: planData.name,
            planDuration: planData.durationHours,
            planDurationLabel: planData.durationLabel,
            planDescription: planData.description,
            planPrice: planData.price,
            planFeatures: planData.features,
            planPopular: planData.popular,
            paymentMethod: 'stripe'
          };

          await updateListingFeatured(
            checkoutData.metadata.listingId,
            checkoutData.metadata.planId,
            paymentId,
            {
              amount: safeParse(checkoutData.amount),
              userId: currentUser.uid,
              status: 'succeeded',
              sessionId: sessionId,
              paymentMethod: 'stripe',
              metadata: enrichedMetadata
            }
          );

          console.log('✅ Annuncio aggiornato come in evidenza');
        }

        // Recupera i dati utente per il tipo
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        
        if (!userDoc.exists()) {
          throw new Error('Utente non trovato');
        }
        
        const userData = userDoc.data();

        // Aggiorna lo stato in base al tipo di pagamento
        setPaymentStatus({
          status: 'success',
          message: cleanMetadata.type === 'credits' 
            ? 'Crediti acquistati con successo! Puoi tornare alla dashboard quando vuoi.'
            : 'Pagamento completato con successo! Puoi tornare alla dashboard quando vuoi.',
          userType: userData.userType
        });

      } catch (error) {
        console.error('❌ Errore gestione pagamento:', error);
        setPaymentStatus({
          status: 'error',
          message: 'Errore durante il completamento del pagamento. Contatta il supporto.'
        });
      }
    };

    handlePaymentSuccess();
  }, [authChecked, searchParams]);

  const statusConfig = {
    loading: {
      icon: <Loader2 className="h-16 w-16 text-blue-500 animate-spin" />,
      textColor: 'text-blue-700',
      bgColor: 'bg-blue-50',
      borderColor: 'border-blue-200'
    },
    success: {
      icon: <CheckCircle2 className="h-16 w-16 text-green-500" />,
      textColor: 'text-green-700',
      bgColor: 'bg-green-50',
      borderColor: 'border-green-200'
    },
    error: {
      icon: <XCircle className="h-16 w-16 text-red-500" />,
      textColor: 'text-red-700',
      bgColor: 'bg-red-50',
      borderColor: 'border-red-200'
    }
  };

  const currentStatus = statusConfig[paymentStatus.status];

  return (
    <div className="flex items-center justify-center min-h-[calc(100vh-80px)] p-8 mt-20">
      <div className={`max-w-2xl w-full ${currentStatus.bgColor} border ${currentStatus.borderColor} rounded-xl p-12`}>
        <div className="text-center">
          <div className="flex justify-center mb-8">
            {currentStatus.icon}
          </div>
          <h2 className={`text-3xl font-semibold mb-4 ${currentStatus.textColor}`}>
            {paymentStatus.status === 'loading' && 'Elaborazione in corso'}
            {paymentStatus.status === 'success' && 'Pagamento completato'}
            {paymentStatus.status === 'error' && 'Errore nel pagamento'}
          </h2>
          <p className={`text-xl ${currentStatus.textColor} mb-8`}>
            {paymentStatus.message}
          </p>
          {(paymentStatus.status === 'error' || paymentStatus.status === 'success') && (
            <button
            onClick={() => navigate(`/dashboard/${paymentStatus.userType || 'student'}`)}
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Torna alla dashboard
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
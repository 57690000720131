import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Star, FileText, AlertTriangle, RefreshCw, Clock, Users, GraduationCap, User, Bus } from 'lucide-react';
import { ListingHeader } from '../components/ListingHeader';
import { ImageGallery } from '../components/ImageGallery';
import { PropertyFeatures } from '../components/PropertyFeatures';
import { ContractDetails } from '../components/ContractDetails';
import { HouseRules } from '../components/HouseRules';
import { ContactForm } from '../components/ContactForm';
import { ListingFeatures } from '../components/ListingFeatures';
import { FavoriteButton } from '../components/FavoriteButton';
import { getListing, incrementListingViews, republishListing } from '../lib/listings';
import { Listing } from '../types/listing';
import { toast } from 'react-toastify';
import { useAuthContext } from '../contexts/AuthContext';
import { generateNumericId } from '../utils/ids';
import { FloorPlanModal } from '../components/FloorPlanModal';
import { ListingBreadcrumb } from '../components/listings/ListingBreadcrumb';
import { getUserCredits } from '../lib/credits';
import { CREDITS_COST } from '../constants';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { LinkedListings } from '../components/LinkedListings';

declare global {
  interface Window {
    google: typeof google;
  }
}

interface ListingLocationMapProps {
  lat: number;
  lng: number;
  title: string;
  price: number;
}

export function ListingLocationMap({ lat, lng, title, price }: ListingLocationMapProps) {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const marker = useRef<mapboxgl.Marker | null>(null);

  useEffect(() => {
    if (!mapContainer.current || !lat || !lng || isNaN(lat) || isNaN(lng)) return;

    try {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [lng, lat],
        zoom: 15,
        locale: {
          'NavigationControl.ZoomIn': 'Aumenta zoom',
          'NavigationControl.ZoomOut': 'Diminuisci zoom'
        }
      });

      // Imposta la lingua italiana per la mappa
      map.current.setLanguage('it');

      // Crea l'elemento HTML per il marker
      const el = document.createElement('div');
      el.className = 'marker';
      el.innerHTML = `
        <div class="relative">
          <div class="absolute -top-[60px] -left-[30px] w-[60px] h-[60px] bg-blue-100/70 rounded-full flex items-center justify-center shadow-lg">
            <div class="w-[30px] h-[30px] bg-blue-600 rounded-full flex items-center justify-center shadow-md">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 text-white">
                <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
              </svg>
            </div>
          </div>
        </div>
      `;

      // Aggiungi il marker
      marker.current = new mapboxgl.Marker(el)
        .setLngLat([lng, lat])
        .setPopup(new mapboxgl.Popup({ offset: 25 })
          .setHTML(`
            <div class="p-2">
              <strong class="block mb-1">${title}</strong>
              <span class="text-gray-600">${price.toLocaleString('it-IT')}€/mese</span>
            </div>
          `))
        .addTo(map.current);

      // Aggiungi i controlli di navigazione
      map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

      return () => {
        if (map.current) {
          map.current.remove();
        }
      };
    } catch (error) {
      console.error('Errore inizializzazione mappa:', error);
    }
  }, [lat, lng, title, price]);

  return <div ref={mapContainer} className="w-full h-full" />;
}

interface NearbyUniversity {
  name: string;
  distance: number;
  duration: {
    walking: number;
    transit: number;
  };
  coordinates: {
    lat: number;
    lng: number;
  };
}

export function ListingDetailSkeleton() {
  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="space-y-6">
          {/* Breadcrumb skeleton */}
          <div className="flex items-center space-x-2 skeleton-fade">
            <div className="h-4 w-24 bg-gray-200 rounded"></div>
            <div className="h-4 w-4 bg-gray-200 rounded"></div>
            <div className="h-4 w-32 bg-gray-200 rounded"></div>
          </div>

          {/* Title skeleton */}
          <div className="bg-white rounded-lg p-6 skeleton-fade">
            <div className="h-8 w-3/4 bg-gray-200 rounded mb-4"></div>
            <div className="h-4 w-1/2 bg-gray-200 rounded"></div>
          </div>

          {/* Image gallery skeleton */}
          <div className="aspect-video w-full bg-gray-200 rounded-lg skeleton-fade"></div>

          {/* Content skeleton */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div className="lg:col-span-2 space-y-6">
              {[1, 2, 3].map((i) => (
                <div key={i} className="bg-white rounded-lg p-6 skeleton-fade">
                  <div className="h-6 w-48 bg-gray-200 rounded mb-4"></div>
                  <div className="space-y-2">
                    <div className="h-4 w-full bg-gray-200 rounded"></div>
                    <div className="h-4 w-5/6 bg-gray-200 rounded"></div>
                  </div>
                </div>
              ))}
            </div>
            
            <div className="lg:col-span-1">
              <div className="bg-white rounded-lg p-6 h-[400px] skeleton-fade">
                <div className="space-y-4">
                  <div className="h-10 w-full bg-gray-200 rounded"></div>
                  <div className="h-4 w-3/4 bg-gray-200 rounded"></div>
                  <div className="h-4 w-1/2 bg-gray-200 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const waitForGoogleMaps = () => {
  return new Promise<void>((resolve) => {
    if (window.google && window.google.maps) {
      resolve();
    } else {
      const checkGoogleMaps = setInterval(() => {
        if (window.google && window.google.maps) {
          clearInterval(checkGoogleMaps);
          resolve();
        }
      }, 100);
    }
  });
};

export function ListingDetail() {
  const { id } = useParams();
  const [listing, setListing] = useState<Listing | null>(null);
  const [loading, setLoading] = useState(true);
  const [universitiesLoading, setUniversitiesLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [viewIncremented, setViewIncremented] = useState(false);
  const [showFloorPlan, setShowFloorPlan] = useState(false);
  const [showRepublishConfirm, setShowRepublishConfirm] = useState(false);
  const { userProfile } = useAuthContext();
  const location = useLocation();
  const isAnalyticsPage = location.pathname.includes('analytics');
  const isOwner = userProfile?.id === listing?.userId;
  const isFeatured = listing?.featured?.active && new Date(listing.featured.expiresAt) > new Date();
  const [nearbyUniversities, setNearbyUniversities] = useState<NearbyUniversity[]>([]);
  const placesServiceRef = useRef<google.maps.places.PlacesService | null>(null);
  const distanceServiceRef = useRef<google.maps.DistanceMatrixService | null>(null);

  const handleRepublish = async () => {
    if (!userProfile || !listing) {
      toast.error('Devi essere autenticato per ripubblicare l\'annuncio');
      return;
    }

    try {
      const userCredits = await getUserCredits(userProfile.id);
      if (userCredits < CREDITS_COST) {
        toast.error(
          `Crediti insufficienti. Servono ${CREDITS_COST} crediti per ripubblicare un annuncio.`
        );
        return;
      }

      setShowRepublishConfirm(true);
    } catch (error) {
      console.error('Errore ripubblicazione:', error);
      toast.error(error instanceof Error ? error.message : 'Errore durante la ripubblicazione');
    }
  };

  const handleConfirmRepublish = async () => {
    if (!listing || !userProfile) return;
    setLoading(true);

    try {
      await republishListing(listing.id, userProfile.id);
      toast.success('Annuncio ripubblicato con successo!');
      const updatedListing = await getListing(listing.id);
      if (updatedListing) {
        setListing(updatedListing);
      }
    } catch (error) {
      console.error('Errore ripubblicazione:', error);
      toast.error(error instanceof Error ? error.message : 'Errore durante la ripubblicazione');
    } finally {
      setShowRepublishConfirm(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadListing = async () => {
      try {
        setLoading(true);
        const data = await getListing(id);
        if (data) {
          setListing(data);
          if (!viewIncremented && !isOwner && !isAnalyticsPage) {
            await incrementListingViews(id);
            setViewIncremented(true);
          }
        }
      } catch (error) {
        console.error('Error loading listing:', error);
        setError('Errore nel caricamento dell\'annuncio');
      } finally {
        setLoading(false);
      }
    };

    loadListing();
  }, [id, isOwner, isAnalyticsPage, viewIncremented]);

  useEffect(() => {
    const initializeServices = async () => {
      try {
        await waitForGoogleMaps();
        
        const mapDiv = document.createElement('div');
        mapDiv.style.display = 'none';
        document.body.appendChild(mapDiv);
        
        const map = new google.maps.Map(mapDiv, {
          center: { lat: 0, lng: 0 },
          zoom: 1
        });
        
        placesServiceRef.current = new google.maps.places.PlacesService(map);
        distanceServiceRef.current = new google.maps.DistanceMatrixService();

        return () => {
          document.body.removeChild(mapDiv);
        };
      } catch (error) {
        console.error('Errore inizializzazione servizi Google Maps:', error);
      }
    };

    initializeServices();
  }, []);

  useEffect(() => {
    async function findNearbyUniversities() {
      if (!listing?.location || !placesServiceRef.current || !distanceServiceRef.current) return;
      
      try {
        setUniversitiesLoading(true);

        const places = await new Promise<google.maps.places.PlaceResult[]>((resolve, reject) => {
          const request = {
            location: new google.maps.LatLng(listing.location.lat, listing.location.lng),
            radius: 5000,
            type: 'university',
            keyword: 'università|university|politecnico|ateneo',
            language: 'it'
          };

          placesServiceRef.current?.nearbySearch(request, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && results) {
              resolve(results.slice(0, 4));
            } else {
              reject(new Error(`Places Service status: ${status}`));
            }
          });
        });

        const origin = new google.maps.LatLng(listing.location.lat, listing.location.lng);
        const destinations = places.map(place => place.geometry!.location);

        // Calcola tempi e distanze a piedi
        const walkingMatrix = await new Promise<google.maps.DistanceMatrixResponse>((resolve, reject) => {
          distanceServiceRef.current?.getDistanceMatrix({
            origins: [origin],
            destinations: destinations,
            travelMode: google.maps.TravelMode.WALKING,
            unitSystem: google.maps.UnitSystem.METRIC
          }, (response, status) => {
            if (status === 'OK') resolve(response);
            else reject(new Error(`Distance Matrix status: ${status}`));
          });
        });

        // Calcola tempi e distanze con i mezzi
        const transitMatrix = await new Promise<google.maps.DistanceMatrixResponse>((resolve, reject) => {
          distanceServiceRef.current?.getDistanceMatrix({
            origins: [origin],
            destinations: destinations,
            travelMode: google.maps.TravelMode.TRANSIT,
            unitSystem: google.maps.UnitSystem.METRIC
          }, (response, status) => {
            if (status === 'OK') resolve(response);
            else reject(new Error(`Distance Matrix status: ${status}`));
          });
        });

        const universities = places.map((place, index) => ({
          name: place.name!,
          coordinates: {
            lat: place.geometry!.location.lat(),
            lng: place.geometry!.location.lng()
          },
          distance: walkingMatrix.rows[0].elements[index].distance.value,
          duration: {
            walking: walkingMatrix.rows[0].elements[index].duration.value / 60,
            transit: transitMatrix.rows[0].elements[index].duration.value / 60
          }
        }));

        universities.sort((a, b) => a.distance - b.distance);
        setNearbyUniversities(universities);
      } catch (error) {
        console.error('Errore nel recupero delle università:', error);
      } finally {
        setUniversitiesLoading(false);
      }
    }

    if (listing) {
      findNearbyUniversities();
    }
  }, [listing]);

  if (loading) {
    return <ListingDetailSkeleton />;
  }

  if (error || !listing) {
    return (
      <div className="min-h-screen bg-gray-50 pt-24 flex items-center justify-center">
        <div className="text-center">
          <AlertTriangle className="h-12 w-12 text-red-500 mx-auto mb-4" />
          <p className="text-red-600 mb-4">{error || 'Annuncio non trovato'}</p>
          <Link
            to="/"
            className="text-blue-600 hover:text-blue-700"
          >
            Torna alla home
          </Link>
        </div>
      </div>
    );
  }

  const isExpired = listing.status === 'expired';
  const isNoSpots = listing.availableSpots === 0 && listing.status === 'active';
  const isUnavailable = listing.status === 'inactive' || listing.status === 'unavailable';
  const availableSpots = listing.availableSpots;
  const spotsText = availableSpots === 1 ? 'posto disponibile' : 'posti disponibili';

  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Breadcrumb con FavoriteButton */}
        <div className="flex items-center justify-between mb-6">
          <ListingBreadcrumb
            listingId={listing.id}
            listingTitle={listing.title}
            isOwner={isOwner}
            isAnalyticsPage={isAnalyticsPage}
          />
          
          {!isOwner && (
            <div>
              <FavoriteButton listingId={listing.id} size="lg" />
            </div>
          )}
        </div>

        {/* Featured Badge */}
        {isFeatured && isOwner && (
          <div className="mb-4 sm:mb-6 bg-gradient-to-r from-yellow-50 to-orange-50 border border-yellow-200 rounded-lg p-3 sm:p-4 flex items-center gap-3">
            <Star className="h-5 w-5 text-yellow-500 flex-shrink-0" fill="currentColor" />
            <div className="min-w-0">
              <p className="font-medium text-yellow-800 text-sm sm:text-base">
                Annuncio in Evidenza
              </p>
              <p className="text-xs sm:text-sm text-yellow-600">
                Questo annuncio è in evidenza fino al {new Date(listing.featured.expiresAt).toLocaleDateString('it-IT')} ore {new Date(listing.featured.expiresAt).toLocaleTimeString('it-IT', {
                  hour: '2-digit',
                  minute: '2-digit'
                })}
              </p>
            </div>
          </div>
        )}

        {(isUnavailable || isExpired || isNoSpots) && (
          <div className="mb-6 bg-red-50 border border-red-200 rounded-lg p-4 sm:p-6">
            <div className="flex items-start gap-3">
              {isExpired ? (
                <>
                  <Clock className="h-5 w-5 text-red-500 flex-shrink-0" />
                  <div>
                    <h3 className="text-lg font-medium text-red-800">
                      Annuncio scaduto
                    </h3>
                    <p className="mt-1 text-sm text-red-600">
                      Questo annuncio è scaduto e non è più visibile nelle ricerche.
                    </p>
                    {isOwner && (
                      <button
                        onClick={handleRepublish}
                        className="mt-4 inline-flex items-center gap-2 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors text-sm font-medium"
                      >
                        <RefreshCw className="h-4 w-4" />
                        Ripubblica annuncio
                      </button>
                    )}
                  </div>
                </>
              ) : isNoSpots ? (
                <>
                  <Users className="h-5 w-5 text-red-500 flex-shrink-0" />
                  <div>
                    <h3 className="text-lg font-medium text-red-800">
                      Posti esauriti
                    </h3>
                    <p className="mt-1 text-sm text-red-600">
                      Questo annuncio ha esaurito i posti disponibili.
                    </p>
                    {isOwner && (
                      <button
                        onClick={handleRepublish}
                        className="mt-4 inline-flex items-center gap-2 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors text-sm font-medium"
                      >
                        <RefreshCw className="h-4 w-4" />
                        Ripubblica annuncio
                      </button>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <AlertTriangle className="h-5 w-5 text-red-500 flex-shrink-0" />
                  <div>
                    <h3 className="text-lg font-medium text-red-800">
                      Annuncio non disponibile
                    </h3>
                    <p className="mt-1 text-sm text-red-600">
                      Questo annuncio non è al momento disponibile.
                    </p>
                    {isOwner && (
                      <button
                        onClick={handleRepublish}
                        className="mt-4 inline-flex items-center gap-2 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors text-sm font-medium"
                      >
                        <RefreshCw className="h-4 w-4" />
                        Ripubblica annuncio
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        <ListingHeader listing={listing} availableSpots={availableSpots} spotsText={spotsText} />

        <div className="flex flex-col lg:flex-row gap-6">
          <div className="lg:w-2/3">
            <div className="space-y-6">
              <div className="space-y-4">
                <ImageGallery images={listing.images} />
                {listing.floorPlans && listing.floorPlans.length > 0 && (
                  <button
                    onClick={() => setShowFloorPlan(true)}
                    className="inline-flex items-center gap-2 px-3 py-1.5 bg-white border border-gray-200 rounded-md hover:bg-gray-50 transition-colors text-sm font-medium text-gray-700"
                  >
                    <FileText className="h-4 w-4 text-gray-500" />
                    Visualizza planimetria
                  </button>
                )}
              </div>

              <div className="bg-white rounded-lg shadow-sm p-6">
                <h2 className="text-lg font-medium text-gray-900 mb-4">Descrizione</h2>
                <div className="prose prose-sm max-w-none text-gray-600">
                  {listing.description ? (
                    <p className="whitespace-pre-wrap">{listing.description}</p>
                  ) : (
                    <p className="text-gray-500 italic">Nessuna descrizione disponibile</p>
                  )}
                </div>
              </div>

              <PropertyFeatures listing={listing} />
              <ListingFeatures listing={listing} />
              <ContractDetails listing={listing} />
              <HouseRules listing={listing} />

              {/* Map Section */}
              <div className="bg-white rounded-lg shadow-sm p-6 mt-6">
                <h2 className="text-lg font-medium text-gray-900 mb-4">Posizione</h2>
                <div className="h-[400px] rounded-lg overflow-hidden mb-6">
                  <ListingLocationMap 
                    lat={listing?.location.lat}
                    lng={listing?.location.lng}
                    title={listing?.title}
                    price={listing?.price}
                  />
                </div>
                
                <div className="space-y-4">
                  <h3 className="text-base font-medium text-gray-900 border-b border-gray-200 pb-3">
                    Università più vicine
                  </h3>

                  {universitiesLoading ? (
                    <div className="flex justify-center py-4">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                    </div>
                  ) : nearbyUniversities.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      {nearbyUniversities.map((uni) => (
                        <div key={uni.name} className="flex items-start gap-3">
                          <div className="p-2 bg-blue-50 rounded-lg">
                            <GraduationCap className="h-5 w-5 text-blue-600" />
                          </div>
                          <div>
                            <p className="font-medium text-gray-900">{uni.name}</p>
                            <div className="space-y-1 mt-1">
                              <p className="text-sm text-gray-500 flex items-center gap-1">
                                <User className="h-4 w-4" /> {Math.round(uni.duration.walking)} min a piedi
                              </p>
                              <p className="text-sm text-gray-500 flex items-center gap-1">
                                <Bus className="h-4 w-4" /> {Math.round(uni.duration.transit)} min con i mezzi
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-sm text-gray-500">Nessuna università trovata nelle vicinanze</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="lg:w-1/3">
            <div className="sticky top-24">
              <ContactForm 
                listingId={listing.id}
                listingOwnerId={listing.userId}
                listingTitle={listing.title}
                featured={listing.featured}
              />
            </div>
          </div>
        </div>

        <LinkedListings 
          listingId={listing.id}
          linkedIds={listing.linkedListings || []}
        />
      </div>

      {showFloorPlan && listing.floorPlans && (
        <FloorPlanModal
          floorPlans={listing.floorPlans}
          onClose={() => setShowFloorPlan(false)}
        />
      )}

      {showRepublishConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <div className="space-y-4">
              <p className="text-sm text-gray-600 font-medium">
                Questa operazione costerà {CREDITS_COST} crediti. Vuoi continuare?
              </p>
              <div className="flex gap-4">
                <button
                  onClick={handleConfirmRepublish}
                  disabled={loading}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
                >
                  {loading ? 'Ripubblicazione...' : 'Conferma'}
                </button>
                <button
                  onClick={() => setShowRepublishConfirm(false)}
                  className="px-4 py-2 bg-white text-gray-600 border border-gray-200 rounded-md hover:bg-gray-50"
                >
                  Annulla
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
import React from 'react';
import { MapPin, Users, Star, Euro, Calendar } from 'lucide-react';
import { Listing } from '../types/listing';

interface ListingHeaderProps {
  listing: Listing;
  availableSpots: number;
  spotsText: string;
}

export function ListingHeader({ listing, availableSpots, spotsText }: ListingHeaderProps) {
  const isFeatured = listing.featured?.active && new Date(listing.featured.expiresAt) > new Date();

  return (
    <div className="bg-white rounded-lg shadow-sm p-4 md:p-6 mb-6">
      <div className="space-y-6">
        {/* Titolo e Badge */}
        <div className="flex flex-col sm:flex-row sm:items-start sm:justify-between gap-2">
          <div className="space-y-2">
            <div className="flex flex-col gap-2">
              {isFeatured && (
                <span className="inline-flex items-center px-2 py-1 text-xs font-semibold rounded-full bg-yellow-100 text-yellow-800 whitespace-nowrap w-fit">
                  <Star className="h-3 w-3 mr-1" fill="currentColor" />
                  In Evidenza
                </span>
              )}
              <h1 className="text-xl md:text-2xl font-bold text-gray-900 break-words">
                {listing.title}
              </h1>
            </div>
            <div className="flex items-center text-gray-500 text-sm">
              <MapPin className="h-4 w-4 mr-1 flex-shrink-0" />
              <span className="break-words">
                {listing.location.formattedAddress}
              </span>
            </div>
          </div>
          <div className="flex items-center text-gray-500 text-sm whitespace-nowrap">
            <Calendar className="h-4 w-4 mr-1 flex-shrink-0" />
            <span>
              Pubblicato il {listing.createdAt.toLocaleDateString('it-IT')}
            </span>
          </div>
        </div>

        {/* Info Cards Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          {/* Canone Card */}
          <div className="bg-white border rounded-lg p-4">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-blue-50 rounded-lg">
                <Euro className="h-5 w-5 text-blue-600" />
              </div>
              <div>
                <p className="text-sm text-gray-500">Canone mensile</p>
                <p className="font-semibold">€{listing.price}/mese</p>
              </div>
            </div>
          </div>

          {/* Disponibilità Card */}
          <div className="bg-white border rounded-lg p-4">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-green-50 rounded-lg">
                <Users className="h-5 w-5 text-green-600" />
              </div>
              <div>
                <p className="text-sm text-gray-500">Disponibilità</p>
                <p className="font-semibold">{availableSpots} {spotsText}</p>
              </div>
            </div>
          </div>

          {/* Data Card */}
          <div className="bg-white border rounded-lg p-4">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-purple-50 rounded-lg">
                <Calendar className="h-5 w-5 text-purple-600" />
              </div>
              <div>
                <p className="text-sm text-gray-500">Disponibile dal</p>
                <p className="font-semibold">{new Date(listing.availableFrom).toLocaleDateString()}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
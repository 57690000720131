export const countries = [
  { code: 'IT', name: 'Italia' },
  { code: 'FR', name: 'Francia' },
  { code: 'DE', name: 'Germania' },
  { code: 'ES', name: 'Spagna' },
  { code: 'GB', name: 'Regno Unito' },
  { code: 'US', name: 'Stati Uniti' },
  { code: 'AT', name: 'Austria' },
  { code: 'BE', name: 'Belgio' },
  { code: 'BG', name: 'Bulgaria' },
  { code: 'HR', name: 'Croazia' },
  { code: 'DK', name: 'Danimarca' },
  { code: 'EE', name: 'Estonia' },
  { code: 'FI', name: 'Finlandia' },
  { code: 'GR', name: 'Grecia' },
  { code: 'IE', name: 'Irlanda' },
  { code: 'LV', name: 'Lettonia' },
  { code: 'LT', name: 'Lituania' },
  { code: 'LU', name: 'Lussemburgo' },
  { code: 'MT', name: 'Malta' },
  { code: 'NL', name: 'Paesi Bassi' },
  { code: 'PL', name: 'Polonia' },
  { code: 'PT', name: 'Portogallo' },
  { code: 'CZ', name: 'Repubblica Ceca' },
  { code: 'RO', name: 'Romania' },
  { code: 'SK', name: 'Slovacchia' },
  { code: 'SI', name: 'Slovenia' },
  { code: 'SE', name: 'Svezia' },
  { code: 'HU', name: 'Ungheria' },
  { code: 'CY', name: 'Cipro' }
]; 
import React from 'react';
import { Building2, Ruler, Users, Bath, Plug, Flame } from 'lucide-react';
import { Listing, heatingLabels, energyClassLabels } from '../types/listing';

interface PropertyFeaturesProps {
  listing: Listing;
}

const floorLabels: { [key: string]: string } = {
  'PT': 'Piano Terra',
  'PR': 'Piano Rialzato',
  'P1': 'Primo Piano',
  'P2': 'Secondo Piano',
  'P3': 'Terzo Piano',
  'P4': 'Quarto Piano',
  'P5': 'Quinto Piano',
  'P6': 'Sesto Piano',
  'P7': 'Settimo Piano',
  'P8': 'Ottavo Piano',
  'P9': 'Nono Piano',
  'P10': 'Decimo Piano',
  'AT': 'Attico',
  'SI': 'Piano Seminterrato',
  'IN': 'Piano Interrato',
  '0': 'Piano Terra',
  '1': 'Primo Piano',
  '2': 'Secondo Piano',
  '3': 'Terzo Piano',
  '4': 'Quarto Piano',
  '5': 'Quinto Piano',
  '6': 'Sesto Piano',
  '7': 'Settimo Piano',
  '8': 'Ottavo Piano',
  '9': 'Nono Piano',
  '10': 'Decimo Piano'
};

export function PropertyFeatures({ listing }: PropertyFeaturesProps) {
  const availableSpots = listing.category === 'single_room' ? 1 : listing.availableSpots;
  const spotsText = availableSpots === 1 ? 'posto disponibile' : 'posti disponibili';
  const isRoom = ['single_room', 'double_room', 'triple_room', 'quad_room'].includes(listing.category);

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h2 className="text-lg font-medium mb-6">Caratteristiche Immobile</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="flex items-start gap-3">
          <div className="p-2 bg-blue-50 rounded-lg">
            <Building2 className="h-5 w-5 text-blue-600" />
          </div>
          <div>
            <p className="font-medium text-gray-900">
              {floorLabels[listing.floor] || (
                isNaN(Number(listing.floor)) 
                  ? `Piano ${listing.floor}`
                  : floorLabels[listing.floor.toString()]
              )}
            </p>
            {listing.features.elevator && (
              <p className="text-sm text-gray-500">Con ascensore</p>
            )}
          </div>
        </div>

        <div className="flex items-start gap-3">
          <div className="p-2 bg-green-50 rounded-lg">
            <Ruler className="h-5 w-5 text-green-600" />
          </div>
          <div>
            {isRoom ? (
              <>
                <p className="font-medium text-gray-900">
                  Stanza: {listing.roomSize} m²
                </p>
                <p className="text-sm text-gray-500">
                  Immobile: {listing.size} m²
                </p>
              </>
            ) : (
              <>
                <p className="font-medium text-gray-900">{listing.size} m²</p>
                <p className="text-sm text-gray-500">
                  {listing.rooms} {listing.rooms === 1 ? 'stanza' : 'stanze'}
                </p>
              </>
            )}
          </div>
        </div>

        <div className="flex items-start gap-3">
          <div className="p-2 bg-yellow-50 rounded-lg">
            <Users className="h-5 w-5 text-yellow-600" />
          </div>
          <div>
            <p className="font-medium text-gray-900">{availableSpots} {spotsText}</p>
            <p className="text-sm text-gray-500">
              {listing.maxOccupants} {listing.maxOccupants === 1 ? 'posto totale' : 'posti totali'}
            </p>
          </div>
        </div>

        <div className="flex items-start gap-3">
          <div className="p-2 bg-purple-50 rounded-lg">
            <Bath className="h-5 w-5 text-purple-600" />
          </div>
          <div>
            <p className="font-medium text-gray-900">
              {listing.bathrooms} {listing.bathrooms === 1 ? 'bagno' : 'bagni'}
            </p>
            <p className="text-sm text-gray-500">
              {energyClassLabels[listing.energyClass]}
            </p>
          </div>
        </div>

        <div className="flex items-start gap-3">
          <div className="p-2 bg-red-50 rounded-lg">
            <Plug className="h-5 w-5 text-red-600" />
          </div>
          <div>
            <p className="font-medium text-gray-900">
              {listing.utilities.type === 'included' ? (
                'Spese incluse'
              ) : (
                <>€{listing.utilities.monthlyFee} /mese</>
              )}
            </p>
            <p className="text-sm text-gray-500">Utenze e spese condominiali</p>
          </div>
        </div>

        <div className="flex items-start gap-3">
          <div className="p-2 bg-orange-50 rounded-lg">
            <Flame className="h-5 w-5 text-orange-600" />
          </div>
          <div>
            <p className="font-medium text-gray-900">
              {heatingLabels[listing.heating]}
            </p>
            <p className="text-sm text-gray-500">Riscaldamento</p>
          </div>
        </div>
      </div>
    </div>
  );
}
import React from 'react';
import { 
  Document, 
  Page, 
  Text, 
  View, 
  StyleSheet, 
  PDFDownloadLink,
  Image,
  Font 
} from '@react-pdf/renderer';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { FileDown } from 'lucide-react';
import { it } from 'date-fns/locale';

// Registra il font Inter
Font.register({
  family: 'Inter',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2',
      fontWeight: 400,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2',
      fontWeight: 500,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2',
      fontWeight: 600,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2',
      fontWeight: 700,
    }
  ]
});

interface PaymentReceiptProps {
  payment: {
    id: string;
    amount: number;
    createdAt: Date;
    paymentMethod: string;
    metadata: {
      type: 'credits' | 'featured';
      listingId?: string;
      listingTitle?: string;
      planId?: string;
      planName?: string;
      planDuration?: number;
      planDurationLabel?: string;
      quantity?: number;
      packageName?: string;
    };
    status: string;
    invoiceData?: {
      user: {
        name: string;
        email: string;
        address?: string;
        city?: string;
        country?: string;
        fiscalCode?: string;
        businessName?: string;
        vatNumber?: string;
      }
    }
  };
}

// Aggiorna il percorso del logo
const LOGO_URL = '/unistanza-logo.png';

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 10,
    fontFamily: 'Helvetica'
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 40
  },
  headerLeft: {
    flex: 1
  },
  headerRight: {
    width: 120
  },
  title: {
    fontSize: 24,
    marginBottom: 20
  },
  infoRow: {
    marginBottom: 5
  },
  infoLabel: {
    color: '#666'
  },
  logo: {
    width: 120,
    height: 'auto'
  },
  billingSection: {
    flexDirection: 'row',
    marginBottom: 40
  },
  billingColumn: {
    flex: 1
  },
  billingHeader: {
    color: '#666',
    marginBottom: 5
  },
  companyName: {
    fontWeight: 'bold'
  },
  amount: {
    fontSize: 14,
    marginBottom: 20
  },
  table: {
    marginBottom: 20
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#666',
    paddingBottom: 5,
    marginBottom: 10
  },
  tableHeaderCell: {
    flex: 1,
    color: '#666'
  },
  tableRow: {
    flexDirection: 'row',
    marginBottom: 5
  },
  tableCell: {
    flex: 1
  },
  summary: {
    marginLeft: 'auto',
    width: '50%'
  },
  summaryRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5
  },
  totalRow: {
    borderTopWidth: 1,
    borderTopColor: '#666',
    paddingTop: 5,
    marginTop: 5,
    fontWeight: 'bold'
  },
  footer: {
    position: 'absolute',
    bottom: 40,
    left: 40,
    right: 40
  },
  footerContent: {
    marginBottom: 20
  },
  footerText: {
    fontSize: 8,
    color: '#666'
  },
  pageNumberContainer: {
    alignItems: 'flex-end'
  },
  pageNumber: {
    fontSize: 8,
    color: '#666'
  },
  alignRight: {
    textAlign: 'right'
  }
});

const ReceiptDocument = ({ payment }: PaymentReceiptProps) => {
  // Funzione per ottenere i dati dell'utente
  const getUserData = () => {
    // Gestisce sia il vecchio formato (invoiceData.user) che il nuovo (invoiceData)
    const paymentUser = payment?.invoiceData?.user || payment?.invoiceData || {};
    
    return {
      firstName: paymentUser.firstName || '',
      lastName: paymentUser.lastName || '',
      email: paymentUser.email || '',
      address: paymentUser.address || '',
      city: paymentUser.city || '',
      province: paymentUser.province || '',
      postalCode: paymentUser.postalCode || '',
      country: paymentUser.country || '',
      fiscalCode: paymentUser.fiscalCode || '',
      businessName: paymentUser.businessName || '',
      vatNumber: paymentUser.vatNumber || '',
      sdiCode: paymentUser.sdiCode || ''
    };
  };

  // Funzione per ottenere la descrizione del prodotto
  const getProductDescription = (payment: PaymentReceiptProps['payment']) => {
    if (!payment?.metadata?.type) return '';
    
    switch (payment.metadata.type) {
      case 'featured':
        return [
          `Piano in Evidenza: ${payment.metadata.planName || 'N/D'}`,
          `Annuncio: "${payment.metadata.listingTitle || 'N/D'}"`,
          `Durata: ${payment.metadata.planDurationLabel || payment.metadata.planDuration + ' ore'}`
        ].filter(Boolean).join('\n');
        
      case 'credits':
        return [
          `${payment.metadata.packageName || 'Pacchetto Crediti'}: ${payment.metadata.quantity} crediti`
        ].filter(Boolean).join('\n');
        
      default:
        return '';
    }
  };

  const userData = getUserData();
  const total = payment.amount || 0;

  // Formatta la data in italiano
  const formattedDate = format(payment.createdAt, 'd MMMM yyyy', { locale: it });

  // Funzione per formattare il metodo di pagamento
  const getPaymentMethodLabel = (method: string) => {
    switch (method?.toLowerCase()) {
      case 'card':
        return 'Carta di credito';
      case 'paypal':
        return 'PayPal';
      case 'stripe':
        return 'Carta di credito';
      default:
        return method || 'N/D';
    }
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerLeft}>
            <Text style={styles.title}>Ricevuta</Text>
            <View style={styles.infoRow}>
              <Text style={styles.infoLabel}>Numero ricevuta</Text>
              <Text style={styles.infoValue}>{payment.id}</Text>
            </View>
            <View style={styles.infoRow}>
              <Text style={styles.infoLabel}>Data</Text>
              <Text style={styles.infoValue}>{formattedDate}</Text>
            </View>
            <View style={styles.infoRow}>
              <Text style={styles.infoLabel}>Metodo di pagamento</Text>
              <Text style={styles.infoValue}>{getPaymentMethodLabel(payment.paymentMethod)}</Text>
            </View>
          </View>
          <View style={styles.headerRight}>
            <Image src={LOGO_URL} style={styles.logo} />
          </View>
        </View>

        <View style={styles.billingSection}>
          <View style={styles.billingColumn}>
            <Text style={styles.billingHeader}>Da</Text>
            <Text style={styles.companyName}>Unistanza</Text>
            <Text>Via Andreas Hofer 59</Text>
            <Text>39055 Laives (BZ)</Text>
            <Text>Italia</Text>
            <Text>P.IVA: IT02967140217</Text>
          </View>
          <View style={styles.billingColumn}>
            <Text style={styles.billingHeader}>A</Text>
            <Text style={styles.companyName}>
              {userData.firstName} {userData.lastName}
            </Text>
            {userData.fiscalCode && <Text>Codice Fiscale: {userData.fiscalCode}</Text>}
            {userData.vatNumber && (
              <>
                <Text>P.IVA: {userData.vatNumber}</Text>
                <Text>{userData.businessName}</Text>
              </>
            )}
            {userData.address && <Text>{userData.address}</Text>}
            {userData.city && <Text>{userData.city}</Text>}
            {userData.province && <Text>{userData.province}</Text>}
            {userData.country && <Text>{userData.country}</Text>}
            <Text>{userData.email}</Text>
          </View>
        </View>

        <Text style={styles.amount}>
          € {total.toFixed(2)} pagati il {formattedDate}
        </Text>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={styles.tableHeaderCell}>Descrizione</Text>
            <Text style={styles.tableHeaderCell}>Qtà</Text>
            <Text style={styles.tableHeaderCell}>Prezzo</Text>
            <Text style={[styles.tableHeaderCell, styles.alignRight]}>Totale</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>{getProductDescription(payment)}</Text>
            <Text style={styles.tableCell}>1</Text>
            <Text style={styles.tableCell}>€ {total.toFixed(2)}</Text>
            <Text style={[styles.tableCell, styles.alignRight]}>€ {total.toFixed(2)}</Text>
          </View>
        </View>

        <View style={styles.summary}>
          <View style={styles.summaryRow}>
            <Text>Subtotale</Text>
            <Text>€ {total.toFixed(2)}</Text>
          </View>
          <View style={[styles.summaryRow, styles.totalRow]}>
            <Text>Totale</Text>
            <Text>€ {total.toFixed(2)}</Text>
          </View>
          <View style={[styles.summaryRow, styles.totalRow]}>
            <Text>Importo dovuto</Text>
            <Text>€ {total.toFixed(2)}</Text>
          </View>
        </View>

        <View style={styles.footer}>
          <View style={styles.footerContent}>
            <Text style={styles.footerText}>
              Operazione senza applicazione dell'IVA, effettuata ai sensi dell'articolo 1, commi da 54 a 89, l. n. 190 del 2014 così come modificato dalla l. n. 208 del 2015 e dalla l. n. 145 del 2018
            </Text>
          </View>
          <View style={styles.pageNumberContainer}>
            <Text style={styles.pageNumber}>Pagina 1 di 1</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

// Componente wrapper per gestire il caricamento lato client
export function PaymentReceipt({ payment }: PaymentReceiptProps) {
  return (
    <PDFDownloadLink
      document={<ReceiptDocument payment={payment} />}
      fileName={`ricevuta_${payment.id}.pdf`}
      className="inline-flex items-center gap-1.5 text-xs text-gray-600 hover:text-gray-900"
    >
      {({ loading, error }) => (
        <div className="inline-flex items-center gap-1.5">
          <FileDown className="h-4 w-4" />
          {loading ? 'Generazione...' : error ? `Errore: ${error.message}` : 'Scarica Ricevuta'}
        </div>
      )}
    </PDFDownloadLink>
  );
} 
import React, { useState, useEffect } from 'react';
import { MessageSquare, Send, CheckCircle2, Clock, XCircle, Bell } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { Notification, onNotificationsChange, markAsRead, markAllAsRead } from '../../lib/notifications';
import { formatDistanceToNow } from 'date-fns';
import { it } from 'date-fns/locale';
import { FirestoreError } from 'firebase/firestore';
import { toast } from 'react-hot-toast';

export function NotificationsTab() {
  const { userProfile } = useAuthContext();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const allowedNotificationTypes = [
    'ticket_reply',
    'application_received',
    'application_accepted',
    'application_rejected',
    'application_expired'
  ];

  useEffect(() => {
    if (!userProfile?.id) {
      setLoading(false);
      return;
    }

    const unsubscribe = onNotificationsChange(userProfile.id, (updatedNotifications) => {
      const filteredNotifications = updatedNotifications.filter(
        notification => allowedNotificationTypes.includes(notification.type)
      );
      setNotifications(filteredNotifications);
      setLoading(false);
    }, () => {
      setLoading(false);
    });

    return () => unsubscribe();
  }, [userProfile?.id]);

  const handleNotificationClick = async (notification: Notification) => {
    try {
      await markAsRead(notification.id);
      
      const { path, state } = getNotificationLink(notification, userProfile?.userType || 'student');
      navigate(path, { state });
      
    } catch (error) {
      console.error('Errore durante la gestione del click sulla notifica:', error);
      toast.error('Errore durante la lettura della notifica');
    }
  };

  const handleMarkAllAsRead = async () => {
    if (!userProfile?.id) return;
    await markAllAsRead(userProfile.id);
  };

  const getNotificationLink = (notification: Notification) => {
    switch (notification.type) {
      case 'application_received':
        return '/dashboard/landlord?tab=applications';
      case 'application_accepted':
        return '/dashboard/student?tab=applications';
      case 'ticket_reply':
        return '/dashboard/support';
      default:
        return '#';
    }
  };

  const getIcon = (type: string) => {
    switch (type) {
      case 'application_received':
        return <Send className="h-5 w-5 text-blue-500" />;
      case 'application_accepted':
        return <CheckCircle2 className="h-5 w-5 text-green-500" />;
      case 'application_rejected':
        return <XCircle className="h-5 w-5 text-red-500" />;
      case 'application_expired':
        return <Clock className="h-5 w-5 text-orange-500" />;
      case 'ticket_reply':
        return <MessageSquare className="h-5 w-5 text-purple-500" />;
      default:
        return <Bell className="h-5 w-5 text-gray-500" />;
    }
  };

  if (loading) {
    return (
      <div className="text-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
        <p className="mt-4 text-gray-500">Caricamento notifiche...</p>
      </div>
    );
  }

  if (!userProfile) {
    return (
      <div className="text-center py-12 bg-white rounded-lg shadow-sm">
        <Clock className="h-12 w-12 text-gray-400 mx-auto" />
        <h3 className="mt-2 text-lg font-medium text-gray-900">
          Nessuna notifica
        </h3>
        <p className="mt-1 text-gray-500">
          Al momento non hai notifiche da visualizzare
        </p>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold">Le tue notifiche</h2>
        {notifications.length > 0 && (
          <button
            onClick={handleMarkAllAsRead}
            className="text-sm text-blue-600 font-medium hover:text-blue-700"
          >
            Segna tutte come lette
          </button>
        )}
      </div>

      <div className="space-y-4">
        {notifications.length === 0 ? (
          <div className="text-center py-12 bg-white rounded-lg shadow-sm">
            <Clock className="h-12 w-12 text-gray-400 mx-auto" />
            <h3 className="mt-2 text-lg font-medium text-gray-900">
              Nessuna notifica
            </h3>
            <p className="mt-1 text-gray-500">
              Al momento non hai notifiche da visualizzare
            </p>
          </div>
        ) : (
          notifications.map((notification) => (
            <Link
              key={notification.id}
              to={getNotificationLink(notification)}
              onClick={() => handleNotificationClick(notification)}
              className={`block bg-white rounded-lg shadow-sm hover:shadow transition-shadow ${
                !notification.read ? 'border-l-4 border-blue-500' : ''
              }`}
            >
              <div className="p-4">
                <div className="flex gap-4">
                  {getIcon(notification.type)}
                  <div className="flex-1 min-w-0">
                    <p className={`text-base font-medium text-gray-900 ${
                      !notification.read ? 'font-semibold' : ''
                    }`}>
                      {notification.title}
                    </p>
                    <p className="text-sm text-gray-500 mt-1">
                      {notification.message}
                    </p>
                    <p className="text-xs text-gray-400 mt-2">
                      {formatDistanceToNow(notification.createdAt, { 
                        addSuffix: true,
                        locale: it 
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          ))
        )}
      </div>
    </div>
  );
}
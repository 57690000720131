import React, { useState, useEffect } from 'react';
import { 
  Mail, 
  Phone, 
  Home, 
  User, 
  AlertTriangle, 
  ChevronDown, 
  FileText, 
  Building2,
  MoreVertical,
  Shield,
  Flag,
  X,
  ArrowLeft
} from 'lucide-react';
import { Chat } from '../../types/chat';
import { useAuthContext } from '../../contexts/AuthContext';
import { ListingDataViewModal } from '../listings/ListingDataViewModal';
import { ApplicationDataViewModal } from '../dashboard/landlord/ApplicationDataViewModal';
import { blockUser, unblockUser, isUserBlocked } from '../../lib/blocks';
import { toast } from 'react-toastify';
import { doc, collection, setDoc, serverTimestamp, query, getDocs, writeBatch, where } from 'firebase/firestore';
import { db } from '../../lib/firebase';

interface ChatHeaderProps {
  chat: Chat;
  otherPartyName: string;
  onBack?: () => void;
  isMobile?: boolean;
}

export function ChatHeader({ chat, otherPartyName, onBack, isMobile }: ChatHeaderProps) {
  const { userProfile } = useAuthContext();
  const [showDetails, setShowDetails] = useState(false);
  const [showDataModal, setShowDataModal] = useState(false);
  const [showListingDataModal, setShowListingDataModal] = useState(false);
  const [showActionsMenu, setShowActionsMenu] = useState(false);
  const isStudent = userProfile?.userType === 'student';
  const [isBlocked, setIsBlocked] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [reportReason, setReportReason] = useState('');
  const [reportDescription, setReportDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [blockedBy, setBlockedBy] = useState<'me' | 'other' | null>(null);
  
  const otherPartyId = isStudent ? chat.landlordId : chat.studentId;

  useEffect(() => {
    if (!userProfile?.id || !otherPartyId) return;
    
    const checkBlockStatus = async () => {
      const blockedByMe = await isUserBlocked(userProfile.id, otherPartyId);
      const blockedByOther = await isUserBlocked(otherPartyId, userProfile.id);
      setIsBlocked(blockedByMe);
      setBlockedBy(blockedByMe ? 'me' : blockedByOther ? 'other' : null);
    };
    
    checkBlockStatus();
  }, [userProfile?.id, otherPartyId]);

  const otherPartyEmail = isStudent ? chat.landlordEmail : chat.studentEmail;
  const otherPartyPhone = isStudent ? chat.landlordPhone : chat.studentPhone;

  const handleBlock = async () => {
    if (!userProfile?.id || !otherPartyId) return;
    
    try {
      await blockUser(userProfile.id, otherPartyId);
      setIsBlocked(true);
      setShowActionsMenu(false);
      setShowBlockModal(false);
      toast.success('Utente bloccato con successo');
    } catch (error) {
      console.error('Errore durante il blocco dell\'utente:', error);
      toast.error('Errore durante il blocco dell\'utente');
    }
  };

  const handleUnblock = async () => {
    if (!userProfile?.id || !otherPartyId) return;
    
    try {
      await unblockUser(userProfile.id, otherPartyId);
      setIsBlocked(false);
      setShowActionsMenu(false);
      toast.success('Utente sbloccato con successo');
    } catch (error) {
      console.error('Errore durante lo sblocco dell\'utente:', error);
      toast.error('Errore durante lo sblocco dell\'utente');
    }
  };

  const handleReport = () => {
    setShowReportModal(true);
    setShowActionsMenu(false);
  };

  const handleReportSubmit = async () => {
    if (!userProfile?.id || !otherPartyId) return;
    
    try {
      setIsSubmitting(true);
      
      // Crea la segnalazione
      const reportRef = doc(collection(db, 'reports'));
      await setDoc(reportRef, {
        reporterId: userProfile.id,
        reportedId: otherPartyId,
        chatId: chat.id,
        reason: reportReason,
        description: reportDescription,
        status: 'pending',
        createdAt: serverTimestamp(),
        reporterName: userProfile.displayName || 'Utente',
        reportedName: otherPartyName
      });

      // Crea notifica per gli admin
      const adminsQuery = query(
        collection(db, 'users'),
        where('userType', '==', 'admin')
      );
      
      const adminsSnapshot = await getDocs(adminsQuery);
      const batch = writeBatch(db);

      adminsSnapshot.forEach(adminDoc => {
        const notificationRef = doc(collection(db, 'notifications'));
        batch.set(notificationRef, {
          userId: adminDoc.id,
          type: 'chat_report',
          title: 'Nuova segnalazione chat',
          content: `${userProfile.displayName || 'Un utente'} ha segnalato una chat con ${otherPartyName}`,
          data: {
            reportId: reportRef.id,
            chatId: chat.id,
            reason: reportReason
          },
          read: false,
          createdAt: serverTimestamp()
        });
      });

      await batch.commit();

      setShowReportModal(false);
      setReportReason('');
      setReportDescription('');
      toast.success('Segnalazione inviata con successo');
    } catch (error) {
      console.error('Errore durante l\'invio della segnalazione:', error);
      toast.error('Errore durante l\'invio della segnalazione');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="border-b">
        {/* Header principale */}
        <div className="p-4 h-[72px] flex items-center">
          <div className="flex items-center justify-between w-full gap-4">
            {isMobile && onBack && (
              <button
                onClick={onBack}
                className="md:hidden p-2 text-gray-600 hover:text-gray-900 rounded-full hover:bg-gray-100"
              >
                <ArrowLeft className="h-5 w-5" />
              </button>
            )}
            <div className="min-w-0">
              <h3 className="font-medium truncate">{otherPartyName}</h3>
              <p className="text-sm text-gray-500 truncate">{chat.listingTitle}</p>
            </div>
            <div className="flex items-center gap-2 flex-shrink-0">
              {otherPartyEmail && (
                <a
                  href={`mailto:${otherPartyEmail}`}
                  className="p-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-full transition-colors"
                  title="Invia email"
                >
                  <Mail className="h-5 w-5" />
                </a>
              )}
              {otherPartyPhone && (
                <a
                  href={`tel:${otherPartyPhone}`}
                  className="p-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-full transition-colors"
                  title="Chiama"
                >
                  <Phone className="h-5 w-5" />
                </a>
              )}
              <button
                onClick={() => setShowDetails(!showDetails)}
                className="p-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-full transition-colors"
              >
                <ChevronDown className={`h-5 w-5 transition-transform ${showDetails ? 'rotate-180' : ''}`} />
              </button>
              
              {/* Menu Azioni */}
              <div className="relative">
                <button
                  onClick={() => setShowActionsMenu(!showActionsMenu)}
                  className="p-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-full transition-colors"
                >
                  <MoreVertical className="h-5 w-5" />
                </button>

                {showActionsMenu && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg border border-gray-100 z-10">
                    <div className="py-1">
                      {isBlocked && blockedBy === 'me' ? (
                        <button
                          onClick={handleUnblock}
                          className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          <Shield className="mr-3 h-4 w-4 text-gray-400" />
                          Sblocca utente
                        </button>
                      ) : !isBlocked && (
                        <button
                          onClick={() => setShowBlockModal(true)}
                          className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          <Shield className="mr-3 h-4 w-4 text-gray-400" />
                          Blocca utente
                        </button>
                      )}
                      <button
                        onClick={handleReport}
                        className="flex items-center gap-2 w-full px-4 py-2 text-sm text-orange-600 hover:bg-orange-50"
                      >
                        <Flag className="h-4 w-4" />
                        Segnala chat
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Disclaimer sempre visibile */}
        <div className="p-3 bg-blue-50 border-t border-blue-100">
          <div className="flex items-start gap-2 text-sm text-blue-700">
            <AlertTriangle className="h-4 w-4 mt-0.5 flex-shrink-0" />
            <p>
              {isStudent ? (
                "L'accettazione della candidatura non garantisce l'assegnazione dell'alloggio. La decisione finale di affittare è a discrezione del proprietario."
              ) : (
                "L'accettazione della candidatura da parte dello studente non costituisce un obbligo di prendere in affitto l'immobile."
              )}
            </p>
          </div>
        </div>

        {/* Dettagli espandibili */}
        {showDetails && (
          <div className="px-4 py-3 bg-gray-50 border-t space-y-4">
            {/* Pulsanti */}
            <div className="flex flex-wrap gap-2">
              <button
                onClick={() => setShowDataModal(true)}
                className="flex items-center gap-1.5 px-3 py-1.5 text-sm text-blue-600 bg-blue-50 border border-blue-100 rounded-md hover:bg-blue-100 transition-colors min-w-[120px] justify-center"
              >
                <FileText className="h-4 w-4" />
                <span>Dati Candidatura</span>
              </button>
              
              <button
                onClick={() => setShowListingDataModal(true)}
                className="flex items-center gap-1.5 px-3 py-1.5 text-sm text-purple-600 bg-purple-50 border border-purple-100 rounded-md hover:bg-purple-100 transition-colors min-w-[120px] justify-center"
              >
                <Building2 className="h-4 w-4" />
                <span>Dati Annuncio</span>
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Modali */}
      {showDataModal && (
        <ApplicationDataViewModal
          application={chat}
          onClose={() => setShowDataModal(false)}
          isLandlord={!isStudent}
        />
      )}

      {showListingDataModal && (
        <ListingDataViewModal
          application={chat}
          onClose={() => setShowListingDataModal(false)}
          isLandlord={!isStudent}
        />
      )}

      {/* Modale di conferma blocco */}
      {showBlockModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">Conferma Blocco</h2>
              <button 
                onClick={() => setShowBlockModal(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            <div className="mb-6">
              <div className="bg-red-50 border border-red-200 rounded-lg p-4">
                <p className="text-sm text-red-700">
                  Bloccando questo utente:
                </p>
                <ul className="mt-2 text-sm text-red-600 list-disc list-inside">
                  <li>Non riceverai più messaggi o notifiche da questo utente</li>
                  <li>L'utente non potrà più inviarti messaggi</li>
                  <li>Potrai sempre sbloccare l'utente in futuro</li>
                </ul>
              </div>
            </div>

            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowBlockModal(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              >
                Annulla
              </button>
              <button
                onClick={handleBlock}
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700"
              >
                Conferma Blocco
              </button>
            </div>
          </div>
        </div>
      )}

      {showReportModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">Segnala Chat</h2>
              <button 
                onClick={() => setShowReportModal(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Motivo della segnalazione
                </label>
                <select
                  value={reportReason}
                  onChange={(e) => setReportReason(e.target.value)}
                  className="w-full rounded-lg border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                >
                  <option value="">Seleziona un motivo</option>
                  <option value="spam">Spam</option>
                  <option value="inappropriate">Contenuto inappropriato</option>
                  <option value="harassment">Molestie</option>
                  <option value="scam">Truffa</option>
                  <option value="other">Altro</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Descrizione
                </label>
                <textarea
                  value={reportDescription}
                  onChange={(e) => setReportDescription(e.target.value)}
                  className="w-full rounded-lg border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows={4}
                  placeholder="Descrivi il problema..."
                  required
                />
              </div>
            </div>

            <div className="flex justify-end gap-4 mt-6">
              <button
                onClick={() => setShowReportModal(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              >
                Annulla
              </button>
              <button
                onClick={handleReportSubmit}
                disabled={!reportReason || !reportDescription || isSubmitting}
                className="px-4 py-2 text-sm font-medium text-white bg-orange-600 rounded-lg hover:bg-orange-700 disabled:opacity-50"
              >
                {isSubmitting ? 'Invio...' : 'Invia Segnalazione'}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
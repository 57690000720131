import React, { useState, useMemo } from 'react';
import { ArrowRight, Save, X, Building2, ListChecks, Plug, Image, FileText, Link } from 'lucide-react';
import { StepIndicator } from './StepIndicator';
import { BasicInfoStep } from './steps/BasicInfoStep';
import { FeaturesStep } from './steps/FeaturesStep';
import { RulesStep } from './steps/RulesStep';
import { MediaStep } from './steps/MediaStep';
import { SummaryStep } from './steps/SummaryStep';
import { LinkListingsStep } from './steps/LinkListingsStep';
import { Listing } from '../../types/listing';
import { PlacesAutocomplete } from '../../components/PlacesAutocomplete';

interface ListingFormProps {
  initialData?: Listing;
  onSubmit: (data: any) => void;
  onCancel: () => void;
  loading?: boolean;
  onDelete?: () => void;
  disableLocationEdit?: boolean;
  userId: string;
  showLinkStep: boolean;
}

const defaultFormData = {
  category: 'single_room',
  contractType: 'standard',
  price: '',
  deposit: '',
  size: '',
  bathrooms: '1',
  floor: '0',
  maxOccupants: '1',
  availableFrom: new Date().toISOString().split('T')[0],
  minStay: '12',
  noticePeriod: '2',
  location: {
    coordinates: [0, 0],
    placeId: '',
    formattedAddress: ''
  },
  features: {},
  kitchenFeatures: {},
  bathroomFeatures: {},
  rules: {},
  utilities: {
    type: 'notIncluded',
    monthlyFee: ''
  },
  images: [],
  floorPlans: [],
  featured: false,
  heating: 'autonomous'
};

export function ListingForm({ 
  initialData, 
  onSubmit, 
  onCancel, 
  loading = false, 
  onDelete,
  disableLocationEdit = false,
  userId,
  showLinkStep
}: ListingFormProps) {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState(() => {
    if (!initialData) return defaultFormData;

    // Format the date if it exists
    let availableFrom = defaultFormData.availableFrom;
    if (initialData.availableFrom) {
      const date = new Date(initialData.availableFrom);
      availableFrom = date.toISOString().split('T')[0];
    }

    return {
      ...defaultFormData,
      ...initialData,
      availableFrom
    };
  });

  const [isStepValid, setIsStepValid] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | { name: string; value: any }) => {
    const name = 'target' in e ? e.target.name : e.name;
    const value = 'target' in e ? (e.target.type === 'checkbox' ? (e.target as HTMLInputElement).checked : e.target.value) : e.value;

    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent as keyof typeof prev],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handlePlaceSelect = (place: google.maps.places.PlaceResult) => {
    if (place.geometry?.location && place.place_id) {
      setFormData(prev => ({
        ...prev,
        location: {
          ...prev.location,
          placeId: place.place_id!,
          formattedAddress: place.formatted_address || '',
          coordinates: [
            place.geometry!.location!.lng(),
            place.geometry!.location!.lat()
          ]
        }
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (currentStep < 5) {
      setCurrentStep(prev => prev + 1);
      return;
    }

    // Convert availableFrom back to a Date object before submitting
    const submitData = {
      ...formData,
      availableFrom: new Date(formData.availableFrom)
    };

    onSubmit(submitData);
  };

  const steps = useMemo(() => [
    {
      id: 1,
      title: 'Informazioni di base',
      icon: Building2
    },
    {
      id: 2,
      title: 'Caratteristiche',
      icon: ListChecks
    },
    {
      id: 3,
      title: 'Servizi e Utenze',
      icon: Plug
    },
    {
      id: 4,
      title: 'Media',
      icon: Image
    },
    {
      id: 5,
      title: 'Riepilogo',
      icon: FileText
    }
  ], []);

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <BasicInfoStep
            formData={formData}
            onChange={handleChange}
            onPlaceSelect={handlePlaceSelect}
            setIsStepValid={setIsStepValid}
            disableLocationEdit={disableLocationEdit}
          />
        );
      case 2:
        return (
          <FeaturesStep
            formData={formData}
            onChange={handleChange}
            setIsStepValid={setIsStepValid}
          />
        );
      case 3:
        return (
          <RulesStep
            formData={formData}
            onChange={handleChange}
          />
        );
      case 4:
        return (
          <MediaStep
            formData={formData}
            onImagesChange={(files) => setFormData(prev => ({ ...prev, images: files }))}
            onFloorPlansChange={(files) => setFormData(prev => ({ ...prev, floorPlans: files }))}
            listingId={initialData?.id}
            isEditing={!!initialData}
          />
        );
      case 5:
        return (
          <SummaryStep
            formData={formData}
            onChange={(field, value) => setFormData(prev => ({ ...prev, [field]: value }))}
            isEditing={!!initialData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 md:space-y-8 w-full max-w-4xl mx-auto px-0 md:px-6">
      <StepIndicator
        steps={steps}
        currentStep={currentStep}
        onChange={setCurrentStep}
        className="overflow-x-auto md:overflow-visible"
      />

      <div className="mt-4 md:mt-8">
        {renderStep()}
      </div>

      <div className="flex flex-col-reverse md:flex-row justify-between items-stretch md:items-center pt-4 md:pt-6 gap-4">
        <div className="flex flex-col-reverse sm:flex-row gap-2 sm:gap-4 w-full md:w-auto order-2 md:order-2">
          {currentStep > 1 && (
            <button
              type="button"
              onClick={() => setCurrentStep(prev => prev - 1)}
              className="w-full md:w-auto px-4 md:px-6 py-2.5 text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
            >
              Indietro
            </button>
          )}

          <button
            type="submit"
            disabled={!isStepValid}
            className={`w-full md:w-auto px-4 md:px-6 py-2.5 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors flex items-center justify-center gap-2 font-medium ${
              !isStepValid 
                ? 'bg-gray-300 cursor-not-allowed text-gray-500' 
                : 'bg-blue-500 hover:bg-blue-600 text-white focus:ring-blue-500'
            }`}
          >
            {loading ? (
              'Pubblicazione...'
            ) : currentStep === 5 ? (
              <>
                <Save className="h-4 w-4" />
                {initialData ? 'Salva Modifiche' : 'Pubblica Annuncio'}
              </>
            ) : (
              <>
                Avanti
                <ArrowRight className="h-4 w-4" />
              </>
            )}
          </button>
        </div>

        <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 w-full md:w-auto order-1 md:order-1">
          <button
            type="button"
            onClick={onCancel}
            className="w-full md:w-auto px-4 md:px-6 py-2.5 text-red-600 bg-white hover:bg-red-50 border border-red-200 rounded-lg transition-colors flex items-center justify-center gap-2 text-sm font-medium"
          >
            <X className="h-4 w-4" />
            Annulla
          </button>

          {onDelete && initialData && (
            <button
              type="button"
              onClick={onDelete}
              className="w-full md:w-auto px-4 md:px-6 py-2.5 text-red-600 bg-red-50 border border-red-200 rounded-lg hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors"
            >
              Elimina
            </button>
          )}
        </div>
      </div>
    </form>
  );
}
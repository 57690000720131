import React, { useState } from 'react';
import { Star, Edit, ExternalLink, RefreshCw, Trash } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Listing } from '../../../types/listing';
import { generateNumericId } from '../../../utils/ids';
import { toast } from 'react-hot-toast';
import { Pencil } from 'lucide-react';

interface ListingCardProps {
  listing: Listing;
  onEdit: () => void;
  onFeature: () => void;
  onRepublish: () => void;
  onDelete: () => void;
  isSelected?: boolean;
}

export function ListingCard({ listing, onEdit, onFeature, onRepublish, onDelete, isSelected = false }: ListingCardProps) {
  const isExpired = listing.status === 'expired';
  const isNoSpots = listing.availableSpots === 0;
  const isUnavailable = listing.status === 'inactive' || listing.status === 'unavailable';
  const isFeatured = listing.featured?.active && new Date(listing.featured.expiresAt) > new Date();
  
  const showOverlay = isExpired || isNoSpots || isUnavailable;
  const showRepublishButton = isExpired || isNoSpots || isUnavailable;
  
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    onEdit();
  };

  const handleFeature = (e: React.MouseEvent) => {
    e.stopPropagation();
    onFeature();
  };

  const handleRepublish = (e: React.MouseEvent) => {
    e.stopPropagation();
    onRepublish();
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    try {
      await onDelete();
      toast.success('Annuncio eliminato con successo');
    } catch (error) {
      console.error('Errore eliminazione:', error);
      toast.error(error instanceof Error ? error.message : 'Errore durante l\'eliminazione');
    } finally {
      setLoading(false);
      setShowDeleteConfirm(false);
    }
  };

  const getListingTypeLabel = (category: string) => {
    switch (category) {
      case 'single_room':
        return 'Stanza Singola';
      case 'double_room':
        return 'Stanza Doppia';
      case 'triple_room':
        return 'Stanza Tripla';
      case 'quad_room':
        return 'Stanza Quadrupla';
      default:
        return category;
    }
  };

  return (
    <>
      <div className={`relative overflow-hidden rounded-lg bg-white shadow-sm ${isSelected ? 'ring-2 ring-blue-500' : ''}`}>
        <div className="relative flex-shrink-0">
          <img
            src={listing.images?.[0] || '/placeholder.jpg'}
            alt={listing.title}
            className="w-full h-48 object-cover rounded-t-lg"
          />
          {isFeatured && (
            <div className="absolute top-2 right-2 bg-yellow-400 text-yellow-800 text-xs font-medium px-2 py-1 rounded-full">
              In Evidenza
            </div>
          )}
          {showOverlay && (
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-t-lg">
              <span className="text-white font-medium px-3 py-1 bg-red-600 rounded-full text-sm">
                {isExpired ? 'Annuncio Scaduto' : isNoSpots ? 'Posti Esauriti' : 'Non Disponibile'}
              </span>
            </div>
          )}
        </div>
        <div className="p-4 flex-grow flex flex-col">
          <div className="flex flex-wrap gap-2 mb-3">
            <span className="px-2 py-1 bg-blue-100 text-blue-800 text-xs font-medium rounded-full">
              {getListingTypeLabel(listing.category)}
            </span>
            <span className="px-2 py-1 bg-green-100 text-green-800 text-xs font-medium rounded-full">
              {listing.availableSpots} {listing.availableSpots === 1 ? 'posto' : 'posti'} disponibil{listing.availableSpots === 1 ? 'e' : 'i'}
            </span>
          </div>

          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2 mb-2">
            <h3 className="font-semibold text-lg truncate">{listing.title}</h3>
            <span className="text-sm text-gray-500 whitespace-nowrap">
              {generateNumericId(listing.id)}
            </span>
          </div>

          <div className="text-sm text-gray-500">
            <span className="line-clamp-1">{listing.location?.formattedAddress || 'Indirizzo non specificato'}</span>
          </div>

          <div className="text-sm text-gray-500 mt-1">
            Pubblicato il {listing.createdAt.toLocaleDateString()}
          </div>

          <div className="flex flex-col sm:flex-row sm:items-center justify-between mt-auto pt-4 gap-3">
            <span className="text-lg font-bold text-blue-600 whitespace-nowrap">
              €{listing.price}
              <span className="text-sm font-normal text-gray-500">/mese</span>
            </span>

            <div className="flex items-center gap-2 flex-wrap sm:flex-nowrap">
              <Link
                to={`/listing/${listing.id}`}
                className="p-2 text-gray-500 hover:text-gray-700 transition-colors"
                title="Visualizza annuncio"
                onClick={(e) => e.stopPropagation()}
              >
                <ExternalLink className="h-5 w-5" />
              </Link>

              <button
                onClick={handleDelete}
                className="p-2 text-gray-500 hover:text-gray-700 transition-colors"
                title="Elimina annuncio"
              >
                <Trash className="h-5 w-5" />
              </button>

              {showRepublishButton ? (
                <button
                  onClick={handleRepublish}
                  className="flex-1 sm:flex-none text-sm text-blue-600 hover:text-blue-700 font-medium px-3 py-1 border border-blue-600 rounded-md hover:bg-blue-50 transition-colors flex items-center justify-center gap-1 min-w-[120px]"
                >
                  <RefreshCw className="h-4 w-4" />
                  <span>Ripubblica</span>
                </button>
              ) : (
                <>
                  <button
                    onClick={handleEdit}
                    className="p-2 text-gray-500 hover:text-gray-700 transition-colors"
                    title="Modifica annuncio"
                  >
                    <Edit className="h-5 w-5" />
                  </button>
                  {!isFeatured && (
                    <button
                      onClick={handleFeature}
                      className="flex-1 sm:flex-none text-sm text-blue-600 hover:text-blue-700 font-medium px-3 py-1 border border-blue-600 rounded-md hover:bg-blue-50 transition-colors flex items-center justify-center gap-1 min-w-[120px]"
                    >
                      <Star className="h-4 w-4" />
                      <span>In Evidenza</span>
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-[110] flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-medium text-red-800 mb-2">Conferma Eliminazione</h3>
            <p className="text-sm text-red-600 mb-4">
              Sei sicuro di voler eliminare questo annuncio? Questa azione è irreversibile.
            </p>
            <div className="flex gap-4">
              <button
                onClick={handleConfirmDelete}
                disabled={loading}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 disabled:opacity-50"
              >
                {loading ? 'Eliminazione...' : 'Conferma'}
              </button>
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="px-4 py-2 bg-white text-red-600 border border-red-200 rounded-md hover:bg-red-50"
              >
                Annulla
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
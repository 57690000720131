import React from 'react';
import { MessageSquare, Clock, Sparkles, Shield } from 'lucide-react';
import { Link, useParams } from 'react-router-dom';
import { Chat } from '../../types/chat';
import { formatDistanceToNow } from 'date-fns';
import { it } from 'date-fns/locale';

interface ChatListProps {
  chats: Chat[];
  userId: string;
}

const isNewChat = (chat: Chat) => !chat.lastMessage;

export function ChatList({ chats, userId }: ChatListProps) {
  const { chatId } = useParams();
  
  // Rimuovi eventuali duplicati prima del rendering
  const uniqueChats = chats.reduce((acc, current) => {
    const x = acc.find(item => item.id === current.id);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, [] as Chat[]);
  
  if (uniqueChats.length === 0) {
    return (
      <div className="text-center py-12">
        <MessageSquare className="h-12 w-12 text-gray-400 mx-auto" />
        <h3 className="mt-2 text-lg font-medium text-gray-900">
          Nessuna chat presente
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Le chat verranno create automaticamente quando le tue candidature verranno accettate
        </p>
      </div>
    );
  }

  return (
    <div className="divide-y divide-gray-100">
      {uniqueChats.map((chat) => {
        const isStudent = userId === chat.studentId;
        const otherPartyName = isStudent ? chat.landlordName : chat.studentName;
        const isNew = isNewChat(chat);
        const isUnread = chat.unreadCount?.[userId] > 0;
        const isSelected = chat.id === chatId;

        return (
          <Link
            key={chat.id}
            to={`/chat/${chat.id}`}
            className={`block p-4 transition-colors ${
              isSelected 
                ? 'bg-blue-50'
                : isUnread
                  ? 'bg-blue-50/50 hover:bg-blue-50'
                  : 'hover:bg-gray-50'
            }`}
          >
            <div className="flex justify-between items-start gap-3">
              <div className="min-w-0 flex-1">
                <div className="flex items-center gap-2 flex-wrap">
                  <h3 className={`${
                    isUnread ? 'font-semibold' : 'font-medium'
                  } text-gray-900 truncate`}>
                    {otherPartyName}
                  </h3>
                  {chat.isBlocked && (
                    <span className="inline-flex items-center gap-1 px-1.5 py-0.5 rounded-full bg-red-50 text-red-700 text-xs font-medium">
                      <Shield className="h-3 w-3" />
                      <span className="hidden sm:inline">
                        {chat.blockedBy === 'me' ? 'Bloccato' : 'Ti ha bloccato'}
                      </span>
                    </span>
                  )}
                  {isNew && (
                    <span className="inline-flex items-center gap-1 px-2 py-1 rounded-full bg-blue-50 text-blue-700 text-xs font-medium">
                      <Sparkles className="h-3 w-3" />
                      Nuova
                    </span>
                  )}
                  {isUnread && (
                    <span className="flex h-2.5 w-2.5 rounded-full bg-blue-600" />
                  )}
                </div>
                <p className="text-sm text-gray-500 truncate">
                  {chat.listingTitle}
                </p>
                {chat.lastMessage ? (
                  <p className={`text-sm mt-1 line-clamp-1 ${
                    isUnread ? 'text-gray-900 font-medium' : 'text-gray-600'
                  }`}>
                    {chat.lastMessage.senderId === userId ? 'Tu: ' : ''}{chat.lastMessage.content}
                  </p>
                ) : (
                  <p className="text-sm text-blue-600 mt-1">
                    Inizia la conversazione
                  </p>
                )}
              </div>
              {chat.lastMessage && (
                <div className="flex items-center text-xs text-gray-500 whitespace-nowrap">
                  <Clock className="h-3 w-3 mr-1" />
                  {formatDistanceToNow(chat.lastMessage.createdAt, {
                    addSuffix: true,
                    locale: it
                  })}
                </div>
              )}
            </div>
          </Link>
        );
      })}
    </div>
  );
}
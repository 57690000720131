import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ListingsMap } from '../components/ListingsMap';
import { ListingCard } from '../components/ListingCard';
import { Listing } from '../types/listing';
import { getListingsInBounds } from '../lib/listings';
import { useMediaQuery } from '../hooks/useMediaQuery';
import { BottomSheet } from '../components/search/BottomSheet';
import { SearchFilters } from '../components/search/SearchFilters';
import debounce from 'lodash/debounce';
import { toast } from 'react-hot-toast';
import { SearchBar } from '../components/SearchBar';
import { SlidersHorizontal, Maximize2, Minimize2 } from 'lucide-react';
import { SearchResults } from '../components/search/SearchResults';

export function Search() {
  const [listings, setListings] = useState<Listing[]>([]);
  const [selectedListing, setSelectedListing] = useState<Listing | null>(null);
  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState({
    category: '',
    minPrice: '',
    maxPrice: '',
    maxOccupants: '',
    availableFrom: ''
  });
  
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const isMobile = useMediaQuery('(max-width: 767px)');
  const listingRefs = useRef<{ [key: string]: HTMLDivElement }>({});
  const [isBottomSheetExpanded, setIsBottomSheetExpanded] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const bottomSheetScrollRef = useRef<HTMLDivElement>(null);

  const fetchListingsInView = useCallback(async () => {
    if (!mapRef.current) return;

    const bounds = mapRef.current.getBounds();
    
    try {
      setLoading(true);
      setError(null);
      const fetchedListings = await getListingsInBounds({
        north: bounds.getNorth(),
        south: bounds.getSouth(),
        east: bounds.getEast(),
        west: bounds.getWest()
      });
      
      if (fetchedListings.length === 0) {
        setError('Nessun annuncio trovato in questa zona');
      }
      
      setListings(fetchedListings);
    } catch (error) {
      console.error('Errore nel recupero degli annunci:', error);
      setError('Si è verificato un errore durante il caricamento degli annunci');
    } finally {
      setLoading(false);
    }
  }, []);

  const debouncedFetch = useCallback(
    debounce(() => {
      fetchListingsInView();
    }, 500),
    [fetchListingsInView]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchListingsInView();
    }, 1000);

    return () => clearTimeout(timer);
  }, [fetchListingsInView]);

  const handleMapMove = useCallback(() => {
    debouncedFetch();
  }, [debouncedFetch]);

  const handleListingSelect = (listing: Listing) => {
    setSelectedListing(listing);
    
    if (mapRef.current && listing.location?.coordinates) {
      mapRef.current.flyTo({
        center: [listing.location.coordinates[0], listing.location.coordinates[1]],
        zoom: 15,
        duration: 1000
      });
    }

    if (isMobile) {
      setIsBottomSheetExpanded(true);
    }
  };

  const handleSearch = useCallback(async (query: string) => {
    if (!query?.trim() || !mapRef.current) return;

    setIsBottomSheetExpanded(false);
    setShowFilters(false);

    const mapboxToken = import.meta.env.VITE_MAPBOX_TOKEN;
    if (!mapboxToken) {
      console.error('Token Mapbox non trovato');
      toast.error('Errore di configurazione');
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json?` + 
        `access_token=${mapboxToken}&` +
        `country=it&` +
        `language=it&` +
        `types=place,address,locality,neighborhood`
      );

      const data = await response.json();
      
      if (data.features && data.features.length > 0) {
        const feature = data.features[0];
        
        mapRef.current.off('moveend', handleMapMove);
        
        if (feature.bbox) {
          mapRef.current.fitBounds([
            [feature.bbox[0], feature.bbox[1]],
            [feature.bbox[2], feature.bbox[3]]
          ], {
            padding: { top: 50, bottom: 50, left: 50, right: 50 },
            duration: 1500,
            maxZoom: 15
          });
        } else {
          mapRef.current.flyTo({
            center: feature.center,
            zoom: 13,
            duration: 1500
          });
        }

        mapRef.current.once('moveend', () => {
          mapRef.current?.on('moveend', handleMapMove);
        });
      } else {
        toast.error('Nessun risultato trovato per questa ricerca');
      }
    } catch (error) {
      console.error('Errore nella ricerca:', error);
      toast.error('Errore durante la ricerca');
    } finally {
      setLoading(false);
    }
  }, [handleMapMove]);

  const filteredListings = listings.filter(listing => {
    if (filters.category && listing.category !== filters.category) return false;
    if (filters.minPrice && listing.price < Number(filters.minPrice)) return false;
    if (filters.maxPrice && listing.price > Number(filters.maxPrice)) return false;
    if (filters.maxOccupants && listing.maxOccupants > Number(filters.maxOccupants)) return false;
    if (filters.availableFrom) {
      const availableDate = new Date(listing.availableFrom);
      const filterDate = new Date(filters.availableFrom);
      if (availableDate > filterDate) return false;
    }
    return true;
  });

  const hasActiveFilters = Object.values(filters).some(value => value !== '');

  const renderResults = () => (
    <div className="space-y-4">
      {loading && listings.length === 0 ? (
        <div className="text-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
          <p className="mt-2 text-gray-500">Caricamento annunci...</p>
        </div>
      ) : error ? (
        <div className="text-center py-8">
          <p className="text-red-500">{error}</p>
          <button
            onClick={fetchListingsInView}
            className="mt-2 text-blue-600 hover:text-blue-700"
          >
            Riprova
          </button>
        </div>
      ) : filteredListings.length === 0 ? (
        <div className="text-center py-8">
          <p className="text-gray-500">Nessun risultato trovato</p>
        </div>
      ) : (
        filteredListings.map((listing) => (
          <div
            key={listing.id}
            ref={el => {
              if (el) listingRefs.current[listing.id] = el;
            }}
            className="transition-all"
            onClick={() => handleListingSelect(listing)}
          >
            <ListingCard listing={listing} isSelected={selectedListing?.id === listing.id} />
          </div>
        ))
      )}
    </div>
  );

  return (
    <div className="min-h-screen pt-16">
      {isMobile ? (
        <>
          {/* Barra di ricerca fissa su mobile */}
          <div className="fixed top-16 left-0 right-0 z-30 bg-white shadow-lg">
            <div className="p-4">
              <SearchBar 
                onSearch={handleSearch}
                placeholder="Cerca per indirizzo o zona..."
                variant="mobile"
              />
            </div>
          </div>

          <div className="fixed inset-0 top-[84px]">
            <ListingsMap
              listings={filteredListings}
              onListingSelect={handleListingSelect}
              selectedListing={selectedListing}
              onMapMove={handleMapMove}
              mapRef={mapRef}
              onMarkerClick={() => setIsBottomSheetExpanded(true)}
            />
          </div>
          
          <div className="z-40">
            <BottomSheet 
              title={
                <div className="flex items-center justify-between w-full">
                  <div>
                    <h2 className="text-lg font-semibold">Risultati della ricerca</h2>
                    <p className="text-sm text-gray-500">{filteredListings.length} risultati trovati</p>
                  </div>
                  <button
                    onClick={() => setShowFilters(!showFilters)}
                    className={`px-4 py-2 text-sm font-medium rounded-md flex items-center gap-2 ${
                      hasActiveFilters 
                        ? 'text-blue-700 bg-blue-50 hover:bg-blue-100' 
                        : 'text-gray-700 bg-gray-100 hover:bg-gray-200'
                    }`}
                  >
                    <SlidersHorizontal className="h-4 w-4" />
                    Filtri
                    {hasActiveFilters && <span className="w-2 h-2 rounded-full bg-blue-600" />}
                  </button>
                </div>
              }
              isExpanded={isBottomSheetExpanded}
              onStateChange={(expanded) => setIsBottomSheetExpanded(expanded)}
              contentRef={bottomSheetScrollRef}
            >
              {showFilters && (
                <SearchFilters
                  filters={filters}
                  onFilterChange={setFilters}
                  searchQuery={searchQuery}
                  onSearchQueryChange={setSearchQuery}
                  onSearch={handleSearch}
                  showFilters={showFilters}
                  onToggleFilters={() => setShowFilters(!showFilters)}
                  totalResults={filteredListings.length}
                  mapRef={mapRef}
                  isMobile={isMobile}
                />
              )}
              <SearchResults
                listings={filteredListings}
                loading={loading}
                error={error}
                selectedListing={selectedListing}
                onListingSelect={handleListingSelect}
                onRetry={fetchListingsInView}
                isExpanded={isExpanded}
              />
            </BottomSheet>
          </div>
        </>
      ) : (
        <div className="grid grid-cols-12 h-[calc(100vh-64px)]">
          <div className={`${
            isExpanded ? 'col-span-8 xl:col-span-9' : 'col-span-5 xl:col-span-4'
          } flex flex-col overflow-hidden border-r border-gray-200 transition-all duration-300`}>
            <div className="p-4 border-b border-gray-200 flex items-center justify-between">
              <div>
                <h2 className="text-lg font-semibold">Risultati della ricerca</h2>
                <p className="text-sm text-gray-500">{filteredListings.length} risultati trovati</p>
              </div>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setIsExpanded(!isExpanded)}
                  className="p-2 text-gray-600 hover:text-gray-900 rounded-md hover:bg-gray-100"
                >
                  {isExpanded ? (
                    <Minimize2 className="h-5 w-5" />
                  ) : (
                    <Maximize2 className="h-5 w-5" />
                  )}
                </button>
                <button
                  onClick={() => setShowFilters(!showFilters)}
                  className={`px-4 py-2 text-sm font-medium rounded-md flex items-center gap-2 ${
                    hasActiveFilters 
                      ? 'text-blue-700 bg-blue-50 hover:bg-blue-100' 
                      : 'text-gray-700 bg-gray-100 hover:bg-gray-200'
                  }`}
                >
                  <SlidersHorizontal className="h-4 w-4" />
                  Filtri
                  {hasActiveFilters && <span className="w-2 h-2 rounded-full bg-blue-600" />}
                </button>
              </div>
            </div>
            <SearchFilters
              filters={filters}
              onFilterChange={setFilters}
              searchQuery={searchQuery}
              onSearchQueryChange={setSearchQuery}
              onSearch={handleSearch}
              showFilters={showFilters}
              onToggleFilters={() => setShowFilters(!showFilters)}
              totalResults={filteredListings.length}
              mapRef={mapRef}
              isMobile={false}
            />
            <div className="flex-1 overflow-y-auto p-4">
              <SearchResults
                listings={filteredListings}
                loading={loading}
                error={error}
                selectedListing={selectedListing}
                onListingSelect={handleListingSelect}
                onRetry={fetchListingsInView}
                isExpanded={isExpanded}
              />
            </div>
          </div>

          <div className={`${
            isExpanded ? 'col-span-4 xl:col-span-3' : 'col-span-7 xl:col-span-8'
          } transition-all duration-300`}>
            <ListingsMap
              listings={filteredListings}
              onListingSelect={handleListingSelect}
              selectedListing={selectedListing}
              onMapMove={handleMapMove}
              mapRef={mapRef}
              onMarkerClick={() => setIsBottomSheetExpanded(true)}
            />
          </div>
        </div>
      )}
    </div>
  );
}
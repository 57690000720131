import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../../../lib/firebase';
import { formatSafeDate } from '../../../utils/dates';

interface ChatViewModalProps {
  chatId: string;
  onClose: () => void;
}

interface Message {
  id: string;
  content: string;
  senderName: string;
  createdAt: any;
}

export function ChatViewModal({ chatId, onClose }: ChatViewModalProps) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const q = query(
      collection(db, 'messages'),
      where('chatId', '==', chatId),
      orderBy('createdAt', 'asc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messagesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Message[];
      
      setMessages(messagesData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [chatId]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg w-full max-w-2xl h-[80vh] flex flex-col">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold">Cronologia Chat</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="flex-1 overflow-y-auto p-4 space-y-4">
          {loading ? (
            <div>Caricamento messaggi...</div>
          ) : (
            messages.map(message => (
              <div key={message.id} className="flex flex-col">
                <div className="flex items-center gap-2 mb-1">
                  <span className="font-medium">{message.senderName}</span>
                  <span className="text-sm text-gray-500">
                    {formatSafeDate(message.createdAt)}
                  </span>
                </div>
                <p className="text-gray-700 bg-gray-50 rounded-lg p-3">
                  {message.content}
                </p>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
} 
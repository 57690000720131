import React from 'react';
import { Check } from 'lucide-react';

interface Step {
  id: number;
  name: string;
}

interface StepIndicatorProps {
  steps: Step[];
  currentStep: number;
  onChange: (step: number) => void;
}

export function StepIndicator({ steps, currentStep, onChange }: StepIndicatorProps) {
  return (
    <div className="w-full">
      {/* Desktop Version - Hidden on mobile */}
      <div className="hidden sm:block relative">
        <div className="absolute top-5 left-0 w-full h-1 bg-gray-200 rounded-full">
          <div 
            className="absolute left-0 top-0 h-full bg-blue-600 rounded-full transition-all duration-500 ease-out"
            style={{ width: `${((currentStep - 1) / (steps.length - 1)) * 100}%` }}
          />
        </div>

        <div className="relative flex justify-between">
          {steps.map((step) => {
            const isCompleted = currentStep > step.id;
            const isCurrent = currentStep === step.id;
            const isClickable = step.id <= currentStep;

            return (
              <button
                key={step.id}
                onClick={() => isClickable && onChange(step.id)}
                disabled={!isClickable}
                className={`flex flex-col items-center ${isClickable ? 'cursor-pointer' : 'cursor-not-allowed'}`}
              >
                <div
                  className={`w-10 h-10 rounded-full flex items-center justify-center transition-all duration-200 ${
                    isCompleted
                      ? 'bg-blue-600'
                      : isCurrent
                      ? 'bg-white border-2 border-blue-600'
                      : 'bg-white border-2 border-gray-200'
                  }`}
                >
                  {isCompleted ? (
                    <Check className="h-5 w-5 text-white" />
                  ) : (
                    <span className={`text-sm font-medium ${isCurrent ? 'text-blue-600' : 'text-gray-400'}`}>
                      {step.id}
                    </span>
                  )}
                </div>
                <span className={`mt-2 text-sm font-medium ${
                  isCurrent ? 'text-blue-600' : isCompleted ? 'text-gray-900' : 'text-gray-400'
                }`}>
                  {step.name}
                </span>
              </button>
            );
          })}
        </div>
      </div>

      {/* Mobile Version - Current Step Only */}
      <div className="sm:hidden">
        <div className="flex items-center justify-between bg-white px-4 py-3 border-b">
          <div className="flex items-center gap-3">
            <div className="w-7 h-7 rounded-full bg-blue-600 flex items-center justify-center text-white text-sm font-medium">
              {currentStep}
            </div>
            <span className="text-sm font-medium text-gray-900">
              {steps.find(step => step.id === currentStep)?.name}
            </span>
          </div>
          <span className="text-xs text-gray-500">
            {currentStep}/{steps.length}
          </span>
        </div>
      </div>
    </div>
  );
}
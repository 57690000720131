import React, { useState } from 'react';
import { HelpCircle, ChevronDown, ChevronUp } from 'lucide-react';
import { useAuthContext } from '../contexts/AuthContext';

const FAQItem = ({ question, answer, isOpen, onClick }: {
  question: string;
  answer: string;
  isOpen: boolean;
  onClick: () => void;
}) => (
  <div className="border-b border-gray-200 last:border-0">
    <button
      className="w-full py-4 flex justify-between items-center text-left"
      onClick={onClick}
    >
      <span className="font-medium text-gray-900">{question}</span>
      {isOpen ? (
        <ChevronUp className="h-5 w-5 text-gray-500" />
      ) : (
        <ChevronDown className="h-5 w-5 text-gray-500" />
      )}
    </button>
    {isOpen && (
      <div className="pb-4 text-gray-600">
        {answer}
      </div>
    )}
  </div>
);

const FAQCategory = ({ title, faqs, categoryKey, openIndices, setOpenIndices }: {
  title: string;
  faqs: Array<{ question: string; answer: string; }>;
  categoryKey: string;
  openIndices: Record<string, number | null>;
  setOpenIndices: (fn: (prev: Record<string, number | null>) => Record<string, number | null>) => void;
}) => (
  <div className="bg-white rounded-lg shadow-sm p-8 mb-6">
    <h2 className="text-xl font-bold text-gray-900 mb-6">{title}</h2>
    <div className="divide-y divide-gray-200">
      {faqs.map((faq, index) => (
        <FAQItem
          key={index}
          question={faq.question}
          answer={faq.answer}
          isOpen={openIndices[categoryKey] === index}
          onClick={() => setOpenIndices(prev => ({
            ...prev,
            [categoryKey]: prev[categoryKey] === index ? null : index
          }))}
        />
      ))}
    </div>
  </div>
);

export function FAQ() {
  const { user, userProfile } = useAuthContext();
  const [openIndices, setOpenIndices] = useState<Record<string, number | null>>({
    general: null,
    student: null,
    landlord: null
  });

  const generalFaqs = [
    {
      question: "Cos'è Unistanza?",
      answer: "Unistanza è una piattaforma che connette studenti universitari e proprietari di immobili, semplificando la ricerca e l'affitto di alloggi per studenti."
    },
    {
      question: "Come funziona la verifica dell'identità?",
      answer: "Verifichiamo l'identità degli studenti attraverso l'email universitaria e dei proprietari attraverso documenti d'identità validi."
    },
    {
      question: "È gratuito utilizzare Unistanza?",
      answer: "La registrazione e la ricerca sono gratuite. I proprietari utilizzano crediti per pubblicare annunci, mentre gli studenti per candidarsi."
    }
  ];

  const studentFaqs = [
    {
      question: "Come posso candidarmi per un alloggio?",
      answer: "Dopo aver trovato l'alloggio ideale, puoi inviare una candidatura utilizzando i crediti disponibili. Il proprietario riceverà la tua richiesta e potrà contattarti."
    },
    {
      question: "Quanti crediti ricevo all'iscrizione?",
      answer: "Gli studenti ricevono 5 crediti di benvenuto all'iscrizione, sufficienti per inviare le prime candidature."
    },
    {
      question: "Come funzionano le visite virtuali?",
      answer: "Puoi richiedere una videochiamata con il proprietario per visitare l'alloggio virtualmente, risparmiando tempo e denaro."
    }
  ];

  const landlordFaqs = [
    {
      question: "Come pubblico un annuncio?",
      answer: "Dalla tua dashboard, puoi creare un nuovo annuncio utilizzando i crediti. Riceverai 10 crediti di benvenuto all'iscrizione."
    },
    {
      question: "Come gestisco le candidature?",
      answer: "Tutte le candidature sono visibili nella tua dashboard. Puoi accettarle, rifiutarle o contattare direttamente gli studenti."
    },
    {
      question: "Come funziona il sistema di crediti?",
      answer: "Ogni pubblicazione di annuncio richiede crediti. Puoi acquistare pacchetti di crediti dallo shop o ricevere bonus per annunci di qualità."
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center mb-12">
          <HelpCircle className="h-12 w-12 text-blue-600 mx-auto mb-4" />
          <h1 className="text-3xl font-bold text-gray-900">Domande Frequenti</h1>
          <p className="mt-4 text-lg text-gray-600">
            Trova le risposte alle domande più comuni su Unistanza
          </p>
        </div>

        <FAQCategory
          title="Informazioni Generali"
          faqs={generalFaqs}
          categoryKey="general"
          openIndices={openIndices}
          setOpenIndices={setOpenIndices}
        />

        {user && userProfile?.userType === 'student' && (
          <FAQCategory
            title="Per gli Studenti"
            faqs={studentFaqs}
            categoryKey="student"
            openIndices={openIndices}
            setOpenIndices={setOpenIndices}
          />
        )}

        {user && userProfile?.userType === 'landlord' && (
          <FAQCategory
            title="Per i Proprietari"
            faqs={landlordFaqs}
            categoryKey="landlord"
            openIndices={openIndices}
            setOpenIndices={setOpenIndices}
          />
        )}
      </div>
    </div>
  );
} 
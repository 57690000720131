import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../../../lib/firebase';
import { formatSafeDate } from '../../../utils/dates';
import { ChatViewModal } from './ChatViewModal';
import { Copy, Check } from 'lucide-react';
import { toast } from 'react-toastify';

interface Report {
  id: string;
  reporterId: string;
  reportedId: string;
  chatId: string;
  reason: string;
  description: string;
  status: 'pending' | 'reviewed';
  createdAt: any;
  reporterName: string;
  reportedName: string;
}

export function ReportsTab() {
  const [reports, setReports] = useState<Report[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedReport, setSelectedReport] = useState<Report | null>(null);
  const [showChatModal, setShowChatModal] = useState(false);
  const [copiedId, setCopiedId] = useState<string | null>(null);

  useEffect(() => {
    const q = query(
      collection(db, 'reports'),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const reportsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Report[];
      
      setReports(reportsData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const getReasonLabel = (reason: string) => {
    const reasons: Record<string, string> = {
      'spam': 'Spam',
      'inappropriate': 'Contenuto inappropriato',
      'harassment': 'Molestie',
      'scam': 'Truffa',
      'other': 'Altro'
    };
    return reasons[reason] || reason;
  };

  const handleCopyId = async (id: string) => {
    try {
      await navigator.clipboard.writeText(id);
      setCopiedId(id);
      toast.success('ID copiato negli appunti');
      
      // Reset dell'icona dopo 2 secondi
      setTimeout(() => {
        setCopiedId(null);
      }, 2000);
    } catch (error) {
      console.error('Errore durante la copia dell\'ID:', error);
      toast.error('Errore durante la copia dell\'ID');
    }
  };

  if (loading) {
    return (
      <div className="text-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
        <p className="mt-4 text-gray-500">Caricamento segnalazioni...</p>
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-lg font-semibold mb-4">Segnalazioni Chat</h2>
      
      <div className="space-y-4">
        {reports.map(report => (
          <div key={report.id} className="border rounded-lg p-4">
            <div className="flex justify-between items-start mb-2">
              <div>
                <div className="mb-2">
                  <span className="text-sm text-gray-500">
                    Segnalato da: 
                    <span className="font-medium text-gray-900 ml-1">{report.reporterName}</span>
                    <button
                      onClick={() => handleCopyId(report.reporterId)}
                      className="ml-2 p-1 text-gray-400 hover:text-gray-600 inline-flex items-center"
                      title="Copia ID utente"
                    >
                      {copiedId === report.reporterId ? (
                        <Check className="h-4 w-4 text-green-500" />
                      ) : (
                        <Copy className="h-4 w-4" />
                      )}
                    </button>
                  </span>
                  <span className="mx-2">•</span>
                  <span className="text-sm text-gray-500">
                    Contro: 
                    <span className="font-medium text-gray-900 ml-1">{report.reportedName}</span>
                    <button
                      onClick={() => handleCopyId(report.reportedId)}
                      className="ml-2 p-1 text-gray-400 hover:text-gray-600 inline-flex items-center"
                      title="Copia ID utente"
                    >
                      {copiedId === report.reportedId ? (
                        <Check className="h-4 w-4 text-green-500" />
                      ) : (
                        <Copy className="h-4 w-4" />
                      )}
                    </button>
                  </span>
                </div>
                <span className="font-medium">
                  Motivo: {getReasonLabel(report.reason)}
                </span>
                <span className="text-sm text-gray-500 ml-4">
                  {formatSafeDate(report.createdAt)}
                </span>
              </div>
            </div>
            
            <p className="text-sm text-gray-600 mb-3">{report.description}</p>
            
            <button
              onClick={() => {
                setSelectedReport(report);
                setShowChatModal(true);
              }}
              className="text-sm text-blue-600 hover:text-blue-700"
            >
              Visualizza Chat
            </button>
          </div>
        ))}
      </div>

      {showChatModal && selectedReport && (
        <ChatViewModal
          chatId={selectedReport.chatId}
          onClose={() => {
            setShowChatModal(false);
            setSelectedReport(null);
          }}
        />
      )}
    </div>
  );
} 
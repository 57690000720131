import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  signOut as firebaseSignOut,
  updateEmail,
  updatePassword,
  deleteUser,
  User,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  updateProfile,
  setPersistence,
  browserLocalPersistence
} from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc, deleteDoc, runTransaction, serverTimestamp, query, collection, where, getDocs } from 'firebase/firestore';
import { auth, db, googleProvider } from './firebase';
import { UserProfile } from '../types/user';
import { toast } from 'react-toastify';

const INITIAL_CREDITS = {
  student: 5,
  landlord: 10
};

export async function getUserProfile(user: User): Promise<UserProfile | null> {
  if (!user) return null;

  try {
    const docRef = doc(db, 'users', user.uid);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists()) {
      return null;
    }

    return {
      id: docSnap.id,
      ...docSnap.data()
    } as UserProfile;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
}

export async function updateUserProfile(userId: string, data: Partial<UserProfile>): Promise<void> {
  if (!userId) {
    throw new Error('User ID is required');
  }

  try {
    await runTransaction(db, async (transaction) => {
      const userRef = doc(db, 'users', userId);
      const userDoc = await transaction.get(userRef);

      if (!userDoc.exists()) {
        throw new Error('User profile not found');
      }

      const currentData = userDoc.data();
      const updatedData = {
        ...currentData,
        ...data,
        updatedAt: new Date()
      };

      if (!updatedData.email || !updatedData.userType) {
        throw new Error('Required fields are missing');
      }

      if (updatedData.userType === 'student' && updatedData.studentInfo) {
        const { birthDate, university, studyProgram, studyYear } = updatedData.studentInfo;
        if (!birthDate || !university || !studyProgram || !studyYear) {
          throw new Error('Required student information is missing');
        }
      }

      transaction.update(userRef, updatedData);
    });

    toast.success('Profilo aggiornato con successo');
  } catch (error: any) {
    console.error('Error updating profile:', error);
    toast.error(error.message || 'Errore durante l\'aggiornamento del profilo');
    throw error;
  }
}

export async function signInWithEmail(email: string, password: string) {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    
    // Aspetta che il profilo sia caricato
    const userProfile = await getUserProfile(result.user);
    if (!userProfile) {
      throw new Error('Profilo utente non trovato');
    }

    // Forza il refresh del token
    await result.user.getIdToken(true);

    // Attendi un momento per assicurarsi che l'autenticazione sia completata
    await new Promise(resolve => setTimeout(resolve, 1000));

    return { user: result.user, userProfile };
  } catch (error: any) {
    console.error('Email sign-in error:', error);
    if (error.code === 'auth/user-not-found') {
      throw new Error('Utente non trovato');
    } else if (error.code === 'auth/wrong-password') {
      throw new Error('Password non corretta');
    }
    throw error;
  }
}

export async function signInWithGoogle(userType: 'student' | 'landlord') {
  try {
    console.log('🔵 Inizio signInWithGoogle, userType:', userType);
    const result = await signInWithPopup(auth, googleProvider);
    
    // Verifica se l'utente esiste già
    console.log('🔵 Verifica esistenza profilo per:', result.user.uid);
    const existingProfile = await getUserProfile(result.user);
    if (existingProfile) {
      console.log('🔵 Profilo esistente trovato');
      return { userProfile: existingProfile };
    }
    
    console.log('🔵 Creazione nuovo profilo Google');
    // Se è un nuovo utente, prepara i dati base
    const nameParts = result.user.displayName?.split(' ') || [];
    const firstName = nameParts[0] || '';
    const lastName = nameParts.slice(1).join(' ') || '';
    
    // Usa una transazione per creare il profilo
    await runTransaction(db, async (transaction) => {
      const userRef = doc(db, 'users', result.user.uid);
      
      const userProfile = {
        id: result.user.uid,
        email: result.user.email || '',
        firstName,
        lastName,
        phoneNumber: result.user.phoneNumber || '',
        userType,
        displayName: result.user.displayName || '',
        photoURL: result.user.photoURL || '',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        [`${userType}Info`]: {
          credits: 0  // Inizializza i crediti a 0
        }
      };
      
      console.log('🔵 Salvataggio profilo:', userProfile);
      transaction.set(userRef, userProfile);
    });

    console.log('🔵 Profilo creato, richiesto completamento');
    return {
      needsProfileCompletion: true,
      userData: {
        id: result.user.uid,
        email: result.user.email || '',
        firstName,
        lastName,
        phoneNumber: result.user.phoneNumber || '',
        userType
      }
    };
  } catch (error: any) {
    console.error('🔴 Errore in signInWithGoogle:', error);
    throw error;
  }
}

export async function signUpWithEmail(email: string, password: string, userType: 'student' | 'landlord') {
  try {
    const result = await createUserWithEmailAndPassword(auth, email, password);
    
    const userProfile: UserProfile = {
      id: result.user.uid,
      email,
      displayName: '',
      userType,
      isAdmin: false,
      createdAt: new Date(),
      ...(userType === 'student' ? {
        studentInfo: {}
      } : {
        landlordInfo: {
          listingsCount: 0,
          verificationStatus: 'pending'
        }
      })
    };
    
    await setDoc(doc(db, 'users', result.user.uid), userProfile);
    return { user: result.user, userProfile };
  } catch (error: any) {
    console.error('Email sign-up error:', error);
    throw error;
  }
}

export async function signOut() {
  try {
    await firebaseSignOut(auth);
  } catch (error: any) {
    console.error('Sign-out error:', error);
    throw error;
  }
}

export async function deleteUserAccount(password?: string): Promise<void> {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('Nessun utente autenticato');
  }

  try {
    // Se è richiesta la password, riautenticare l'utente
    if (password) {
      await reauthenticateUser(password);
    }

    // Ottieni il riferimento al documento utente
    const userRef = doc(db, 'users', user.uid);

    // Elimina tutti i dati correlati all'utente
    await runTransaction(db, async (transaction) => {
      // Elimina il documento utente
      transaction.delete(userRef);

      // Elimina le applicazioni dell'utente
      const applicationsQuery = query(collection(db, 'applications'), where('userId', '==', user.uid));
      const applicationsSnapshot = await getDocs(applicationsQuery);
      applicationsSnapshot.forEach(doc => {
        transaction.delete(doc.ref);
      });

      // Elimina i crediti dell'utente
      const creditsQuery = query(collection(db, 'credits'), where('userId', '==', user.uid));
      const creditsSnapshot = await getDocs(creditsQuery);
      creditsSnapshot.forEach(doc => {
        transaction.delete(doc.ref);
      });

      // Elimina le notifiche dell'utente
      const notificationsQuery = query(collection(db, 'notifications'), where('userId', '==', user.uid));
      const notificationsSnapshot = await getDocs(notificationsQuery);
      notificationsSnapshot.forEach(doc => {
        transaction.delete(doc.ref);
      });
    });

    // Infine, elimina l'account di autenticazione
    await user.delete();

  } catch (error: any) {
    console.error('Errore durante l\'eliminazione dell\'account:', error);
    if (error.code === 'auth/requires-recent-login') {
      throw new Error('Per motivi di sicurezza, effettua nuovamente il login prima di eliminare l\'account');
    }
    throw error;
  }
}

export async function updateUserEmail(newEmail: string, password: string) {
  const user = auth.currentUser;
  if (!user || !user.email) {
    throw new Error('No user logged in');
  }

  try {
    // Re-authenticate user
    const credential = EmailAuthProvider.credential(user.email, password);
    await reauthenticateWithCredential(user, credential);

    // Update email
    await updateEmail(user, newEmail);
    
    // Update profile in Firestore
    await updateDoc(doc(db, 'users', user.uid), {
      email: newEmail,
      updatedAt: new Date()
    });

    toast.success('Email updated successfully');
  } catch (error: any) {
    console.error('Error updating email:', error);
    throw error;
  }
}

export async function updateUserPassword(currentPassword: string, newPassword: string) {
  const user = auth.currentUser;
  if (!user || !user.email) {
    throw new Error('No user logged in');
  }

  try {
    // Re-authenticate user
    const credential = EmailAuthProvider.credential(user.email, currentPassword);
    await reauthenticateWithCredential(user, credential);

    // Update password
    await updatePassword(user, newPassword);

    toast.success('Password updated successfully');
  } catch (error: any) {
    console.error('Error updating password:', error);
    throw error;
  }
}

export async function sendVerificationEmailToUser() {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('No user logged in');
  }

  try {
    await sendEmailVerification(user);
    toast.success('Verification email sent');
  } catch (error: any) {
    console.error('Error sending verification email:', error);
    throw error;
  }
}

export async function sendPasswordResetEmailToUser(email: string) {
  try {
    await sendPasswordResetEmail(auth, email);
    toast.success('Password reset email sent');
  } catch (error: any) {
    console.error('Error sending password reset email:', error);
    throw error;
  }
}

export async function requireAdmin(): Promise<void> {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('Utente non autenticato');
  }

  const userDoc = await getDoc(doc(db, 'users', user.uid));
  if (!userDoc.exists() || userDoc.data().userType !== 'admin') {
    throw new Error('Permessi insufficienti');
  }
}

interface RegisterData {
  email: string;
  password: string;
  userType: 'student' | 'landlord';
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export async function registerWithEmailAndPassword({
  email,
  password,
  userType,
  firstName,
  lastName,
  phoneNumber
}: RegisterData) {
  try {
    const result = await createUserWithEmailAndPassword(auth, email, password);

    await updateProfile(result.user, {
      displayName: `${firstName} ${lastName}`
    });

    // Usa una transazione per creare il profilo utente
    await runTransaction(db, async (transaction) => {
      const userRef = doc(db, 'users', result.user.uid);
      
      const userProfile = {
        id: result.user.uid,
        email,
        firstName,
        lastName,
        phoneNumber,
        userType,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        [`${userType}Info`]: {
          credits: 0  // Inizializza i crediti a 0
        }
      };
      
      transaction.set(userRef, userProfile);
    });

    await sendEmailVerification(result.user);
    
    // Aspetta che il profilo sia effettivamente creato
    const createdProfile = await getUserProfile(result.user);
    if (!createdProfile) {
      throw new Error('Errore nella creazione del profilo');
    }

    // Forza il refresh del token
    await result.user.getIdToken(true);

    return { user: result.user, userProfile: createdProfile };
  } catch (error: any) {
    console.error('Registration error:', error);
    throw error;
  }
}

export async function checkAuthState() {
  return new Promise((resolve) => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      unsubscribe();
      
      if (!user) {
        resolve(null);
        return;
      }

      try {
        const userProfile = await getUserProfile(user);
        if (!userProfile) {
          resolve(null);
          return;
        }

        resolve({ user, userProfile });
      } catch (error) {
        console.error('Error checking auth state:', error);
        resolve(null);
      }
    });
  });
}

export async function initializeAuth() {
  try {
    await setPersistence(auth, browserLocalPersistence);
    const authState = await checkAuthState();
    return authState;
  } catch (error) {
    console.error('Error initializing auth:', error);
    return null;
  }
}

export async function reauthenticateUser(password: string): Promise<void> {
  const user = auth.currentUser;
  if (!user || !user.email) {
    throw new Error('No user logged in');
  }

  try {
    const credential = EmailAuthProvider.credential(user.email, password);
    await reauthenticateWithCredential(user, credential);
  } catch (error: any) {
    console.error('Error reauthenticating:', error);
    if (error.code === 'auth/wrong-password') {
      throw new Error('Password non corretta');
    }
    throw error;
  }
}
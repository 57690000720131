import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Plus, Pencil, Trash2, Eye } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { BlogPost, getBlogPosts, deleteBlogPost } from '../../../lib/blogPosts';
import { formatDate } from '../../../utils/dates';

export function BlogPostsTab() {
  const navigate = useNavigate();
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedPosts, setSelectedPosts] = useState<string[]>([]);

  useEffect(() => {
    loadPosts();
  }, []);

  const loadPosts = async () => {
    try {
      const data = await getBlogPosts();
      setPosts(data);
    } catch (error) {
      console.error('Error loading posts:', error);
      toast.error('Errore nel caricamento degli articoli');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Sei sicuro di voler eliminare questo articolo?')) {
      try {
        await deleteBlogPost(id);
        toast.success('Articolo eliminato con successo');
        loadPosts();
      } catch (error) {
        console.error('Error deleting post:', error);
        toast.error('Errore durante l\'eliminazione dell\'articolo');
      }
    }
  };

  const handleDeleteSelected = async () => {
    if (selectedPosts.length === 0) return;
    
    if (window.confirm(`Sei sicuro di voler eliminare ${selectedPosts.length} articoli?`)) {
      try {
        await Promise.all(selectedPosts.map(id => deleteBlogPost(id)));
        toast.success('Articoli eliminati con successo');
        setSelectedPosts([]);
        loadPosts();
      } catch (error) {
        console.error('Error deleting posts:', error);
        toast.error('Errore durante l\'eliminazione degli articoli');
      }
    }
  };

  const togglePostSelection = (id: string) => {
    setSelectedPosts(prev => 
      prev.includes(id) 
        ? prev.filter(postId => postId !== id)
        : [...prev, id]
    );
  };

  const toggleAllPosts = () => {
    setSelectedPosts(prev => 
      prev.length === posts.length ? [] : posts.map(post => post.id)
    );
  };

  if (loading) {
    return (
      <div className="text-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold">Gestione Blog</h2>
        <div className="flex gap-4">
          {selectedPosts.length > 0 && (
            <button
              onClick={handleDeleteSelected}
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
            >
              <Trash2 className="h-5 w-5 mr-2" />
              Elimina Selezionati ({selectedPosts.length})
            </button>
          )}
          <Link
            to="/dashboard/admin/blog/new"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
          >
            <Plus className="h-5 w-5 mr-2" />
            Nuovo Articolo
          </Link>
        </div>
      </div>

      <div className="bg-white shadow-sm rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left">
                <input
                  type="checkbox"
                  checked={selectedPosts.length === posts.length}
                  onChange={toggleAllPosts}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Titolo
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Categoria
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Data
              </th>
              <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Azioni
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {posts.map(post => (
              <tr key={post.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <input
                    type="checkbox"
                    checked={selectedPosts.includes(post.id)}
                    onChange={() => togglePostSelection(post.id)}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {post.title}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {post.category}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {formatDate(post.publishedAt)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right">
                  <div className="flex justify-end gap-2">
                    <button
                      onClick={() => navigate(`/blog/${post.url}`)}
                      className="text-gray-600 hover:text-blue-600"
                    >
                      <Eye className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => navigate(`/dashboard/admin/blog/${post.id}`)}
                      className="text-gray-600 hover:text-blue-600"
                    >
                      <Pencil className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDelete(post.id)}
                      className="text-gray-600 hover:text-red-600"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
} 
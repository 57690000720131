import React from 'react';
import { User, Clock, Check, CheckCheck } from 'lucide-react';
import { Message } from '../../types/chat';
import { formatSafeDate } from '../../utils/dates';

interface ChatMessageProps {
  message: Message;
  isOwnMessage: boolean;
}

export function ChatMessage({ message, isOwnMessage }: ChatMessageProps) {
  const renderReadIndicator = () => {
    if (!isOwnMessage) return null;
    
    const isRead = message.readBy.length >= 2;
    
    return (
      <div className="flex items-center gap-1 mt-1 text-xs">
        {isRead ? (
          <>
            <CheckCheck className="h-3 w-3 text-blue-500" />
            <span className="text-blue-500">Letto</span>
          </>
        ) : (
          <>
            <Check className="h-3 w-3 text-gray-400" />
            <span className="text-gray-400">Non letto</span>
          </>
        )}
      </div>
    );
  };

  switch (message.type) {
    case 'system':
      return (
        <div className="flex justify-center my-4">
          <span className="text-xs text-gray-500 bg-gray-100 px-3 py-1.5 rounded-full">
            {message.content}
          </span>
        </div>
      );

    case 'contact':
      const contactInfo = JSON.parse(message.content);
      return (
        <div className={`flex ${isOwnMessage ? 'justify-end' : 'justify-start'} mb-4`}>
          <div className={`max-w-[70%] rounded-2xl p-4 ${
            isOwnMessage 
              ? 'bg-blue-600 text-white shadow-sm' 
              : 'bg-gray-100 text-gray-900'
          }`}>
            <div className="flex items-center gap-2 mb-2">
              <User className="h-4 w-4" />
              <span className="font-medium">{contactInfo.name}</span>
            </div>
            <div className="space-y-1 text-sm">
              <p>Email: {contactInfo.email}</p>
              {contactInfo.phone && <p>Telefono: {contactInfo.phone}</p>}
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className={`flex items-center gap-1 text-xs ${
                isOwnMessage ? 'text-blue-100' : 'text-gray-500'
              }`}>
                <Clock className="h-3 w-3" />
                {formatSafeDate(message.createdAt)}
              </div>
              {renderReadIndicator()}
            </div>
          </div>
        </div>
      );

    default:
      return (
        <div className={`flex ${isOwnMessage ? 'justify-end' : 'justify-start'} mb-4`}>
          <div className={`relative max-w-[70%] rounded-2xl px-4 py-2 ${
            isOwnMessage ? 'bg-blue-600 text-white' : 'bg-gray-100'
          }`}>
            <p className="whitespace-pre-wrap break-words">{message.content}</p>
            <div className="flex items-center justify-between gap-2 mt-1">
              <div className={`flex items-center gap-1 text-xs ${
                isOwnMessage ? 'text-blue-100' : 'text-gray-500'
              }`}>
                <Clock className="h-3 w-3" />
                {formatSafeDate(message.createdAt)}
              </div>
              {renderReadIndicator()}
            </div>
          </div>
        </div>
      );
  }
}
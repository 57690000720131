import React, { useState, useEffect } from 'react';
import { Building2, LineChart, Send, CreditCard, User, Bell, Settings, Calendar, ChevronLeft, ChevronRight } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import { ListingsList } from './ListingsList';
import { ListingAnalytics } from './ListingAnalytics';
import { Applications } from './Applications';
import { Credits } from './Credits';
import { ProfileSettings } from '../../profile/ProfileSettings';
import { NotificationsTab } from '../NotificationsTab';
import { AdminManagement } from '../admin/AdminManagement';
import { AvailabilityTab } from './AvailabilityTab';
import { useAuthContext } from '../../../contexts/AuthContext';
import { AnalyticsTab } from '../admin/AnalyticsTab';
import { useSwipeable } from 'react-swipeable';

export function LandlordDashboard() {
  const location = useLocation();
  const { userProfile } = useAuthContext();
  const [activeTab, setActiveTab] = useState('listings');
  const isAdmin = userProfile?.email === 'info@unistanza.it';

  const tabs = [
    { id: 'listings', label: 'I Miei Annunci', icon: Building2 },
    { id: 'analytics', label: 'Analytics', icon: LineChart },
    { id: 'applications', label: 'Candidature', icon: Send },
    { id: 'notifications', label: 'Notifiche', icon: Bell },
    { id: 'credits', label: 'Crediti', icon: CreditCard },
    { id: 'availability', label: 'Disponibilità', icon: Calendar },
    ...(isAdmin ? [{ id: 'management', label: 'Gestione', icon: Settings }] : []),
    { id: 'profile', label: 'Profilo', icon: User }
  ];

  const currentTabIndex = tabs.findIndex(tab => tab.id === activeTab);

  const handlePrevTab = () => {
    if (currentTabIndex > 0) {
      setActiveTab(tabs[currentTabIndex - 1].id);
    }
  };

  const handleNextTab = () => {
    if (currentTabIndex < tabs.length - 1) {
      setActiveTab(tabs[currentTabIndex + 1].id);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    if (tab) {
      setActiveTab(tab);
    }
  }, [location.search]);

  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentTabIndex < tabs.length - 1) {
        setActiveTab(tabs[currentTabIndex + 1].id);
      }
    },
    onSwipedRight: () => {
      if (currentTabIndex > 0) {
        setActiveTab(tabs[currentTabIndex - 1].id);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: false
  });

  return (
    <div className="min-h-screen bg-gray-50 pt-16 md:pt-24">
      <div className="h-[calc(100vh-4rem)] md:h-auto md:max-w-7xl mx-auto md:px-4 md:py-8">
        <div className="h-full bg-white md:rounded-lg md:shadow">
          <div className="border-b">
            {/* Desktop navigation */}
            <nav className="hidden md:flex items-center -mb-px overflow-x-auto">
              {tabs.map(tab => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`flex items-center gap-2 py-4 px-6 border-b-2 font-medium text-sm whitespace-nowrap ${
                    activeTab === tab.id
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700'
                  }`}
                >
                  <tab.icon className="h-5 w-5" />
                  {tab.label}
                </button>
              ))}
            </nav>

            {/* Mobile navigation */}
            <div className="md:hidden" {...handlers}>
              <div className="flex items-center justify-between px-4 py-3">
                <button
                  onClick={handlePrevTab}
                  disabled={currentTabIndex === 0}
                  className="p-2 text-gray-500 hover:text-gray-700 disabled:opacity-50"
                >
                  <ChevronLeft className="h-5 w-5" />
                </button>

                <div className="flex items-center gap-2">
                  {tabs[currentTabIndex].icon && (
                    <div className="h-5 w-5 text-blue-600">
                      {React.createElement(tabs[currentTabIndex].icon)}
                    </div>
                  )}
                  <span className="font-medium text-blue-600">
                    {tabs[currentTabIndex].label}
                  </span>
                </div>

                <button
                  onClick={handleNextTab}
                  disabled={currentTabIndex === tabs.length - 1}
                  className="p-2 text-gray-500 hover:text-gray-700 disabled:opacity-50"
                >
                  <ChevronRight className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>

          <div className="h-[calc(100%-72px)] md:h-auto overflow-y-auto p-6" {...handlers}>
            {activeTab === 'listings' && <ListingsList />}
            {activeTab === 'analytics' && (isAdmin ? <AnalyticsTab /> : <ListingAnalytics />)}
            {activeTab === 'applications' && <Applications />}
            {activeTab === 'notifications' && <NotificationsTab />}
            {activeTab === 'credits' && <Credits />}
            {activeTab === 'availability' && <AvailabilityTab />}
            {activeTab === 'management' && isAdmin && <AdminManagement />}
            {activeTab === 'profile' && <ProfileSettings />}
          </div>
        </div>
      </div>
    </div>
  );
}
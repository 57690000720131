import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { User, Mail, Lock, Phone, Building2, GraduationCap, CheckCircle2 } from 'lucide-react';
import { registerWithEmailAndPassword, signInWithGoogle } from '../../lib/auth';
import { addCredits } from '../../lib/credits';
import { ProfileCompletionModal } from '../../components/auth/ProfileCompletionModal';

export function Register() {
  const [userType, setUserType] = useState<'student' | 'landlord'>('student');
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
  });
  const [loading, setLoading] = useState(false);
  const [showProfileCompletion, setShowProfileCompletion] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleGoogleSignIn = async () => {
    try {
      console.log('🔵 Inizio registrazione Google');
      setLoading(true);
      const result = await signInWithGoogle(userType);
      console.log('🔵 Risultato signInWithGoogle:', result);
      
      if (result.needsProfileCompletion) {
        console.log('🔵 Necessario completamento profilo');
        const profileDataWithoutCredits = {
          ...result.userData
        };
        
        setShowProfileCompletion(true);
        setProfileData(profileDataWithoutCredits);
        return;
      }

      if (result.userProfile) {
        console.log('🔵 Profilo utente esistente, aggiungo crediti iniziali');
        const credits = userType === 'landlord' ? 10 : 5;
        await addCredits(result.userProfile.id, credits, 'Crediti di benvenuto');
        console.log('🔵 Crediti aggiunti con successo:', credits);

        toast.success('Registrazione completata con successo!');
        navigate(`/dashboard/${userType}`);
      }
    } catch (error: any) {
      console.error('🔴 Errore Google sign-in:', error);
      toast.error(error.message || 'Errore durante la registrazione con Google');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const phoneRegex = /^\+39\s?\d{3}\s?\d{7}$/;
    if (!phoneRegex.test(formData.phoneNumber)) {
      toast.error('Inserisci un numero di telefono italiano valido (formato: +39 XXX XXXXXXX)');
      return;
    }

    try {
      setLoading(true);
      const { user, userProfile } = await registerWithEmailAndPassword({
        ...formData,
        userType
      });

      if (!user || !userProfile) {
        throw new Error('Errore durante la registrazione');
      }

      const credits = userType === 'landlord' ? 10 : 5;
      await addCredits(userProfile.id, credits, 'Crediti di benvenuto');

      // Attendi un momento per assicurarti che l'autenticazione sia completata
      await new Promise(resolve => setTimeout(resolve, 1000));

      toast.success('Registrazione completata con successo!');
      navigate(`/dashboard/${userType}`, { replace: true });
    } catch (error: any) {
      toast.error(error.message || 'Errore durante la registrazione');
    } finally {
      setLoading(false);
    }
  };

  const getHeroContent = (userType: 'student' | 'landlord') => {
    if (userType === 'student') {
      return {
        title: "Trova la tua casa ideale con UniStanza",
        subtitle: "La piattaforma che ti aiuta a trovare l'alloggio perfetto per i tuoi studi",
        features: [
          {
            title: "Verifica dell'identità",
            description: "Tutti i proprietari sono verificati per garantire la massima sicurezza"
          },
          {
            title: "Visite virtuali",
            description: "Videochiama i proprietari e visita gli alloggi comodamente da casa"
          },
          {
            title: "Ricerca intelligente",
            description: "Trova l'alloggio perfetto in base alle tue esigenze e al tuo budget"
          }
        ]
      };
    }

    return {
      title: "Affitta il tuo immobile con UniStanza",
      subtitle: "La piattaforma che ti aiuta a trovare gli inquilini ideali in modo sicuro",
      features: [
        {
          title: "Studenti verificati",
          description: "Tutti gli studenti sono verificati tramite email universitaria"
        },
        {
          title: "Gestione semplificata",
          description: "Gestisci prenotazioni, contratti e pagamenti in un unico posto"
        },
        {
          title: "Massima visibilità",
          description: "Raggiungi migliaia di studenti in cerca di alloggio"
        }
      ]
    };
  };

  const heroContent = getHeroContent(userType);

  return (
    <div className="pt-16 bg-gray-50 min-h-screen">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8 md:py-12">
        <div className="flex">
          {/* Sezione sinistra - Hero/Features */}
          <div className="hidden lg:flex lg:w-1/2 bg-gradient-to-br from-blue-600 to-indigo-700 rounded-2xl p-4 md:p-8">
            <div className="flex flex-col w-full max-w-lg">
              <h1 className="text-4xl font-bold text-white mb-6">
                {heroContent.title}
              </h1>
              <p className="text-xl text-blue-100 mb-12">
                {heroContent.subtitle}
              </p>

              <div className="space-y-8">
                {heroContent.features.map((feature, index) => (
                  <div key={index} className="flex items-start gap-4">
                    <div className="p-3 bg-white/10 rounded-xl">
                      <CheckCircle2 className="h-6 w-6 text-blue-200" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-white mb-1">
                        {feature.title}
                      </h3>
                      <p className="text-blue-100">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Sezione destra - Form */}
          <div className="w-full lg:w-1/2 lg:pl-8">
            {/* Selettore tipo utente */}
            <div className="mb-10">
              <div className="grid grid-cols-2 gap-6">
                <button
                  type="button"
                  onClick={() => setUserType('student')}
                  className={`relative flex flex-col items-center gap-3 p-6 rounded-2xl border-2 transition-all ${
                    userType === 'student'
                      ? 'border-blue-600 bg-blue-50'
                      : 'border-gray-200 hover:border-gray-300'
                  }`}
                >
                  <GraduationCap className={`h-8 w-8 ${
                    userType === 'student' ? 'text-blue-600' : 'text-gray-400'
                  }`} />
                  <div className="text-center">
                    <span className={`text-base font-medium ${
                      userType === 'student' ? 'text-blue-600' : 'text-gray-600'
                    }`}>
                      Studente
                    </span>
                    <p className="text-sm text-gray-500 mt-1">
                      Cerca il tuo alloggio ideale
                    </p>
                  </div>
                </button>

                <button
                  type="button"
                  onClick={() => setUserType('landlord')}
                  className={`relative flex flex-col items-center gap-3 p-6 rounded-2xl border-2 transition-all ${
                    userType === 'landlord'
                      ? 'border-blue-600 bg-blue-50'
                      : 'border-gray-200 hover:border-gray-300'
                  }`}
                >
                  <Building2 className={`h-8 w-8 ${
                    userType === 'landlord' ? 'text-blue-600' : 'text-gray-400'
                  }`} />
                  <div className="text-center">
                    <span className={`text-base font-medium ${
                      userType === 'landlord' ? 'text-blue-600' : 'text-gray-600'
                    }`}>
                      Proprietario
                    </span>
                    <p className="text-sm text-gray-500 mt-1">
                      Pubblica i tuoi annunci
                    </p>
                  </div>
                </button>
              </div>
            </div>

            {/* Form di registrazione */}
            <div className="bg-white rounded-2xl p-4 md:p-8 shadow-xl border border-gray-100">
              <form onSubmit={handleSubmit} className="space-y-6 w-full max-w-lg mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Nome
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                      autoComplete="given-name"
                      className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Il tuo nome"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Cognome
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                      autoComplete="family-name"
                      className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Il tuo cognome"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Email
                  </label>
                  <div className="relative">
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      autoComplete="email"
                      className="h-10 w-full px-4 pr-10 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="La tua email"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                      <Mail className="h-5 w-5 text-gray-400" />
                    </div>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Password
                  </label>
                  <div className="relative">
                    <input
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                      minLength={6}
                      autoComplete="new-password"
                      className="h-10 w-full px-4 pr-10 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Minimo 6 caratteri"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                      <Lock className="h-5 w-5 text-gray-400" />
                    </div>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Numero di Telefono
                  </label>
                  <div className="relative">
                    <input
                      type="tel"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      required
                      pattern="\+39\s?\d{3}\s?\d{7}"
                      autoComplete="tel"
                      className="h-10 w-full px-4 pr-10 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="+39 XXX XXXXXXX"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                      <Phone className="h-5 w-5 text-gray-400" />
                    </div>
                  </div>
                </div>

                <div className="pt-6">
                  <button
                    type="submit"
                    disabled={loading}
                    className="w-full h-10 flex justify-center items-center px-6 rounded-lg text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 transition-colors"
                  >
                    {loading ? 'Registrazione...' : 'Registrati'}
                  </button>
                </div>

                <div className="relative my-8">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-4 bg-white text-gray-500">
                      Oppure continua con
                    </span>
                  </div>
                </div>

                <button
                  type="button"
                  onClick={handleGoogleSignIn}
                  disabled={loading}
                  className="w-full h-10 flex items-center justify-center px-6 rounded-lg text-base font-medium text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 transition-colors"
                >
                  <img
                    className="h-5 w-5 mr-2"
                    src="https://www.svgrepo.com/show/475656/google-color.svg"
                    alt="Google logo"
                  />
                  Continua con Google
                </button>

                <div className="text-center text-sm text-gray-600">
                  Hai già un account?{' '}
                  <Link to="/login" className="font-medium text-blue-600 hover:text-blue-500">
                    Accedi
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {showProfileCompletion && profileData && (
        <ProfileCompletionModal
          initialData={profileData}
          onSubmit={async (data) => {
            try {
              console.log('🟡 Inizio completamento profilo:', data);
              const credits = userType === 'landlord' ? 10 : 5;
              console.log('🟡 Tentativo di aggiungere crediti:', credits);
              await addCredits(data.id, credits, 'Crediti di benvenuto');
              console.log('🟡 Crediti aggiunti con successo');
              
              setShowProfileCompletion(false);
              toast.success('Profilo completato con successo!');
              navigate(`/dashboard/${userType}`);
            } catch (error) {
              console.error('🔴 Errore completamento profilo:', error);
              toast.error('Errore durante il salvataggio del profilo');
            }
          }}
          onClose={() => setShowProfileCompletion(false)}
        />
      )}
    </div>
  );
}
import React, { useState, useEffect, useRef } from 'react';
import { Send, Paperclip, AlertCircle, ChevronLeft, X } from 'lucide-react';
import { Message, Ticket } from '../../types/ticket';
import { onTicketMessagesChange, sendMessage } from '../../lib/tickets';
import { useAuthContext } from '../../contexts/AuthContext';
import { formatDistanceToNow } from 'date-fns';
import { it } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { AttachmentUpload } from '../shared/AttachmentUpload';
import { Timestamp } from 'firebase/firestore';
import { format } from 'date-fns';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import JSZip from 'jszip';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../../lib/firebase';

interface TicketChatProps {
  ticket: Ticket;
  onUpdateTicket: (ticketId: string, data: Partial<Ticket>) => void;
  onBack: () => void;
  onClose: () => void;
}

export function TicketChat({ ticket, onUpdateTicket, onBack, onClose }: TicketChatProps) {
  const { userProfile } = useAuthContext();
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [attachments, setAttachments] = useState<(string | File)[]>([]);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [showAttachments, setShowAttachments] = useState(false);

  useEffect(() => {
    const unsubscribe = onTicketMessagesChange(ticket.id, (updatedMessages) => {
      setMessages(updatedMessages);
    });

    return () => unsubscribe();
  }, [ticket.id]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if ((!newMessage.trim() && !attachments.length) || !userProfile) return;

    if (ticket.status === 'chiuso') {
      toast.error('Non puoi inviare messaggi su un ticket chiuso');
      return;
    }

    setLoading(true);
    try {
      if (ticket.status === 'risolto') {
        await onUpdateTicket(ticket.id, {
          status: 'aperto',
          resolvedAt: null
        });
        toast.info('Il ticket è stato riaperto');
      }

      await sendMessage({
        ticketId: ticket.id,
        userId: userProfile.id,
        userEmail: userProfile.email,
        content: newMessage.trim(),
        isAdmin: userProfile.email === 'info@unistanza.it',
        attachments: attachments.filter((att): att is File => att instanceof File)
      });

      setNewMessage('');
      setAttachments([]);
      setShowAttachments(false);
    } catch (error: any) {
      console.error('Error sending message:', error);
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error('Errore durante l\'invio del messaggio');
      }
    } finally {
      setLoading(false);
    }
  };

  const formatSafeDate = (date: any): string => {
    if (!date) return '';
    
    try {
      let dateObj: Date;
      
      if (date instanceof Timestamp) {
        dateObj = date.toDate();
      } else if (typeof date === 'string') {
        dateObj = new Date(date);
      } else if (date instanceof Date) {
        dateObj = date;
      } else if (date.seconds) {
        dateObj = new Date(date.seconds * 1000);
      } else {
        return '';
      }

      if (isNaN(dateObj.getTime())) {
        return '';
      }

      return format(dateObj, 'dd/MM/yyyy HH:mm', { locale: it });
    } catch (error) {
      console.error('Errore nella formattazione della data:', error);
      return '';
    }
  };

  const getDisplayName = (message: Message) => {
    if (message.isAdmin) {
      return "Supporto Unistanza";
    }
    
    const emailName = message.userEmail.split('@')[0];
    return emailName.charAt(0).toUpperCase() + emailName.slice(1);
  };

  const renderMessage = (message: Message) => {
    const handleDownloadAll = async (attachments: any[]) => {
      console.log('Apertura file:', attachments.length, 'file(s)');
      
      for (const attachment of attachments) {
        try {
          const url = attachment.url || attachment;
          window.open(url, '_blank');
          await new Promise(resolve => setTimeout(resolve, 500));
        } catch (error) {
          console.error('Errore nell\'apertura del file:', error);
          toast.error('Errore nell\'apertura del file');
        }
      }
    };

    const isOtherUser = message.userId !== userProfile?.id;

    return (
      <div className={`flex flex-col ${isOtherUser ? 'items-start' : 'items-end'} mb-4`}>
        <div className={`max-w-[90%] md:max-w-[80%] rounded-lg p-2 md:p-3 ${
          isOtherUser ? 'bg-gray-100' : 'bg-blue-100'
        }`}>
          <div className="flex flex-col sm:flex-row sm:items-center gap-1 sm:gap-2 mb-1">
            <span className="text-xs sm:text-sm font-medium truncate">
              {getDisplayName(message)}
            </span>
            <span className="text-xs text-gray-500">
              {formatSafeDate(message.createdAt)}
            </span>
          </div>
          <p className="text-xs sm:text-sm whitespace-pre-wrap break-words">{message.content}</p>
          
          {message.attachments && message.attachments.length > 0 && (
            <div className="mt-2">
              <button 
                onClick={() => handleDownloadAll(message.attachments)}
                className="flex items-center gap-2 p-2 bg-white rounded-lg border hover:bg-gray-50 transition-colors"
              >
                <svg
                  className="w-5 h-5 text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                  />
                </svg>
                <span className="text-sm text-gray-600">
                  {message.attachments.length === 1 ? 'Visualizza allegato' : 'Visualizza allegati'}
                </span>
                <span className="ml-1 px-1.5 py-0.5 text-xs bg-gray-100 text-gray-600 rounded-full">
                  {message.attachments.length}
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleResolveTicket = async () => {
    if (!userProfile || !ticket) return;

    try {
      await onUpdateTicket(ticket.id, {
        status: 'risolto',
        resolvedAt: new Date()
      });
      toast.success('Ticket risolto con successo');
    } catch (error) {
      console.error('Errore durante la risoluzione del ticket:', error);
      toast.error('Errore durante la risoluzione del ticket');
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-none border-b p-3 md:p-4 bg-white">
        <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-0">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center gap-3 min-w-0">
              <button
                onClick={onBack}
                className="p-1 hover:bg-gray-100 rounded-full transition-colors flex-shrink-0"
              >
                <ChevronLeft className="h-5 w-5 text-gray-500" />
              </button>
              <div className="min-w-0">
                <h3 className="text-base md:text-lg font-medium text-gray-900 truncate">
                  {ticket.subject}
                </h3>
                <p className="text-xs md:text-sm text-gray-500">
                  Stato: {ticket.status}
                </p>
              </div>
            </div>
            
            <button
              onClick={onClose}
              className="p-1 hover:bg-gray-100 rounded-full transition-colors flex-shrink-0 sm:hidden"
            >
              <X className="h-5 w-5 text-gray-500" />
            </button>
          </div>

          <div className="flex items-center justify-between sm:justify-end gap-2 mt-2 sm:mt-0">
            {userProfile && userProfile.id === ticket.userId && ticket.status === 'aperto' && (
              <button
                onClick={handleResolveTicket}
                className="flex-1 sm:flex-none px-3 py-1.5 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700 transition-colors"
              >
                Segna come Risolto
              </button>
            )}
            <button
              onClick={onClose}
              className="hidden sm:block p-1 hover:bg-gray-100 rounded-full transition-colors flex-shrink-0"
            >
              <X className="h-5 w-5 text-gray-500" />
            </button>
          </div>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto p-3 md:p-4 bg-gray-50">
        {renderMessage({
          id: 'first-message',
          content: ticket.description,
          userId: ticket.userId,
          userEmail: ticket.userEmail,
          isAdmin: false,
          createdAt: ticket.createdAt,
          attachments: ticket.firstMessageAttachments || []
        })}

        {messages.filter(message => 
          message.createdAt && ticket.createdAt && 
          message.createdAt.getTime() !== ticket.createdAt.getTime()
        ).map((message) => (
          <div key={message.id}>
            {renderMessage(message)}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      <div className="flex-none border-t bg-white">
        {ticket.status === 'risolto' ? (
          <div className="p-3 md:p-4 bg-yellow-50">
            <div className="flex items-start gap-2">
              <div className="flex-shrink-0">
                <AlertCircle className="h-5 w-5 text-yellow-400" />
              </div>
              <div>
                <h3 className="text-sm font-medium text-yellow-800">
                  Ticket Risolto
                </h3>
                <p className="mt-1 text-sm text-yellow-700">
                  Questo ticket è stato contrassegnato come risolto. Se hai bisogno di ulteriore assistenza, 
                  ti invitiamo ad aprire un nuovo ticket dalla dashboard di supporto.
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-3 md:p-4">
            <form onSubmit={handleSendMessage} className="space-y-3">
              {showAttachments && (
                <div>
                  <div className="border rounded-lg p-3">
                    <AttachmentUpload
                      attachments={attachments}
                      onChange={setAttachments}
                      maxAttachments={3}
                      title="Aggiungi allegati"
                      subtitle="PNG, JPG, PDF fino a 10MB"
                    />
                  </div>
                  <p className="mt-2 text-xs text-gray-500 italic">
                    È possibile caricare un massimo di 3 file per messaggio
                  </p>
                </div>
              )}

              <div className="flex gap-2 w-full">
                <div className="relative flex-1">
                  <button
                    type="button"
                    onClick={() => setShowAttachments(true)}
                    className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-600 transition-colors"
                  >
                    <Paperclip className="h-5 w-5" />
                  </button>

                  <textarea
                    value={newMessage}
                    onChange={(e) => {
                      setNewMessage(e.target.value);
                      e.target.style.height = 'auto';
                      e.target.style.height = Math.min(e.target.scrollHeight, 200) + 'px';
                    }}
                    placeholder="Scrivi un messaggio..."
                    className="w-full text-sm rounded-lg border-gray-200 focus:ring-blue-500 focus:border-blue-500 bg-gray-50 pl-12 pr-4 pt-3 pb-2 resize-none overflow-hidden min-h-[48px] max-h-[200px] leading-[1.6]"
                    disabled={loading}
                    rows={1}
                  />
                </div>

                <button
                  type="submit"
                  disabled={loading || (!newMessage.trim() && !attachments.length)}
                  className="flex-shrink-0 h-12 w-12 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:hover:bg-blue-500 transition-colors flex items-center justify-center"
                >
                  <Send className="h-5 w-5" />
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}


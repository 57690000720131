import { useEffect, useState } from 'react';
import { Building2 } from 'lucide-react';
import { Listing } from '@/types';
import { getLinkedListings } from '@/lib/listings';
import { ListingCard } from './ListingCard';

interface LinkedListingsProps {
  listingId: string;
  linkedIds: string[];
}

export function LinkedListings({ listingId, linkedIds }: LinkedListingsProps) {
  const [linkedListings, setLinkedListings] = useState<Listing[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLinkedListings = async () => {
      if (!linkedIds.length) return;
      
      try {
        setLoading(true);
        const listings = await getLinkedListings(linkedIds);
        setLinkedListings(listings);
      } catch (error) {
        console.error('Errore nel caricamento degli annunci collegati:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLinkedListings();
  }, [linkedIds]);

  if (!linkedIds.length || !linkedListings.length) return null;

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 mt-6">
      <div className="flex items-center gap-3 mb-6">
        <Building2 className="h-5 w-5 text-gray-400" />
        <h2 className="text-lg font-medium text-gray-900">
          Altri annunci in questo appartamento
        </h2>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {linkedListings.map((listing) => (
          <ListingCard 
            key={listing.id}
            listing={listing}
            variant="compact"
          />
        ))}
      </div>
    </div>
  );
} 
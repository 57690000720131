import { Combobox } from '@headlessui/react';
import usePlacesAutocomplete, { getGeocode, getDetails } from 'use-places-autocomplete';
import { toast } from 'react-toastify';

interface PlacesAutocompleteProps {
  onPlaceSelect: (place: google.maps.places.PlaceResult) => void;
  defaultValue?: string;
  placeholder?: string;
  className?: string;
  required?: boolean;
}

export function PlacesAutocomplete({ 
  onPlaceSelect, 
  defaultValue = '', 
  placeholder = '', 
  className = '',
  required = false 
}: PlacesAutocompleteProps) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: { 
      componentRestrictions: { country: 'it' },
      types: ['address']
    },
    debounce: 300,
    defaultValue
  });

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address: string) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ 
        address,
        componentRestrictions: { country: 'it' }
      });
      
      const isStreetAddress = results[0].types.some(type => 
        ['street_address', 'route', 'premise'].includes(type)
      );
      
      if (!isStreetAddress) {
        toast.error('Per favore seleziona un indirizzo dalla lista dei suggerimenti');
        return;
      }

      const place = await getDetails({
        placeId: results[0].place_id,
        fields: ['formatted_address', 'geometry', 'address_components']
      });

      onPlaceSelect(place);
    } catch (error) {
      console.error('Errore:', error);
      toast.error('Si è verificato un errore nella selezione dell\'indirizzo');
    }
  };

  return (
    <div className="relative">
      <input
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder={placeholder}
        className={className}
        required={required}
        autoComplete="off"
        role="combobox"
        aria-autocomplete="list"
        aria-expanded={status === "OK"}
      />
      
      {status === "OK" && (
        <ul className="absolute z-50 w-full bg-white shadow-lg max-h-60 rounded-md mt-1 overflow-auto py-1">
          {data.map(({ place_id, description }) => (
            <li
              key={place_id}
              onClick={() => handleSelect(description)}
              className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
            >
              {description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
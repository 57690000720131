import { format } from 'date-fns';
import { it } from 'date-fns/locale';

export function formatSafeDate(date: any): string {
  if (!date) return '';
  
  try {
    let dateObj: Date;
    
    if (date.toDate) {
      // Firestore Timestamp
      dateObj = date.toDate();
    } else if (date.seconds) {
      // Unix timestamp in secondi
      dateObj = new Date(date.seconds * 1000);
    } else if (typeof date === 'string') {
      dateObj = new Date(date);
    } else if (date instanceof Date) {
      dateObj = date;
    } else {
      return '';
    }

    // Verifica che la data sia valida
    if (isNaN(dateObj.getTime())) {
      return '';
    }

    return new Intl.DateTimeFormat('it-IT', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(dateObj);
  } catch (error) {
    console.error('Errore nella formattazione della data:', error);
    return '';
  }
}

export const formatDate = (date: string | Date): string => {
  if (!date) return '';
  
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  return format(dateObj, 'dd MMMM yyyy', { locale: it });
}; 
import { useState, useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';

export function useAvailability(userId: string | undefined) {
  const [hasAvailability, setHasAvailability] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return () => {};
    }

    console.log('Debug - useAvailability hook - userId:', userId);

    const unsubscribe = onSnapshot(
      doc(db, 'availability', userId),
      (doc) => {
        console.log('Debug - useAvailability hook - doc data:', doc.data());
        
        if (!doc.exists()) {
          console.log('Debug - useAvailability hook - doc does not exist');
          setHasAvailability(false);
          setLoading(false);
          return;
        }

        const data = doc.data();
        const hasSchedule = data?.weeklySchedule &&
          Object.values(data.weeklySchedule).some(day => 
            Array.isArray(day) && day.length > 0 &&
            day.some(slot => {
              if (!slot.startTime || !slot.endTime) return false;
              const [startHour, startMinute] = slot.startTime.split(':').map(Number);
              const [endHour, endMinute] = slot.endTime.split(':').map(Number);
              const start = startHour * 60 + startMinute;
              const end = endHour * 60 + endMinute;
              return end > start;
            })
          );

        console.log('Debug - useAvailability hook - hasSchedule:', hasSchedule);
        setHasAvailability(hasSchedule);
        setLoading(false);
      },
      (error) => {
        console.error('Debug - useAvailability hook - error:', error);
        setHasAvailability(false);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [userId]);

  return { hasAvailability, loading };
}

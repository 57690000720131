import React, { useState, useEffect } from 'react';
import { Send, Settings } from 'lucide-react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { onApplicationsChange } from '../../../lib/applications';
import { Application } from '../../../types/application';
import { ApplicationCard } from './ApplicationCard';
import { ApplicationDataModal } from './ApplicationDataModal';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

export function ApplicationsList() {
  const { userProfile } = useAuthContext();
  const [applications, setApplications] = useState<Application[]>([]);
  const [loading, setLoading] = useState(true);
  const [showDataModal, setShowDataModal] = useState(false);
  const location = useLocation();
  const [highlightedId, setHighlightedId] = useState<string | null>(null);

  useEffect(() => {
    let unsubscribe: () => void;

    if (userProfile?.id) {
      unsubscribe = onApplicationsChange(
        userProfile.id,
        (updatedApplications) => {
          setApplications(updatedApplications);
          setLoading(false);
        },
        () => {
          setApplications([]);
          setLoading(false);
        }
      );
    } else {
      setApplications([]);
      setLoading(false);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
      setApplications([]); // Reset esplicito dello stato
    };
  }, [userProfile?.id]);

  useEffect(() => {
    if (location.state?.highlightedId) {
      const element = document.getElementById(location.state.highlightedId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setHighlightedId(location.state.highlightedId);
        setTimeout(() => setHighlightedId(null), 3000);
      }
    }
  }, [location.state]);

  const handleDataSaved = () => {
    setShowDataModal(false);
  };

  if (loading) {
    return (
      <div className="text-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
        <p className="mt-4 text-gray-500">Caricamento candidature...</p>
      </div>
    );
  }

  return (
    <div className="space-y-4 sm:space-y-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <h2 className="text-lg sm:text-xl font-semibold">Le Mie Candidature</h2>
        <button
          onClick={() => setShowDataModal(true)}
          className="w-full sm:w-auto flex items-center justify-center gap-2 px-3 sm:px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          <Settings className="h-4 w-4" />
          Gestisci Dati Candidatura
        </button>
      </div>

      {applications.length === 0 ? (
        <div className="text-center py-8 sm:py-12">
          <Send className="h-10 w-10 sm:h-12 sm:w-12 text-gray-400 mx-auto" />
          <h3 className="mt-2 text-base sm:text-lg font-medium text-gray-900">
            Nessuna candidatura inviata
          </h3>
          <p className="mt-1 text-sm sm:text-base text-gray-500">
            Inizia a cercare alloggi e invia la tua prima candidatura!
          </p>
        </div>
      ) : (
        <div className="space-y-4">
          {applications.map((application) => (
            <div
              key={application.id}
              className={`transition-all duration-300 ${
                highlightedId === application.id 
                  ? 'ring-2 ring-blue-500 ring-opacity-50 shadow-lg' 
                  : ''
              }`}
            >
              <ApplicationCard application={application} />
            </div>
          ))}
        </div>
      )}

      {showDataModal && (
        <ApplicationDataModal 
          onClose={() => setShowDataModal(false)}
          onSave={handleDataSaved}
        />
      )}
    </div>
  );
}
import { db } from './firebase';
import { doc, setDoc, deleteDoc, getDoc } from 'firebase/firestore';

export async function blockUser(blockerId: string, blockedId: string) {
  const blockRef = doc(db, 'blocks', `${blockerId}_${blockedId}`);
  await setDoc(blockRef, {
    blockerId,
    blockedId,
    createdAt: new Date()
  });
}

export async function unblockUser(blockerId: string, blockedId: string) {
  const blockRef = doc(db, 'blocks', `${blockerId}_${blockedId}`);
  await deleteDoc(blockRef);
}

export async function isUserBlocked(blockerId: string, blockedId: string) {
  const blockRef = doc(db, 'blocks', `${blockerId}_${blockedId}`);
  const blockDoc = await getDoc(blockRef);
  return blockDoc.exists();
}

export const getBlockTimestamp = async (blockerId: string, blockedId: string): Promise<Date | null> => {
  try {
    const blockDoc = await getDoc(doc(db, 'blocks', `${blockerId}_${blockedId}`));
    
    if (blockDoc.exists()) {
      const data = blockDoc.data();
      return data.createdAt?.toDate() || null;
    }
    
    return null;
  } catch (error) {
    console.error('Errore nel recupero del timestamp del blocco:', error);
    return null;
  }
}; 
import {
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  Timestamp,
  serverTimestamp,
  query,
  where,
  getDocs,
  orderBy,
  limit as firestoreLimit,
  runTransaction,
  increment,
  DocumentData,
  writeBatch,
  addDoc,
  onSnapshot,
  QuerySnapshot,
  FirestoreError
} from 'firebase/firestore';
import { db, auth } from './firebase';
import { Listing } from '../types/listing';
import { uploadFiles, deleteFile, cleanupTempFiles } from './storage';
import { toast } from 'react-toastify';
import { generateListingTitle, generateListingDescription } from '../utils/ai';
import { useCredits } from './credits';
import { featuredPlansCollection } from './plans';

const LISTING_CREATION_COST = 10; // Costo in crediti per creare un annuncio
const LISTING_REPUBLISH_COST = 10; // o il valore appropriato per la ripubblicazione
const listingsCollection = collection(db, 'listings');

interface LocationData {
  formattedAddress: string;
  lat: number;
  lng: number;
  streetNumber: string;
  route: string;
  locality: string;
  area: string;
  country: string;
}

interface ListingData {
  // ... altri campi
  location: LocationData;
  // ... altri campi
}

const parseFirestoreDate = (date: any): Date | undefined => {
  if (!date) return undefined;
  if (date instanceof Timestamp) return date.toDate();
  if (date instanceof Date) return date;
  if (typeof date === 'string') return new Date(date);
  return undefined;
};

const formatListing = (doc: DocumentData): Listing => {
  const data = doc.data();
  return {
    id: doc.id,
    ...data,
    createdAt: parseFirestoreDate(data.createdAt) || new Date(),
    updatedAt: parseFirestoreDate(data.updatedAt),
    availableFrom: parseFirestoreDate(data.availableFrom),
    featured: data.featured ? {
      ...data.featured,
      expiresAt: parseFirestoreDate(data.featured.expiresAt)
    } : undefined
  } as Listing;
};

// Nuova funzione helper per controllare se un annuncio in evidenza è scaduto
const isFeaturedExpired = (listing: Listing): boolean => {
  if (!listing.featured?.active || !listing.featured.expiresAt) {
    return true;
  }
  return new Date() > new Date(listing.featured.expiresAt);
};

// Nuova funzione helper per ordinare gli annunci
const sortListingsByFeatured = (listings: Listing[]): Listing[] => {
  return listings.sort((a, b) => {
    const aFeatured = a.featured?.active && !isFeaturedExpired(a);
    const bFeatured = b.featured?.active && !isFeaturedExpired(b);
    
    if (aFeatured && !bFeatured) return -1;
    if (!aFeatured && bFeatured) return 1;
    
    return b.createdAt.getTime() - a.createdAt.getTime();
  });
};

const SIX_MONTHS_IN_MS = 6 * 30 * 24 * 60 * 60 * 1000; // 6 mesi in millisecondi

export async function createListing(userId: string, data: ListingData): Promise<string> {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser || currentUser.uid !== userId) {
      throw new Error('Utente non autenticato o non autorizzato');
    }

    // Validazione location
    if (!data.location?.formattedAddress || !data.location?.lat || !data.location?.lng) {
      throw new Error('Indirizzo non valido o incompleto');
    }

    // Assicurati che tutti i campi dell'indirizzo siano stringhe vuote se non definiti
    const location: LocationData = {
      formattedAddress: data.location.formattedAddress,
      lat: data.location.lat,
      lng: data.location.lng,
      streetNumber: data.location.streetNumber || '',
      route: data.location.route || '',
      locality: data.location.locality || '',
      area: data.location.area || '',
      country: data.location.country || 'Italia'
    };

    // Genera titolo e descrizione se non forniti
    const title = data.title || await generateListingTitle(data);
    const description = data.description || await generateListingDescription(data);

    // Gestione immagini
    let imageUrls: string[] = [];
    if (data.images?.length > 0) {
      const imagesToUpload = data.images.filter((img: any) => img instanceof File);
      if (imagesToUpload.length > 0) {
        try {
          imageUrls = await uploadFiles(imagesToUpload, {
            folder: `temp/${userId}/images`,
            maxFiles: 15,
            allowedTypes: ['image/jpeg', 'image/png', 'image/webp'],
            maxFileSize: 10 * 1024 * 1024,
            silent: true
          });
        } catch (error) {
          console.error('Error uploading images:', error);
          throw new Error('Errore durante il caricamento delle immagini');
        }
      }
      const existingUrls = data.images.filter((img: any) => typeof img === 'string');
      imageUrls = [...existingUrls, ...imageUrls];
    }

    // Gestione planimetrie
    let floorPlanUrls: string[] = [];
    if (data.floorPlans?.length > 0) {
      const plansToUpload = data.floorPlans.filter(plan => plan instanceof File);
      if (plansToUpload.length > 0) {
        try {
          floorPlanUrls = await uploadFiles(plansToUpload, {
            folder: `temp/${userId}/floorplans`,
            maxFiles: 5,
            allowedTypes: ['image/jpeg', 'image/png', 'image/webp', 'application/pdf'],
            maxFileSize: 10 * 1024 * 1024,
            silent: true
          });
        } catch (error) {
          // Se il caricamento delle planimetrie fallisce, elimina le immagini già caricate
          await Promise.all(imageUrls.map(url => deleteFile(url)));
          console.error('Error uploading floor plans:', error);
          throw new Error('Errore durante il caricamento delle planimetrie');
        }
      }
      const existingPlans = data.floorPlans.filter(plan => typeof plan === 'string');
      floorPlanUrls = [...existingPlans, ...floorPlanUrls];
    }

    // Esegui tutto in una singola transazione
    const listingRef = doc(collection(db, 'listings'));
    await runTransaction(db, async (transaction) => {
      const userRef = doc(db, 'users', userId);
      const userDoc = await transaction.get(userRef);
      
      if (!userDoc.exists()) {
        throw new Error('Utente non trovato');
      }

      const credits = userDoc.data()?.landlordInfo?.credits || 0;
      if (credits < LISTING_CREATION_COST) {
        throw new Error(`Crediti insufficienti (${credits}/${LISTING_CREATION_COST})`);
      }

      // Aggiorna i crediti dell'utente
      transaction.update(userRef, {
        'landlordInfo.credits': increment(-LISTING_CREATION_COST),
        'landlordInfo.listingsCount': increment(1)
      });

      const now = new Date();
      const expiresAt = new Date(now.getTime() + SIX_MONTHS_IN_MS);

      // Crea il documento dell'annuncio
      const listingData = {
        ...data,
        id: listingRef.id,
        userId,
        title,
        description,
        images: imageUrls,
        floorPlans: floorPlanUrls,
        location,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        expiresAt: Timestamp.fromDate(expiresAt),
        status: 'active'
      };

      transaction.set(listingRef, listingData);

      // Registra il movimento dei crediti
      const creditRef = doc(collection(db, 'credits'));
      transaction.set(creditRef, {
        userId,
        amount: -LISTING_CREATION_COST,
        type: 'usage',
        description: 'Pubblicazione annuncio',
        listingId: listingRef.id,
        createdAt: serverTimestamp(),
        status: 'completed'
      });
    });

    await cleanupTempFiles(userId);
    return listingRef.id;

  } catch (error) {
    console.error('Errore creazione annuncio:', error);
    throw error;
  }
}

export const updateListing = async (listingId: string, data: Partial<Listing>): Promise<void> => {
  try {
    const docRef = doc(listingsCollection, listingId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      throw new Error('Annuncio non trovato');
    }

    const currentData = docSnap.data();
    const { category, ...updateData } = data;
    let newImages = [...(currentData.images || [])];
    let newFloorPlans = [...(currentData.floorPlans || [])];

    // Handle images
    if (Array.isArray(data.images)) {
      const existingImages = data.images.filter(img => typeof img === 'string');
      const imagesToUpload = data.images.filter((img): img is File => img instanceof File);

      try {
        // Delete removed images
        const imagesToDelete = currentData.images?.filter(
          (url: string) => !existingImages.includes(url)
        ) || [];

        await Promise.all(
          imagesToDelete.map(url => deleteFile(url).catch(err => {
            console.error('Errore eliminazione immagine:', err);
          }))
        );

        // Upload new images
        if (imagesToUpload.length > 0) {
          const imageUrls = await uploadFiles(imagesToUpload, {
            folder: `listings/${listingId}/images`,
            maxFiles: 15,
            allowedTypes: ['image/jpeg', 'image/png', 'image/webp'],
            maxFileSize: 10 * 1024 * 1024,
            onProgress: (progress) => {
              if (progress === 100) {
                toast.success('Immagini aggiornate con successo');
              }
            }
          });
          newImages = [...existingImages, ...imageUrls];
        } else {
          newImages = existingImages;
        }
      } catch (error) {
        console.error('Errore gestione immagini:', error);
        throw new Error('Errore durante la gestione delle immagini');
      }
    }

    // Handle floor plans
    if (Array.isArray(data.floorPlans)) {
      const existingPlans = data.floorPlans.filter(plan => typeof plan === 'string');
      const plansToUpload = data.floorPlans.filter((plan): plan is File => plan instanceof File);

      try {
        // Delete removed floor plans
        const plansToDelete = currentData.floorPlans?.filter(
          (url: string) => !existingPlans.includes(url)
        ) || [];

        await Promise.all(
          plansToDelete.map(url => deleteFile(url).catch(err => {
            console.error('Errore eliminazione planimetria:', err);
          }))
        );

        // Upload new floor plans
        if (plansToUpload.length > 0) {
          const planUrls = await uploadFiles(plansToUpload, {
            folder: `listings/${listingId}/floorplans`,
            maxFiles: 5,
            allowedTypes: ['image/jpeg', 'image/png', 'image/webp', 'application/pdf'],
            maxFileSize: 10 * 1024 * 1024,
            onProgress: (progress) => {
              if (progress === 100) {
                toast.success('Planimetrie aggiornate con successo');
              }
            }
          });
          newFloorPlans = [...existingPlans, ...planUrls];
        } else {
          newFloorPlans = existingPlans;
        }
      } catch (error) {
        console.error('Errore gestione planimetrie:', error);
        throw new Error('Errore durante la gestione delle planimetrie');
      }
    }

    // Update listing document senza includere category
    await updateDoc(docRef, {
      ...updateData,
      images: newImages,
      floorPlans: newFloorPlans,
      updatedAt: serverTimestamp()
    });

  } catch (error: any) {
    console.error('Error updating listing:', error);
    throw new Error(error.message || 'Errore durante l\'aggiornamento dell\'annuncio');
  }
}

export async function deleteListing(listingId: string): Promise<void> {
  try {
    const listingRef = doc(listingsCollection, listingId);
    const listingDoc = await getDoc(listingRef);
    
    if (!listingDoc.exists()) {
      throw new Error('Annuncio non trovato');
    }
    
    const listingData = listingDoc.data();
    const batch = writeBatch(db);
    
    // Elimina il documento principale
    batch.delete(listingRef);
    
    // Esegui la transazione
    await batch.commit();
    
    // Dopo aver eliminato il documento, prova a eliminare i file
    try {
      // Elimina le immagini
      if (listingData.images?.length > 0) {
        await Promise.all(
          listingData.images.map(url => deleteFile(url).catch(err => {
            console.warn(`Impossibile eliminare l'immagine ${url}:`, err);
          }))
        );
      }
      
      // Elimina le planimetrie
      if (listingData.floorPlans?.length > 0) {
        await Promise.all(
          listingData.floorPlans.map(url => deleteFile(url).catch(err => {
            console.warn(`Impossibile eliminare la planimetria ${url}:`, err);
          }))
        );
      }
    } catch (storageError) {
      // Log dell'errore ma non bloccare l'operazione
      console.warn('Errore durante l\'eliminazione dei file:', storageError);
    }

    toast.success('Annuncio eliminato con successo');
  } catch (error) {
    console.error('Error deleting listing:', error);
    throw new Error('Errore durante l\'eliminazione dell\'annuncio');
  }
}

export async function getListing(listingId: string): Promise<Listing | null> {
  try {
    const docRef = doc(listingsCollection, listingId);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists()) {
      return null;
    }

    return formatListing(docSnap);
  } catch (error: any) {
    console.error('Error fetching listing:', error);
    throw new Error(error.message || 'Errore durante il recupero dell\'annuncio');
  }
}

export async function incrementListingViews(listingId: string): Promise<void> {
  if (!listingId) return;

  try {
    // Aggiorna prima l'annuncio
    const listingRef = doc(listingsCollection, listingId);
    await updateDoc(listingRef, {
      views: increment(1),
      updatedAt: serverTimestamp()
    });

    // Poi aggiorna o crea il documento analytics
    try {
      const analyticsRef = doc(collection(db, 'analytics'), listingId);
      const analyticsDoc = await getDoc(analyticsRef);

      if (analyticsDoc.exists()) {
        await updateDoc(analyticsRef, {
          views: increment(1),
          updatedAt: serverTimestamp()
        });
      } else {
        await setDoc(analyticsRef, {
          views: 1,
          applications: 0,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        });
      }
    } catch (analyticsError) {
      // Se fallisce l'aggiornamento delle analytics, logghiamo l'errore ma non blocchiamo l'operazione
      console.warn('Error updating analytics:', analyticsError);
    }
  } catch (error: any) {
    console.error('Error incrementing views:', error);
    // Non rilanciare l'errore per evitare di bloccare il caricamento della pagina
    // ma registra l'errore per il monitoraggio
    if (error.code === 'permission-denied') {
      console.warn('Permission denied while incrementing views');
    }
  }
}

export async function updateListingFeatured(
  listingId: string,
  planId: string,
  paymentId: string,
  paymentData: {
    amount: number;
    userId: string;
    status: string;
    sessionId: string;
    metadata: any;
  }
): Promise<void> {
  if (!listingId || !planId || !paymentId) {
    throw new Error('Parametri mancanti per l\'aggiornamento dell\'annuncio in evidenza');
  }

  try {
    await runTransaction(db, async (transaction) => {
      // Verifica piano
      const planRef = doc(featuredPlansCollection, planId);
      const planDoc = await transaction.get(planRef);
      
      if (!planDoc.exists()) {
        throw new Error('Piano non trovato');
      }

      const planData = planDoc.data();
      if (!planData) {
        throw new Error('Dati del piano non validi');
      }

      // Verifica listing
      const listingRef = doc(listingsCollection, listingId);
      const listingDoc = await transaction.get(listingRef);
      
      if (!listingDoc.exists()) {
        throw new Error('Annuncio non trovato');
      }

      // Gestione sicura della conversione da stringa a numero
      let durationHours: number;
      if (typeof planData.durationHours === 'string') {
        durationHours = parseInt(planData.durationHours, 10);
      } else if (typeof planData.durationHours === 'number') {
        durationHours = planData.durationHours;
      } else {
        throw new Error('Formato durata piano non valido');
      }

      if (isNaN(durationHours) || durationHours <= 0) {
        throw new Error('Durata del piano non valida');
      }

      const now = new Date();
      const expiresAt = new Date(now.getTime() + durationHours * 60 * 60 * 1000);

      // Crea il documento del pagamento all'interno della transazione
      const paymentRef = doc(db, 'payments', paymentId);
      transaction.set(paymentRef, {
        id: paymentId,
        amount: paymentData.amount,
        userId: paymentData.userId,
        status: paymentData.status,
        paymentMethod: paymentData.metadata?.paymentMethod || 'paypal',
        type: 'featured',
        planId,
        listingId,
        sessionId: paymentData.sessionId,
        metadata: paymentData.metadata,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      }, { merge: true });

      // Aggiorna l'annuncio senza precondizioni di updateTime
      transaction.update(listingRef, {
        featured: {
          active: true,
          plan: planId,
          paymentId,
          expiresAt: Timestamp.fromDate(expiresAt),
          startDate: Timestamp.fromDate(now)
        },
        updatedAt: serverTimestamp()
      });
    });

    console.log('✅ Aggiornamento annuncio in evidenza completato:', {
      listingId,
      planId,
      paymentId
    });
  } catch (error) {
    console.error('❌ Errore dettagliato updateListingFeatured:', error);
    throw error;
  }
}

export async function getFeaturedListings(maxResults = 4): Promise<Listing[]> {
  try {
    const now = new Date();
    const q = query(
      listingsCollection,
      where('status', '==', 'active'),
      where('featured.active', '==', true),
      where('featured.expiresAt', '>', now),
      orderBy('featured.expiresAt', 'desc'),
      firestoreLimit(maxResults)
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs
      .map(formatListing)
      .filter(listing => listing.availableSpots > 0);
  } catch (error) {
    console.error('Errore recupero annunci in evidenza:', error);
    // Fallback query usando l'indice più semplice
    try {
      const fallbackQuery = query(
        listingsCollection,
        where('featured.active', '==', true),
        where('featured.expiresAt', '>', now),
        orderBy('featured.expiresAt', 'desc'),
        firestoreLimit(maxResults * 2) // Richiediamo più risultati per compensare il filtro
      );
      
      const snapshot = await getDocs(fallbackQuery);
      return snapshot.docs
        .map(formatListing)
        .filter(listing => 
          listing.status === 'active' && 
          listing.availableSpots > 0
        )
        .slice(0, maxResults);
    } catch (err) {
      console.error('Anche la query di fallback è fallita:', err);
      return [];
    }
  }
}

export async function getRecentListings(maxResults = 4): Promise<Listing[]> {
  try {
    const now = new Date();
    // Prima query per gli annunci senza featured
    const q = query(
      listingsCollection,
      where('status', '==', 'active'),
      orderBy('createdAt', 'desc'),
      firestoreLimit(maxResults * 2)
    );

    const querySnapshot = await getDocs(q);
    const listings = querySnapshot.docs
      .map(formatListing)
      .filter(listing => 
        listing.availableSpots > 0 && 
        (!listing.featured?.active || // non ha featured attivo
         (listing.featured?.expiresAt && new Date(listing.featured.expiresAt) < now)) // o è scaduto
      )
      .slice(0, maxResults);

    // Se non abbiamo abbastanza risultati, facciamo una query più ampia
    if (listings.length < maxResults) {
      const fallbackQuery = query(
        listingsCollection,
        where('status', '==', 'active'),
        orderBy('createdAt', 'desc'),
        firestoreLimit(maxResults * 4)
      );
      
      const fallbackSnapshot = await getDocs(fallbackQuery);
      const allListings = fallbackSnapshot.docs
        .map(formatListing)
        .filter(listing => 
          listing.availableSpots > 0 && 
          (!listing.featured?.active || 
           (listing.featured?.expiresAt && new Date(listing.featured.expiresAt) < now))
        )
        .slice(0, maxResults);
      
      return allListings;
    }

    return listings;
  } catch (error) {
    console.error('Errore recupero annunci recenti:', error);
    return [];
  }
}

export async function getListingsInBounds(bounds: MapBounds): Promise<Listing[]> {
  try {
    const q = query(
      listingsCollection,
      where('status', '==', 'active')
    );

    const snapshot = await getDocs(q);
    const now = new Date();

    const filteredListings = snapshot.docs
      .map(doc => {
        try {
          const listing = formatListing(doc);
          const lat = listing.location?.lat;
          const lng = listing.location?.lng;
          
          // Verifica se l'annuncio è scaduto
          if (listing.expiresAt && new Date(listing.expiresAt) <= now) {
            return null;
          }

          // Verifica se l'annuncio ha posti disponibili
          if (!listing.availableSpots || listing.availableSpots <= 0) {
            return null;
          }
          
          if (typeof lat !== 'number' || typeof lng !== 'number') {
            console.warn(`Coordinate non valide per l'annuncio ${listing.id}`);
            return null;
          }
          
          if (lat <= bounds.north && 
              lat >= bounds.south && 
              lng <= bounds.east && 
              lng >= bounds.west) {
            return listing;
          }
          return null;
        } catch (docError) {
          console.error('Errore nella formattazione del documento:', docError);
          return null;
        }
      })
      .filter((listing): listing is Listing => 
        listing !== null && 
        listing.status === 'active' &&
        listing.availableSpots > 0
      );

    // Ordina gli annunci: prima quelli in evidenza, poi per data
    return sortListingsByFeatured(filteredListings);
  } catch (error) {
    console.error('Errore nel recupero degli annunci:', error);
    return [];
  }
}

export const republishListing = async (listingId: string, userId: string): Promise<void> => {
  try {
    await runTransaction(db, async (transaction) => {
      // Verifica l'esistenza dell'annuncio
      const listingRef = doc(listingsCollection, listingId);
      const listingSnap = await transaction.get(listingRef);
      
      if (!listingSnap.exists()) {
        throw new Error('Annuncio non trovato');
      }

      const listing = listingSnap.data();

      // Verifica i crediti dell'utente
      const userRef = doc(db, 'users', userId);
      const userSnap = await transaction.get(userRef);
      
      if (!userSnap.exists()) {
        throw new Error('Utente non trovato');
      }

      const credits = userSnap.data()?.landlordInfo?.credits || 0;
      if (credits < LISTING_REPUBLISH_COST) {
        throw new Error(`Crediti insufficienti (${credits}/${LISTING_REPUBLISH_COST})`);
      }

      const now = new Date();
      const newExpiresAt = new Date(now.getTime() + SIX_MONTHS_IN_MS);

      // Aggiorna l'annuncio
      transaction.update(listingRef, {
        availableSpots: listing.maxOccupants,
        rentedUnits: 0,
        status: 'active',
        updatedAt: serverTimestamp(),
        expiresAt: Timestamp.fromDate(newExpiresAt)
      });

      // Scala i crediti
      transaction.update(userRef, {
        'landlordInfo.credits': increment(-LISTING_REPUBLISH_COST)
      });

      // Registra il movimento dei crediti
      const creditRef = doc(collection(db, 'credits'));
      transaction.set(creditRef, {
        userId,
        amount: -LISTING_REPUBLISH_COST,
        type: 'usage',
        description: 'Ripubblicazione annuncio',
        listingId,
        createdAt: serverTimestamp(),
        status: 'completed'
      });
    });
  } catch (error: any) {
    console.error('Error republishing listing:', error);
    throw new Error(error.message || 'Errore durante la ripubblicazione dell\'annuncio');
  }
};

export const getListingsByUser = async (userId: string): Promise<Listing[]> => {
  try {
    const q = query(
      listingsCollection,
      where('userId', '==', userId),
      orderBy('createdAt', 'desc')
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(formatListing);

  } catch (error: any) {
    console.error('Error getting user listings:', error);
    throw new Error(error.message || 'Errore durante il recupero degli annunci');
  }
};

export const formatFirestoreDate = (date: any): string => {
  const parsedDate = parseFirestoreDate(date);
  if (!parsedDate) return 'Data non disponibile';
  
  return parsedDate.toLocaleDateString('it-IT', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

// Nuova funzione per la pulizia automatica degli annunci in evidenza scaduti
export async function cleanupExpiredFeatured(): Promise<void> {
  try {
    const now = new Date();
    const q = query(
      listingsCollection,
      where('featured.active', '==', true),
      where('featured.expiresAt', '<=', now)
    );

    const snapshot = await getDocs(q);
    const batch = writeBatch(db);

    snapshot.docs.forEach(doc => {
      batch.update(doc.ref, {
        'featured.active': false,
        'featured.plan': null,
        'featured.expiresAt': null,
        updatedAt: serverTimestamp()
      });
    });

    await batch.commit();
  } catch (error) {
    console.error('Error cleaning up expired featured listings:', error);
  }
}

export async function cleanupExpiredListings(): Promise<void> {
  try {
    const now = new Date();
    const q = query(
      listingsCollection,
      where('status', '==', 'active'),
      where('expiresAt', '<=', now)
    );

    const snapshot = await getDocs(q);
    const batch = writeBatch(db);

    snapshot.docs.forEach(doc => {
      batch.update(doc.ref, {
        status: 'expired',
        updatedAt: serverTimestamp()
      });
    });

    await batch.commit();
  } catch (error) {
    console.error('Errore pulizia annunci scaduti:', error);
  }
}

export function initializeListingSystem() {
  // Esegui la pulizia ogni giorno a mezzanotte
  const runCleanup = () => {
    const now = new Date();
    const midnight = new Date(now);
    midnight.setHours(24, 0, 0, 0);
    const timeUntilMidnight = midnight.getTime() - now.getTime();

    setTimeout(async () => {
      await cleanupExpiredListings();
      // Imposta il prossimo controllo per il giorno successivo
      runCleanup();
    }, timeUntilMidnight);
  };

  // Avvia il sistema di pulizia
  runCleanup();
}

export async function getLinkedListings(listingIds: string[]): Promise<Listing[]> {
  try {
    const listings = await Promise.all(
      listingIds.map(id => getListing(id))
    );
    return listings.filter((listing): listing is Listing => listing !== null);
  } catch (error) {
    console.error('Errore nel recupero degli annunci collegati:', error);
    return [];
  }
}

export async function getUserListings(userId: string): Promise<Listing[]> {
  const q = query(
    collection(db, 'listings'),
    where('userId', '==', userId),
    where('status', '==', 'active')
  );

  const snapshot = await getDocs(q);
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  } as Listing));
}

import { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { BlogPost as BlogPostType, getBlogPostByUrl } from '../lib/blogPosts';
import { ArrowLeft, Calendar, Clock, Tag } from 'lucide-react';
import { formatDate } from '../utils/dates';
import { fixUnsplashUrl } from '../utils/images';

export function BlogPost() {
  const { url } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState<BlogPostType | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('URL del post:', url);
    loadPost();
  }, [url]);

  const loadPost = async () => {
    try {
      if (!url) throw new Error('URL non valido');
      console.log('BlogPost - Caricamento post con URL:', url);
      const currentPost = await getBlogPostByUrl(url);
      console.log('BlogPost - Post caricato:', currentPost);
      console.log('BlogPost - Immagine di copertina:', currentPost.coverImage);
      setPost(currentPost);
    } catch (error) {
      console.error('BlogPost - Errore caricamento post:', error);
      toast.error('Errore nel caricamento dell\'articolo');
      navigate('/blog');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 pt-24 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (!post) return null;

  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-gray-600 hover:text-gray-900 mb-8"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Torna al blog
        </button>

        <article>
          <div className="relative h-[400px] rounded-xl overflow-hidden mb-8">
            <img
              src={fixUnsplashUrl(post.coverImage)}
              alt={post.title}
              className="w-full h-full object-cover"
            />
          </div>

          <div className="flex items-center gap-6 mb-6">
            <div className="flex items-center gap-2 text-gray-600">
              <Calendar className="h-5 w-5" />
              <time>{formatDate(post.publishedAt)}</time>
            </div>
            <div className="flex items-center gap-2 text-gray-600">
              <Clock className="h-5 w-5" />
              <span>{post.readTime} min lettura</span>
            </div>
            <div className="flex items-center gap-2">
              <Tag className="h-5 w-5 text-blue-600" />
              <span className="text-blue-600 font-medium">{post.category}</span>
            </div>
          </div>

          <h1 className="text-4xl font-bold text-gray-900 mb-6">{post.title}</h1>

          <div className="prose prose-lg max-w-none">
            {/* Contenuto dell'articolo in HTML/Markdown */}
            <div dangerouslySetInnerHTML={{ __html: post.content }} />
          </div>
        </article>
      </div>
    </div>
  );
} 
import React from 'react';
import { Link } from 'react-router-dom';
import { Home, Search, AlertCircle } from 'lucide-react';

export function NotFound() {
  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center">
          <AlertCircle className="h-16 w-16 text-red-500 mx-auto mb-6" />
          
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Pagina non trovata
          </h1>
          
          <p className="text-xl text-gray-600 mb-8">
            La pagina che stai cercando non esiste o è stata spostata
          </p>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 max-w-md mx-auto">
            <Link
              to="/"
              className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors"
            >
              <Home className="h-5 w-5 mr-2" />
              Torna alla home
            </Link>

            <Link
              to="/search"
              className="inline-flex items-center justify-center px-6 py-3 border border-blue-600 text-base font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50 transition-colors"
            >
              <Search className="h-5 w-5 mr-2" />
              Cerca alloggi
            </Link>
          </div>

          <div className="mt-12 p-6 bg-white rounded-lg shadow-sm max-w-2xl mx-auto">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">
              Hai bisogno di aiuto?
            </h2>
            <p className="text-gray-600 mb-4">
              Se pensi che questa sia un errore o hai bisogno di assistenza, visita la nostra pagina di supporto
            </p>
            <Link
              to="/support"
              className="inline-flex items-center text-blue-600 hover:text-blue-700 font-medium"
            >
              Vai al supporto →
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
} 
import React, { useState, useRef, useEffect, RefObject } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { SlidersHorizontal, X } from 'lucide-react';

interface BottomSheetProps {
  children: React.ReactNode;
  title: React.ReactNode;
  isExpanded: boolean;
  onStateChange: (expanded: boolean) => void;
  contentRef?: RefObject<HTMLDivElement>;
}

const COLLAPSED_HEIGHT = 200;
const DRAG_THRESHOLD = 50;

export function BottomSheet({ 
  children, 
  title, 
  isExpanded,
  onStateChange,
  contentRef 
}: BottomSheetProps) {
  const controls = useAnimation();
  const sheetRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const dragStartY = useRef(0);

  useEffect(() => {
    if (isExpanded) {
      controls.start({ height: 'calc(90vh - 64px)' });
    } else {
      controls.start({ height: COLLAPSED_HEIGHT });
    }
  }, [isExpanded, controls]);

  const handleDragStart = (event: MouseEvent | TouchEvent | PointerEvent) => {
    setIsDragging(true);
    dragStartY.current = event.pageY || (event as TouchEvent).touches?.[0]?.pageY || 0;
  };

  const handleDrag = (event: MouseEvent | TouchEvent | PointerEvent) => {
    if (!isDragging || !sheetRef.current) return;
    const currentY = event.pageY || (event as TouchEvent).touches?.[0]?.pageY || 0;
    const deltaY = currentY - dragStartY.current;
    
    if (deltaY < -50 && !isExpanded) {
      onStateChange(true);
    } else if (deltaY > 50 && isExpanded) {
      onStateChange(false);
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const toggleExpanded = () => {
    onStateChange(!isExpanded);
  };

  return (
    <motion.div
      ref={sheetRef}
      drag="y"
      dragConstraints={{ top: 0, bottom: 0 }}
      dragElastic={0}
      dragMomentum={false}
      onDragStart={handleDragStart}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      animate={controls}
      initial={{ height: COLLAPSED_HEIGHT }}
      className="fixed bottom-0 left-0 right-0 bg-white rounded-t-2xl shadow-lg z-30 flex flex-col"
      style={{ 
        willChange: 'transform',
        touchAction: 'none',
        transform: 'translate3d(0, 0, 0)',
        maxHeight: 'calc(85vh - 64px)'
      }}
    >
      {/* Handle and Header */}
      <div 
        className="cursor-grab active:cursor-grabbing"
        onClick={toggleExpanded}
      >
        {/* Drag Handle */}
        <div className="w-12 h-1.5 bg-gray-300 rounded-full mx-auto my-3" />
        
        {/* Header Content */}
        <div className="px-4 pb-4 mb-2">
          {title}
        </div>
      </div>

      {/* Content */}
      <div className="flex-1 overflow-y-auto px-4 pb-4" ref={contentRef}>
        {children}
      </div>
    </motion.div>
  );
}
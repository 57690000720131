import React, { useState, useEffect } from 'react';
import { CreditCard, ChevronLeft, ChevronRight } from 'lucide-react';
import { useAuthContext } from '../../contexts/AuthContext';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { PaymentReceipt } from './PaymentReceipt';

interface Payment {
  id: string;
  amount: number;
  status: string;
  createdAt: Date;
  paymentMethod: string;
  metadata: {
    type: 'credits' | 'featured';
    quantity?: string;
    planId?: string;
    listingId?: string;
    listingTitle?: string;
    planName?: string;
    planDuration?: string;
    planDurationLabel?: string;
    planDescription?: string;
    planFeatures?: string[];
    planPopular?: boolean;
    planPrice?: number;
  };
  invoiceData: any;
}

export function PaymentsHistory() {
  const { userProfile } = useAuthContext();
  const [payments, setPayments] = useState<Payment[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const paymentsPerPage = 5;

  useEffect(() => {
    loadPayments();
  }, [userProfile?.id]);

  const loadPayments = async () => {
    if (!userProfile?.id) return;
    try {
      setLoading(true);
      const paymentsRef = collection(db, 'payments');
      
      // Ottieni i documenti dove userId è una stringa
      const q1 = query(
        paymentsRef,
        where('userId', '==', userProfile.id),
        orderBy('createdAt', 'desc')
      );
      
      // Ottieni i documenti dove userId è una mappa con firebaseUID
      const q2 = query(
        paymentsRef,
        where('userId.metadata.firebaseUID', '==', userProfile.id),
        orderBy('createdAt', 'desc')
      );
      
      const [snapshot1, snapshot2] = await Promise.all([
        getDocs(q1),
        getDocs(q2)
      ]);
      
      const allDocs = [...snapshot1.docs, ...snapshot2.docs];
      
      const paymentsData = allDocs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          amount: data.amount || 0,
          status: data.status || 'pending',
          createdAt: data.createdAt?.toDate(),
          paymentMethod: data.paymentMethod || data.metadata?.paymentMethod,
          metadata: {
            type: data.metadata?.type || data.type || 'unknown',
            quantity: data.metadata?.quantity,
            planId: data.metadata?.planId,
            listingId: data.metadata?.listingId,
            listingTitle: data.metadata?.listingTitle,
            planName: data.metadata?.planName,
            planDuration: data.metadata?.planDuration,
            planDurationLabel: data.metadata?.planDurationLabel,
            planDescription: data.metadata?.planDescription,
            planFeatures: data.metadata?.planFeatures,
            planPopular: data.metadata?.planPopular,
            planPrice: data.metadata?.planPrice
          },
          invoiceData: data.invoiceData || {}
        };
      }) as Payment[];
      
      // Ordina i pagamenti per data decrescente
      paymentsData.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
      
      setPayments(paymentsData);
    } catch (error) {
      console.error('Error loading payments:', error);
      toast.error('Errore nel caricamento dei pagamenti');
    } finally {
      setLoading(false);
    }
  };

  const getPaymentDescription = (payment: Payment) => {
    if (payment.metadata.type === 'credits') {
      return `Acquisto crediti`;
    } else if (payment.metadata.type === 'featured') {
      return 'Piano in evidenza per annuncio';
    } else {
      return 'Pagamento';
    }
  };

  const formatPaymentMethod = (method: string) => {
    if (!method) return '';
    
    switch (method.toLowerCase()) {
      case 'stripe':
        return 'Carta';
      case 'paypal':
        return 'PayPal';
      default:
        return method;
    }
  };

  // Paginazione
  const indexOfLastPayment = currentPage * paymentsPerPage;
  const indexOfFirstPayment = indexOfLastPayment - paymentsPerPage;
  const currentPayments = payments.slice(indexOfFirstPayment, indexOfLastPayment);
  const totalPages = Math.max(1, Math.ceil(payments.length / paymentsPerPage));

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  if (loading) {
    return (
      <div className="text-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
        <p className="mt-2 text-sm text-gray-500">Caricamento pagamenti...</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="text-base sm:text-lg font-medium text-gray-900">Storico Pagamenti</h3>
        <span className="text-sm text-gray-500">
          {payments.length} pagamenti totali
        </span>
      </div>

      <div className="space-y-3">
        {currentPayments.length === 0 ? (
          <p className="text-sm text-gray-500 text-center py-4">Nessun pagamento disponibile</p>
        ) : (
          <>
            {currentPayments.map((payment) => (
              <div
                key={payment.id}
                className="bg-gray-50 rounded-lg p-3 sm:p-4"
              >
                <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:items-center justify-between">
                  <div className="flex items-start space-x-3">
                    <div className="bg-blue-100 p-2 rounded-lg shrink-0">
                      <CreditCard className="h-4 w-4 text-blue-600" />
                    </div>
                    
                    <div className="min-w-0 flex-1">
                      <p className="text-sm sm:text-base font-medium text-gray-900">
                        {getPaymentDescription(payment)}
                      </p>
                      <div className="flex flex-col sm:flex-row sm:items-center gap-1 sm:gap-3">
                        <p className="text-xs sm:text-sm text-gray-500">
                          {format(new Date(payment.createdAt), 'dd/MM/yyyy HH:mm')}
                        </p>
                        {payment.paymentMethod && payment.paymentMethod !== 'N/D' && (
                          <span className="text-xs bg-gray-100 px-2 py-0.5 rounded-full text-gray-600 capitalize">
                            {formatPaymentMethod(payment.paymentMethod)}
                          </span>
                        )}
                        <PaymentReceipt payment={payment} />
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center gap-3">
                    <span className="text-sm sm:text-base font-medium text-gray-900">
                      €{payment.amount.toFixed(2)}
                    </span>
                    <span className={`text-xs px-2 py-1 rounded-full ${
                      payment.status === 'succeeded' || payment.status === 'completed' || payment.status === 'complete'
                        ? 'bg-green-100 text-green-800' 
                        : payment.status === 'pending'
                        ? 'bg-yellow-100 text-yellow-800'
                        : 'bg-red-100 text-red-800'
                    }`}>
                      {payment.status === 'succeeded' || payment.status === 'completed' || payment.status === 'complete'
                        ? 'Completato' 
                        : payment.status === 'pending'
                        ? 'In attesa'
                        : 'Fallito'}
                    </span>
                  </div>
                </div>
              </div>
            ))}

            {/* Paginazione */}
            {totalPages > 1 && (
              <div className="flex justify-center items-center gap-1 pt-6">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handlePageChange(currentPage - 1);
                  }}
                  type="button"
                  disabled={currentPage === 1}
                  className="p-1.5 text-gray-500 hover:text-gray-700 disabled:opacity-50 hover:bg-gray-100 rounded-md"
                  aria-label="Pagina precedente"
                >
                  <ChevronLeft className="h-4 w-4" />
                </button>
                
                {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
                  <button
                    key={pageNumber}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handlePageChange(pageNumber);
                    }}
                    type="button"
                    className={`w-8 h-8 rounded-md ${
                      currentPage === pageNumber
                        ? 'bg-blue-600 text-white'
                        : 'text-gray-700 hover:bg-gray-100'
                    }`}
                    aria-label={`Pagina ${pageNumber}`}
                    aria-current={currentPage === pageNumber ? 'page' : undefined}
                  >
                    {pageNumber}
                  </button>
                ))}
                
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handlePageChange(currentPage + 1);
                  }}
                  type="button"
                  disabled={currentPage === totalPages}
                  className="p-1.5 text-gray-500 hover:text-gray-700 disabled:opacity-50 hover:bg-gray-100 rounded-md"
                  aria-label="Pagina successiva"
                >
                  <ChevronRight className="h-4 w-4" />
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
} 
import React, { useState, useEffect, useMemo } from 'react';
import { Book, Search, SortAsc, ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom';
import { formatDate } from '../utils/dates';
import { toast } from 'react-hot-toast';
import { getBlogPosts } from '../lib/blogPosts';
import { BlogCard } from '../components/blog/BlogCard';

interface BlogPost {
  id: string;
  title: string;
  excerpt: string;
  coverImage: string;
  publishedAt: string;
  category: string;
  readTime: number;
  url: string;
}

export function Blog() {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [sortBy, setSortBy] = useState<'date' | 'title' | 'readTime'>('date');
  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    loadPosts();
  }, []);

  useEffect(() => {
    // Estrai categorie uniche dai post
    const uniqueCategories = [...new Set(posts.map(post => post.category))];
    setCategories(uniqueCategories);
  }, [posts]);

  const loadPosts = async () => {
    try {
      console.log('Blog - Inizio caricamento posts');
      const data = await getBlogPosts();
      console.log('Blog - Posts caricati:', data);
      setPosts(data);
    } catch (error) {
      console.error('Blog - Errore caricamento posts:', error);
      toast.error('Errore nel caricamento degli articoli');
    } finally {
      setLoading(false);
    }
  };

  const filteredAndSortedPosts = useMemo(() => {
    let result = [...posts];

    // Filtra per categoria
    if (selectedCategory) {
      result = result.filter(post => post.category === selectedCategory);
    }

    // Filtra per termine di ricerca
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      result = result.filter(post => 
        post.title.toLowerCase().includes(searchLower) ||
        post.excerpt.toLowerCase().includes(searchLower) ||
        post.content.toLowerCase().includes(searchLower)
      );
    }

    // Ordina i risultati
    result.sort((a, b) => {
      switch (sortBy) {
        case 'title':
          return a.title.localeCompare(b.title);
        case 'readTime':
          return a.readTime - b.readTime;
        case 'date':
        default:
          return new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime();
      }
    });

    return result;
  }, [posts, selectedCategory, searchTerm, sortBy]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 pt-24 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center mb-12">
          <Book className="h-12 w-12 text-blue-600 mx-auto mb-4" />
          <h1 className="text-3xl font-bold text-gray-900">Blog Unistanza</h1>
          <p className="mt-4 text-lg text-gray-600">
            Guide, consigli e novità sul mondo degli affitti universitari
          </p>
        </div>

        {/* Barra di ricerca e controlli */}
        <div className="mb-8">
          <div className="flex flex-col md:flex-row gap-4 bg-white p-4 rounded-xl shadow-sm">
            {/* Barra di ricerca */}
            <div className="flex-1 relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                placeholder="Cerca articoli..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="block w-full pl-10 pr-4 py-2.5 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-gray-50 text-gray-900 placeholder-gray-500"
              />
            </div>

            {/* Dropdown ordinamento */}
            <div className="relative min-w-[200px]">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SortAsc className="h-5 w-5 text-gray-400" />
              </div>
              <select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value as 'date' | 'title' | 'readTime')}
                className="block w-full pl-10 pr-10 py-2.5 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-gray-50 text-gray-900 appearance-none cursor-pointer"
              >
                <option value="date">Più recenti</option>
                <option value="title">Titolo A-Z</option>
                <option value="readTime">Tempo di lettura</option>
              </select>
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ChevronDown className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>

          {/* Filtri per categoria */}
          <div className="mt-4 flex flex-wrap gap-2">
            <button
              onClick={() => setSelectedCategory('')}
              className={`px-4 py-2 rounded-lg text-sm font-medium transition-all ${
                selectedCategory === '' 
                  ? 'bg-blue-600 text-white shadow-sm' 
                  : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-200'
              }`}
            >
              Tutti gli articoli
            </button>
            {categories.map(category => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-all ${
                  selectedCategory === category 
                    ? 'bg-blue-600 text-white shadow-sm' 
                    : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-200'
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        {/* Griglia dei post */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredAndSortedPosts.map((post) => (
            <BlogCard key={post.id} post={post} />
          ))}
        </div>

        {/* Messaggio se non ci sono risultati */}
        {filteredAndSortedPosts.length === 0 && (
          <div className="text-center py-12">
            <p className="text-gray-500">Nessun articolo trovato</p>
          </div>
        )}
      </div>
    </div>
  );
} 
import React from 'react';
import { Info, Clock } from 'lucide-react';
import { Link } from 'react-router-dom';

interface AvailabilityMessageProps {
  type: 'landlord-not-set' | 'student-waiting' | 'student-cannot-book';
}

export function AvailabilityMessage({ type }: AvailabilityMessageProps) {
  switch (type) {
    case 'landlord-not-set':
      return (
        <div className="p-4 bg-yellow-50 border border-yellow-100 rounded-lg mt-4">
          <div className="flex items-center gap-3">
            <Info className="h-5 w-5 text-yellow-600 flex-shrink-0" />
            <div>
              <p className="text-sm font-medium text-yellow-900">
                Imposta le tue disponibilità
              </p>
              <p className="text-sm text-yellow-700">
                Per permettere allo studente di prenotare una videochiamata, devi prima impostare le tue disponibilità nella sezione{' '}
                <Link 
                  to="/dashboard/landlord/settings?tab=availability" 
                  className="text-yellow-800 underline hover:text-yellow-900"
                >
                  Disponibilità
                </Link>
              </p>
            </div>
          </div>
        </div>
      );

    case 'student-waiting':
      return (
        <div className="p-4 bg-gray-50 border border-gray-100 rounded-lg mt-4">
          <div className="flex items-center gap-3">
            <Clock className="h-5 w-5 text-gray-600 flex-shrink-0" />
            <p className="text-sm text-gray-700">
              Lo studente non ha ancora prenotato una videochiamata
            </p>
          </div>
        </div>
      );

    case 'student-cannot-book':
      return (
        <div className="p-4 bg-yellow-50 border border-yellow-100 rounded-lg mt-4">
          <div className="flex items-center gap-3">
            <Info className="h-5 w-5 text-yellow-600 flex-shrink-0" />
            <p className="text-sm text-yellow-700">
              Il proprietario non ha ancora impostato le sue disponibilità per le videochiamate
            </p>
          </div>
        </div>
      );

    default:
      return null;
  }
}

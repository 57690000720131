import React, { useState, useEffect } from 'react';
import { X, Loader2, Ticket, Euro, Calendar, Users, ChevronDown } from 'lucide-react';
import { doc, setDoc, updateDoc, collection, serverTimestamp, Timestamp } from 'firebase/firestore';
import { db } from '@/lib/firebase';
import { toast } from 'react-toastify';
import { DiscountCode } from '@/types/discount';

interface DiscountCodeModalProps {
  discount?: DiscountCode | null;
  onClose: () => void;
  onSuccess: () => void;
}

export function DiscountCodeModal({ discount, onClose, onSuccess }: DiscountCodeModalProps) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<Partial<DiscountCode>>({
    code: '',
    type: 'percentage',
    value: 0,
    maxUses: undefined,
    usedCount: 0,
    validFrom: new Date(),
    validTo: undefined,
    minAmount: undefined,
    maxAmount: undefined,
    userType: 'all',
    specificUsers: [],
    applicableTo: ['credits', 'featured'],
    active: true
  });

  useEffect(() => {
    if (discount) {
      const validFrom = discount.validFrom instanceof Timestamp 
        ? discount.validFrom.toDate() 
        : new Date(discount.validFrom);

      const validTo = discount.validTo 
        ? (discount.validTo instanceof Timestamp 
          ? discount.validTo.toDate() 
          : new Date(discount.validTo))
        : undefined;

      setFormData({
        ...discount,
        validFrom,
        validTo
      });
    } else {
      setFormData(prev => ({
        ...prev,
        validFrom: new Date()
      }));
    }
  }, [discount]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const cleanedData = Object.entries(formData).reduce((acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = value;
        }
        return acc;
      }, {} as Record<string, any>);

      const discountData = {
        ...cleanedData,
        updatedAt: serverTimestamp(),
        createdAt: discount ? discount.createdAt : serverTimestamp()
      };

      if (discount?.id) {
        await updateDoc(doc(db, 'discountCodes', discount.id), discountData);
        toast.success('Codice sconto aggiornato con successo');
      } else {
        const newDocRef = doc(collection(db, 'discountCodes'));
        await setDoc(newDocRef, {
          ...discountData,
          id: newDocRef.id,
          usedCount: 0
        });
        toast.success('Codice sconto creato con successo');
      }

      onSuccess();
    } catch (error) {
      console.error('Errore durante il salvataggio:', error);
      toast.error('Errore durante il salvataggio del codice sconto');
    } finally {
      setLoading(false);
    }
  };

  // Componente wrapper per i campi select
  const SelectField = ({ label, name, value, onChange, options }: {
    label: string;
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    options: { value: string; label: string; }[];
  }) => (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
      <div className="relative">
        <select
          name={name}
          value={value}
          onChange={onChange}
          className="h-10 w-full px-4 pr-12 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 appearance-none"
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
          <ChevronDown className="h-5 w-5 text-gray-400" />
        </div>
      </div>
    </div>
  );

  const formatDateForInput = (date: Date | null | undefined): string => {
    if (!date) return '';
    try {
      const dateObj = date instanceof Date ? date : new Date(date);
      if (isNaN(dateObj.getTime())) return '';
      return dateObj.toISOString().slice(0, 16);
    } catch (error) {
      console.error('Errore nella formattazione della data:', error);
      return '';
    }
  };

  return (
    <div style={{ margin: 0 }} className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold">
            {discount ? 'Modifica Codice Sconto' : 'Nuovo Codice Sconto'}
          </h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="p-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Codice Card */}
            <div className="bg-white rounded-2xl p-6 shadow-xl border border-gray-100">
              <div className="flex items-center gap-3 mb-6">
                <div className="p-2 bg-purple-100 rounded-lg">
                  <Ticket className="h-5 w-5 text-purple-600" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900">Dettagli Codice</h3>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Codice
                  </label>
                  <input
                    type="text"
                    value={formData.code}
                    onChange={(e) => setFormData(prev => ({ ...prev, code: e.target.value.toUpperCase() }))}
                    className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    required
                  />
                </div>

                <SelectField
                  label="Tipo di Sconto"
                  name="type"
                  value={formData.type}
                  onChange={(e) => setFormData(prev => ({ ...prev, type: e.target.value as 'percentage' | 'fixed' }))}
                  options={[
                    { value: 'percentage', label: 'Percentuale' },
                    { value: 'fixed', label: 'Importo Fisso' }
                  ]}
                />
              </div>
            </div>

            {/* Valore Card */}
            <div className="bg-white rounded-2xl p-6 shadow-xl border border-gray-100">
              <div className="flex items-center gap-3 mb-6">
                <div className="p-2 bg-green-100 rounded-lg">
                  <Euro className="h-5 w-5 text-green-600" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900">Valore e Limiti</h3>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    {formData.type === 'percentage' ? 'Percentuale Sconto' : 'Importo Sconto (€)'}
                  </label>
                  <div className="relative">
                    <input
                      type="number"
                      value={formData.value}
                      onChange={(e) => setFormData(prev => ({ ...prev, value: parseFloat(e.target.value) }))}
                      className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      required
                      min="0"
                      max={formData.type === 'percentage' ? "100" : undefined}
                      step="0.01"
                    />
                    {formData.type === 'percentage' && (
                      <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500">%</span>
                    )}
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Importo Minimo (€)
                  </label>
                  <input
                    type="number"
                    value={formData.minAmount || ''}
                    onChange={(e) => setFormData(prev => ({ ...prev, minAmount: e.target.value ? parseFloat(e.target.value) : undefined }))}
                    className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    min="0"
                    step="0.01"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Utilizzi Massimi
                  </label>
                  <input
                    type="number"
                    value={formData.maxUses || ''}
                    onChange={(e) => setFormData(prev => ({ ...prev, maxUses: e.target.value ? parseInt(e.target.value) : undefined }))}
                    className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    min="0"
                  />
                </div>
              </div>
            </div>

            {/* Validità Card */}
            <div className="bg-white rounded-2xl p-6 shadow-xl border border-gray-100">
              <div className="flex items-center gap-3 mb-6">
                <div className="p-2 bg-orange-100 rounded-lg">
                  <Calendar className="h-5 w-5 text-orange-600" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900">Validità</h3>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Valido dal</label>
                  <input
                    type="datetime-local"
                    value={formatDateForInput(formData.validFrom)}
                    onChange={(e) => {
                      const date = e.target.value ? new Date(e.target.value) : new Date();
                      setFormData(prev => ({ ...prev, validFrom: date }));
                    }}
                    className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Valido fino al</label>
                  <input
                    type="datetime-local"
                    value={formatDateForInput(formData.validTo)}
                    onChange={(e) => {
                      const date = e.target.value ? new Date(e.target.value) : undefined;
                      setFormData(prev => ({ ...prev, validTo: date }));
                    }}
                    className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
            </div>

            {/* Restrizioni Card */}
            <div className="bg-white rounded-2xl p-6 shadow-xl border border-gray-100">
              <div className="flex items-center gap-3 mb-6">
                <div className="p-2 bg-blue-100 rounded-lg">
                  <Users className="h-5 w-5 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900">Restrizioni</h3>
              </div>

              <div className="grid grid-cols-1 gap-6">
                <SelectField
                  label="Tipo Utente"
                  name="userType"
                  value={formData.userType ?? 'all'}
                  onChange={(e) => setFormData(prev => ({ 
                    ...prev, 
                    userType: e.target.value || 'all'
                  }))}
                  options={[
                    { value: 'all', label: 'Tutti gli utenti' },
                    { value: 'student', label: 'Solo Studenti' },
                    { value: 'landlord', label: 'Solo Proprietari' }
                  ]}
                />

                <div>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.active}
                      onChange={(e) => setFormData(prev => ({ ...prev, active: e.target.checked }))}
                      className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                    <span className="ml-2 text-sm text-gray-700">Codice attivo</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Annulla
              </button>
              <button
                type="submit"
                disabled={loading}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50 flex items-center gap-2"
              >
                {loading ? (
                  <>
                    <Loader2 className="h-4 w-4 animate-spin" />
                    Salvataggio...
                  </>
                ) : (
                  'Salva'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
} 
import React from 'react';
import { MapPin, Users, Star } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Listing } from '../types/listing';
import { FavoriteButton } from '../components/FavoriteButton';
import { CheckCircle2 } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { useApplications } from '../hooks/useApplications';

interface ListingCardProps {
  listing: Listing;
  isSelected?: boolean;
}

export function ListingCard({ listing, isSelected = false }: ListingCardProps) {
  const { user } = useAuth();
  const { applications } = useApplications();

  const hasApplied = user && applications.some(app => app.listingId === listing.id);
  const isFeatured = listing.featured?.active && new Date(listing.featured.expiresAt) > new Date();
  const isStudent = user?.role === 'student';
  
  const imageUrl = listing.images?.[0] || 'https://images.unsplash.com/photo-1522708323590-d24dbb6b0267?auto=format&fit=crop&q=80';
  const availableSpots = listing.availableSpots || 0;
  const spotsText = availableSpots === 1 ? 'posto disponibile' : 'posti disponibili';

  return (
    <Link 
      to={`/listing/${listing.id}`}
      className={`block bg-white rounded-lg transition-all duration-200 relative overflow-hidden ${
        isFeatured
          ? `border-2 border-yellow-400 ${isSelected ? 'shadow-lg' : 'hover:shadow-md'}`
          : `border ${isSelected ? 'border-2 border-blue-500' : 'border-gray-200'} hover:shadow-md`
      }`}
    >
      <div className="relative">
        <img
          src={imageUrl}
          alt={listing.title}
          className="w-full h-36 object-cover rounded-t-lg transition-transform duration-300 hover:scale-[1.02]"
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.src = "https://images.unsplash.com/photo-1522708323590-d24dbb6b0267?auto=format&fit=crop&q=80";
          }}
        />
        <div className="absolute top-2 left-2 flex flex-wrap items-start gap-2 max-w-[calc(100%-80px)]">
          {isFeatured && (
            <span className="px-2 py-1 text-xs font-semibold rounded-full bg-yellow-100 text-yellow-800 whitespace-nowrap flex items-center gap-1">
              <Star className="h-3 w-3" fill="currentColor" />
              In Evidenza
            </span>
          )}
          <span className={`px-2 py-1 text-xs font-medium rounded-full backdrop-blur-sm bg-opacity-90 flex items-center ${
            availableSpots > 0 
              ? 'bg-green-100 text-green-800' 
              : 'bg-red-100 text-red-800'
          }`}>
            <Users className="h-3 w-3 mr-1" />
            {availableSpots} {spotsText}
          </span>
        </div>
        {isStudent && (
          <div className="absolute bottom-2 right-2">
            <FavoriteButton listingId={listing.id} />
          </div>
        )}
      </div>

      <div className="p-4">
        <h3 className="listing-title font-semibold text-gray-900 mb-1.5">
          {listing.title}
        </h3>
        <div className="flex items-center text-gray-500 text-sm mb-3">
          <MapPin className="h-4 w-4 mr-1.5 flex-shrink-0" />
          <span className="truncate">{listing.location.formattedAddress}</span>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1">
            <span className="text-lg font-bold text-blue-600">
              €{listing.price}
            </span>
            <span className="text-sm font-normal text-gray-500">/mese</span>
          </div>
        </div>
        {hasApplied && (
          <div className="mt-2 flex items-center gap-1.5 text-green-600 text-sm font-medium">
            <CheckCircle2 className="h-4 w-4" />
            <span>Candidatura inviata</span>
          </div>
        )}
      </div>
    </Link>
  );
}
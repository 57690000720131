import { Combobox } from '@headlessui/react';
import usePlacesAutocomplete, { getGeocode, getDetails } from 'use-places-autocomplete';
import { toast } from 'react-toastify';

interface ProfilePlacesAutocompleteProps {
  onPlaceSelect: (place: google.maps.places.PlaceResult) => void;
  defaultValue?: string;
  placeholder?: string;
  className?: string;
  required?: boolean;
  countryCode: string;
}

export function ProfilePlacesAutocomplete({ 
  onPlaceSelect, 
  defaultValue = '', 
  placeholder = '', 
  className = '',
  required = false,
  countryCode = ''
}: ProfilePlacesAutocompleteProps) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: { 
      componentRestrictions: countryCode ? { country: countryCode.toLowerCase() } : undefined,
      types: ['address'],
      language: 'it',
      region: 'it'
    },
    debounce: 300,
    defaultValue,
    cache: false
  });

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address: string) => {
    setValue(address, false);
    clearSuggestions();

    try {
      if (!countryCode) {
        toast.error('Seleziona prima uno stato');
        setValue('');
        return;
      }

      const geocodeRequest = {
        address,
        componentRestrictions: { 
          country: countryCode.toLowerCase()
        },
        language: 'it'
      };

      const results = await getGeocode(geocodeRequest);
      
      const addressCountry = results[0].address_components?.find(
        component => component.types.includes('country')
      )?.short_name;

      if (!addressCountry || addressCountry.toLowerCase() !== countryCode.toLowerCase()) {
        toast.error('Seleziona un indirizzo nel paese selezionato');
        setValue('');
        return;
      }

      if (!results[0].place_id) {
        toast.error('Errore nel recupero dei dettagli dell\'indirizzo');
        return;
      }

      const place = await getDetails({
        placeId: results[0].place_id,
        fields: ['address_components', 'formatted_address', 'geometry', 'place_id', 'name'],
        language: 'it'
      });

      if (!place) {
        toast.error('Errore nel recupero dei dettagli dell\'indirizzo');
        return;
      }

      onPlaceSelect(place);
    } catch (error) {
      console.error('Errore:', error);
      toast.error('Si è verificato un errore nella selezione dell\'indirizzo');
      setValue('');
    }
  };

  return (
    <div className="relative">
      <input
        value={value}
        onChange={handleInput}
        disabled={!ready || !countryCode}
        placeholder={!countryCode ? 'Seleziona prima uno stato' : placeholder}
        className={className}
        required={required}
        autoComplete="off"
      />
      
      {status === "OK" && data.length > 0 && (
        <ul className="absolute z-50 w-full bg-white border border-gray-100 max-h-60 rounded-md mt-1 overflow-auto py-1">
          {data.map(({ place_id, description }) => (
            <li
              key={place_id}
              onClick={() => handleSelect(description)}
              className="px-3 py-2 hover:bg-gray-100 cursor-pointer text-sm"
            >
              {description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
} 
export const fixUnsplashUrl = (url: string): string => {
  try {
    console.log('fixUnsplashUrl - Input URL:', url);
    
    if (!url) {
      console.warn('fixUnsplashUrl - URL vuoto o non definito');
      return '';
    }

    // Se l'URL è già nel formato corretto, lo restituiamo così com'è
    if (url.startsWith('https://images.unsplash.com/') || 
        url.startsWith('https://firebasestorage.googleapis.com/')) {
      console.log('fixUnsplashUrl - URL già valido, nessuna modifica necessaria');
      return url;
    }

    console.log('fixUnsplashUrl - URL risultante:', url);
    return url;
  } catch (error) {
    console.error('fixUnsplashUrl - Errore:', error);
    return url;
  }
}; 
import { ref, uploadBytes, getDownloadURL, deleteObject, listAll } from 'firebase/storage';
import { storage } from './firebase';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { auth } from './firebase';
import { getAuth } from 'firebase/auth';
import { verifyAdmin } from './admin';

export interface UploadOptions {
  folder: string;
  maxFiles?: number;
  maxFileSize?: number;
  allowedTypes?: string[];
  onProgress?: (progress: number) => void;
  silent?: boolean;
}

const DEFAULT_OPTIONS: UploadOptions = {
  folder: 'uploads',
  maxFiles: 10,
  maxFileSize: 10 * 1024 * 1024, // 10MB
  allowedTypes: ['image/jpeg', 'image/png', 'image/webp', 'application/pdf']
};

export function validateFile(file: File, options: Partial<UploadOptions> = {}): boolean {
  const { maxFileSize = DEFAULT_OPTIONS.maxFileSize, allowedTypes = DEFAULT_OPTIONS.allowedTypes, silent = false } = options;

  if (file.size > maxFileSize!) {
    if (!silent) {
      toast.error(`Il file ${file.name} supera i 10MB`);
    }
    return false;
  }

  if (!allowedTypes!.includes(file.type)) {
    if (!silent) {
      toast.error(`Il file ${file.name} non è in un formato valido`);
    }
    return false;
  }

  return true;
}

export async function uploadFile(file: File, options: UploadOptions): Promise<string> {
  const auth = getAuth();
  const user = auth.currentUser;
  
  if (!user) {
    throw new Error('Utente non autenticato');
  }

  console.log('Upload file:', {
    fileName: file.name,
    fileType: file.type,
    fileSize: file.size,
    folder: options.folder,
    userId: user.uid
  });

  if (options.folder === 'blog') {
    const isAdmin = await verifyAdmin();
    console.log('Admin check result:', isAdmin);
    if (!isAdmin) {
      throw new Error('Non hai i permessi per caricare immagini nel blog');
    }
  }

  if (!validateFile(file, options)) {
    throw new Error('File non valido');
  }

  try {
    const fileExtension = file.name.split('.').pop()?.toLowerCase() || 'bin';
    const fileName = `${options.folder}/${uuidv4()}.${fileExtension}`;
    console.log('Attempting upload to:', fileName);
    
    const fileRef = ref(storage, fileName);
    const snapshot = await uploadBytes(fileRef, file);
    const url = await getDownloadURL(snapshot.ref);

    console.log('Upload successful:', url);

    if (options.onProgress && !options.silent) {
      options.onProgress(100);
    }

    return url;
  } catch (error: any) {
    console.error('Upload error details:', {
      code: error.code,
      message: error.message,
      serverResponse: error.serverResponse
    });
    
    if (error.code === 'storage/unauthorized') {
      throw new Error('Non hai i permessi necessari per caricare questo file');
    }
    throw new Error('Errore durante il caricamento del file');
  }
}

export async function uploadFiles(files: File[], options: UploadOptions): Promise<string[]> {
  const { maxFiles = DEFAULT_OPTIONS.maxFiles } = options;

  if (files.length > maxFiles!) {
    throw new Error(`Puoi caricare al massimo ${maxFiles} file`);
  }

  const uploadedUrls: string[] = [];
  let progress = 0;

  try {
    for (const file of files) {
      const url = await uploadFile(file, options);
      uploadedUrls.push(url);

      progress = Math.round((uploadedUrls.length / files.length) * 100);
      if (options.onProgress && !options.silent) {
        options.onProgress(progress);
      }
    }

    return uploadedUrls;
  } catch (error) {
    // Clean up any uploaded files on error
    await Promise.all(uploadedUrls.map(url => deleteFile(url)));
    throw error;
  }
}

export async function uploadListingImages(files: File[], listingId: string): Promise<string[]> {
  return uploadFiles(files, {
    folder: `listings/${listingId}/images`,
    maxFiles: 10,
    allowedTypes: ['image/jpeg', 'image/png', 'image/webp'],
    maxFileSize: 10 * 1024 * 1024
  });
}

export async function uploadFloorPlans(files: File[], listingId: string): Promise<string[]> {
  return uploadFiles(files, {
    folder: `listings/${listingId}/floorplans`,
    maxFiles: 5,
    allowedTypes: ['image/jpeg', 'image/png', 'image/webp', 'application/pdf'],
    maxFileSize: 10 * 1024 * 1024
  });
}

export async function uploadProductImage(file: File): Promise<string> {
  const url = await uploadFile(file, {
    folder: 'products',
    maxFiles: 1,
    allowedTypes: ['image/jpeg', 'image/png', 'image/webp'],
    maxFileSize: 10 * 1024 * 1024
  });
  return url;
}

export async function deleteFile(url: string): Promise<void> {
  if (!url.startsWith('gs://') && !url.includes('firebase')) {
    return;
  }

  try {
    const fileRef = ref(storage, url);
    await deleteObject(fileRef);
  } catch (error: any) {
    console.error('Error deleting file:', error);
    throw new Error(`Error deleting file: ${error.message}`);
  }
}

export async function cleanupTempFiles(userId: string): Promise<void> {
  try {
    const tempFolderRef = ref(storage, `temp/${userId}`);
    const tempFiles = await listAll(tempFolderRef);
    
    await Promise.all(
      tempFiles.items.map(fileRef => deleteObject(fileRef))
    );
  } catch (error) {
    console.error('Error cleaning up temp files:', error);
  }
}
import { Link } from 'react-router-dom';
import { BlogPost } from '../../types';
import { formatDate } from '../../utils/dates';
import { useState, useEffect } from 'react';
import { fixUnsplashUrl } from '../../utils/images';

interface BlogCardProps {
  post: BlogPost;
}

export function BlogCard({ post }: BlogCardProps) {
  const [imageError, setImageError] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    console.log('BlogCard - URL immagine originale:', post.coverImage);
    const fixedUrl = fixUnsplashUrl(post.coverImage);
    console.log('BlogCard - URL immagine dopo fix:', fixedUrl);
    setImageUrl(fixedUrl);
  }, [post.coverImage]);

  return (
    <Link
      to={`/blog/${post.url}`}
      className="group bg-white rounded-xl shadow-sm overflow-hidden hover:shadow-md transition-shadow"
    >
      <div className="relative h-48 bg-gray-100">
        {!imageError && imageUrl ? (
          <img
            src={imageUrl}
            alt={post.title}
            className="w-full h-full object-cover"
            onError={(e) => {
              console.error('BlogCard - Errore caricamento immagine:', e);
              setImageError(true);
            }}
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center text-gray-400">
            {console.log('BlogCard - Mostrando placeholder per:', post.title)}
            <svg className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
          </div>
        )}
      </div>
      <div className="p-6">
        <div className="flex items-center gap-4 mb-4">
          <span className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm font-medium">
            {post.category}
          </span>
          <span className="text-sm text-gray-500">
            {post.readTime} min lettura
          </span>
        </div>
        <h3 className="text-xl font-bold text-gray-900 mb-2 group-hover:text-blue-600 transition-colors">
          {post.title}
        </h3>
        <p className="text-gray-600 mb-4 line-clamp-2">
          {post.excerpt}
        </p>
        <time className="text-sm text-gray-500">
          {formatDate(post.publishedAt)}
        </time>
      </div>
    </Link>
  );
} 
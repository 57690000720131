import { CheckCircle2, AlertCircle } from 'lucide-react';

interface ProfileCompletionProps {
  formData: any;
}

export function ProfileCompletion({ formData }: ProfileCompletionProps) {
  const requiredFields = [
    { name: 'firstName', label: 'Nome' },
    { name: 'lastName', label: 'Cognome' },
    { name: 'email', label: 'Email' },
    { name: 'phoneNumber', label: 'Telefono' },
    { name: 'billingAddress.country', label: 'Stato' },
    { name: 'billingAddress.formattedAddress', label: 'Indirizzo' },
    { name: 'billingAddress.zipCode', label: 'CAP' },
    { name: 'billingAddress.city', label: 'Città' },
    { name: 'billingAddress.province', label: 'Provincia' }
  ];

  // Aggiungi campi fiscali in base al tipo
  if (formData.fiscalType === 'cf') {
    requiredFields.push({ name: 'fiscalCode', label: 'Codice Fiscale' });
  } else {
    requiredFields.push(
      { name: 'businessName', label: 'Ragione Sociale' },
      { name: 'vatNumber', label: 'Partita IVA' },
      { name: 'sdiCode', label: 'Codice SDI' }
    );
  }

  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const completedFields = requiredFields.filter(field => {
    const value = getNestedValue(formData, field.name);
    return value && value.trim() !== '';
  });

  const completionPercentage = Math.round((completedFields.length / requiredFields.length) * 100);

  return (
    <div className="bg-white rounded-2xl p-6 border border-gray-100">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-semibold text-gray-900">
          Completamento Profilo
        </h3>
        <span className="text-lg font-bold text-blue-600">
          {completionPercentage}%
        </span>
      </div>

      {/* Barra di progressione */}
      <div className="h-2 bg-gray-200 rounded-full">
        <div 
          className="h-full bg-blue-600 rounded-full transition-all duration-500"
          style={{ width: `${completionPercentage}%` }}
        />
      </div>
    </div>
  );
} 
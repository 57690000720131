import React from 'react';
import { Upload, Image, FileText } from 'lucide-react';
import { ImageUpload } from '../../shared/ImageUpload';
import { FloorPlanUpload } from '../FloorPlanUpload';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';

interface MediaStepProps {
  formData: any;
  onImagesChange: (files: (string | File)[]) => void;
  onFloorPlansChange: (files: (string | File)[]) => void;
  listingId?: string;
  isEditing?: boolean;
}

export function MediaStep({ formData, onImagesChange, onFloorPlansChange, listingId, isEditing }: MediaStepProps) {
  const handleImageDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(formData.images || []);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    onImagesChange(items);
  };

  const handleFloorPlanDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(formData.floorPlans || []);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    onFloorPlansChange(items);
  };

  return (
    <div className="space-y-8">
      {/* Images Section */}
      <div className="bg-gradient-to-br from-blue-500 to-blue-600 rounded-2xl p-4 md:p-8 text-white shadow-xl">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-white/10 rounded-lg backdrop-blur-sm">
            <Image className="h-5 w-5 text-white" />
          </div>
          <div>
            <h3 className="text-xl font-semibold text-white/90">Foto dell'alloggio</h3>
            <p className="text-sm text-white/70 mt-1">Carica foto di qualità per attirare più candidati</p>
          </div>
        </div>

        <div className="bg-white/10 rounded-xl p-4 md:p-6 backdrop-blur-sm border border-white/20">
          <ImageUpload
            images={formData.images || []}
            onChange={onImagesChange}
            maxImages={10}
            title="Carica foto"
            subtitle="PNG, JPG fino a 10MB"
            required={!formData.images?.length}
            variant="dark"
            listingId={listingId}
            enableDragAndDrop={true}
            onDragEnd={handleImageDragEnd}
          />
        </div>
      </div>

      {/* Floor Plans Section */}
      <div className="bg-white rounded-2xl p-4 md:p-8 shadow-xl border border-gray-100">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-orange-100 rounded-lg">
            <FileText className="h-5 w-5 text-orange-600" />
          </div>
          <div>
            <h3 className="text-xl font-semibold text-gray-900">Planimetrie</h3>
            <p className="text-sm text-gray-500 mt-1">Carica le planimetrie dell'appartamento</p>
          </div>
        </div>

        {/* Floor Plans Upload */}
        <FloorPlanUpload
          listingId={listingId || ''}
          floorPlans={formData.floorPlans || []}
          onChange={onFloorPlansChange}
          maxFiles={5}
        />
      </div>
    </div>
  );
}
import React, { useState, useEffect } from 'react';
import { Hero } from '../components/Hero';
import { getFeaturedListings, getRecentListings } from '../lib/listings';
import { Listing } from '../types/listing';
import { ListingCard } from '../components/ListingCard';
import { Building2, Star, ArrowRight, BookText } from 'lucide-react';
import { ValueProposition } from '../components/ValueProposition';
import { Link } from 'react-router-dom';
import { getRecentBlogPosts } from '../lib/blogPosts';
import { formatDate } from '../utils/dates';
import { BlogCard } from '../components/blog/BlogCard';

export function Home() {
  const [featuredListings, setFeaturedListings] = useState<Listing[]>([]);
  const [recentListings, setRecentListings] = useState<Listing[]>([]);
  const [recentPosts, setRecentPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      loadListings();
      loadRecentPosts();
    }, 1000);

    return () => clearTimeout(timer);
  }, [retryCount]);

  const loadListings = async () => {
    try {
      setLoading(true);
      setError(null);

      const featured = await getFeaturedListings(4).catch(() => []);
      setFeaturedListings(featured.filter(l => l.availableSpots > 0));

      const recent = await getRecentListings(4).catch(() => []);
      setRecentListings(recent);

      if (featured.length === 0 && recent.length === 0) {
        setError('Nessun annuncio disponibile al momento');
      }
    } catch (error) {
      console.error('Errore nel caricamento degli annunci:', error);
      setError('Nessun annuncio disponibile al momento');
    } finally {
      setLoading(false);
    }
  };

  const loadRecentPosts = async () => {
    try {
      console.log('Home - Caricamento post recenti...');
      const posts = await getRecentBlogPosts(3);
      console.log('Home - Post recenti caricati:', posts);
      setRecentPosts(posts);
    } catch (error) {
      console.error('Home - Errore nel caricamento degli articoli:', error);
    }
  };

  const handleRetry = () => {
    setRetryCount(prev => prev + 1);
  };

  const filteredRecentListings = recentListings.filter(listing => 
    listing.availableSpots > 0 && listing.status === 'active'
  );

  return (
    <div className="min-h-screen">
      <Hero />
      
      {/* Featured Listings Section */}
      {(loading || featuredListings.length > 0) && (
        <div className="mt-16 sm:mt-20 pt-12 sm:pt-20 pb-20 bg-gradient-to-br from-yellow-50 to-orange-50 border-y border-yellow-100">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between mb-8">
              <div className="flex items-center gap-3">
                <Star className="h-8 w-8 text-yellow-500" fill="currentColor" />
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Annunci in Evidenza
                </h2>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {loading ? (
                <div className="col-span-4 text-center py-12">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
                  <p className="mt-4 text-gray-500">Caricamento annunci...</p>
                </div>
              ) : featuredListings.map((listing) => (
                <ListingCard key={listing.id} listing={listing} />
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Recent Listings Section */}
      <div className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between mb-8">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
              Ultimi Annunci
            </h2>
          </div>

          {loading ? (
            <div className="text-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
              <p className="mt-4 text-gray-500">Caricamento annunci...</p>
            </div>
          ) : error ? (
            <div className="text-center py-12">
              <Building2 className="h-12 w-12 text-gray-400 mx-auto mb-4" />
              <p className="text-gray-900 mb-4">{error}</p>
              <button
                onClick={handleRetry}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                Riprova
              </button>
            </div>
          ) : filteredRecentListings.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {filteredRecentListings.map((listing) => (
                <ListingCard key={listing.id} listing={listing} />
              ))}
            </div>
          ) : (
            <div className="text-center py-12">
              <Building2 className="h-12 w-12 text-gray-400 mx-auto" />
              <h3 className="mt-2 text-lg font-medium text-gray-900">
                Nessun annuncio disponibile
              </h3>
              <p className="mt-1 text-gray-500">
                Al momento non ci sono annunci da mostrare
              </p>
            </div>
          )}
        </div>
      </div>

      <ValueProposition />

      {/* Recent Blog Posts Section */}
      <div className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between mb-8">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
              Dal nostro Blog
            </h2>
            <Link
              to="/blog"
              className="text-blue-600 hover:text-blue-700 font-medium flex items-center gap-2"
            >
              Vedi tutti
              <ArrowRight className="h-4 w-4" />
            </Link>
          </div>

          {loading ? (
            <div className="text-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
              <p className="mt-4 text-gray-500">Caricamento articoli...</p>
            </div>
          ) : recentPosts.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {recentPosts.map((post) => (
                <BlogCard key={post.id} post={post} />
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { 
  getFirestore, 
  initializeFirestore,
  CACHE_SIZE_UNLIMITED,
  serverTimestamp
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Configurazione telemetria browser-safe
const initTelemetry = () => {
  if (typeof window !== 'undefined' && window.firebase) {
    window.firebase.analytics?.();
    console.log('Firebase browser analytics enabled');
  }
};

// Inizializza la telemetria solo in produzione
if (import.meta.env.PROD) {
  initTelemetry();
}

const firebaseConfig = {
  apiKey: "AIzaSyCcC2pTvOe9PiF3IOf6XL0YXmrkDBd_hbI",
  authDomain: "app-unistanza-49b40.firebaseapp.com",
  databaseURL: "https://app-unistanza-49b40-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "app-unistanza-49b40",
  storageBucket: "app-unistanza-49b40.firebasestorage.app",
  messagingSenderId: "1052571827181",
  appId: "1:1052571827181:web:a94f897cca6add413d05e4"
};

const app = initializeApp(firebaseConfig);
const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
  cache: {
    persistenceEnabled: true,
    tabSynchronization: true
  }
});

// Initialize other services
const auth = getAuth(app);
const storage = getStorage(app);

// Configure storage settings
storage.maxOperationRetryTime = 120000; // 2 minutes
storage.maxUploadRetryTime = 120000; // 2 minutes

// Initialize collections
export const collections = {
  users: 'users',
  listings: 'listings',
  applications: 'applications',
  notifications: 'notifications',
  products: 'products',
  analytics: 'analytics',
  credits: 'credits',
  featured: 'featured',
  messages: 'messages',
  chats: 'chats'
};

export const googleProvider = new GoogleAuthProvider();

// Configurazione aggiuntiva per il provider Google
googleProvider.setCustomParameters({
  prompt: 'select_account'
});

// Imposta la persistenza locale
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log('Persistenza autenticazione configurata');
  })
  .catch((error) => {
    console.error('Errore configurazione persistenza:', error);
  });

// Aggiungi listener per lo stato dell'autenticazione
auth.onAuthStateChanged((user) => {
  if (user) {
    console.log('Utente autenticato:', user.uid);
  } else {
    console.log('Utente non autenticato');
  }
});

export { auth, db, storage, serverTimestamp };
import React, { useState } from 'react';
import { X, Calendar, MapPin, GraduationCap, Globe, User, ChevronDown } from 'lucide-react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { updateUserProfile } from '../../../lib/auth';
import { toast } from 'react-toastify';

interface ApplicationDataModalProps {
  onClose: () => void;
  onSuccess: () => void;
  message?: string;
}

export function ApplicationDataModal({ onClose, onSuccess, message }: ApplicationDataModalProps) {
  const { userProfile } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    birthDate: userProfile?.studentInfo?.birthDate || '',
    residenceCity: userProfile?.studentInfo?.residenceCity || '',
    residenceCountry: userProfile?.studentInfo?.residenceCountry || '',
    studyProgram: userProfile?.studentInfo?.studyProgram || '',
    university: userProfile?.studentInfo?.university || '',
    studyYear: userProfile?.studentInfo?.studyYear || '',
    moveInDate: userProfile?.studentInfo?.moveInDate || '',
    stayDuration: userProfile?.studentInfo?.stayDuration || '',
    gender: userProfile?.studentInfo?.gender || '',
    nationality: userProfile?.studentInfo?.nationality || ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const validateBirthDate = (date: string): boolean => {
    const birthDate = new Date(date);
    const today = new Date();
    
    if (isNaN(birthDate.getTime())) {
      return false;
    }

    if (birthDate > today) {
      return false;
    }

    const minAge = 18;
    const maxAge = 100;
    const age = Math.floor((today.getTime() - birthDate.getTime()) / (365.25 * 24 * 60 * 60 * 1000));
    
    return age >= minAge && age <= maxAge;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!userProfile?.id) {
      toast.error('Sessione scaduta. Effettua nuovamente il login.');
      return;
    }

    if (!validateBirthDate(formData.birthDate)) {
      toast.error('Data di nascita non valida. Devi avere almeno 18 anni.');
      return;
    }

    if (!formData.gender) {
      toast.error('Il campo Sesso è obbligatorio');
      return;
    }

    setLoading(true);
    try {
      const updatedProfile: Partial<UserProfile> = {
        studentInfo: {
          ...userProfile.studentInfo,
          birthDate: formData.birthDate,
          residenceCity: formData.residenceCity,
          residenceCountry: formData.residenceCountry,
          studyProgram: formData.studyProgram,
          university: formData.university,
          studyYear: formData.studyYear,
          moveInDate: formData.moveInDate,
          stayDuration: formData.stayDuration,
          gender: formData.gender as 'M' | 'F' | 'O',
          nationality: formData.nationality,
          credits: userProfile.studentInfo?.credits || 0
        }
      };

      await updateUserProfile(userProfile.id, updatedProfile);
      
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error: any) {
      console.error('Error updating profile:', error);
      toast.error(error.message || 'Errore durante il salvataggio delle informazioni');
    } finally {
      setLoading(false);
    }
  };

  const maxBirthDate = new Date();
  maxBirthDate.setFullYear(maxBirthDate.getFullYear() - 18);
  const maxBirthDateString = maxBirthDate.toISOString().split('T')[0];

  return (
    <div 
      style={{ margin: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
    >
      <div className="bg-white rounded-lg max-w-2xl w-full">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold">Informazioni per la Candidatura</h2>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-6 w-6" />
            </button>
          </div>

          {message && (
            <div className="mb-6 p-4 bg-blue-50 border border-blue-200 rounded-lg">
              <p className="text-sm text-blue-700">{message}</p>
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  <div className="flex items-center gap-2">
                    <User className="h-4 w-4 text-gray-400" />
                    Sesso
                  </div>
                </label>
                <div className="relative">
                  <select
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    className="h-10 w-full px-4 pr-12 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 appearance-none"
                    required
                  >
                    <option value="">Seleziona sesso</option>
                    <option value="M">Uomo</option>
                    <option value="F">Donna</option>
                    <option value="O">Altro</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                    <ChevronDown className="h-5 w-5 text-gray-400" />
                  </div>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  <div className="flex items-center gap-2">
                    <Calendar className="h-4 w-4 text-gray-400" />
                    Data di Nascita
                  </div>
                </label>
                <input
                  type="date"
                  name="birthDate"
                  value={formData.birthDate}
                  onChange={handleChange}
                  max={maxBirthDateString}
                  className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  <div className="flex items-center gap-2">
                    <MapPin className="h-4 w-4 text-gray-400" />
                    Città di Residenza
                  </div>
                </label>
                <input
                  type="text"
                  name="residenceCity"
                  value={formData.residenceCity}
                  onChange={handleChange}
                  className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  <div className="flex items-center gap-2">
                    <Globe className="h-4 w-4 text-gray-400" />
                    Nazione di Residenza
                  </div>
                </label>
                <input
                  type="text"
                  name="residenceCountry"
                  value={formData.residenceCountry}
                  onChange={handleChange}
                  className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  <div className="flex items-center gap-2">
                    <GraduationCap className="h-4 w-4 text-gray-400" />
                    Percorso di Studi
                  </div>
                </label>
                <input
                  type="text"
                  name="studyProgram"
                  value={formData.studyProgram}
                  onChange={handleChange}
                  placeholder="es. Ingegneria Informatica"
                  className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  <div className="flex items-center gap-2">
                    <GraduationCap className="h-4 w-4 text-gray-400" />
                    Università
                  </div>
                </label>
                <input
                  type="text"
                  name="university"
                  value={formData.university}
                  onChange={handleChange}
                  placeholder="es. Politecnico di Milano"
                  className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Anno di Frequentazione
                </label>
                <div className="relative">
                  <select
                    name="studyYear"
                    value={formData.studyYear}
                    onChange={handleChange}
                    className="h-10 w-full px-4 pr-12 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 appearance-none"
                    required
                  >
                    <option value="">Seleziona anno</option>
                    <option value="1">1° anno</option>
                    <option value="2">2° anno</option>
                    <option value="3">3° anno</option>
                    <option value="4">4° anno</option>
                    <option value="5">5° anno</option>
                    <option value="phd">Dottorato</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                    <ChevronDown className="h-5 w-5 text-gray-400" />
                  </div>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Data di Check-in
                </label>
                <input
                  type="date"
                  name="moveInDate"
                  value={formData.moveInDate}
                  onChange={handleChange}
                  min={new Date().toISOString().split('T')[0]}
                  className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Durata del Soggiorno
                </label>
                <div className="relative">
                  <select
                    name="stayDuration"
                    value={formData.stayDuration}
                    onChange={handleChange}
                    className="h-10 w-full px-4 pr-12 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 appearance-none"
                    required
                  >
                    <option value="">Seleziona durata</option>
                    <option value="6">6 mesi</option>
                    <option value="12">12 mesi</option>
                    <option value="18">18 mesi</option>
                    <option value="24">24 mesi</option>
                    <option value="36">Più di 24 mesi</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                    <ChevronDown className="h-5 w-5 text-gray-400" />
                  </div>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  <div className="flex items-center gap-2">
                    <Globe className="h-4 w-4 text-gray-400" />
                    Cittadinanza
                  </div>
                </label>
                <input
                  type="text"
                  name="nationality"
                  value={formData.nationality}
                  onChange={handleChange}
                  className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>
            </div>

            <div className="flex justify-end gap-4 pt-6">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
              >
                Annulla
              </button>
              <button
                type="submit"
                disabled={loading}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 disabled:opacity-50 transition-colors"
              >
                {loading ? 'Salvataggio...' : 'Salva'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
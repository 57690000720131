import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LogIn, Mail, Lock, CheckCircle2 } from 'lucide-react';
import { signInWithGoogle, signInWithEmail } from '../../lib/auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      const result = await signInWithGoogle('student');
      
      if (result.needsProfileCompletion) {
        toast.error('Account non trovato. Per favore registrati prima.');
        navigate('/register');
        return;
      }

      if (result.userProfile) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        toast.success('Accesso effettuato con successo!');
        navigate(`/dashboard/${result.userProfile.userType}`, { replace: true });
      }
    } catch (error: any) {
      console.error('Google sign-in error:', error);
      if (error.code === 'auth/popup-blocked') {
        toast.error('Per favore, abilita i popup per questo sito e riprova.');
      } else {
        toast.error('Errore durante l\'accesso. Riprova più tardi.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      setLoading(true);
      const { user, userProfile } = await signInWithEmail(email, password);
      
      if (!user || !userProfile) {
        throw new Error('Errore durante il login');
      }

      toast.success('Login effettuato con successo!');
      navigate(`/dashboard/${userProfile.userType}`, { replace: true });
    } catch (error: any) {
      toast.error(error.message || 'Errore durante il login');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="pt-16 bg-gray-50 min-h-screen">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8 md:py-12">
        <div className="flex">
          {/* Sezione sinistra - Hero/Features */}
          <div className="hidden lg:flex lg:w-1/2 bg-gradient-to-br from-blue-600 to-indigo-700 rounded-2xl p-4 md:p-8">
            <div className="flex flex-col justify-center w-full max-w-lg mx-auto">
              <h1 className="text-4xl font-bold text-white mb-6">
                Bentornato su UniStanza
              </h1>
              <p className="text-xl text-blue-100 mb-12">
                Accedi al tuo account per gestire i tuoi annunci e le tue prenotazioni
              </p>

              <div className="space-y-8">
                <div className="flex items-start gap-4">
                  <div className="p-3 bg-white/10 rounded-xl">
                    <CheckCircle2 className="h-6 w-6 text-blue-200" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-white mb-1">
                      Gestione semplificata
                    </h3>
                    <p className="text-blue-100">
                      Gestisci i tuoi annunci, prenotazioni e messaggi in un unico posto
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="p-3 bg-white/10 rounded-xl">
                    <CheckCircle2 className="h-6 w-6 text-blue-200" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-white mb-1">
                      Comunicazione diretta
                    </h3>
                    <p className="text-blue-100">
                      Resta in contatto con studenti e proprietari attraverso la chat integrata
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="p-3 bg-white/10 rounded-xl">
                    <CheckCircle2 className="h-6 w-6 text-blue-200" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-white mb-1">
                      Notifiche in tempo reale
                    </h3>
                    <p className="text-blue-100">
                      Ricevi aggiornamenti immediati su nuovi messaggi e prenotazioni
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Sezione destra - Form */}
          <div className="w-full lg:w-1/2 lg:pl-8">
            <div className="bg-white rounded-2xl p-4 md:p-8 shadow-xl border border-gray-100 h-full flex items-center">
              <form onSubmit={handleSubmit} className="space-y-6 w-full max-w-lg mx-auto">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Email
                  </label>
                  <div className="relative">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      autoComplete="email"
                      className="h-10 w-full px-4 pr-10 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="La tua email"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                      <Mail className="h-5 w-5 text-gray-400" />
                    </div>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Password
                  </label>
                  <div className="relative">
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      autoComplete="current-password"
                      className="h-10 w-full px-4 pr-10 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="La tua password"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                      <Lock className="h-5 w-5 text-gray-400" />
                    </div>
                  </div>
                </div>

                <div className="pt-6">
                  <button
                    type="submit"
                    disabled={loading}
                    className="w-full h-10 flex justify-center items-center px-6 rounded-lg text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 transition-colors"
                  >
                    {loading ? 'Accesso in corso...' : 'Accedi'}
                  </button>
                </div>

                <div className="relative my-8">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-4 bg-white text-gray-500">
                      Oppure continua con
                    </span>
                  </div>
                </div>

                <button
                  type="button"
                  onClick={handleGoogleSignIn}
                  disabled={loading}
                  className="w-full h-10 flex items-center justify-center px-6 rounded-lg text-base font-medium text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 transition-colors"
                >
                  <img
                    className="h-5 w-5 mr-2"
                    src="https://www.svgrepo.com/show/475656/google-color.svg"
                    alt="Google logo"
                  />
                  Continua con Google
                </button>

                <div className="text-center text-sm text-gray-600">
                  Non hai un account?{' '}
                  <Link to="/register" className="font-medium text-blue-600 hover:text-blue-500">
                    Registrati
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
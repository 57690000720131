import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { X, CreditCard, FileText, AlertTriangle, ArrowRight, Loader2, Check } from 'lucide-react';
import { PayPalButtons } from "@paypal/react-paypal-js";
import { Elements } from '@stripe/react-stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../contexts/AuthContext';
import { PAYMENT_CONFIG } from '../../config/api';
import { createStripeCheckoutSession } from '@/lib/payments';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { StripePaymentForm } from './StripePaymentForm';
import { doc, getDoc, updateDoc, increment, collection, setDoc, serverTimestamp, onSnapshot, arrayUnion, query, getDocs, where, limit } from 'firebase/firestore';
import { db } from '@/lib/firebase';
import { updateListingFeatured } from '@/lib/listings';
import { createPayment } from '../../lib/payments';
import { Switch } from '@/components/ui/switch';
import { useNavigate } from 'react-router-dom';
import { ProfileCompletion } from '../profile/ProfileCompletion';
import { validateDiscountCode } from '@/lib/discounts';
import { addCredits } from '@/lib/credits';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

interface PaymentModalProps {
  amount: number;
  title: string;
  description: string;
  metadata: Partial<PaymentMetadata>;
  onClose: () => void;
  onSuccess: (paymentId: string) => void;
  initialRequiresInvoice?: boolean;
  listingId?: string;
  planId?: string;
}

interface PaymentMetadata {
  userId?: string;
  type: 'credits' | 'featured';
  quantity?: number;
  packageId?: string;
  listingId?: string;
  planId?: string;
  listingTitle?: string;
  planName?: string;
  planDuration?: number;
  planDurationLabel?: string;
  packageName?: string;
}

export function PaymentModal({
  amount: initialAmount,
  title,
  description,
  metadata,
  onClose,
  onSuccess,
  initialRequiresInvoice,
  listingId,
  planId
}: PaymentModalProps) {
  const { userProfile } = useAuthContext();
  const [sessionId, setSessionId] = useState<string>('');
  const [isLoading, setIsLoading] = useState(!initialRequiresInvoice);
  const [requiresInvoice, setRequiresInvoice] = useState(initialRequiresInvoice ?? false);
  const [showProfileWarning, setShowProfileWarning] = useState(false);
  const navigate = useNavigate();
  const requiresInvoiceRef = useRef(requiresInvoice);
  const [discountCode, setDiscountCode] = useState('');
  const [discountLoading, setDiscountLoading] = useState(false);
  const [discountError, setDiscountError] = useState<string | null>(null);
  const [appliedDiscount, setAppliedDiscount] = useState<{
    type: 'percentage' | 'fixed';
    value: number;
  } | null>(null);
  const [currentAmount, setCurrentAmount] = useState<number>(initialAmount);

  // Modifica dell'useEffect esistente per il caricamento iniziale
  useEffect(() => {
    const loadInitialState = async () => {
      if (!userProfile?.id || initialRequiresInvoice !== undefined) return;
      
      try {
        const userRef = doc(db, 'users', userProfile.id);
        const userDoc = await getDoc(userRef);
        const value = userDoc.data()?.requiresInvoice ?? false;
        setRequiresInvoice(value);
        requiresInvoiceRef.current = value;
      } catch (error) {
        console.error('❌ Errore caricamento stato iniziale:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadInitialState();
  }, [userProfile?.id, initialRequiresInvoice]);

  // Aggiungi questo useEffect per loggare lo stato iniziale e i cambiamenti
  useEffect(() => {
    console.log('👀 Stato attuale requiresInvoice:', requiresInvoice);
  }, [requiresInvoice]);

  // Aggiungi questo useEffect per controllare sia lo stato locale che quello in Firebase
  useEffect(() => {
    const checkFirebaseValue = async () => {
      if (userProfile?.id) {
        const userRef = doc(db, 'users', userProfile.id);
        const userDoc = await getDoc(userRef);
        const firebaseValue = userDoc.data()?.requiresInvoice || false;
        console.log('🔍 Stato requiresInvoice:', {
          local: requiresInvoice,
          firebase: firebaseValue,
          userId: userProfile.id
        });
      }
    };
    
    checkFirebaseValue();
  }, [userProfile?.id, requiresInvoice]);

  // Aggiungi un listener in tempo reale per requiresInvoice
  useEffect(() => {
    if (!userProfile?.id) return;
    
    console.log('🎯 Inizializzazione listener requiresInvoice');
    
    const userRef = doc(db, 'users', userProfile.id);
    const unsubscribe = onSnapshot(userRef, (snapshot) => {
      const newValue = snapshot.data()?.requiresInvoice ?? false;
      console.log('🔄 Aggiornamento requiresInvoice da Firebase:', newValue);
      setRequiresInvoice(newValue);
      requiresInvoiceRef.current = newValue;
    }, (error) => {
      console.error('❌ Errore listener requiresInvoice:', error);
    });

    return () => {
      console.log('👋 Cleanup listener requiresInvoice');
      unsubscribe();
    };
  }, [userProfile?.id]);

  // Funzione per verificare se il profilo è completo
  const checkProfileCompletion = () => {
    if (!userProfile) return false;

    const requiredFields = [
      'firstName',
      'lastName',
      'email',
      'phoneNumber',
      'billingAddress'
    ];

    // Verifica campi base
    const hasBasicFields = requiredFields.every(field => {
      if (field === 'billingAddress') {
        const address = userProfile.billingAddress || {};
        return address.formattedAddress && 
               address.city && 
               address.province && 
               address.zipCode && 
               address.country;
      }
      return userProfile[field];
    });

    if (!hasBasicFields) return false;

    // Verifica campi fiscali
    if (userProfile.fiscalType === 'cf') {
      return !!userProfile.fiscalCode;
    } else {
      return !!(userProfile.vatNumber && 
                userProfile.businessName && 
                userProfile.sdiCode);
    }
  };

  // Gestisce il cambio dello switch per la fattura
  const handleInvoiceToggle = useCallback(async (checked: boolean) => {
    if (!userProfile?.id) {
      toast.error('Sessione scaduta. Effettua nuovamente il login.');
      return;
    }

    try {
      setIsLoading(true);
      console.log('🔄 Toggle fatturazione - Nuovo valore:', checked);
      
      // Aggiorna Firestore
      const userRef = doc(db, 'users', userProfile.id);
      await updateDoc(userRef, {
        requiresInvoice: checked,
        updatedAt: serverTimestamp()
      });

      // Attendi un breve momento per assicurarsi che il listener abbia ricevuto l'update
      await new Promise(resolve => setTimeout(resolve, 500));
      
      toast.success(checked ? 'Richiesta fattura attivata' : 'Richiesta fattura disattivata');
      
    } catch (error) {
      console.error('❌ Errore aggiornamento preferenza fattura:', error);
      toast.error('Errore nel salvataggio della preferenza fattura');
    } finally {
      setIsLoading(false);
    }
  }, [userProfile?.id]);

  const handleRequiresInvoiceToggle = async (newValue: boolean) => {
    try {
      if (!userProfile?.id) return;
      
      console.log('🔄 Toggle fatturazione - Nuovo valore:', newValue);
      
      // Aggiorna solo Firebase - lo stato locale verrà aggiornato dal listener
      await updateDoc(doc(db, 'users', userProfile.id), {
        requiresInvoice: newValue
      });
      
    } catch (error) {
      console.error('❌ Errore durante il toggle:', error);
      toast.error('Si è verificato un errore. Riprova.');
    }
  };

  const completeMetadata = useMemo(() => ({
    type: metadata?.type || 'unknown',
    userId: userProfile?.id,
    userType: userProfile?.userType,
    quantity: metadata?.quantity,
    requiresInvoice,
    ...metadata
  }), [metadata, userProfile, requiresInvoice]);

  const handleStripeClick = async () => {
    if (!userProfile?.id) {
      toast.error('Devi essere autenticato per effettuare il pagamento');
      return;
    }

    if (requiresInvoice && !checkProfileCompletion()) {
      toast.error('Completa il profilo per richiedere la fattura');
      return;
    }

    try {
      setIsLoading(true);
      
      if (!metadata) {
        throw new Error('Dati del pagamento non validi');
      }

      // Prepara i metadati puliti
      const cleanMetadata = {
        type: metadata.type,
        userId: userProfile.id,
        ...(metadata.type === 'credits' && {
          quantity: metadata.quantity,
          packageId: metadata.packageId,
          packageName: metadata.packageName || 'Pacchetto Crediti'
        }),
        ...(metadata.type === 'featured' && {
          listingId: metadata.listingId,
          planId: metadata.planId,
          listingTitle: metadata.listingTitle || 'N/D',
          planName: metadata.planName || 'N/D',
          planDuration: metadata.planDuration || 0,
          planDurationLabel: metadata.planDurationLabel || `${metadata.planDuration} ore`
        }),
        ...(appliedDiscount && discountCode && {
          discountCode,
          discountType: appliedDiscount.type,
          discountValue: appliedDiscount.value,
          originalAmount: initialAmount
        })
      };

      let lineItems = {
        name: title || 'Pagamento',
        description: description || ''
      };

      // Logica specifica per tipo di acquisto
      if (cleanMetadata.type === 'featured') {
        lineItems = {
          name: 'Piano in Evidenza',
          description: `${description} - Durata: ${metadata?.durationLabel || '24 ore'}`
        };
      } else if (cleanMetadata.type === 'credits') {
        lineItems = {
          name: 'Pacchetto Crediti',
          description: `${description} crediti${userProfile.userType === 'student' ? ' per candidarti agli annunci' : ' per pubblicare annunci'}`
        };
      }

      const sessionData = {
        amount: currentAmount,
        metadata: {
          ...metadata,
          discountCode: appliedDiscount ? discountCode : null,
          discountType: appliedDiscount?.type || null,
          discountValue: appliedDiscount?.value?.toString() || null,
          originalAmount: appliedDiscount ? initialAmount.toString() : null,
          quantity: metadata.quantity
        },
        lineItems,
        requiresInvoice,
        invoiceData: requiresInvoice ? {
          firstName: userProfile.firstName || '',
          lastName: userProfile.lastName || '',
          email: userProfile.email || '',
          fiscalCode: userProfile.fiscalCode || '',
          vatNumber: userProfile.vatNumber || '',
          businessName: userProfile.businessName || '',
          sdiCode: userProfile.sdiCode || '',
          address: userProfile.billingAddress?.formattedAddress || '',
          city: userProfile.billingAddress?.city || '',
          province: userProfile.billingAddress?.province || '',
          postalCode: userProfile.billingAddress?.zipCode || '',
          country: userProfile.billingAddress?.country || ''
        } : undefined
      };

      console.log('📦 Creazione sessione Stripe:', {
        currentAmount,
        hasDiscount: !!appliedDiscount,
        discountCode,
        metadata: sessionData.metadata
      });

      const sessionId = await createStripeCheckoutSession(sessionData);
      
      if (!sessionId) {
        throw new Error('Impossibile ottenere la sessione');
      }

      const stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);
      if (!stripe) {
        throw new Error('Stripe non inizializzato');
      }
      
      await stripe.redirectToCheckout({
        sessionId: sessionId
      });
      
    } catch (error: any) {
      console.error('Errore inizializzazione Stripe:', error);
      let errorMessage = 'Errore durante l\'inizializzazione del pagamento';
      
      if (error.message.includes('Dati del pagamento non validi')) {
        errorMessage = 'Configurazione del pagamento non valida';
      } else if (error.message.includes('Dati di pagamento incompleti')) {
        errorMessage = 'Dati di pagamento incompleti';
      }
      
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const createPaymentWithInvoiceData = async (basePaymentData: any) => {
    console.log('📝 createPaymentWithInvoiceData - requiresInvoice:', requiresInvoice);
    console.log('📝 basePaymentData:', basePaymentData);
    
    if (requiresInvoice && userProfile) {
      // Aggiungiamo i dati di fatturazione dal profilo utente
      return {
        ...basePaymentData,
        requiresInvoice: true,
        invoiceData: {
          firstName: userProfile.firstName || '',
          lastName: userProfile.lastName || '',
          email: userProfile.email || '',
          fiscalCode: userProfile.fiscalCode || '',
          vatNumber: userProfile.vatNumber || '',
          businessName: userProfile.businessName || '',
          sdiCode: userProfile.sdiCode || '',
          address: userProfile.billingAddress?.formattedAddress || '',
          city: userProfile.billingAddress?.city || '',
          province: userProfile.billingAddress?.province || '',
          postalCode: userProfile.billingAddress?.zipCode || '',
          country: userProfile.billingAddress?.country || ''
        },
        metadata: {
          ...basePaymentData.metadata,
          requiresInvoice: true
        }
      };
    }
    return {
      ...basePaymentData,
      requiresInvoice: false,
      metadata: {
        ...basePaymentData.metadata,
        requiresInvoice: false
      }
    };
  };

  const paypalCreateOrder = useCallback((data, actions) => {
    // Formatta il numero con esattamente 2 decimali
    const formattedAmount = Number(currentAmount).toFixed(2);
    
    console.log('🔄 PayPal createOrder:', {
      currentAmount,
      formattedAmount,
      hasDiscount: !!appliedDiscount,
      discountDetails: appliedDiscount
    });
    
    return actions.order.create({
      purchase_units: [{
        amount: {
          currency_code: 'EUR',
          value: formattedAmount
        }
      }]
    });
  }, [currentAmount, appliedDiscount]);

  const prepareMetadata = (metadata: any) => {
    return {
      type: metadata.type,
      userId: userProfile.id,
      ...(metadata.type === 'credits' && {
        quantity: metadata.quantity,
        packageId: metadata.packageId
      }),
      ...(metadata.type === 'featured' && {
        listingId: metadata.listingId,
        planId: metadata.planId
      }),
      ...(appliedDiscount && discountCode && {
        discountCode,
        discountType: appliedDiscount.type,
        discountValue: appliedDiscount.value,
        originalAmount: initialAmount
      })
    };
  };

  const handlePayPalPayment = async (details: any) => {
    try {
      // Leggi lo stato attuale da Firestore
      const userDoc = await getDoc(doc(db, 'users', userProfile.id));
      const currentRequiresInvoice = userDoc.data()?.requiresInvoice ?? false;

      // Recupera i dati aggiuntivi per featured
      if (metadata.type === 'featured') {
        // Recupera i dati dell'annuncio e del piano direttamente per ID
        const [listingDoc, planDoc] = await Promise.all([
          getDoc(doc(db, 'listings', metadata.listingId)),
          getDoc(doc(db, 'featuredPlans', metadata.planId))
        ]);

        console.log('📄 Dati piano:', planDoc.data());

        if (listingDoc.exists() && planDoc.exists()) {
          const listing = listingDoc.data();
          const plan = planDoc.data();
          
          metadata.listingTitle = listing.title || 'N/D';
          metadata.planName = plan.name || 'N/D';
          metadata.planDuration = plan.duration || 0;
          metadata.planDurationLabel = plan.durationLabel || `${plan.duration} ore`;
        }
      }

      console.log('🔍 Metadata finale:', metadata);

      // Prepara i metadati puliti
      const cleanMetadata = {
        ...metadata,
        type: metadata.type,
        userId: userProfile.id,
        ...(metadata.type === 'credits' && {
          quantity: metadata.quantity,
          packageId: metadata.packageId,
          packageName: metadata.packageName || 'Pacchetto Crediti'
        }),
        ...(metadata.type === 'featured' && {
          listingId: metadata.listingId,
          listingTitle: metadata.listingTitle,
          planId: metadata.planId,
          planName: metadata.planName,
          planDuration: metadata.planDuration,
          planDurationLabel: metadata.planDurationLabel
        }),
        ...(appliedDiscount && discountCode && {
          discountCode,
          discountType: appliedDiscount.type,
          discountValue: appliedDiscount.value,
          originalAmount: initialAmount
        })
      };

      // Il resto rimane invariato
      const paymentData = {
        amount: currentAmount,
        userId: userProfile.id,
        status: 'succeeded',
        sessionId: details.id,
        paymentMethod: 'paypal',
        requiresInvoice: currentRequiresInvoice,
        metadata: cleanMetadata,
        invoiceData: currentRequiresInvoice ? {
          firstName: userProfile.firstName || '',
          lastName: userProfile.lastName || '',
          email: userProfile.email || '',
          fiscalCode: userProfile.fiscalCode || '',
          vatNumber: userProfile.vatNumber || '',
          businessName: userProfile.businessName || '',
          sdiCode: userProfile.sdiCode || '',
          address: userProfile.billingAddress?.formattedAddress || '',
          city: userProfile.billingAddress?.city || '',
          province: userProfile.billingAddress?.province || '',
          postalCode: userProfile.billingAddress?.zipCode || '',
          country: userProfile.billingAddress?.country || ''
        } : undefined
      };

      // Gestisci lo sconto se presente
      if (appliedDiscount && discountCode) {
        console.log('🎯 Gestione sconto PayPal:', {
          type: metadata.type,
          discountCode,
          appliedDiscount
        });

        const discountRef = collection(db, 'discountCodes');
        const q = query(discountRef, where('code', '==', discountCode.toUpperCase()));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          const discountDoc = querySnapshot.docs[0];
          await updateDoc(doc(db, 'discountCodes', discountDoc.id), {
            usedCount: increment(1),
            usedBy: arrayUnion(userProfile.id),
            lastUsedAt: serverTimestamp(),
            lastUsedBy: userProfile.id,
            updatedAt: serverTimestamp()
          });
          console.log('✅ Aggiornato utilizzo codice sconto:', {
            code: discountCode,
            type: metadata.type,
            docId: discountDoc.id
          });
        }
      }

      // Crea il pagamento
      console.log('💳 Creazione pagamento PayPal:', {
        type: metadata.type,
        amount: currentAmount,
        hasDiscount: !!appliedDiscount
      });

      const paymentId = await createPayment(paymentData);

      // Log di conferma
      console.log('✅ Pagamento completato:', {
        paymentId,
        type: metadata.type,
        amount: currentAmount
      });

      // Gestione sconto PayPal se presente
      if (appliedDiscount && discountCode) {
        await updateDiscountUsage(discountCode, userProfile.id);
      }

      // Se il pagamento è per i crediti, accreditali all'utente
      if (metadata.type === 'credits' && metadata.quantity) {
        const creditsAmount = parseInt(metadata.quantity);
        await addCredits(
          userProfile.id,
          creditsAmount,
          `Acquisto ${creditsAmount} crediti`
        );
        
        console.log('✅ Crediti accreditati:', {
          userId: userProfile.id,
          amount: creditsAmount
        });
      }

      onSuccess(paymentId);
      return paymentId;
    } catch (error) {
      console.error('❌ Errore durante il pagamento PayPal:', error);
      throw error;
    }
  };

  const handleDiscountValidation = async () => {
    setDiscountLoading(true);
    setDiscountError(null);

    try {
      if (!userProfile?.id) {
        setDiscountError('Sessione scaduta. Effettua nuovamente il login.');
        return;
      }

      const result = await validateDiscountCode(
        discountCode,
        initialAmount,
        userProfile.id,
        userProfile.type || 'student',
        metadata?.type || 'credits'
      );

      if (result.isValid && result.discount) {
        // Calcola il nuovo amount con lo sconto
        const discountedAmount = result.discount.type === 'percentage' 
          ? Number((initialAmount * (1 - result.discount.value / 100)).toFixed(2))
          : Number(Math.max(0, initialAmount - result.discount.value).toFixed(2));
        
        console.log('💰 Calcolo sconto:', {
          originalAmount: initialAmount,
          discountType: result.discount.type,
          discountValue: result.discount.value,
          finalAmount: discountedAmount
        });

        setCurrentAmount(discountedAmount);
        setAppliedDiscount(result.discount);
        setDiscountError(null);
        toast.success('Codice sconto applicato con successo!');
      } else {
        setCurrentAmount(initialAmount);
        setAppliedDiscount(null);
        setDiscountError(result.error);
      }
    } catch (error) {
      console.error('Errore durante la validazione del codice:', error);
      setDiscountError(result.error);
      setAppliedDiscount(null);
      setCurrentAmount(initialAmount);
    } finally {
      setDiscountLoading(false);
    }
  };

  const finalAmount = currentAmount;

  // Aggiungi un effetto per loggare i cambiamenti del prezzo
  useEffect(() => {
    console.log('💵 Prezzo aggiornato:', {
      currentAmount,
      hasDiscount: !!appliedDiscount,
      discountDetails: appliedDiscount
    });
  }, [currentAmount, appliedDiscount]);

  const updateDiscountUsage = async (discountCode: string, userId: string) => {
    try {
      // Cerca il documento che ha il campo 'code' uguale a discountCode
      const discountsRef = collection(db, 'discountCodes');
      const q = query(discountsRef, where('code', '==', discountCode));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.error('❌ Codice sconto non trovato:', discountCode);
        throw new Error('Codice sconto non valido');
      }

      // Prendi il primo (e dovrebbe essere l'unico) documento
      const discountDoc = querySnapshot.docs[0];
      const discountData = discountDoc.data();
      const usedBy = discountData.usedBy || [];

      // Verifica se l'utente ha già usato questo codice
      if (usedBy.includes(userId)) {
        console.warn('⚠️ Utente ha già usato questo codice:', {userId, discountCode});
        throw new Error('Hai già utilizzato questo codice sconto');
      }

      // Aggiorna l'utilizzo dello sconto usando l'ID del documento
      await updateDoc(doc(db, 'discountCodes', discountDoc.id), {
        usedBy: arrayUnion(userId),
        lastUsed: serverTimestamp(),
        usedCount: increment(1)
      });

      console.log('✅ Utilizzo sconto registrato:', {
        code: discountCode,
        userId,
        timestamp: new Date().toISOString()
      });

    } catch (error) {
      console.error('❌ Errore durante l\'aggiornamento dell\'utilizzo dello sconto:', error);
      throw error;
    }
  };

  const validateDiscountCode = async (code: string) => {
    console.log('🔍 Inizio validazione codice:', {
      code,
      upperCode: code.toUpperCase(),
      timestamp: new Date().toISOString()
    });

    try {
      // Usiamo la collection corretta: 'discountCodes' invece di 'discounts'
      const discountRef = collection(db, 'discountCodes');
      
      const q = query(discountRef, where('code', '==', code.toUpperCase()));
      
      console.log('🟢 Query costruita:', {
        collection: 'discountCodes', // collection corretta
        field: 'code',
        value: code.toUpperCase()
      });

      const snapshot = await getDocs(q);
      
      console.log('🟢 Risultato query:', {
        empty: snapshot.empty,
        size: snapshot.size,
        docs: snapshot.docs.map(doc => ({
          id: doc.id,
          code: doc.data().code,
          active: doc.data().active
        }))
      });

      if (snapshot.empty) {
        console.error('❌ Codice sconto non trovato:', code);
        throw new Error('Codice sconto non trovato');
      }

      const discountDoc = snapshot.docs[0].data();
      
      console.log('🎯 Documento sconto trovato:', {
        id: snapshot.docs[0].id,
        ...discountDoc,
        validFrom: discountDoc.validFrom?.toDate(),
        createdAt: discountDoc.createdAt?.toDate(),
        updatedAt: discountDoc.updatedAt?.toDate()
      });

      // Verifica se il codice è attivo
      if (!discountDoc.active) {
        throw new Error('Codice sconto non attivo');
      }

      // Verifica il limite di utilizzo
      if (discountDoc.usedCount >= discountDoc.maxUses) {
        throw new Error('Codice sconto ha raggiunto il limite massimo di utilizzi');
      }

      // Verifica la data di validità
      const now = new Date();
      if (discountDoc.validFrom && now < discountDoc.validFrom.toDate()) {
        throw new Error('Codice sconto non ancora valido');
      }

      // Verifica il tipo di utente
      if (discountDoc.userType !== 'all' && discountDoc.specificUsers) {
        if (!discountDoc.specificUsers.includes(userProfile.id)) {
          throw new Error('Codice sconto non valido per questo utente');
        }
      }

      return discountDoc;
    } catch (error) {
      console.error('❌ Errore validazione codice sconto:', error);
      throw error;
    }
  };

  // Funzione per gestire l'applicazione del codice sconto
  const handleApplyDiscount = async () => {
    if (!discountCode || !userProfile?.id) return;
    
    try {
      setDiscountLoading(true);
      setDiscountError(null);
      
      console.log('🎯 Tentativo di applicazione sconto:', {
        discountCode,
        currentAmount,
        initialAmount
      });

      // Verifica se il codice è già stato utilizzato dall'utente
      const discountsRef = collection(db, 'discountCodes');
      const q = query(discountsRef, where('code', '==', discountCode.toUpperCase()));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        throw new Error('Codice sconto non valido');
      }

      const discountDoc = querySnapshot.docs[0].data();
      
      // Verifica se l'utente ha già utilizzato questo codice
      const usedBy = discountDoc.usedBy || [];
      if (usedBy.includes(userProfile.id)) {
        throw new Error('Hai già utilizzato questo codice sconto');
      }

      console.log('✅ Sconto validato:', discountDoc);

      // Calcola l'importo dello sconto
      let discountAmount = 0;
      if (discountDoc.type === 'percentage') {
        discountAmount = (currentAmount * discountDoc.value) / 100;
      } else {
        discountAmount = discountDoc.value;
      }

      // Applica l'importo minimo se specificato
      if (discountDoc.minAmount && currentAmount < discountDoc.minAmount) {
        throw new Error(`Importo minimo richiesto: ${discountDoc.minAmount}€`);
      }

      const newAmount = Math.max(currentAmount - discountAmount, 0);
      
      console.log('💰 Calcolo sconto:', {
        originalAmount: currentAmount,
        discountAmount,
        newAmount,
        discountType: discountDoc.type,
        discountValue: discountDoc.value
      });

      // Aggiorna lo stato con il nuovo importo e i dettagli dello sconto
      setCurrentAmount(newAmount);
      setAppliedDiscount({
        type: discountDoc.type,
        value: discountDoc.value
      });

      toast.success('Codice sconto applicato con successo!');
    } catch (error) {
      console.error('❌ Errore applicazione sconto:', error);
      setDiscountError(error instanceof Error ? error.message : 'Errore sconosciuto');
      setAppliedDiscount(null);
      setCurrentAmount(initialAmount);
      toast.error(error instanceof Error ? error.message : 'Errore nell\'applicazione del codice sconto');
    } finally {
      setDiscountLoading(false);
    }
  };

  // Funzione per incrementare il contatore degli utilizzi del codice sconto
  const incrementDiscountUsage = async (discountCode: string) => {
    try {
      const discountRef = doc(collection(db, 'discountCodes'), discountCode);
      await updateDoc(discountRef, {
        usedCount: increment(1),
        updatedAt: serverTimestamp()
      });
      console.log('✅ Incrementato contatore utilizzi per codice sconto:', discountCode);
    } catch (error) {
      console.error('❌ Errore incremento contatore codice sconto:', error);
      // Non blocchiamo il flusso di pagamento se questo fallisce
    }
  };

  useEffect(() => {
    if (!appliedDiscount) {
      setCurrentAmount(initialAmount);
    }
  }, [appliedDiscount, initialAmount]);

  return (
    <div style={{ margin: 0 }} className="fixed inset-0 bg-black bg-opacity-50 z-[100] flex items-center justify-center">
      <div className="bg-white rounded-lg w-full max-w-md max-h-[90vh] overflow-y-auto my-4">
        <div className="sticky top-0 bg-white border-b border-gray-200 px-6 py-4 flex justify-between items-center z-10">
          <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="h-6 w-6 text-gray-400" />
          </button>
        </div>

        <div className="p-6">
          {isLoading ? (
            <div className="flex items-center justify-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
            </div>
          ) : (
            <>
              <h3 className="text-lg text-gray-700 mb-2">{description}</h3>
              <p className="text-3xl font-bold text-blue-600 mb-6">€{currentAmount.toFixed(2)}</p>
              
              <div className="mb-6 space-y-3">
                <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                  <div className="flex items-center gap-2">
                    <FileText className="h-5 w-5 text-gray-600" />
                    <span className="text-sm font-medium text-gray-700">Richiedi fattura</span>
                  </div>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={requiresInvoice}
                      onChange={(e) => handleInvoiceToggle(e.target.checked)}
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                  </label>
                </div>

                {requiresInvoice && !checkProfileCompletion() && (
                  <div className="p-4 bg-yellow-50 border border-yellow-200 rounded-lg">
                    <div className="flex items-start gap-3">
                      <AlertTriangle className="h-5 w-5 text-yellow-600 mt-0.5 flex-shrink-0" />
                      <div className="flex-1">
                        <h4 className="text-sm font-medium text-yellow-800">
                          Completa il tuo profilo
                        </h4>
                        <p className="mt-1 text-sm text-yellow-700">
                          Per richiedere la fattura è necessario completare tutte le informazioni del profilo
                        </p>
                        <button
                          onClick={() => {
                            const userType = userProfile?.userType === 'student' ? 'student' : 'landlord';
                            navigate(`/dashboard/${userType}`, {
                              state: { activeTab: 'profile' }
                            });
                            onClose();
                          }}
                          className="mt-3 px-4 py-2 bg-yellow-100 hover:bg-yellow-200 text-yellow-800 rounded-md text-sm font-medium inline-flex items-center gap-2 transition-colors"
                        >
                          Vai al profilo
                          <ArrowRight className="h-4 w-4" />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {!(requiresInvoice && !checkProfileCompletion()) && (
                <div className="space-y-4 mb-6">
                  <div className="flex gap-3">
                    <div className="flex-1">
                      <input
                        type="text"
                        value={discountCode}
                        onChange={(e) => setDiscountCode(e.target.value.toUpperCase())}
                        placeholder="Inserisci codice sconto"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                        disabled={discountLoading || !!appliedDiscount}
                      />
                    </div>
                    <button
                      onClick={handleApplyDiscount}
                      disabled={discountLoading || !discountCode}
                      className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
                    >
                      {discountLoading ? (
                        <Loader2 className="h-4 w-4 animate-spin" />
                      ) : (
                        'Applica'
                      )}
                    </button>
                    {appliedDiscount && (
                      <button
                        onClick={() => {
                          setAppliedDiscount(null);
                          setDiscountCode('');
                          setDiscountError(null);
                          setCurrentAmount(initialAmount);
                        }}
                        className="px-3 py-2 text-gray-600 hover:text-red-600 rounded-md"
                      >
                        <X className="h-4 w-4" />
                      </button>
                    )}
                  </div>

                  {discountError && (
                    <p className="text-sm text-red-600">{discountError}</p>
                  )}

                  {appliedDiscount && (
                    <div className="bg-green-50 border border-green-200 rounded-md p-3">
                      <p className="text-sm text-green-800">
                        Sconto applicato: {appliedDiscount.type === 'percentage' ? 
                          `${appliedDiscount.value}%` : 
                          `€${appliedDiscount.value}`
                        }
                      </p>
                      <p className="text-sm font-medium text-green-800">
                        Totale finale: €{currentAmount.toFixed(2)}
                      </p>
                    </div>
                  )}
                </div>
              )}

              <div className="space-y-4">
                <button
                  onClick={handleStripeClick}
                  disabled={isLoading}
                  className="w-full flex items-center justify-center gap-2 p-4 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors disabled:opacity-50"
                >
                  <CreditCard className="h-5 w-5" />
                  <span>{isLoading ? 'Elaborazione...' : 'Paga con carta'}</span>
                </button>

                {!isLoading && (
                  <PayPalButtons
  style={{ layout: "horizontal" }}
  forceReRender={[currentAmount, appliedDiscount]}
  createOrder={paypalCreateOrder}
  onApprove={(data, actions) => {
    console.log('🟡 PAYPAL APPROVE - requiresInvoice:', requiresInvoice);

    return actions.order.capture().then(async (details) => {
      console.log('🟢 PAYPAL CAPTURE - requiresInvoice:', requiresInvoice);
      try {
        // 1) Recupero il valore di requiresInvoice direttamente dal profilo utente in Firestore
        const userDoc = await getDoc(doc(db, 'users', userProfile.id));
        const currentRequiresInvoice = userDoc.data()?.requiresInvoice ?? false;

        // 2) Se il pagamento è di tipo "featured", recupera i dati dell'annuncio e del piano
        if (metadata.type === 'featured') {
          // Recupera i dati dell'annuncio
          const listingDocRef = doc(db, 'listings', metadata.listingId);
          const listingSnap = await getDoc(listingDocRef);
          if (listingSnap.exists()) {
            const listingData = listingSnap.data();
            // Assegno il titolo dell'annuncio a metadata.listingTitle
            metadata.listingTitle = listingData.title || 'N/D';
          }

          // Recupera i dati del piano
          const plansRef = collection(db, 'featuredPlans');
          const planQuery = query(plansRef, where('id', '==', metadata.planId));
          const planSnapshot = await getDocs(planQuery);

          if (!planSnapshot.empty) {
            const planData = planSnapshot.docs[0].data();
            // Assegno i campi del piano a metadata
            metadata.planName = planData.name || 'N/D';
            metadata.planDuration = planData.duration || 0;
            metadata.planDurationLabel = planData.durationLabel || `${planData.duration} ore`;
          }
        }

        // 3) Prepara i metadati completi, unendo i campi caricati sopra
        const finalMetadata = prepareMetadata(metadata);

        // 4) Costruisci l'oggetto "paymentData" con tutti i dati necessari al documento payments
        const paymentData = {
          amount: currentAmount,
          userId: userProfile.id,
          status: 'succeeded',
          sessionId: details.id,           // ID derivato da PayPal
          paymentMethod: 'paypal',
          requiresInvoice: currentRequiresInvoice,
          metadata: finalMetadata,
          invoiceData: currentRequiresInvoice ? {
            firstName: userProfile.firstName || '',
            lastName: userProfile.lastName || '',
            email: userProfile.email || '',
            fiscalCode: userProfile.fiscalCode || '',
            vatNumber: userProfile.vatNumber || '',
            businessName: userProfile.businessName || '',
            sdiCode: userProfile.sdiCode || '',
            address: userProfile.billingAddress?.formattedAddress || '',
            city: userProfile.billingAddress?.city || '',
            province: userProfile.billingAddress?.province || '',
            postalCode: userProfile.billingAddress?.zipCode || '',
            country: userProfile.billingAddress?.country || ''
          } : undefined
        };

        // 5) Crea il documento del pagamento su Firestore
        const paymentId = await createPayment(paymentData);

        // 6) Se hai sconti applicati, aggiorna i dati del codice sconto
        if (appliedDiscount && discountCode) {
          console.log('🎯 Gestione sconto PayPal:', {
            type: metadata.type,
            discountCode,
            appliedDiscount
          });

          const discountRef = collection(db, 'discountCodes');
          const q = query(discountRef, where('code', '==', discountCode.toUpperCase()));
          const querySnapshot = await getDocs(q);
          
          if (!querySnapshot.empty) {
            const discountDoc = querySnapshot.docs[0];
            await updateDoc(doc(db, 'discountCodes', discountDoc.id), {
              usedCount: increment(1),
              usedBy: arrayUnion(userProfile.id),
              lastUsedAt: serverTimestamp(),
              lastUsedBy: userProfile.id,
              updatedAt: serverTimestamp()
            });
            console.log('✅ Aggiornato utilizzo codice sconto:', {
              code: discountCode,
              type: metadata.type,
              docId: discountDoc.id
            });
          }
        }

        // Se il pagamento è per i crediti, accreditali all'utente
        if (metadata.type === 'credits' && metadata.quantity) {
          const creditsAmount = parseInt(metadata.quantity);
          await addCredits(
            userProfile.id,
            creditsAmount,
            `Acquisto ${creditsAmount} crediti via PayPal`
          );
          
          console.log('✅ Crediti accreditati:', {
            userId: userProfile.id,
            amount: creditsAmount
          });
        }

        console.log('✅ Pagamento PayPal completato:', {
          paymentId,
          type: metadata.type,
          amount: currentAmount
        });

        // 7) Chiamo onSuccess (prop) per proseguire il flusso
        onSuccess(paymentId);

      } catch (error) {
        console.error('❌ Errore durante il pagamento PayPal:', error);
        throw error;
      }
    });
  }}
/>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export const createPayment = async (paymentData: PaymentData) => {
  try {
    // Validazione dei dati dello sconto
    if (paymentData.metadata.discountCode) {
      if (!paymentData.metadata.discountType || 
          !paymentData.metadata.discountValue) {
        throw new Error('Dati sconto incompleti');
      }
    }

    // Arricchimento metadati per pagamenti featured
    if (paymentData.metadata?.type === 'featured' && paymentData.metadata.listingId) {
      const [listingDoc, planDoc] = await Promise.all([
        getDoc(doc(db, 'listings', paymentData.metadata.listingId)),
        getDoc(doc(db, 'featuredPlans', paymentData.metadata.planId))
      ]);

      const listingData = listingDoc.data();
      const planData = planDoc.data();

      if (listingData) {
        paymentData.metadata.listingTitle = listingData.title;
      }
      
      if (planData) {
        paymentData.metadata = {
          ...paymentData.metadata,
          planName: planData.name,
          planDuration: planData.durationHours,
          planDurationLabel: planData.durationLabel,
          planDescription: planData.description,
          planPrice: planData.price,
          planFeatures: planData.features,
          planPopular: planData.popular
        };
      }
    }

    // Crea il documento del pagamento
    const paymentRef = doc(collection(db, 'payments'));
    await setDoc(paymentRef, {
      ...paymentData,
      metadata: {
        ...paymentData.metadata,
        type: paymentData.metadata.type
      },
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    return paymentRef.id;
  } catch (error) {
    console.error('Errore durante la creazione del pagamento:', error);
    throw error;
  }
};
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MessageSquare, ArrowLeft } from 'lucide-react';
import { useAuthContext } from '../../contexts/AuthContext';
import { onChatsChange } from '../../lib/chat';
import { Chat } from '../../types/chat';
import { ChatList } from '../../components/chat/ChatList';
import { ChatWindow } from '../../components/chat/ChatWindow';
import { ChatHeader } from '../../components/chat/ChatHeader';

export function ChatPage() {
  const { userProfile } = useAuthContext();
  const [chats, setChats] = useState<Chat[]>([]);
  const [loading, setLoading] = useState(true);
  const { chatId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userProfile?.id) return;

    // Sottoscrizione ai cambiamenti delle chat
    const unsubscribe = onChatsChange(userProfile.id, (updatedChats) => {
      // Rimuovi eventuali duplicati basandoti sull'ID della chat
      const uniqueChats = updatedChats.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, [] as Chat[]);
      
      setChats(uniqueChats);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [userProfile?.id]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 pt-16 md:pt-24 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  const selectedChat = chatId ? chats.find(chat => chat.id === chatId) : null;
  const otherPartyName = selectedChat
    ? userProfile?.id === selectedChat.studentId
      ? selectedChat.landlordName
      : selectedChat.studentName
    : '';

  return (
    <div className="min-h-screen bg-gray-50 pt-16 md:pt-24">
      <div className="h-[calc(100vh-4rem)] md:h-[calc(100vh-12rem)] md:max-w-7xl mx-auto md:px-4 md:py-8">
        <div className="h-full bg-white md:rounded-lg md:shadow-sm flex flex-col md:flex-row">
          {/* Lista chat - nascosta su mobile quando una chat è selezionata */}
          <div className={`${
            selectedChat ? 'hidden md:flex' : 'flex'
          } md:w-1/3 border-r flex-col h-full`}>
            <div className="p-4 border-b flex-shrink-0 h-[72px] flex items-center justify-between">
              <h2 className="text-lg font-semibold flex items-center gap-2">
                <MessageSquare className="h-5 w-5" />
                Chat
              </h2>
            </div>
            <div className="overflow-y-auto flex-1">
              <ChatList chats={chats} userId={userProfile?.id || ''} />
            </div>
          </div>

          {/* Finestra chat - visibile su mobile solo quando selezionata */}
          <div className={`${
            selectedChat ? 'flex' : 'hidden md:flex'
          } flex-1 flex flex-col h-full`}>
            {selectedChat ? (
              <>
                <ChatHeader 
                  chat={selectedChat} 
                  otherPartyName={otherPartyName}
                  onBack={() => navigate('/chat')} 
                  isMobile={true}
                />
                <div className="flex-1 overflow-hidden">
                  <ChatWindow chatId={selectedChat.id} otherPartyName={otherPartyName} />
                </div>
              </>
            ) : (
              <div className="h-full flex items-center justify-center p-4">
                <div className="text-center">
                  <MessageSquare className="h-12 w-12 text-gray-400 mx-auto" />
                  <h3 className="mt-2 text-lg font-medium text-gray-900">
                    Seleziona una chat
                  </h3>
                  <p className="mt-1 text-gray-500">
                    Scegli una conversazione dalla lista per visualizzarla
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
import React from 'react';
import { rulesLabels } from '../../../types/listing';
import { Plug, ScrollText } from 'lucide-react';

interface RulesStepProps {
  formData: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function RulesStep({ formData, onChange }: RulesStepProps) {
  const handleUtilitiesFeeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    onChange({
      target: {
        name: 'utilities.monthlyFee',
        value,
        type: 'text'
      }
    } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <div className="space-y-8">
      {/* House Rules */}
      <div className="bg-gradient-to-br from-blue-500 to-blue-600 rounded-2xl p-4 md:p-8 text-white shadow-xl">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-white/10 rounded-lg backdrop-blur-sm">
            <ScrollText className="h-5 w-5 text-white" />
          </div>
          <h3 className="text-xl font-semibold text-white/90">Regole della Casa</h3>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {Object.entries(rulesLabels).map(([key, label]) => (
            <label key={key} className="relative flex items-center p-4 rounded-lg border border-white/20 hover:border-white/40 cursor-pointer transition-colors backdrop-blur-sm">
              <input
                type="checkbox"
                name={`rules.${key}`}
                checked={formData.rules[key] || false}
                onChange={onChange}
                className="h-4 w-4 text-blue-600 rounded border-white/40 bg-white/10 focus:ring-offset-blue-600 focus:ring-2"
              />
              <span className="ml-3 text-sm text-white">{label}</span>
            </label>
          ))}
        </div>
      </div>

      {/* Utilities */}
      <div className="bg-white rounded-2xl p-4 md:p-8 shadow-xl border border-gray-100">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-green-100 rounded-lg">
            <Plug className="h-5 w-5 text-green-600" />
          </div>
          <h3 className="text-xl font-semibold text-gray-900">Spese</h3>
        </div>

        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <label className="relative flex items-center p-4 rounded-lg border border-gray-200 hover:border-blue-500 cursor-pointer transition-colors">
              <input
                type="radio"
                name="utilities.type"
                value="included"
                checked={formData.utilities.type === 'included'}
                onChange={onChange}
                className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
              />
              <span className="ml-3 text-sm text-gray-700">Spese incluse</span>
            </label>

            <label className="relative flex items-center p-4 rounded-lg border border-gray-200 hover:border-blue-500 cursor-pointer transition-colors">
              <input
                type="radio"
                name="utilities.type"
                value="notIncluded"
                checked={formData.utilities.type === 'notIncluded'}
                onChange={onChange}
                className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
              />
              <span className="ml-3 text-sm text-gray-700">Spese non incluse</span>
            </label>
          </div>

          {formData.utilities.type === 'notIncluded' && (
            <div className="bg-gray-50 rounded-xl p-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Spese mensili stimate (€)
              </label>
              <div className="relative">
                <input
                  type="text"
                  inputMode="numeric"
                  value={formData.utilities.monthlyFee}
                  onChange={handleUtilitiesFeeChange}
                  required
                  className="h-10 w-full px-4 pl-8 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                />
                <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                  <span className="text-gray-500">€</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
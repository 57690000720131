import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Menu, X, LogIn, UserPlus, ShoppingBag, Users, Bell, Building2, Search, MessageSquare, Plus, BookText } from 'lucide-react';
import { useAuthContext } from '../contexts/AuthContext';
import { signOut } from '../lib/auth';
import { toast } from 'react-toastify';
import { NotificationsDropdown } from './notifications/NotificationsDropdown';
import { onNotificationsChange, getUnreadCount } from '../lib/notifications';
import { useUnreadChatsCount } from '../hooks/useUnreadChatsCount';
import { useCredits } from '../hooks/useCredits';

export function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const { user, userProfile } = useAuthContext();
  const navigate = useNavigate();
  const [hasUnread, setHasUnread] = useState(false);
  const location = useLocation();
  const isSearchPage = location.pathname === '/search';
  const unreadMessagesCount = useUnreadChatsCount();
  const credits = useCredits();

  useEffect(() => {
    let unsubscribe = () => {};

    if (user && userProfile?.id) {
      unsubscribe = onNotificationsChange(userProfile.id, (notifications) => {
        const count = getUnreadCount(notifications);
        setUnreadCount(count);
      });
    } else {
      setUnreadCount(0);
    }

    return () => unsubscribe();
  }, [user, userProfile?.id]);

  const handleLogout = async () => {
    try {
      await signOut();
      toast.success('Logout effettuato con successo');
      navigate('/');
    } catch (error) {
      toast.error('Errore durante il logout');
    }
  };

  const handleCreditsClick = () => {
    if (userProfile?.userType) {
      navigate(`/dashboard/${userProfile.userType}?tab=credits`);
    }
  };

  // Close notifications dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.notifications-container')) {
        setShowNotifications(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <nav className="fixed top-0 left-0 right-0 bg-white border-b border-gray-200 z-50">
      <div className={`mx-auto ${isSearchPage ? 'max-w-full px-4' : 'max-w-7xl px-4 sm:px-6 lg:px-8'}`}>
        <div className="flex justify-between h-16">
          {/* Logo and Primary Navigation */}
          <div className="flex items-center gap-8">
            <Link to="/" className="flex items-center">
              <img 
                src="https://firebasestorage.googleapis.com/v0/b/app-unistanza-49b40.firebasestorage.app/o/Logo-2025.svg?alt=media&token=96c7878b-0941-46a5-a9ae-55bc626e31af" 
                alt="Unistanza Logo" 
                className="h-10"
              />
            </Link>

            {/* Primary Navigation - Desktop */}
            <div className="hidden md:flex md:items-center md:space-x-6">
              <Link 
                to="/search" 
                className="flex items-center gap-2 text-gray-600 hover:text-gray-900 font-medium"
              >
                <Search className="h-4 w-4" />
                Ricerca
              </Link>
              {user && (
                <Link 
                  to="/shop" 
                  className="flex items-center gap-2 text-gray-600 hover:text-gray-900 font-medium"
                >
                  <ShoppingBag className="h-4 w-4" />
                  Shop
                </Link>
              )}
              <Link 
                to="/community" 
                className="flex items-center gap-2 text-gray-600 hover:text-gray-900 font-medium"
              >
                <Users className="h-4 w-4" />
                Community
              </Link>
              <Link 
                to="/blog" 
                className="flex items-center gap-2 text-gray-600 hover:text-gray-900 font-medium"
              >
                <BookText className="h-4 w-4" />
                Blog
              </Link>
            </div>
          </div>

          {/* Secondary Navigation - Desktop */}
          <div className="hidden md:flex md:items-center md:space-x-4">
            {user ? (
              <div className="flex items-center gap-4">
                <div className="flex items-center">
                  <button
                    onClick={handleCreditsClick}
                    className="flex items-center"
                    title="Acquista crediti"
                  >
                    <div className="p-2 bg-blue-600 hover:bg-blue-700 transition-colors rounded-full">
                      <Plus className="h-5 w-5 text-white" />
                    </div>
                    <span className="hidden sm:block pl-6 pr-5 py-2 text-sm font-medium text-blue-600 border border-blue-200 border-l-0 rounded-r-full -ml-3">
                      {credits} crediti
                    </span>
                  </button>
                </div>
                {/* Notifications container */}
                <div className="relative notifications-container">
                  <button
                    onClick={() => setShowNotifications(!showNotifications)}
                    className="relative p-2 text-gray-600 hover:text-gray-900 rounded-full hover:bg-gray-100"
                  >
                    <Bell className="h-5 w-5" />
                    {unreadCount > 0 && (
                      <span className="absolute -top-0.5 -right-0.5 flex h-5 w-5 items-center justify-center">
                        <span className="relative inline-flex rounded-full h-5 w-5 bg-blue-500 text-[11px] text-white font-medium items-center justify-center px-1">
                          {unreadCount > 9 ? '9+' : unreadCount}
                        </span>
                      </span>
                    )}
                  </button>

                  {showNotifications && (
                    <NotificationsDropdown onClose={() => setShowNotifications(false)} />
                  )}
                </div>

                {/* Chat Link con indicatore */}
                <Link
                  to="/chat"
                  className="relative p-2 text-gray-600 hover:text-gray-900 rounded-full hover:bg-gray-100"
                >
                  <MessageSquare className="h-5 w-5" />
                  {unreadMessagesCount > 0 && (
                    <span className="absolute -top-0.5 -right-0.5 flex h-5 w-5 items-center justify-center">
                      <span className="relative inline-flex rounded-full h-5 w-5 bg-blue-500 text-[11px] text-white font-medium items-center justify-center px-1">
                        {unreadMessagesCount > 9 ? '9+' : unreadMessagesCount}
                      </span>
                    </span>
                  )}
                </Link>

                {/* Dashboard Button */}
                <Link 
                  to={`/dashboard/${userProfile?.userType || 'student'}`}
                  className="flex items-center gap-2 px-4 py-2 text-gray-600 hover:text-gray-900 font-medium rounded-lg hover:bg-gray-100"
                >
                  <Building2 className="h-4 w-4" />
                  Dashboard
                </Link>

                {/* Logout Button */}
                <button
                  onClick={handleLogout}
                  className="px-4 py-2 text-gray-600 hover:text-gray-900 font-medium rounded-lg hover:bg-gray-100"
                >
                  Logout
                </button>
              </div>
            ) : (
              <div className="flex items-center gap-4">
                <Link 
                  to="/login"
                  className="flex items-center gap-2 px-4 py-2 text-gray-600 hover:text-gray-900 font-medium rounded-lg hover:bg-gray-100"
                >
                  <LogIn className="h-4 w-4" />
                  Accedi
                </Link>
                <Link
                  to="/register"
                  className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  <UserPlus className="h-4 w-4" />
                  Registrati
                </Link>
              </div>
            )}
          </div>

          {/* Mobile menu button */}
          <div className="flex items-center md:hidden">
            {user && (
              <>
                <div className="flex items-center">
                  <button
                    onClick={handleCreditsClick}
                    className="flex items-center mr-2"
                    title="Acquista crediti"
                  >
                    <div className="p-2 bg-blue-600 hover:bg-blue-700 transition-colors rounded-full">
                      <Plus className="h-5 w-5 text-white" />
                    </div>
                    <span className="px-4 py-2 text-sm font-medium text-blue-600 border border-blue-200 border-l-0 rounded-r-full -ml-3">
                      {credits}
                    </span>
                  </button>
                </div>
                <div className="relative notifications-container mr-2">
                  <button
                    onClick={() => setShowNotifications(!showNotifications)}
                    className="relative p-2 text-gray-600 hover:text-gray-900 rounded-full hover:bg-gray-100"
                  >
                    <Bell className="h-5 w-5" />
                    {unreadCount > 0 && (
                      <span className="absolute -top-0.5 -right-0.5 flex h-5 w-5 items-center justify-center">
                        <span className="relative inline-flex rounded-full h-5 w-5 bg-blue-500 text-[11px] text-white font-medium items-center justify-center px-1">
                          {unreadCount > 9 ? '9+' : unreadCount}
                        </span>
                      </span>
                    )}
                  </button>

                  {showNotifications && (
                    <NotificationsDropdown onClose={() => setShowNotifications(false)} />
                  )}
                </div>
                
                <Link
                  to="/chat"
                  className="relative p-2 text-gray-600 hover:text-gray-900 rounded-full hover:bg-gray-100 mr-2"
                >
                  <MessageSquare className="h-5 w-5" />
                  {unreadMessagesCount > 0 && (
                    <span className="absolute -top-0.5 -right-0.5 flex h-5 w-5 items-center justify-center">
                      <span className="relative inline-flex rounded-full h-5 w-5 bg-blue-500 text-[11px] text-white font-medium items-center justify-center px-1">
                        {unreadMessagesCount > 9 ? '9+' : unreadMessagesCount}
                      </span>
                    </span>
                  )}
                </Link>
              </>
            )}
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-2 text-gray-600 hover:text-gray-900 rounded-full hover:bg-gray-100"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="md:hidden border-t border-gray-200">
          <div className="px-4 pt-2 pb-3 space-y-1">
            <Link
              to="/search"
              className="block px-3 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg"
              onClick={() => setIsMenuOpen(false)}
            >
              <div className="flex items-center gap-2">
                <Search className="h-4 w-4" />
                Cerca Alloggi
              </div>
            </Link>
            <Link
              to="/community"
              className="block px-3 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg"
              onClick={() => setIsMenuOpen(false)}
            >
              <div className="flex items-center gap-2">
                <Users className="h-4 w-4" />
                Community
              </div>
            </Link>
            <Link 
              to="/blog" 
              className="block px-3 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg"
              onClick={() => setIsMenuOpen(false)}
            >
              <div className="flex items-center gap-2">
                <BookText className="h-4 w-4" />
                Blog
              </div>
            </Link>
            {user && (
              <>
                <Link
                  to="/shop"
                  className="block px-3 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <div className="flex items-center gap-2">
                    <ShoppingBag className="h-4 w-4" />
                    Shop
                  </div>
                </Link>
                <Link
                  to={`/dashboard/${userProfile?.userType || 'student'}`}
                  className="block px-3 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <div className="flex items-center gap-2">
                    <Building2 className="h-4 w-4" />
                    Dashboard
                  </div>
                </Link>
                <button
                  onClick={() => {
                    handleLogout();
                    setIsMenuOpen(false);
                  }}
                  className="block w-full text-left px-3 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg"
                >
                  Logout
                </button>
              </>
            )}
            {!user && (
              <>
                <Link
                  to="/login"
                  className="block px-3 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <div className="flex items-center gap-2">
                    <LogIn className="h-4 w-4" />
                    Accedi
                  </div>
                </Link>
                <Link
                  to="/register"
                  className="block px-3 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <div className="flex items-center gap-2">
                    <UserPlus className="h-4 w-4" />
                    Registrati
                  </div>
                </Link>
              </>
            )}
          </div>
        </div>
      )}
    </nav>
  );
}
import { Listing } from '../types/listing';

interface ListingData {
  category: string;
  size?: string;
  bathrooms: string;
  floor: string;
  location: {
    formattedAddress: string;
  };
  features: Record<string, boolean>;
  maxOccupants: string;
  utilities: {
    type: string;
    monthlyFee?: number;
  };
  heating: string;
  price: number;
  availableFrom: string;
  minStay: number;
  noticePeriod: number;
}

function getRandomElement<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

export function generateListingTitle(data: ListingData): string {
  const adjectives = {
    masculine: [
      'Luminoso',
      'Moderno',
      'Accogliente',
      'Elegante',
      'Spazioso',
      'Confortevole',
      'Grazioso',
      'Rinnovato',
      'Silenzioso',
      'Centrale',
    ],
    feminine: [
      'Luminosa',
      'Moderna',
      'Accogliente',
      'Elegante',
      'Spaziosa',
      'Confortevole',
      'Graziosa',
      'Rinnovata',
      'Silenziosa',
      'Centrale',
    ]
  };

  const propertyTypes = {
    single_room: {
      gender: 'feminine',
      singular: true,
      variants: [
        'Stanza singola',
        'Camera singola',
        'Camera indipendente',
        'Stanza privata'
      ]
    },
    double_room: {
      gender: 'feminine',
      singular: true,
      variants: [
        'Stanza doppia',
        'Camera doppia',
        'Stanza condivisa per due persone'
      ]
    },
    triple_room: {
      gender: 'feminine',
      singular: true,
      variants: [
        'Stanza tripla',
        'Camera tripla',
        'Stanza per tre persone'
      ]
    },
    quad_room: {
      gender: 'feminine',
      singular: true,
      variants: [
        'Stanza quadrupla',
        'Camera quadrupla',
        'Stanza per quattro persone'
      ]
    }
  };

  const locationHighlights = [
    'centro',
    'zona universitaria',
    'zona ben servita',
    'quartiere residenziale',
    'posizione strategica',
  ];

  // Extract location parts
  const [street, city] = data.location.formattedAddress.split(',').map(s => s.trim());
  const streetName = street
    .replace(/\s*\d{5}\s*/g, '')
    .replace(/\s*\([A-Z]{2}\)\s*$/, '')
    .trim();

  // Get property type info
  const propertyTypeInfo = propertyTypes[data.category as keyof typeof propertyTypes];
  const propertyType = getRandomElement(propertyTypeInfo.variants);
  
  // Select appropriate adjective based on gender
  const adjective = getRandomElement(
    propertyTypeInfo.gender === 'feminine' ? adjectives.feminine : adjectives.masculine
  );

  // Generate title variants
  const titleTemplates = [
    `${adjective} ${propertyType} in ${getRandomElement(locationHighlights)} a ${city}`,
    `${propertyType} ${adjective.toLowerCase()} in ${streetName}, ${city}`,
    `${propertyType} in ${getRandomElement(locationHighlights)} vicino ${streetName}`,
    `${adjective} ${propertyType} vicino ${city}`,
    `${propertyType} in ${city}, ${getRandomElement(locationHighlights)}`
  ];

  return getRandomElement(titleTemplates);
}

const getFloorText = (floor: string) => {
  switch (floor) {
    case 'PT':
      return 'Piano Terra';
    case 'PR':
      return 'Piano Rialzato';
    default:
      return `${floor}° piano`;
  }
};

export async function generateListingDescription(data: ListingData): Promise<string> {
  try {
    const isRoom = ['single_room', 'double_room', 'triple_room', 'quad_room'].includes(data.category);
    
    // Frasi per la superficie
    let surfacePhrases;
    if (isRoom) {
      surfacePhrases = [
        `La stanza di ${data.roomSize} m² si trova in un immobile di ${data.size} m²`,
        `All'interno di un immobile di ${data.size} m², la stanza misura ${data.roomSize} m²`,
        `La camera, che misura ${data.roomSize} m², è parte di un immobile di ${data.size} m²`
      ];
    } else {
      const isPropertyMasculine = ['monolocale', 'appartamento'].includes(data.category);
      surfacePhrases = [
        `La superficie totale è di ${data.size} m²`,
        `L'immobile misura ${data.size} m²`,
        `${isPropertyMasculine ? 'Questo immobile' : 'Questa proprietà'} si sviluppa su ${data.size} m²`
      ];
    }

    const propertyTypes = {
      single_room: {
        gender: 'feminine',
        singular: true,
        variants: [
          'Accogliente stanza singola',
          'Confortevole camera singola',
          'Graziosa stanza privata',
          'Luminosa camera singola'
        ]
      },
      double_room: {
        gender: 'feminine',
        singular: true,
        variants: [
          'Spaziosa stanza doppia',
          'Ampia camera doppia',
          'Confortevole stanza per due persone'
        ]
      },
      triple_room: {
        gender: 'feminine',
        singular: true,
        variants: [
          'Ampia stanza tripla',
          'Spaziosa camera tripla',
          'Confortevole stanza per tre persone'
        ]
      },
      quad_room: {
        gender: 'feminine',
        singular: true,
        variants: [
          'Grande stanza quadrupla',
          'Spaziosa camera quadrupla',
          'Ampia stanza per quattro persone'
        ]
      }
    };

    const locationPhrases = [
      `situata in ${data.location.formattedAddress}`,
      `posizionata in ${data.location.formattedAddress}`,
      `ubicata in ${data.location.formattedAddress}`,
      `collocata in ${data.location.formattedAddress}`,
      `che si trova in ${data.location.formattedAddress}`
    ];

    const sizePhrases = data.size
      ? [
          `La superficie è di ${data.size}mq`,
          `Con una metratura di ${data.size}mq`,
          `Si estende per ${data.size}mq`,
          `Dotata di ${data.size}mq di superficie`,
          `Caratterizzata da ${data.size}mq di spazio`
        ]
      : [];

    const propertyTypeInfo = propertyTypes[data.category as keyof typeof propertyTypes];
    const propertyType = getRandomElement(propertyTypeInfo.variants);
    const isPropertyMasculine = propertyTypeInfo.gender === 'masculine';

    const roomPhrases = [
      `L'immobile dispone di ${data.bathrooms} ${Number(data.bathrooms) === 1 ? 'bagno' : 'bagni'}`,
      `La proprietà offre ${data.bathrooms} ${Number(data.bathrooms) === 1 ? 'bagno' : 'bagni'}`,
      `${isPropertyMasculine ? 'Composto' : 'Composta'} da ${data.bathrooms} ${Number(data.bathrooms) === 1 ? 'bagno' : 'bagni'}`
    ];

    const floorPhrases = [
      `si trova al ${getFloorText(data.floor)}`,
      `${isPropertyMasculine ? 'posto' : 'posta'} al ${getFloorText(data.floor)}`,
      `${isPropertyMasculine ? 'situato' : 'situata'} al ${getFloorText(data.floor)}`
    ];

    // Determina il numero di occupanti in base alla tipologia
    const occupantsByCategory = {
      single_room: 1,
      double_room: 2,
      triple_room: 3,
      quad_room: 4
    };

    const categoryOccupants = occupantsByCategory[data.category as keyof typeof occupantsByCategory];

    const occupantsPhrases = [
      `Ideale per ${categoryOccupants} ${categoryOccupants === 1 ? 'studente' : 'studenti'}`,
      `Perfetto per ${categoryOccupants} ${categoryOccupants === 1 ? 'persona' : 'persone'}`,
      `Ottimo per ${categoryOccupants} ${categoryOccupants === 1 ? 'inquilino' : 'coinquilini'}`
    ];

    const features = Object.entries(data.features)
      .filter(([_, value]) => value)
      .map(([key]) => translateFeature(key));

    const featuresPhrases =
      features.length > 0
        ? [
            `Tra i comfort troviamo: ${features.join(', ')}`,
            `La proprietà include: ${features.join(', ')}`,
            `${isPropertyMasculine ? 'Dotato' : 'Dotata'} di: ${features.join(', ')}`
          ]
        : [];

    const contractPhrases = [
      `Permanenza minima richiesta di ${data.minStay} mesi con ${data.noticePeriod} mesi di preavviso per il rilascio.`,
      `Si richiede una permanenza minima di ${data.minStay} mesi. Preavviso di ${data.noticePeriod} mesi per il rilascio.`,
      `Contratto con durata minima ${data.minStay} mesi e ${data.noticePeriod} mesi di preavviso.`
    ];

    // Compose description using random phrases
    const description = [
      `${propertyType} ${getRandomElement(locationPhrases)}.`,
      sizePhrases.length > 0 ? `${getRandomElement(sizePhrases)}.` : '',
      `${getRandomElement(roomPhrases)}, ${getRandomElement(floorPhrases)}.`,
      `${getRandomElement(occupantsPhrases)}.`,
      `Il riscaldamento è ${
        data.heating === 'autonomous' ? 'autonomo' : 'centralizzato'
      } e le spese sono ${
        data.utilities.type === 'included' 
          ? 'incluse nel canone' 
          : `escluse (circa €${data.utilities.monthlyFee}/mese)`
      }.`,
      featuresPhrases.length > 0 ? `${getRandomElement(featuresPhrases)}.` : '',
      getRandomElement(contractPhrases)
    ]
      .filter(Boolean)
      .join(' ');

    return description.trim();
  } catch (error) {
    console.error('Error generating description:', error);
    throw new Error('Errore durante la generazione della descrizione');
  }
}

function translateFeature(key: string): string {
  const translations: Record<string, string> = {
    wifi: 'Wi-Fi',
    airConditioning: 'aria condizionata',
    elevator: 'ascensore',
    parking: 'parcheggio',
    furnished: 'arredato',
    petsAllowed: 'animali ammessi',
    balcony: 'balcone',
    garden: 'giardino',
    dishwasher: 'lavastoviglie',
    washingMachine: 'lavatrice',
    studyRoom: 'sala studio',
    securityDoor: 'porta blindata',
    doublePaneWindows: 'doppi vetri',
    storage: 'ripostiglio',
    bikeStorage: 'deposito bici',
    laundryRoom: 'lavanderia'
  };

  return translations[key] || key;
}
import { useState, useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuthContext } from '../contexts/AuthContext';

export function useCredits() {
  const [credits, setCredits] = useState<number>(0);
  const { userProfile } = useAuthContext();

  useEffect(() => {
    if (!userProfile?.id) return;

    const userRef = doc(db, 'users', userProfile.id);
    const unsubscribe = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        const userData = doc.data();
        const userCredits = userProfile.userType === 'student' 
          ? userData.studentInfo?.credits 
          : userData.landlordInfo?.credits;
        setCredits(userCredits || 0);
      }
    });

    return () => unsubscribe();
  }, [userProfile?.id, userProfile?.userType]);

  return credits;
} 
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { BlogPost, getBlogPost, createBlogPost, updateBlogPost, generateSlug } from '../../../lib/blogPosts';
import { ImageUpload } from '../../shared/ImageUpload';
import { uploadFile } from '../../../lib/upload';

interface BlogPostForm extends Omit<BlogPost, 'id' | 'url' | 'publishedAt'> {}

const calculateReadTime = (content: string): number => {
  const wordsPerMinute = 200; // Velocità media di lettura
  const words = content.trim().split(/\s+/).length;
  const readTime = Math.ceil(words / wordsPerMinute);
  return Math.max(1, readTime); // Minimo 1 minuto
};

const isValidImageUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

export function BlogPostEditor() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [formData, setFormData] = useState<BlogPostForm>({
    title: '',
    excerpt: '',
    content: '',
    coverImage: '',
    category: '',
    readTime: 1
  });

  useEffect(() => {
    if (id) {
      loadPost();
    }
  }, [id]);

  const loadPost = async () => {
    try {
      const post = await getBlogPost(id);
      setFormData({
        title: post.title,
        excerpt: post.excerpt,
        content: post.content,
        coverImage: post.coverImage,
        category: post.category,
        readTime: post.readTime
      });
    } catch (error) {
      console.error('Error loading post:', error);
      toast.error('Errore nel caricamento dell\'articolo');
      navigate('/dashboard/admin');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (uploading) {
      toast.error('Attendi il completamento del caricamento dell\'immagine');
      return;
    }

    try {
      new URL(formData.coverImage);
    } catch (error) {
      toast.error('Inserisci un URL valido per l\'immagine di copertina');
      return;
    }
    
    setLoading(true);
    try {
      if (id) {
        await updateBlogPost(id, formData);
        toast.success('Articolo aggiornato con successo');
        navigate(`/blog/${generateSlug(formData.title)}`);
      } else {
        const newPostId = await createBlogPost(formData);
        toast.success('Articolo creato con successo');
        navigate(`/blog/${generateSlug(formData.title)}`);
      }
    } catch (error) {
      console.error('Error saving post:', error);
      toast.error('Errore durante il salvataggio dell\'articolo');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    
    if (name === 'coverImage') {
      // Salva direttamente l'URL dell'immagine senza manipolazioni
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    } else if (name === 'content') {
      const readTime = calculateReadTime(value);
      setFormData(prev => ({
        ...prev,
        [name]: value,
        readTime
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleImageChange = async (images: (string | File)[]) => {
    if (images.length > 0) {
      const image = images[0];
      if (typeof image === 'string') {
        // Se è già un URL, lo usiamo direttamente
        setFormData(prev => ({
          ...prev,
          coverImage: image
        }));
      } else {
        setUploading(true);
        try {
          const downloadUrl = await uploadFile(image, {
            folder: 'blog',
            maxFiles: 1,
            maxFileSize: 10 * 1024 * 1024, // 10MB
            allowedTypes: ['image/jpeg', 'image/png', 'image/webp'],
            silent: false
          });
          
          setFormData(prev => ({
            ...prev,
            coverImage: downloadUrl
          }));
        } catch (error) {
          console.error('Errore nel caricamento dell\'immagine:', error);
          toast.error('Errore nel caricamento dell\'immagine');
        } finally {
          setUploading(false);
        }
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-gray-600 hover:text-gray-900 mb-8"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Torna indietro
        </button>

        <div className="bg-white rounded-lg shadow-sm p-8">
          <h1 className="text-2xl font-bold text-gray-900 mb-8">
            {id ? 'Modifica Articolo' : 'Nuovo Articolo'}
          </h1>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Titolo
              </label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Estratto
              </label>
              <textarea
                name="excerpt"
                value={formData.excerpt}
                onChange={handleChange}
                required
                rows={3}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Contenuto
              </label>
              <textarea
                name="content"
                value={formData.content}
                onChange={handleChange}
                required
                rows={10}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Immagine di Copertina
              </label>
              <ImageUpload
                images={formData.coverImage ? [formData.coverImage] : []}
                onChange={handleImageChange}
                maxImages={1}
                title="Carica immagine di copertina"
                subtitle="PNG, JPG fino a 10MB"
                required={true}
              />
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Categoria
                </label>
                <select
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Seleziona categoria</option>
                  <option value="Guide">Guide</option>
                  <option value="Consigli">Consigli</option>
                  <option value="Novità">Novità</option>
                  <option value="Storie">Storie</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Tempo di Lettura (minuti)
                </label>
                <input
                  type="number"
                  name="readTime"
                  value={formData.readTime}
                  readOnly
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-gray-50 cursor-not-allowed"
                />
                <p className="mt-1 text-sm text-gray-500">
                  Calcolato automaticamente in base al contenuto
                </p>
              </div>
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                disabled={loading}
                className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
              >
                {loading ? 'Salvataggio...' : 'Salva Articolo'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
} 
import React, { useRef, useEffect } from 'react';
import { ListingCard } from '../ListingCard';
import { Listing } from '../../types/listing';
import { Star } from 'lucide-react';

interface SearchResultsProps {
  listings: Listing[];
  loading: boolean;
  error?: string;
  selectedListing?: Listing | null;
  onListingSelect: (listing: Listing) => void;
  onRetry?: () => void;
  isExpanded?: boolean;
}

export function SearchResults({
  listings,
  loading,
  error,
  selectedListing,
  onListingSelect,
  onRetry,
  isExpanded = false
}: SearchResultsProps) {
  const listingRefs = useRef<{ [key: string]: HTMLDivElement }>({});

  // Ordina gli annunci: prima quelli in evidenza, poi gli altri
  const sortedListings = [...listings].sort((a, b) => {
    const aFeatured = a.featured?.active && new Date(a.featured.expiresAt) > new Date();
    const bFeatured = b.featured?.active && new Date(b.featured.expiresAt) > new Date();
    
    if (aFeatured && !bFeatured) return -1;
    if (!aFeatured && bFeatured) return 1;
    return 0;
  });

  useEffect(() => {
    if (selectedListing && listingRefs.current[selectedListing.id]) {
      const container = listingRefs.current[selectedListing.id].closest('.overflow-y-auto');
      if (container) {
        const isMobile = window.innerWidth < 768;
        const cardElement = listingRefs.current[selectedListing.id];
        
        if (isMobile) {
          const containerHeight = container.clientHeight;
          const cardHeight = cardElement.clientHeight;
          const headerHeight = 180;
          const mobilePadding = 40; // Padding extra per mobile
          const scrollTop = cardElement.offsetTop - (containerHeight - cardHeight) / 2 - headerHeight - mobilePadding;
          
          setTimeout(() => {
            container.scrollTo({
              top: Math.max(0, scrollTop),
              behavior: 'smooth'
            });
          }, 400);
        } else {
          const headerHeight = 180;
          const extraPadding = 80;
          const scrollTop = cardElement.offsetTop - headerHeight - extraPadding;
          
          setTimeout(() => {
            container.scrollTo({
              top: Math.max(0, scrollTop),
              behavior: 'smooth'
            });
          }, 400);
        }
      }
    }
  }, [selectedListing]);

  if (loading && listings.length === 0) {
    return (
      <div className="text-center py-8 px-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
        <p className="mt-2 text-gray-500">Caricamento annunci...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8 px-4">
        <p className="text-red-500">{error}</p>
        <button
          onClick={onRetry}
          className="mt-2 text-blue-600 hover:text-blue-700"
        >
          Riprova
        </button>
      </div>
    );
  }

  if (listings.length === 0) {
    return (
      <div className="text-center py-8 px-4">
        <p className="text-gray-500">Nessun risultato trovato</p>
      </div>
    );
  }

  return (
    <div className="pb-32">
      <div className={`grid gap-4 ${
        isExpanded 
          ? 'grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 px-4' 
          : 'grid-cols-1 px-0 sm:px-4'
      }`}>
        {sortedListings.map((listing) => (
          <div
            key={listing.id}
            ref={el => {
              if (el) listingRefs.current[listing.id] = el;
            }}
            className="h-full listing-card"
            onClick={() => onListingSelect(listing)}
          >
            <div className="w-full h-full bg-white rounded-lg hover:shadow-md transition-shadow">
              <ListingCard 
                listing={listing} 
                isSelected={selectedListing?.id === listing.id}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
import { useState, useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { onSnapshot, query, collection, where, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';

export function useUnreadMessages(chatId?: string) {
  const { userProfile } = useAuthContext();
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    if (!chatId || !userProfile?.id) {
      setUnreadCount(0);
      return;
    }

    // Ascolta i cambiamenti nel documento della chat
    const chatRef = doc(db, 'chats', chatId);
    const unsubscribeChat = onSnapshot(chatRef, (chatDoc) => {
      if (chatDoc.exists()) {
        const chatData = chatDoc.data();
        const unreadCounts = chatData.unreadCount || {};
        setUnreadCount(unreadCounts[userProfile.id] || 0);
      }
    });

    // Ascolta anche i cambiamenti nei messaggi per aggiornamenti in tempo reale
    const messagesQuery = query(
      collection(db, 'messages'),
      where('chatId', '==', chatId),
      where('senderId', '!=', userProfile.id)
    );

    const unsubscribeMessages = onSnapshot(messagesQuery, (snapshot) => {
      const count = snapshot.docs.filter(doc => {
        const data = doc.data();
        const readBy = data.readBy || [];
        return data.senderId !== userProfile.id && !readBy.includes(userProfile.id);
      }).length;
      
      setUnreadCount(count);
    });

    // Cleanup function che rimuove entrambi i listener
    return () => {
      unsubscribeChat();
      unsubscribeMessages();
    };
  }, [chatId, userProfile?.id]);

  return { unreadCount };
}
import React, { useState } from 'react';
import { X, MessageSquare, Paperclip, Loader2 } from 'lucide-react';
import { useAuthContext } from '../../contexts/AuthContext';
import { createTicket } from '../../lib/tickets';
import { toast } from 'react-toastify';
import { db, storage } from '../../lib/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { AttachmentUpload } from '../shared/AttachmentUpload';

interface CreateTicketModalProps {
  onClose: () => void;
  onSuccess: () => void;
}

export type TicketStatus = 'aperto' | 'in_lavorazione' | 'risolto' | 'chiuso';

export function CreateTicketModal({ onClose, onSuccess }: CreateTicketModalProps) {
  const { userProfile } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [attachments, setAttachments] = useState<(string | File)[]>([]);
  const [formData, setFormData] = useState({
    subject: '',
    message: ''
  });

  const uploadFiles = async (files: File[]) => {
    const uploadPromises = files.map(async (file) => {
      const storageRef = ref(storage, `tickets/${Date.now()}_${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      
      return {
        name: file.name,
        url: downloadURL,
        type: file.type,
        size: file.size
      };
    });

    return Promise.all(uploadPromises);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!userProfile) return;

    setLoading(true);
    try {
      const fileAttachments = attachments.filter((att): att is File => att instanceof File);
      const uploadedFiles = fileAttachments.length > 0 ? await uploadFiles(fileAttachments) : [];

      const ticketData = {
        userId: userProfile.id,
        userEmail: userProfile.email,
        userType: userProfile.userType,
        subject: formData.subject,
        status: 'aperto' as TicketStatus,
        createdAt: new Date(),
        firstMessage: formData.message,
        firstMessageAttachments: uploadedFiles
      };

      const ticketId = await createTicket(ticketData);
      
      const ticketDoc = await getDoc(doc(db, 'tickets', ticketId));
      if (!ticketDoc.exists() || ticketDoc.data().status !== 'aperto') {
        throw new Error('Errore nella creazione del ticket');
      }

      toast.success('Ticket creato con successo');
      onSuccess();
    } catch (error) {
      console.error('Errore durante la creazione del ticket:', error);
      toast.error('Errore durante la creazione del ticket');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-start sm:items-center justify-center">
      <div className="bg-white w-full h-[100dvh] sm:h-auto sm:max-h-[90vh] sm:max-w-2xl sm:rounded-lg shadow-xl flex flex-col overflow-hidden">
        <div className="flex-none border-b p-3 md:p-4">
          <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-0">
            <div className="flex items-center justify-between w-full">
              <h2 className="text-lg md:text-xl font-medium text-gray-900">
                Crea un nuovo ticket
              </h2>
              <button
                onClick={onClose}
                className="p-1 hover:bg-gray-100 rounded-full transition-colors sm:hidden"
              >
                <X className="h-5 w-5 text-gray-500" />
              </button>
            </div>

            <div className="hidden sm:flex items-center justify-end">
              <button
                onClick={onClose}
                className="p-1 hover:bg-gray-100 rounded-full transition-colors"
              >
                <X className="h-5 w-5 text-gray-500" />
              </button>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="flex flex-col flex-1">
          <div className="flex-1 overflow-y-auto p-4 sm:p-6">
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Oggetto
                </label>
                <input
                  type="text"
                  value={formData.subject}
                  onChange={(e) => setFormData(prev => ({ ...prev, subject: e.target.value }))}
                  className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Messaggio
                </label>
                <div className="relative">
                  <button
                    type="button"
                    onClick={() => setShowAttachments(true)}
                    className="absolute left-3 top-4 text-gray-500 hover:text-gray-600 transition-colors"
                  >
                    <Paperclip className="h-5 w-5" />
                  </button>
                  <textarea
                    value={formData.message}
                    onChange={(e) => setFormData(prev => ({ ...prev, message: e.target.value }))}
                    rows={4}
                    className="w-full rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 pl-12 pr-4 pt-3"
                    required
                  />
                </div>
              </div>

              {showAttachments && (
                <div className="border rounded-lg p-4 relative">
                  <AttachmentUpload
                    attachments={attachments}
                    onChange={setAttachments}
                    maxAttachments={3}
                    title="Aggiungi allegati"
                    subtitle="PNG, JPG, PDF fino a 10MB"
                  />
                  <p className="mt-2 text-xs text-gray-500 italic">
                    È possibile caricare un massimo di 3 file per messaggio
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="flex-none p-4 sm:p-6 border-t bg-gray-50">
            <div className="flex gap-3 justify-end">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 transition-colors"
              >
                Annulla
              </button>
              <button
                type="submit"
                disabled={loading}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:hover:bg-blue-600 transition-colors flex items-center gap-2"
              >
                {loading ? (
                  <>
                    <Loader2 className="h-4 w-4 animate-spin" />
                    Creazione in corso...
                  </>
                ) : (
                  'Crea ticket'
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
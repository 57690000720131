import React, { useState } from 'react';
import { Search, MapPin } from 'lucide-react';

interface SearchBarProps {
  onSearch: (query: string) => void;
  placeholder?: string;
  variant?: 'default' | 'mobile';
  className?: string;
}

export function SearchBar({ 
  onSearch, 
  placeholder = 'Cerca per città...', 
  variant = 'default',
  className = ''
}: SearchBarProps) {
  const [query, setQuery] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (query.trim()) {
      onSearch(query.trim());
    }
  };

  const mobileStyles = variant === 'mobile' ? {
    wrapper: "fixed top-16 left-0 right-0 z-50 px-4 py-3 bg-white shadow-lg mb-4",
    input: "pl-12 pr-12 py-3 text-gray-900 placeholder-gray-500 bg-gray-100",
    button: "absolute right-2 top-1/2 -translate-y-1/2 h-[38px] w-[38px] bg-blue-600 text-white rounded-full hover:bg-blue-700 flex items-center justify-center z-20"
  } : {
    wrapper: `relative ${className}`,
    input: "pl-12 pr-16 sm:pr-24 py-4 text-gray-900 placeholder-gray-500 bg-transparent",
    button: "absolute right-2 top-1/2 -translate-y-1/2 h-[42px] px-3 sm:px-4 bg-blue-600 text-white rounded-[21px] hover:bg-blue-700 flex items-center justify-center gap-2 z-20"
  };

  return (
    <form onSubmit={handleSubmit} className={variant === 'mobile' ? 'shadow-lg mb-3' : 'w-full'}>
      <div className={mobileStyles.wrapper}>
        <div 
          className="group relative bg-white rounded-[28px] transition-all duration-200 w-full"
          style={{ zIndex: 10 }}
        >
          <div className="absolute inset-0 rounded-[28px] ring-1 ring-inset ring-gray-200" />
          
          <div className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400 z-10">
            <MapPin className="h-5 w-5" />
          </div>
          
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={placeholder}
            className={`relative w-full border-0 focus:ring-0 focus:outline-none z-10 rounded-[28px] ${mobileStyles.input}`}
          />
          
          <button 
            type="submit"
            className={mobileStyles.button}
          >
            <Search className="h-5 w-5" />
            {variant === 'default' && <span className="hidden sm:inline">Cerca</span>}
          </button>
        </div>
      </div>
    </form>
  );
}
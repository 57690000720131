import React, { useState, useEffect, useRef } from 'react';
import { Message } from '../../types/chat';
import { useAuthContext } from '../../contexts/AuthContext';
import { onMessagesChange, sendMessage, markMessageAsRead, markMessagesAsRead } from '../../lib/chat';
import { ChatMessage } from './ChatMessage';
import { ChatInput } from './ChatInput';
import { Sparkles, Shield, Clock } from 'lucide-react';
import { isUserBlocked } from '../../lib/blocks';
import { doc, onSnapshot, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { toast } from 'react-hot-toast';

interface ChatWindowProps {
  chatId: string;
  otherPartyName: string;
}

export function ChatWindow({ chatId, otherPartyName }: ChatWindowProps) {
  const { userProfile } = useAuthContext();
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [blockedBy, setBlockedBy] = useState<'me' | 'other' | null>(null);
  const [averageResponseTime, setAverageResponseTime] = useState<number | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  // Verifica lo stato di blocco dalla chat
  useEffect(() => {
    if (!userProfile?.id) return;

    const chatRef = doc(db, 'chats', chatId);
    const unsubscribe = onSnapshot(chatRef, async (chatDoc) => {
      if (chatDoc.exists()) {
        const chatData = chatDoc.data();
        const otherPartyId = chatData.studentId === userProfile.id ? 
          chatData.landlordId : 
          chatData.studentId;

        const blockedByMe = await isUserBlocked(userProfile.id, otherPartyId);
        const blockedByOther = await isUserBlocked(otherPartyId, userProfile.id);
        
        setIsBlocked(blockedByMe || blockedByOther);
        if (blockedByMe) setBlockedBy('me');
        else if (blockedByOther) setBlockedBy('other');
        else setBlockedBy(null);
      }
    });

    return () => unsubscribe();
  }, [userProfile?.id, chatId]);

  useEffect(() => {
    if (!userProfile?.id) return;

    const unsubscribe = onMessagesChange(chatId, async (updatedMessages) => {
      setMessages(updatedMessages);
      setLoading(false);

      // Marca immediatamente i messaggi come letti quando la chat viene aperta o aggiornata
      if (updatedMessages.length > 0) {
        try {
          await markMessagesAsRead(chatId, userProfile.id);
        } catch (error) {
          console.error('Errore durante la marcatura dei messaggi come letti:', error);
        }
      }
    });

    return () => unsubscribe();
  }, [chatId, userProfile?.id]);

  useEffect(() => {
    if (isAtBottom && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ 
        behavior: 'instant',
        block: 'end'
      });
    }
  }, [messages, isAtBottom]);

  useEffect(() => {
    if (messages.length > 0 && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ 
        behavior: 'instant',
        block: 'end'
      });
    }
  }, []);

  const handleScroll = () => {
    if (!chatContainerRef.current) return;
    
    const { scrollHeight, scrollTop, clientHeight } = chatContainerRef.current;
    const isBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 50;
    setIsAtBottom(isBottom);
  };

  const handleSendMessage = async (content: string) => {
    if (!userProfile) return;

    setSending(true);
    try {
      const chatRef = doc(db, 'chats', chatId);
      const chatDoc = await getDoc(chatRef);
      
      if (!chatDoc.exists()) {
        throw new Error('Chat non trovata');
      }

      const chatData = chatDoc.data();
      const otherPartyId = chatData.studentId === userProfile.id ? 
        chatData.landlordId : 
        chatData.studentId;

      await sendMessage(
        chatId,
        userProfile.id,
        userProfile.displayName,
        content
      );
      setIsAtBottom(true);
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error(error instanceof Error ? error.message : 'Errore nell\'invio del messaggio');
    } finally {
      setSending(false);
    }
  };

  // Calcola il tempo medio di risposta
  useEffect(() => {
    const calculateAverageResponseTime = async () => {
      if (!userProfile?.id) return;
      
      try {
        const chatsRef = collection(db, 'chats');
        const q = query(
          chatsRef,
          where('participants', 'array-contains', otherPartyName),
          orderBy('lastMessageAt', 'desc'),
          limit(10)
        );
        
        const querySnapshot = await getDocs(q);
        let totalResponseTime = 0;
        let responseCount = 0;

        querySnapshot.forEach(doc => {
          const chatData = doc.data();
          if (chatData.messages && chatData.messages.length > 1) {
            // Calcola i tempi di risposta per ogni coppia di messaggi
            for (let i = 1; i < chatData.messages.length; i++) {
              if (chatData.messages[i].senderId === otherPartyName &&
                  chatData.messages[i-1].senderId !== otherPartyName) {
                const responseTime = chatData.messages[i].timestamp - chatData.messages[i-1].timestamp;
                totalResponseTime += responseTime;
                responseCount++;
              }
            }
          }
        });

        if (responseCount > 0) {
          const averageTime = Math.round(totalResponseTime / responseCount / (1000 * 60 * 60)); // Converti in ore
          setAverageResponseTime(averageTime);
        }
      } catch (error) {
        console.error('Errore nel calcolo del tempo medio di risposta:', error);
      }
    };

    calculateAverageResponseTime();
  }, [userProfile?.id, otherPartyName]);

  if (loading) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  const isNewChat = messages.length === 0;

  return (
    <div className="flex flex-col h-full bg-white overflow-hidden">
      {isNewChat ? (
        <div className="flex-1 flex items-center justify-center p-8">
          <div className="text-center max-w-md">
            <div className="bg-blue-50 rounded-full p-3 w-fit mx-auto mb-4">
              <Sparkles className="h-6 w-6 text-blue-600" />
            </div>
            <h3 className="text-lg font-medium text-gray-900 mb-2">
              Nuova conversazione con {otherPartyName}
            </h3>
            <p className="text-gray-500 mb-4">
              Questa è una nuova chat. Inizia la conversazione inviando il primo messaggio!
            </p>
            {averageResponseTime && (
              <div className="bg-gray-50 rounded-lg p-4">
                <div className="flex items-center justify-center gap-2 text-sm text-gray-600">
                  <Clock className="h-4 w-4" />
                  <span>
                    {averageResponseTime === 1
                      ? "Tempo di risposta medio: 1 ora"
                      : `Tempo di risposta medio: ${averageResponseTime} ore`}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div 
          ref={chatContainerRef}
          onScroll={handleScroll}
          className="flex-1 overflow-y-auto p-4 space-y-4"
        >
          {messages.map((message) => (
            <ChatMessage 
              key={message.id} 
              message={message} 
              isOwnMessage={message.senderId === userProfile?.id} 
            />
          ))}
          <div ref={messagesEndRef} />
        </div>
      )}

      {isBlocked ? (
        <div className="border-t p-4 bg-red-50">
          <div className="flex items-center gap-2 text-red-700 justify-center">
            <Shield className="h-5 w-5" />
            <p className="text-sm font-medium">
              {blockedBy === 'me' 
                ? `Hai bloccato ${otherPartyName}. Non potete più scambiarvi messaggi.`
                : `${otherPartyName} ti ha bloccato. Non potete più scambiarvi messaggi.`
              }
            </p>
          </div>
        </div>
      ) : (
        <ChatInput 
          onSendMessage={handleSendMessage}
          disabled={sending}
        />
      )}
    </div>
  );
}
import { 
  collection, 
  query, 
  where, 
  getDocs,
  doc,
  setDoc,
  updateDoc,
  serverTimestamp,
  onSnapshot,
  orderBy,
  limit,
  QuerySnapshot,
  DocumentData,
  runTransaction,
  getDoc,
  enableIndexedDbPersistence,
  writeBatch,
  arrayUnion
} from 'firebase/firestore';
import { db } from './firebase';
import { Chat, Message, ContactInfo } from '../types/chat';
import { toast } from 'react-toastify';
import { createChatMessageNotification } from './notifications';
import { isUserBlocked } from './blocks';
import { getBlockTimestamp } from './blocks';

const chatsCollection = collection(db, 'chats');
const messagesCollection = collection(db, 'messages');

export async function createChat(
  listingId: string,
  listingTitle: string,
  applicationId: string,
  studentInfo: ContactInfo,
  landlordInfo: ContactInfo
): Promise<string> {
  try {
    // Create chat document
    const chatRef = doc(chatsCollection);
    await setDoc(chatRef, {
      listingId,
      listingTitle,
      applicationId,
      studentId: studentInfo.id,
      studentName: studentInfo.name,
      studentEmail: studentInfo.email,
      landlordId: landlordInfo.id,
      landlordName: landlordInfo.name,
      landlordEmail: landlordInfo.email,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      lastMessage: null,
      status: 'active',
      unreadCount: {
        [studentInfo.id]: 0,
        [landlordInfo.id]: 0
      }
    });

    return chatRef.id;
  } catch (error) {
    console.error('Error creating chat:', error);
    throw error;
  }
}

export function onChatsChange(userId: string, callback: (chats: Chat[]) => void) {
  if (!userId) {
    console.log('Debug - onChatsChange: userId non presente');
    callback([]);
    return () => {};
  }

  console.log('Debug - onChatsChange: inizializzazione con userId:', userId);

  const studentQuery = query(
    collection(db, 'chats'),
    where('studentId', '==', userId),
    orderBy('updatedAt', 'desc')
  );

  const landlordQuery = query(
    collection(db, 'chats'),
    where('landlordId', '==', userId),
    orderBy('updatedAt', 'desc')
  );

  let allChats: Chat[] = [];

  const unsubscribeStudent = onSnapshot(studentQuery, async (snapshot) => {
    const studentChats = await Promise.all(snapshot.docs.map(async doc => {
      const data = doc.data();
      const otherPartyId = data.landlordId;
      
      // Verifica lo stato di blocco
      const blockedByMe = await isUserBlocked(userId, otherPartyId);
      const blockedByOther = await isUserBlocked(otherPartyId, userId);
      
      return {
        id: doc.id,
        ...data,
        createdAt: data.createdAt ? 
          new Date(data.createdAt.seconds * 1000) : 
          null,
        updatedAt: data.updatedAt ? 
          new Date(data.updatedAt.seconds * 1000) : 
          null,
        lastMessage: data.lastMessage ? {
          ...data.lastMessage,
          createdAt: data.lastMessage.createdAt ? 
            new Date(data.lastMessage.createdAt.seconds * 1000) : 
            null
        } : null,
        isBlocked: blockedByMe || blockedByOther,
        blockedBy: blockedByMe ? 'me' : blockedByOther ? 'other' : null
      };
    })) as Chat[];
    
    // Ordina le chat per data di aggiornamento
    allChats = [...studentChats].sort((a, b) => {
      const dateA = a.updatedAt?.getTime() || 0;
      const dateB = b.updatedAt?.getTime() || 0;
      return dateB - dateA;
    });
    callback(allChats);
  });

  const unsubscribeLandlord = onSnapshot(landlordQuery, async (snapshot) => {
    const landlordChats = await Promise.all(snapshot.docs.map(async doc => {
      const data = doc.data();
      const otherPartyId = data.studentId;
      
      // Verifica lo stato di blocco
      const blockedByMe = await isUserBlocked(userId, otherPartyId);
      const blockedByOther = await isUserBlocked(otherPartyId, userId);
      
      return {
        id: doc.id,
        ...data,
        createdAt: data.createdAt ? 
          new Date(data.createdAt.seconds * 1000) : 
          null,
        updatedAt: data.updatedAt ? 
          new Date(data.updatedAt.seconds * 1000) : 
          null,
        lastMessage: data.lastMessage ? {
          ...data.lastMessage,
          createdAt: data.lastMessage.createdAt ? 
            new Date(data.lastMessage.createdAt.seconds * 1000) : 
            null
        } : null,
        isBlocked: blockedByMe || blockedByOther,
        blockedBy: blockedByMe ? 'me' : blockedByOther ? 'other' : null
      };
    })) as Chat[];
    
    // Combina e ordina tutte le chat
    allChats = [...allChats, ...landlordChats].sort((a, b) => {
      const dateA = a.updatedAt?.getTime() || 0;
      const dateB = b.updatedAt?.getTime() || 0;
      return dateB - dateA;
    });
    
    // Rimuovi eventuali duplicati mantenendo l'ordine
    allChats = [...new Map(allChats.map(chat => [chat.id, chat])).values()];
    
    callback(allChats);
  });

  return () => {
    unsubscribeStudent();
    unsubscribeLandlord();
  };
}

export const onMessagesChange = (chatId: string, callback: (messages: Message[]) => void) => {
  const q = query(
    collection(db, 'messages'),
    where('chatId', '==', chatId),
    orderBy('createdAt', 'asc')
  );

  return onSnapshot(q, async (snapshot) => {
    const messages = [];
    for (const doc of snapshot.docs) {
      const message = { id: doc.id, ...doc.data() } as Message;
      
      // Verifica se il messaggio è stato inviato prima del blocco
      const isBlocked = await isUserBlocked(message.receiverId, message.senderId);
      
      if (isBlocked) {
        const blockTimestamp = await getBlockTimestamp(message.receiverId, message.senderId);
        // Se non c'è timestamp di blocco o il messaggio è precedente al blocco, lo includiamo
        if (!blockTimestamp || message.createdAt < blockTimestamp) {
          messages.push(message);
        }
      } else {
        messages.push(message);
      }
    }
    
    callback(messages);
  });
};

export async function sendMessage(
  chatId: string,
  senderId: string,
  senderName: string,
  content: string,
  type: 'text' | 'system' = 'text'
): Promise<void> {
  try {
    await runTransaction(db, async (transaction) => {
      // Get chat document to verify recipient
      const chatRef = doc(chatsCollection, chatId);
      const chatDoc = await transaction.get(chatRef);
      
      if (!chatDoc.exists()) {
        throw new Error('Chat not found');
      }

      const chatData = chatDoc.data();
      const recipientId = chatData.studentId === senderId ? chatData.landlordId : chatData.studentId;

      // Verifica che non si stia inviando un messaggio a se stessi
      if (senderId === recipientId) {
        throw new Error('Non puoi inviare messaggi a te stesso');
      }

      // Verifica se l'utente è bloccato
      const isBlocked = await isUserBlocked(recipientId, senderId);
      if (isBlocked) {
        throw new Error('Non puoi inviare messaggi a questo utente perché sei stato bloccato');
      }

      // Create message
      const messageRef = doc(messagesCollection);
      const messageData = {
        chatId,
        senderId,
        senderName,
        receiverId: recipientId,  // Aggiungo il receiverId per il controllo del blocco
        type,
        content,
        createdAt: serverTimestamp(),
        readBy: [senderId]
      };

      transaction.set(messageRef, messageData);

      // Update chat's last message and unread count
      const unreadCount = chatData.unreadCount || {};
      unreadCount[recipientId] = (unreadCount[recipientId] || 0) + 1;

      transaction.update(chatRef, {
        lastMessage: {
          content,
          createdAt: serverTimestamp(),
          senderId
        },
        unreadCount,
        updatedAt: serverTimestamp()
      });

      // Create notification for recipient
      await createChatMessageNotification(
        recipientId,
        chatId,
        messageRef.id,
        senderName,
        content
      );
    });
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
}

export async function markMessageAsRead(messageId: string, userId: string): Promise<void> {
  try {
    const messageRef = doc(messagesCollection, messageId);
    const messageDoc = await getDoc(messageRef);
    
    if (!messageDoc.exists()) {
      return;
    }

    const currentReadBy = messageDoc.data().readBy || [];
    if (!currentReadBy.includes(userId)) {
      await updateDoc(messageRef, {
        readBy: [...currentReadBy, userId]
      });

      // Update unread count in chat
      const chatRef = doc(chatsCollection, messageDoc.data().chatId);
      const chatDoc = await getDoc(chatRef);
      
      if (chatDoc.exists()) {
        const unreadCount = chatDoc.data().unreadCount || {};
        if (unreadCount[userId] > 0) {
          unreadCount[userId]--;
          await updateDoc(chatRef, { unreadCount });
        }
      }
    }
  } catch (error) {
    console.error('Error marking message as read:', error);
  }
}

export async function markMessagesAsRead(chatId: string, userId: string): Promise<void> {
  try {
    // Prima otteniamo tutti i messaggi della chat
    const q = query(
      messagesCollection,
      where('chatId', '==', chatId),
      where('senderId', '!=', userId)
    );

    const snapshot = await getDocs(q);
    
    if (snapshot.empty) return;

    const batch = writeBatch(db);
    let hasUnreadMessages = false;
    
    snapshot.docs.forEach(doc => {
      const data = doc.data();
      const readBy = data.readBy || [];
      if (!readBy.includes(userId)) {
        hasUnreadMessages = true;
        batch.update(doc.ref, {
          readBy: arrayUnion(userId)
        });
      }
    });

    // Esegui il batch solo se ci sono messaggi da aggiornare
    if (hasUnreadMessages) {
      await batch.commit();
      
      // Aggiorna il conteggio non letti nella chat
      const chatRef = doc(chatsCollection, chatId);
      await updateDoc(chatRef, {
        [`unreadCount.${userId}`]: 0
      });
    }

  } catch (error) {
    console.error('Errore durante la marcatura dei messaggi come letti:', error);
    throw error;
  }
}

export async function migrateExistingChats() {
  try {
    console.log('Inizio migrazione chat...');
    const snapshot = await getDocs(collection(db, 'chats'));
    console.log(`Trovate ${snapshot.size} chat da processare`);
    
    const batch = writeBatch(db);
    let updateCount = 0;
    
    snapshot.docs.forEach(doc => {
      const data = doc.data();
      console.log('Chat data:', {
        id: doc.id,
        studentId: data.studentId,
        landlordId: data.landlordId,
        status: data.status
      });
      
      if (!data.status) {
        batch.update(doc.ref, { 
          status: 'active',
          updatedAt: serverTimestamp()
        });
        updateCount++;
      }
    });

    if (updateCount > 0) {
      await batch.commit();
      console.log(`Migrate ${updateCount} chat con successo`);
    } else {
      console.log('Nessuna chat da migrare');
    }
  } catch (error) {
    console.error('Errore durante la migrazione delle chat:', error);
    throw error;
  }
}
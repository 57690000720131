import { useState, useEffect } from 'react';
import { onChatsChange } from '../lib/chat';
import { useAuthContext } from '../contexts/AuthContext';

export function useUnreadChatsCount() {
  const [unreadCount, setUnreadCount] = useState(0);
  const { userProfile } = useAuthContext();

  useEffect(() => {
    if (!userProfile?.id) {
      setUnreadCount(0);
      return;
    }

    const unsubscribe = onChatsChange(userProfile.id, (chats) => {
      const totalUnread = chats.reduce((acc, chat) => {
        if (!chat.lastMessage) return acc;
        
        // Verifica se l'ultimo messaggio non è dell'utente corrente
        const isLastMessageFromOther = chat.lastMessage.senderId !== userProfile.id;
        const unreadForUser = isLastMessageFromOther ? (chat.unreadCount?.[userProfile.id] || 0) : 0;
        
        return acc + unreadForUser;
      }, 0);
      
      setUnreadCount(totalUnread);
    });

    return () => unsubscribe();
  }, [userProfile?.id]);

  return unreadCount;
} 
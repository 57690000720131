import { db, storage } from './firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, getDocs, doc, getDoc, addDoc, updateDoc, deleteDoc, query, orderBy, where, limit as firestoreLimit } from 'firebase/firestore';

export interface BlogPost {
  id: string;
  title: string;
  excerpt: string;
  content: string;
  coverImage: string;
  category: string;
  readTime: number;
  publishedAt: string;
  url: string;
}

// Ottiene tutti i post del blog
export const getBlogPosts = async (): Promise<BlogPost[]> => {
  try {
    const q = query(
      collection(db, 'blogPosts'),
      orderBy('publishedAt', 'desc')
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    } as BlogPost));
  } catch (error) {
    console.error('Error getting blog posts:', error);
    throw error;
  }
};

// Ottiene un singolo post del blog tramite ID
export const getBlogPost = async (id: string): Promise<BlogPost> => {
  try {
    const docRef = doc(db, 'blogPosts', id);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists()) {
      throw new Error('Post non trovato');
    }

    return {
      id: docSnap.id,
      ...docSnap.data()
    } as BlogPost;
  } catch (error) {
    console.error('Error getting blog post:', error);
    throw error;
  }
};

// Ottiene un singolo post del blog tramite URL
export const getBlogPostByUrl = async (url: string): Promise<BlogPost> => {
  try {
    const q = query(
      collection(db, 'blogPosts'),
      where('url', '==', url)
    );
    const querySnapshot = await getDocs(q);
    
    if (querySnapshot.empty) {
      throw new Error('Post non trovato');
    }

    const doc = querySnapshot.docs[0];
    return {
      id: doc.id,
      ...doc.data()
    } as BlogPost;
  } catch (error) {
    console.error('Error getting blog post:', error);
    throw error;
  }
};

// Funzione per generare l'URL SEO-friendly
export const generateSlug = (title: string): string => {
  return title
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-z0-9\s-]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
    .trim();
};

const processImageUrl = async (imageUrl: string): Promise<string> => {
  try {
    // Se è già un URL di Firebase Storage, lo restituiamo direttamente
    if (imageUrl.includes('firebasestorage.googleapis.com')) {
      return imageUrl;
    }

    // Se è un blob URL, dobbiamo caricarlo su Firebase Storage
    if (imageUrl.startsWith('blob:')) {
      console.log('Caricamento blob immagine su Storage:', imageUrl);
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      
      // Genera un nome file unico
      const fileName = `blog/${Date.now()}-${Math.random().toString(36).substring(7)}.jpg`;
      const storageRef = ref(storage, fileName);
      
      // Verifica che il blob sia effettivamente un'immagine
      if (!blob.type.startsWith('image/')) {
        throw new Error('Il file non è un\'immagine valida');
      }
      
      // Carica il blob
      await uploadBytes(storageRef, blob, {
        contentType: blob.type
      });
      
      const downloadUrl = await getDownloadURL(storageRef);
      console.log('URL immagine caricata:', downloadUrl);
      return downloadUrl;
    }

    // Se è un URL normale, lo restituiamo così com'è
    return imageUrl;
  } catch (error) {
    console.error('Errore nel processamento dell\'immagine:', error);
    throw error;
  }
};

// Crea un nuovo post del blog
export const createBlogPost = async (post: Omit<BlogPost, 'id' | 'url' | 'publishedAt'>): Promise<string> => {
  try {
    console.log('Creazione post con immagine:', post.coverImage);
    const processedImageUrl = await processImageUrl(post.coverImage);
    const url = generateSlug(post.title);
    
    const docRef = await addDoc(collection(db, 'blogPosts'), {
      ...post,
      coverImage: processedImageUrl,
      url,
      publishedAt: new Date().toISOString()
    });
    
    console.log('Post creato con successo, ID:', docRef.id);
    return docRef.id;
  } catch (error) {
    console.error('Error creating blog post:', error);
    throw error;
  }
};

// Aggiorna un post del blog esistente
export const updateBlogPost = async (id: string, post: Partial<BlogPost>): Promise<void> => {
  try {
    console.log('Aggiornamento post con ID:', id);
    const updates = { ...post };
    
    if (post.coverImage) {
      updates.coverImage = await processImageUrl(post.coverImage);
    }
    
    if (post.title) {
      updates.url = generateSlug(post.title);
    }
    
    const docRef = doc(db, 'blogPosts', id);
    await updateDoc(docRef, updates);
    console.log('Post aggiornato con successo');
  } catch (error) {
    console.error('Error updating blog post:', error);
    throw error;
  }
};

// Elimina un post del blog
export const deleteBlogPost = async (id: string): Promise<void> => {
  try {
    const docRef = doc(db, 'blogPosts', id);
    await deleteDoc(docRef);
  } catch (error) {
    console.error('Error deleting blog post:', error);
    throw error;
  }
};

// Ottiene gli ultimi N post del blog
export const getRecentBlogPosts = async (limitNum: number = 3): Promise<BlogPost[]> => {
  try {
    const q = query(
      collection(db, 'blogPosts'),
      orderBy('publishedAt', 'desc'),
      firestoreLimit(limitNum)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    } as BlogPost));
  } catch (error) {
    console.error('Error getting recent blog posts:', error);
    throw error;
  }
}; 
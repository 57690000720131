import React, { useState } from 'react';
import { Mail, MessageCircle, Send, HelpCircle, ChevronDown, ChevronUp, ArrowRight } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { toast } from 'react-hot-toast';

const FAQItem = ({ question, answer, isOpen, onClick }: {
  question: string;
  answer: string;
  isOpen: boolean;
  onClick: () => void;
}) => (
  <div className="border-b border-gray-200 last:border-0">
    <button
      className="w-full py-4 flex justify-between items-center text-left"
      onClick={onClick}
    >
      <span className="font-medium text-gray-900">{question}</span>
      {isOpen ? (
        <ChevronUp className="h-5 w-5 text-gray-500" />
      ) : (
        <ChevronDown className="h-5 w-5 text-gray-500" />
      )}
    </button>
    {isOpen && (
      <div className="pb-4 text-gray-600">
        {answer}
      </div>
    )}
  </div>
);

const getFaqs = (isLoggedIn: boolean) => {
  const commonFaqs = [
    {
      question: "Come funziona Unistanza?",
      answer: "Unistanza è una piattaforma che connette studenti e proprietari di immobili. Gli studenti possono cercare alloggi verificati e i proprietari possono pubblicare i loro annunci in modo sicuro."
    },
    {
      question: "Come vengono verificati gli utenti?",
      answer: "Verifichiamo l'identità degli utenti attraverso email universitaria per gli studenti e documenti di identità per i proprietari."
    }
  ];

  const loggedOutFaqs = [
    {
      question: "Come posso registrarmi?",
      answer: "Puoi registrarti gratuitamente cliccando sul pulsante 'Registrati' in alto a destra. Scegli se registrarti come studente o proprietario e segui la procedura guidata."
    },
    {
      question: "È gratuito utilizzare Unistanza?",
      answer: "La registrazione e la ricerca di alloggi sono completamente gratuite per gli studenti. I proprietari possono pubblicare annunci acquistando crediti nell'area shop."
    },
    {
      question: "Posso visitare gli alloggi prima di registrarmi?",
      answer: "Puoi visualizzare tutti gli annunci disponibili, ma per contattare i proprietari e prenotare visite è necessario registrarsi."
    }
  ];

  const loggedInFaqs = [
    {
      question: "Come posso modificare i miei dati personali?",
      answer: "Puoi modificare i tuoi dati personali accedendo alla sezione Profilo nella tua dashboard personale."
    },
    {
      question: "Come funziona il sistema di messaggistica?",
      answer: "Il sistema di messaggistica integrato permette una comunicazione diretta tra studenti e proprietari dopo l'accettazione di una candidatura."
    },
    {
      question: "Come posso ricevere assistenza?",
      answer: "Puoi aprire un ticket di supporto dalla tua dashboard personale o utilizzare il form di contatto in questa pagina."
    }
  ];

  return [...commonFaqs, ...(isLoggedIn ? loggedInFaqs : loggedOutFaqs)];
};

export function Support() {
  const { user, userProfile } = useAuthContext();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);
  const [openFaqIndex, setOpenFaqIndex] = useState<number | null>(null);

  const supportChannels = [
    {
      icon: MessageCircle,
      title: "Chat di Supporto",
      description: "Accedi alla tua area personale per aprire un ticket di supporto",
      action: user ? "/dashboard/student?tab=profile" : "/login",
      buttonText: user ? "Apri Ticket" : "Accedi",
      primary: true
    },
    {
      icon: Mail,
      title: "Email",
      description: "Scrivici per qualsiasi domanda o richiesta",
      action: "mailto:info@unistanza.it",
      buttonText: "Invia Email",
      primary: false
    }
  ];

  const faqs = getFaqs(!!user);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      // Qui implementare la logica di invio del form
      await new Promise(resolve => setTimeout(resolve, 1000)); // Simulazione
      toast.success('Messaggio inviato con successo!');
      setFormData({ name: '', email: '', subject: '', message: '' });
    } catch (error) {
      toast.error('Errore durante l\'invio del messaggio');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Header */}
        <div className="text-center mb-12">
          <HelpCircle className="h-12 w-12 text-blue-600 mx-auto mb-4" />
          <h1 className="text-3xl font-bold text-gray-900">Centro Assistenza</h1>
          <p className="mt-4 text-lg text-gray-600">
            Come possiamo aiutarti oggi?
          </p>
        </div>

        {/* Canali di supporto */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-16">
          {supportChannels.map((channel) => (
            <div
              key={channel.title}
              className="bg-white rounded-lg shadow-sm p-6 flex flex-col items-center text-center"
            >
              <channel.icon className="h-8 w-8 text-blue-600 mb-4" />
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                {channel.title}
              </h3>
              <p className="text-gray-600 mb-6 flex-grow">
                {channel.description}
              </p>
              {channel.action.startsWith('/') ? (
                <Link
                  to={channel.action}
                  className={`w-full py-2 px-4 rounded-md text-center transition-colors ${
                    channel.primary
                      ? 'bg-blue-600 text-white hover:bg-blue-700'
                      : 'border border-blue-600 text-blue-600 hover:bg-blue-50'
                  }`}
                >
                  {channel.buttonText}
                </Link>
              ) : (
                <a
                  href={channel.action}
                  className={`w-full py-2 px-4 rounded-md text-center transition-colors ${
                    channel.primary
                      ? 'bg-blue-600 text-white hover:bg-blue-700'
                      : 'border border-blue-600 text-blue-600 hover:bg-blue-50'
                  }`}
                >
                  {channel.buttonText}
                </a>
              )}
            </div>
          ))}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Form di contatto */}
          <div className="bg-white rounded-lg shadow-sm p-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              Contattaci
            </h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                    Nome completo
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
                  Oggetto
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                  Messaggio
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows={6}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <button
                type="submit"
                disabled={loading}
                className="w-full md:w-auto inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? (
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                ) : (
                  <>
                    <Send className="h-5 w-5 mr-2" />
                    Invia messaggio
                  </>
                )}
              </button>
            </form>
          </div>

          {/* FAQ Accordion */}
          <div className="bg-white rounded-lg shadow-sm p-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              Domande Frequenti
            </h2>
            <div className="divide-y divide-gray-200">
              {faqs.map((faq, index) => (
                <FAQItem
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                  isOpen={openFaqIndex === index}
                  onClick={() => setOpenFaqIndex(openFaqIndex === index ? null : index)}
                />
              ))}
            </div>
            
            {/* Link to FAQ page */}
            <div className="mt-8 text-center">
              <p className="text-gray-600 mb-2">Hai altre domande?</p>
              <Link 
                to="/faq" 
                className="inline-flex items-center text-blue-600 hover:text-blue-700 font-medium"
              >
                Visita la pagina FAQ completa
                <ArrowRight className="ml-2 h-4 w-4" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 